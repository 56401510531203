
/* injects from baggage-loader */

'use strict';

export default class IssuerOnboardingFinalizeController {

	constructor($log, $state, $window, $timeout, Alerts) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$window = $window;
		this.$timeout = $timeout;
		this.Alerts = Alerts;
		this.savingForm = false;

		this.certifications = [{
			checked: false,
			name: 'backgroundChecks',
			statement: 'I, the Issuer, authorize Common Owner CF LLC and/or its agents, successors and assigns to conduct any such background checks (including Rule 506 "Bad Actor" checks and any other such checks deemed necessary or appropriate by Common Owner CF LLC) for both the Issuer and any Principals and/or Affiliated Persons of the Issuer.'
		}, {
			checked: false,
			name: 'disqualifyingEventCertification',
			statement: 'I, the Issuer certify that I have not been subject to a Disqualifying Event as set forth in: <a href="https://www.law.cornell.edu/cfr/text/17/227.100" target="_blank">17 CFR 227.100 et seq.</a>'
		},
		{
			checked: false,
			name: 'reviewedIssuerRequirements',
			statement: 'I, the Issuer, acknowledge that I have reviewed and am familiar with the Issuer Requirements set for at: <a href="https://www.law.cornell.edu/uscode/text/15/77d-1" target="_blank">15 USC 77d-1(b)</a>, including the limitations on advertising the offering, rules regarding compensating promoters, and reports of operations and financial statements.'
		}]

		this.prevStep = 'issuer-onboarding.records';
		this.nextStep = 'onboarding-confirmation';

		this.currentStepName = 'finalize';

	}

	$onInit() {

		if (this.onboarding.get('certifications')) {
			this.certifications = this.onboarding.get('certifications');
		}

	}

	checkboxClicked(item, event) {
		if (event) {
			event.stopPropagation();
			if (event.target.tagName === 'A') return false;
		}
		item.checked = !item.checked;
		if (item.checked) {
			item.acceptedAt = new Date();
		} else {
			item.acceptedAt = undefined;
		}
	}

	isFormComplete() {
		for (let cert of this.certifications) {
			if (cert.checked === false) {
				return false;
			}
		}
		return true;
	}

	async saveForm() {

		try {

			this.savingForm = true;
			this.Alerts.clearLocal();
			this.$timeout();

			await this.onboarding.save({
				certifications: this.certifications,
				submitted: true,
				lastCompletedStep: this.currentStepName
			});

			// Take the user to the next step
			this.$state.go(this.nextStep, { id: this.onboarding.id })
			this.savingForm = false;

		} catch (err) {

			this.savingForm = false;
			this.onboarding.revert();

			let error = err.toString().replace('ParseError: 141 ', '');
			if (error === 'INCOMPLETE_FORM') {
				// TODO: show error, redirect to incomplete step
				this.$log.error(error)
				// Show error
				this.Alerts.setLocal({
					type: 'danger',
					msg: 'Please complete all steps in the onboarding process before submitting.'
				});
			}

			this.$timeout();

		}

	}

}
