
/* injects from baggage-loader */

// Load font awesome core
import { library, dom } from "@fortawesome/fontawesome-svg-core";

// Solid icons
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons/faSlidersH";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faColumns } from "@fortawesome/free-solid-svg-icons/faColumns";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faToolbox } from "@fortawesome/free-solid-svg-icons/faToolbox";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons/faCloudUploadAlt";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faGripLines } from "@fortawesome/free-solid-svg-icons/faGripLines";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faUniversity } from "@fortawesome/free-solid-svg-icons/faUniversity";
import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons/faHandHoldingUsd";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons/faShareAlt";
import { faBookmark as faBookmarkSolid } from "@fortawesome/free-solid-svg-icons/faBookmark";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUser as faUserSolid } from "@fortawesome/free-solid-svg-icons/faUser";
import { faCertificate } from "@fortawesome/free-solid-svg-icons/faCertificate";
import { faFlag } from "@fortawesome/free-solid-svg-icons/faFlag";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons/faMapMarker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons/faCalendar";
import { faListAlt } from "@fortawesome/free-solid-svg-icons/faListAlt";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faPrint } from "@fortawesome/free-solid-svg-icons/faPrint";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faUndo } from "@fortawesome/free-solid-svg-icons/faUndo";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { faListUl } from "@fortawesome/free-solid-svg-icons/faListUl";
import { faListOl } from "@fortawesome/free-solid-svg-icons/faListOl";
import { faStrikethrough } from "@fortawesome/free-solid-svg-icons/faStrikethrough";
import { faUnderline } from "@fortawesome/free-solid-svg-icons/faUnderline";
import { faItalic } from "@fortawesome/free-solid-svg-icons/faItalic";
import { faBold } from "@fortawesome/free-solid-svg-icons/faBold";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons/faQuoteRight";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons/faAlignLeft";
import { faAlignRight } from "@fortawesome/free-solid-svg-icons/faAlignRight";
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons/faAlignCenter";
import { faIndent } from "@fortawesome/free-solid-svg-icons/faIndent";
import { faOutdent } from "@fortawesome/free-solid-svg-icons/faOutdent";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faUniversity as faBank } from '@fortawesome/free-solid-svg-icons/faUniversity';
import { faWallet } from "@fortawesome/free-solid-svg-icons/faWallet";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";


// Regular icons
import { faHandshake } from "@fortawesome/free-regular-svg-icons/faHandshake";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons/faFileAlt";
import { faCircle as faCircleRegular } from "@fortawesome/free-regular-svg-icons/faCircle";
import { faUser } from "@fortawesome/free-regular-svg-icons/faUser";
import { faFile } from "@fortawesome/free-regular-svg-icons/faFile";
import { faFileImage } from "@fortawesome/free-regular-svg-icons/faFileImage";
import { faImage } from "@fortawesome/free-regular-svg-icons/faImage";
import { faComment } from "@fortawesome/free-regular-svg-icons/faComment";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faEye } from "@fortawesome/free-regular-svg-icons/faEye";
import { faBookmark as faBookmarkRegular } from "@fortawesome/free-regular-svg-icons/faBookmark";
import { faArrowAltCircleDown } from "@fortawesome/free-regular-svg-icons/faArrowAltCircleDown";
import { faBell } from "@fortawesome/free-regular-svg-icons/faBell";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons/faEyeSlash";
import { faClipboard} from "@fortawesome/free-regular-svg-icons/faClipboard";
 
// Brand icons
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons/faFacebookMessenger";
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";

// Set config options
// config.replacementClass = '';
// config.observeMutations = true;

faBank.iconName = 'bank';

// Add icons to the library
library.add(
  // Solids
  faChevronLeft, faCircleNotch, faCircle, faEnvelope, faDollarSign, faHome, faSlidersH,
  faCheckCircle, faColumns, faList, faUser, faChartBar, faToolbox, faExternalLinkAlt, faBars,
  faTrash, faEllipsisV, faCloudUploadAlt, faCheck, faTimes, faGripLines, faInfoCircle,
  faUniversity, faHandHoldingUsd, faFilePdf, faMapMarkerAlt, faExclamationCircle, faShareAlt,
  faBookmarkSolid, faPhone, faUsers, faUserSolid, faCertificate, faFlag, faMapMarker, faCalendar,
  faListAlt, faChevronDown, faPrint, faPencilAlt, faUndo, faSpinner, faCopy, faRedo, faBan, faListOl,
  faListUl, faStrikethrough, faUnderline, faItalic, faBold, faQuoteRight, faAlignLeft, faAlignCenter,
  faAlignRight, faIndent, faOutdent, faCode, faLink, faPlus, faCog, faChevronRight, faExclamationTriangle,
  faBank, faWallet, faQuestionCircle, faArrowRight, faAngleDown,
  // Regular
  faHandshake, faFileAlt, faCircleRegular, faFile, faImage, faComment, faClock, faEye, faBookmarkRegular,
  faFileImage, faArrowAltCircleDown, faBell, faEyeSlash, faClipboard,
  // Brands
  faFacebookSquare, faTwitter, faLinkedin, faFacebookF, faFacebookMessenger, faYoutube,
);
dom.watch();

// Show the svg data for the icon
// Useful for creating .svg files and importing directly
// const plus = findIconDefinition({iconName: 'plus'})
// console.log(icon(faTimes).html)