
/* injects from baggage-loader */

'use strict';

export default class OnboardingEntryController {

	constructor($log, $state, $location, OnboardingSchema, User, FormBuilder, ISSUER_ONBOARDING_DOCUMENT_URL, OFFERING_PREP_DOCUMENT_URL) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$location = $location;
		this.User = User;
		this.OnboardingSchema = OnboardingSchema;
		this.emailValidator = FormBuilder.Validators.email;

		this.onboardinMaterialsUrl = ISSUER_ONBOARDING_DOCUMENT_URL;
		this.offeringPrepMaterialsUrl = OFFERING_PREP_DOCUMENT_URL;

		this.creatingOnboarding = false;
		this.invalidEmailAddress = false;
		this.emailAddress = '';

		// TODO: autofill email with users account

	}

	$onInit() {

		// TODO: load and display incomplete onboarding applications 

	}

	async createOnboarding() {
		this.invalidEmailAddress = false;

		// validate email address format
		if (!this.emailValidator.validate(this.emailAddress)) {
			this.invalidEmailAddress = true;
			return;
		}

		// Check that a user is logged in
		// NOTE: allow unregistered users to begin the onboarding process
		// if (!this.User.isUserLoggedIn()) {
		// 	this.$state.go('login', { ref: this.$location.url().toString() });
		// 	return false;
		// }

		this.creatingOnboarding = true;

		if (this.User.isUserLoggedIn()) {
			// Create a new onboarding application object and redirect to the onboarding wizard
			let onboarding = new this.OnboardingSchema();
			await onboarding.save({
				email_address: this.emailAddress,
				creator: this.User.getCurrent()
			});

			this.$state.go('onboarding-application', {
				id: onboarding.id
			});
			return;
		}

		// Go to application page where user signup will be captured as part of the
		//  onboarding process.
		this.$state.go('onboarding-application', {
			id: 'new',
			email: this.emailAddress
		});

	}

}
