
/* injects from baggage-loader */

'use strict';

import ProjectManagerInvestmentsComponent from './projectmanager.investments.component';

const ProjectManagerInvestmentsPageModule = angular.module('project-manager-investments-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.investments', {
        url: '/investments',
        component: 'projectManagerInvestments',
        title: 'Project Manager: Investments',
        localTitle: 'Investments',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          investments: function (project, Investments) {
            return Investments.listForProject(project);
          },
          offering: function (project, Parse, $q) {
            if (project.raise_id) {
              return Parse.Cloud.run('getOffering', { id: project.raise_id, project_id: project.id })
            } else {
              return $q.resolve({});
            }
          }
        }
      });
  })
  .component('projectManagerInvestments', new ProjectManagerInvestmentsComponent());

export default ProjectManagerInvestmentsPageModule;
