
/* injects from baggage-loader */

'use strict';

import PhotoIdUploadController from './photoidupload.controller';
import PhotoIdUploadTemplate from './photoidupload.html';

export default class PhotoIdUploadComponent {
		constructor() {
				this.templateUrl = PhotoIdUploadTemplate;
				this.controller = PhotoIdUploadController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					investor: '<',
					investment: '<',
					successCallback: '&',
					errorCallback: '&'
				};
		}
}
