
/* injects from baggage-loader */

'use strict';

import ModalComponentController from './modalcomponent.controller';
import ModalComponentTemplate from './modalcomponent.html';

export default class ModalComponentComponent {
	constructor() {
		this.templateUrl = ModalComponentTemplate;
		this.controller = ModalComponentController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			resolve: '<',
			close: '&',
			dismiss: '&'
		};
	}
}
