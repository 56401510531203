
/* injects from baggage-loader */

'use strict';

import InvestConfirmationNorthCapController from './invest-confirmation-north-cap.controller';
import InvestConfirmationNorthCapTemplate from './invest-confirmation-north-cap.html';

export default class InvestConfirmationNorthCapComponent {
		constructor() {
				this.templateUrl = InvestConfirmationNorthCapTemplate;
				this.controller = InvestConfirmationNorthCapController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					issuer: '<',
					sponsor: '<',
					investment: '<',
					investor: '<',
					sidePanelData: '<',
					updateSummaryData: '&'
				};
		}
}
