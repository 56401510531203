
/* injects from baggage-loader */

'use strict';

import LoginController from './login.controller';
import LoginTemplate from './login.html';

export default class LoginComponent {
		constructor() {
				this.templateUrl = LoginTemplate;
				this.controller = LoginController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
