
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingDocumentsComponent from './issuer-onboarding.documents.component';

const IssuerOnboardingDocumentsPageModule = angular.module('issuer-onboarding-documents-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('issuer-onboarding.documents', {
        url: '/documents',
        title: 'Issuer Onboarding: Organizational Documents',
        localTitle: 'Organizational Documents',
        component: 'issuerOnboardingDocuments',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          documents: function(onboarding, OnboardingDocumentSchema) {
            let query = onboarding.relation("documents").query();
            query.doesNotExist('identifier')
            return query.find();
          }
        }
      });
  })
  .component('issuerOnboardingDocuments', new IssuerOnboardingDocumentsComponent());

export default IssuerOnboardingDocumentsPageModule;
