
/* injects from baggage-loader */

'use strict';

export default class InvestorProfileListItemController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.selected = null;

	}

	$onInit() {

	}

	getIsSelected() {
		return this.selected && this.profile.id === this.selected.id;
	}

	getInvestorName() {
		return [
			this.profile.first_name,
			this.profile.middle_initial,
			this.profile.last_name,
		].filter(n => n).join(' ')
	}

	getInvestorType() {
		return this.profile.type;
	}

	getLastInvestedDate() {
		return this.profile.lastInvestedAt;
	}

	getInvestorNote() {
		return this.profile.note;
	}

}
