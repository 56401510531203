
/* injects from baggage-loader */

'use strict';

import InvestController from './invest.controller';
import InvestTemplate from './invest.html';

export default class InvestComponent {
		constructor() {
				this.templateUrl = InvestTemplate;
				this.controller = InvestController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					documents: '<',
					investment: '<',
					investor: '<'
				};
		}
}
