
/* injects from baggage-loader */

'use strict';

import AccountDocumentsController from './account.documents.controller';
import AccountDocumentsTemplate from './account.documents.html';

export default class AccountDocumentsComponent {
		constructor() {
				this.templateUrl = AccountDocumentsTemplate;
				this.controller = AccountDocumentsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					documents: '<'
				};
		}
}
