
/* injects from baggage-loader */

'use strict';

export default class ProjectCommentController {

	constructor($log, User, $rootScope, $timeout, ProjectCommentSchema) {
		'ngInject';

		this.$log = $log;
		this.$rootScope = $rootScope;
		this.timeout = $timeout;
		this.User = User;

		// State of a comment being flagged
		this.flagged = false;
		this.isDeleted = false;

		this.comments = [];
		this.showCommentBox = false;

		this.isProjectView = true;

		this.authorTag = '';
		this.authorName = '[Deleted User]';
		this.authorInitials = '?';
		this.isPromoter = false;
		this.isPromoterCompensated = false;

		this.parent = {};
		this.project = {};
		this.replies = [];

	}

	$onInit() {
		// this.$log.debug(this.scope);
		this.id = `comment-${this.comment.id}`;

		this.selected = false;
		this.$rootScope.$on('commentClicked', (event, ctx) => {
			if (!this.isProjectView) {
				this.selected = this.comment.id === ctx.comment.id;
			}
		});

		this.loadState();

	}


	loadState() {
		if (this.scope === 'admin') {
			this.isProjectView = false;
		}

		// Setup author tag based on view
		if (this.scope === 'admin' && this.User.getCurrent() && this.comment.author.id === this.User.getCurrent().profile.id) {
			this.authorTag = 'You';
		} else {
			this.authorTag = this.comment.get('author_tag');
		}

		// Setup author name
		let author = this.comment.get('author');
		if (author) {
			this.authorName = this.authorInitials = `${author.get('first_name')} ${author.get('last_name')}`;
		}

		this.isDeleted = this.comment.get('deleted');

		this.ts = this.comment.createdAt;
		this.text = this.comment.get('text');
		this.parent = this.comment.get('parent');
		this.project = this.comment.get('project');
		this.isPromoter = this.comment.get('isPromoter');
		this.isPromoterCompensated = this.comment.get('isPromoterCompensated');

		this.loadReplies();
	}


	/**
	 * Reveal the comment box
	 */
	reply() {
		this.showCommentBox = true;
	}

	loadReplies() {
		this.replies = this.comments.filter(comment => comment.get('parent') ? comment.get('parent').id === this.comment.id : false);
	}

	clicked(e) {
		this.$rootScope.$emit('commentClicked', {
			comment: this.comment,
			callbacks: {
				delete: () => this.deleteComment(),
				openCommentBox: () => this.showCommentBox = true,
				markRead: () => this.markRead()
			}
		});
	}

	markRead() {
		this.comment.save({ unread: false });
		this.$rootScope.$emit('commentMarkedRead');
	}

	deleteComment() {
		this.$log.debug('Delete comment', this.comment.id, 'index', this.comments.indexOf(this.comment));
		// this.comments.splice(this.comments.indexOf(this.comment), 1);
		// this.loadReplies();
		// this.timeout();
		this.comment.set('deleted', true)
		this.comment.save().then(comment => {
			this.$log.debug('Comment deleted', comment.id);
		})
		this.loadState();
	}

	/**
	 * Reports the comment as containing spam and or inappropriate/irrelevant contnet to the project
	 */
	flagInappropriate() {

		// After comment is flagged once by the current user, don't allow them todo it again
		if (this.flagged === true) {
			return false;
		}

		this.$log.debug('Comment flagged')
		this.flagged = true;

		/*
				FLAGGING A COMMENT PROCESS

				1. Someone clicks the comment "flag"
				2. They are prompted to click a "reason" that pops up in the modal
				3. A flag is created in the "Comment_Flags" collection with a ts, reason, and pointer to the comment
				4. A notification is sent to our system admins and possibly the project creator to review the comment
				5. Action can be taken to remove or mediate the comment

				OUR TERMS AND CONDITIONS SHOULD CLEARLY STATE OUR COMMENT ABUSE/SPAM POLICY
				
				"* All comments are subject to review under our Terms & Conditions."

		*/
	}

}
