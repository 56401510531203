
/* injects from baggage-loader */

'use strict';

import ProjectManagerMediaController from './projectmanager.media.controller';
import ProjectManagerMediaTemplate from './projectmanager.media.html';

export default class ProjectManagerMediaComponent {
	constructor() {
		this.templateUrl = ProjectManagerMediaTemplate;
		this.controller = ProjectManagerMediaController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			project: '<',
			media: '<'
		};
	}
}
