
/* injects from baggage-loader */

'use strict';

import AnimatedSuccessCircleComponent from './animated-success-circle.component';
import './animated-success-circle.scss';

const AnimatedSuccessCircleModule = angular.module('animated-success-circle-module', []);

AnimatedSuccessCircleModule
    .component('animatedSuccessCircle', new AnimatedSuccessCircleComponent());

export default AnimatedSuccessCircleModule;

