
/* injects from baggage-loader */

'use strict';

export default function (app) {

	// Error definitions
	app.constant('Errors', {

		'ProjectNotFound': {
			message: 'The requested project was not found',
			redirectTo: 'projects'
		}

	});

}