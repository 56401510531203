
/* injects from baggage-loader */

'use strict';

import ImageGalleryModalController from './imagegallerymodal.controller';
import ImageGalleryModalTemplate from './imagegallerymodal.html';

export default class ImageGalleryModalComponent {
		constructor() {
				this.templateUrl = ImageGalleryModalTemplate;
				this.controller = ImageGalleryModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
