
/* injects from baggage-loader */

'use strict';

export default class AccountLogoutController {

	constructor($log, $stateParams, User) {
		'ngInject';

		this.$log = $log;
		this.$query = $stateParams;
		this.UserService = User;

	}

	$onInit() {
		
		// Logout the current user and return to the home page
		this.UserService.logout(this.$query.ref);

	}

}
