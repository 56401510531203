
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectCommentSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let ProjectComment = Parse.Object.extend('Project_Comments');

		// Define 'ProjectComment' scheme
		let schema = [
			'author',
			'approved',
			'author_tag',
			'parent',
			'project',
			'text',
			'unread',
			'deleted',
			'isPromoter',
			'isPromoterCompensated'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(ProjectComment.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProjectComment;

	});
}

