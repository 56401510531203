
/* injects from baggage-loader */

'use strict';

import EditDocumentModalController from './editdocumentmodal.controller';
import EditDocumentModalTemplate from './editdocumentmodal.html';

export default class EditDocumentModalComponent {
		constructor() {
				this.templateUrl = EditDocumentModalTemplate;
				this.controller = EditDocumentModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
