
/* injects from baggage-loader */

'use strict';

import InvestmentCheckoutController from './investment-checkout.controller';
import InvestmentCheckoutTemplate from './investment-checkout.html';

export default class InvestmentCheckoutComponent {
		constructor() {
				this.templateUrl = InvestmentCheckoutTemplate;
				this.controller = InvestmentCheckoutController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					data: '=',
					project: '=',
					documents: '<'
				};
		}
}
