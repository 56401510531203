
/* injects from baggage-loader */

'use strict';

export default class CancelInvestmentModalController {

	constructor($log, $timeout, Parse) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.Parse = Parse;

		this.cancelingInvestment;
		this.errorMsg;
		this.confirmCancel;

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;

	}

	isRegCf() {
		return this.context.type === 'cf';
	}

	ok() {

		if (!this.confirmCancel) {
			this.confirmCancel = true;
			return false;
		}

		this.errorMsg = '';
		this.cancelingInvestment = true;

		this.Parse.Cloud.run('cancelInvestment', this.context)
			.then(res => {
				// this.$log.debug(res);
				this.close({ $value: true });
			})
			.catch(err => {
				this.cancelingInvestment = false;
				// this.$log.error(err.message);
				this.errorMsg = err.message;
				this.$timeout()
			});

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
