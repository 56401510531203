
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProfileSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Profile = Parse.Object.extend('Profiles');


		// Define 'Profile' scheme
		let schema = [
			'first_name',
			'last_name',
			'address_line_1',
			'address_line_2',
			'city',
			// 'state',
			'postal_code',
			'country',
			'phone',
			'email',
			'dob',
			'role',
			'image',
			'company'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Profile.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});
    
    return Profile;
		
	});
}

