
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('NotificationSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Notification = Parse.Object.extend('Notifications');


		// Define 'Notification' schema
		let schema = [
			'message',
			'sender_name',
			'sender_avatar',
			'unread',
			'target'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Notification.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
    });
    
    return Notification;
		
	});
}