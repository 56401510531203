
/* injects from baggage-loader */

'use strict';

import InvestVerifyComponent from './invest.verify.component';

const InvestVerifyPageModule = angular.module('invest-verify-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('invest.verify', {
        url: '/verify',
        component: 'investVerify',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          // TODO: can we modify this or use a run block with some combination to prevent this transition
          // and redirect back to the commitment form
          // _investment: function(investment, $transition$) {
          //   if (investment.isNew()) {
          //     $transition$.router.stateService.target('invest.commitment', {}, { id: $transition$.params('to').id });
          //   }
          //   return;
          // }
          // kycCheck: function(Parse, $transition$) {
          //   const investmentId = $transition$.params('to').investmentId;
          //   return Parse.Cloud.run('runKycAml', { investmentId });
          // },
          // investor: function(kycCheck) {
          //   return kycCheck.investor;
          // },
          // investment: function(kycCheck) {
          //   return kycCheck.investment;
          // }
        }
      });
  })
  .run(function($transitions, StateService) {
    $transitions.onSuccess({ to: 'invest.verify' }, transition => {
      // Show the summary panel
      StateService.investment.showSummaryPanel = true;
    });
  })
  .component('investVerify', new InvestVerifyComponent());

export default InvestVerifyPageModule;
