
/* injects from baggage-loader */

'use strict';

import ProjectUpdateController from './projectupdate.controller';
import ProjectUpdateTemplate from './projectupdate.html';

export default class ProjectUpdateComponent {
		constructor() {
				this.templateUrl = ProjectUpdateTemplate;
				this.controller = ProjectUpdateController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					update: '<',
				};
		}
}
