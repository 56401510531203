
/* injects from baggage-loader */

'use strict';

import AccountLogoutComponent from './account-logout.component';

const AccountLogoutPageModule = angular.module('account-logout-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('accountLogout', {
        url: '/account/logout?ref',
        component: 'accountLogout',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('accountLogout', new AccountLogoutComponent());

export default AccountLogoutPageModule;
