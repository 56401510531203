
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('InvestorSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Investor = Parse.Object.extend('Investors');

		// Define 'Investor' scheme
		let schema = [
			'first_name',
			'middle_initial',
			'last_name',
			'address_line_1',
			'address_line_2',
			'city',
			'state',
			'postal_code',
			'country',
			'phone_number',
			'email',
			'dob',
			'domicile',
			'type',
			'entity_type',
			'account_id',
			'party_id',
			'linked_cc',
			'linked_cc_type',
			'linked_ach',
			'linked_ach_type',
			'linked_ach_name',
			'lastInvestedAt',
			'kyc_status',
			'aml_status',
			'kyc_id',
			'kyc_questions',
			'kyc_exp',
			'kyc_verified',
			'note',
			'kyc_doc_uploaded',
			'kycDocUploadedAt',
			'kyc_document'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Investor.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});
    
    return Investor;
		
	});
}

