
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.directive('dynamicForm', DynamicFormDirective);

	function DynamicFormDirective() {
		'ngInject';

		let template = `
		<form>
		
			<div ng-repeat="group in $dform.template.fields" ng-class="{{$dform.template.class}}">

				<!-- General fields -->
				<div class="form-group" ng-class="{{group.class}}" ng-if="group.type !== 'file' && group.type !== 'select' && group.type !== 'checkbox' && group.type !== 'radio'">
						<label ng-if="group.label" for={{group.model}}>{{ group.label }}</label>
						<input type={{group.type}} ng-model="$dform.model[group.model]" class="form-control" aria-describedby={{group.describedby}}
								placeholder={{model.placeholder}} autocomplete={{group.autocomplete}} ng-change={{group.onChange()}}>
				</div>

				<!-- Checkbox and Radio -->
				<div class="form-group form-check" ng-if="group.type == 'checkbox' || group.type == 'radio'">
					<input type="checkbox" ng-model="$dform.model[group.model]" class="form-check-input" id={{group.model}}>
					<label class="form-check-label" for={{group.model}}>{{group.label}}</label>
				</div>

			</div>

			<button ng-if="$dform.template.submit" type="submit" class="btn btn-lg btn-primary my-4" ng-click="$dform.template.submit.action()" ng-disabled="$dform.template.submit.disabled()">{{$dform.template.submit.text}}</button>

		</form>
		`;

		return {
      scope: {
        ngModel: '<',
        template: '<'
      },
      template: template,
      restrict: 'E',
      controllerAs: '$dform',
      controller: controller
    };

		function controller($scope) {

			this.model = $scope.ngModel;
			this.template = $scope.template;

			// Establish defaults
			if (this.template.submit) {
				/* empty */
			}
			
		}

	}
}