
/* injects from baggage-loader */

'use strict';

import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
// import "flatpickr/dist/themes/material_blue.css";

export default function (app) {

	app.directive('datePicker', DatePickerDirective);

	function DatePickerDirective($log) {
		'ngInject';

		return {
			restrict: 'A',
			link: linkFn,
			require: 'ngModel',
      scope: {
        fpOpts: '&',
        fpOnSetup: '&'
      },
		};

		function linkFn(scope, elem, attrs, ctrl) {

        if (!flatpickr) {
          $log.warn('Unable to find any flatpickr installation');
          return;	
        }

				let vp = new flatpickr(elem[0], scope.fpOpts());

        if (scope.fpOnSetup) {
          scope.fpOnSetup({
            fpItem: vp,
            date: ctrl.$modelValue
          });
        }

        // destroy the flatpickr instance when the dom element is removed
        elem.on('$destroy', function () {
          vp.destroy();
        });
		}

	}
}