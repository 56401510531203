
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingDetailsComponent from './issuer-onboarding.details.component';

const IssuerOnboardingDetailsPageModule = angular.module('issuer-onboarding-details-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('issuer-onboarding.details', {
        url: '/details',
        title: 'Issuer Onboarding: Project Details',
        localTitle: 'Project Details',
        component: 'issuerOnboardingDetails',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('issuerOnboardingDetails', new IssuerOnboardingDetailsComponent());

export default IssuerOnboardingDetailsPageModule;
