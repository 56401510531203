
/* injects from baggage-loader */

'use strict';

import moment from 'moment';

export default function (app) {
	app.service('ProjectSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Project = Parse.Object.extend('Projects');

		// Define 'Projects' scheme
		let schema = [
			'title',
			'type',
			'description',
			'location',
			'header_media',					// Pointer to "Media" object
			'start_date',
			'end_date',
			'investment_min',
			'investment_goal_min',
			'investment_goal_max',
			'live',
			'share_price',
			'disclosure_doc',			// Pointer to "Project_Document" object
			'sections',						// Array of objects
			'comments',						// Relation to collection of "Comments" objects
			'status',
			'stats',							// Pointer to "Project_Stats" object
			'creator',						// Pointer to User that created the project 
			'street_address_1',
			'street_address_2',
			'city',
			'region',
			'country',
			'gallery',						// Relation to collection of "Media" objects
			'key_points',					// Array of objects
			'body',
			'postal_code',
			'documents',
			'raise_id',
			'notes',
			'sponsor',
			'terms_updates',			// Relation to collection of "Terms updates" object
			'is_tigermark_enabled',
			'invest_now_key',
			'slug',
			'metadata_title',
			'metadata_description',
			'metadata_image_url',
			'concluded',
			'testing',
			'valuation_cap',
			'investment_terms',
			'interest_rate',
			'issuer_name',
			'investment_suspended'
		];

		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Project.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		// Custom Class methods

		Project.prototype.getMetadataTitle = function () {
			return `${(this.metadata_title || this.title)} | Common Owner`;
		}

		Project.prototype.getMetadataDescription = function () {
			return this.metadata_description || this.description || '';
		}

		Project.prototype.getMetadataImageUrl = function (images) {
			return this.metadata_image_url || images ? (images.length ? images[0].src : '') : '';
		}

		Project.prototype.getDaysRemaining = function () {
			// let days = moment(this.get('end_date')).diff(moment(), 'days');
			// let exact = moment(this.get('end_date')).diff(moment(), 'days', true);
			// if (exact > 0 && exact < 1) {
			// 	days = 1;
			// }
			// return days > 0 ? days : 0;
			let days = Math.ceil(moment(this.get('end_date')).diff(moment(), 'days', true));
			return days > 0 ? days : 0;
		}

		Project.prototype.hasEnded = function () {
			return this.getDaysRemaining() === 0;
		}

		Project.prototype.IsTestingWaters = function () {
			return this.status === 'testing';
		}

		Project.prototype.isInvestmentSuspended = function () {
			return this.investment_suspended;
		}

		return Project;

	});
}

