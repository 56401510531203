
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('InvestorDocuments', function (Parse, User, InvestorDocumentSchema) {

		// ==================================================
		// Methods
		// ==================================================

		let InvestorDocument = InvestorDocumentSchema;

		/**
		 * Retrieve an InvestorDocument by ID
		 */
		this.get = id => {
			let query = new Parse.Query(InvestorDocument);
			return query.get(id);
		}


		/**
		 * Retrieve the investors InvestorDocuments
		 */
		this.getDocuments = investor => {
			let query = new Parse.Query(InvestorDocument);
			query.equalTo("investor", investor);
			query.ascending("createdAt");
			return query.find();
		};

		this.getPhotoIdDocument = investor => {
			let query = new Parse.Query(InvestorDocument);
			query.equalTo("investor", investor);
			query.equalTo("identifier", "kyc-photo-document");
			// Gets the latest upload
			query.ascending("createdAt");
			return query.first();
		}
		
	});
}

