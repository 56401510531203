
/* injects from baggage-loader */

'use strict';

import ProjectListItemController from './projectlistitem.controller';
import ProjectListItemTemplate from './projectlistitem.html';

export default class ProjectListItemComponent {
		constructor() {
				this.templateUrl = ProjectListItemTemplate;
				this.controller = ProjectListItemController;
				this.controllerAs = '$project';
				// Bind project data input to the controller
				this.bindings = { 
					data: '<',
					ctx: '@'
				};
		}
}
