
/* injects from baggage-loader */

'use strict';

export default class DocumentListItemController {

	constructor($log, Parse) {
		'ngInject';

		this.$log = $log;
		this.Parse = Parse;

		this.fileExists = false;

		this.document = {};

	}

	$onInit() {

		// Create get function if missing
		if (typeof this.document.get === 'undefined') {
			this.document.get = function() {}
		}

		// Set true if the file URL exists
		this.fileExists = typeof this.document.get('file') !== 'undefined' && typeof this.document.get('file').url() === 'string';	

	}


	/**
	 * Return the name of the document
	 */
	getName() {
		return this.document.get('name');
	}


	/**
	 * Return the type of document
	 */
	getType() {
		return this.document.get('type');
	}


	/**
	 * Return the document URL or false
	 */
	getFileUrl() {
		if (this.document.get('file') && this.document.get('file').url()) {
			return this.document.get('file').url();
		} else {
			return false;
		}
	}


	/**
	 * Returns the timestamp of when the document was upload
	 */
	getUploadTs() {
		return this.document.createdAt;
	}


	/**
	 * Returns the icon class for the type of document
	 */
	getIcon() {
		// if (this.document.get('type') === 'pdf') {
		// 	return 'fa-file-pdf-o';
		// } else if (this.document.get('type') === 'image') {
		// 	return 'fa-file-image-o';
		// } else {
		// 	return 'fa-file-o';
		// }
		if (this.document.get('type').includes('pdf')) {
			return 'fas fa-file-pdf';
		} else if (this.document.get('type').includes('image') || this.document.get('type').includes('img')) {
			return 'far fa-file-image';
		} else {
			return 'far fa-file-alt';
		}
	}

}
