
/* injects from baggage-loader */

'use strict';

import AccountProjectsComponent from './account.projects.component';

const AccountProjectsPageModule = angular.module('account-projects-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.projects', {
        url: '/projects',
        title: 'Account My Projects',
        component: 'accountProjects',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          projects: function(Parse, ProjectStatusSchema, ProjectSchema) {
            return Parse.Cloud.run('getMyProjects');
          }
        }
      });
  })
  .component('accountProjects', new AccountProjectsComponent());

export default AccountProjectsPageModule;
