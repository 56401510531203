
/* injects from baggage-loader */

'use strict';

import ProjectManagerAnalyticsComponent from './projectmanager.analytics.component';

const ProjectManagerAnalyticsPageModule = angular.module('project-manager-analytics-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.analytics', {
        url: '/analytics',
        title: 'Project Manager: Analytics',
        localTitle: 'Analytics',
        component: 'projectManagerAnalytics',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('projectManagerAnalytics', new ProjectManagerAnalyticsComponent());

export default ProjectManagerAnalyticsPageModule;
