
/* injects from baggage-loader */

'use strict';

import InvestmentConfirmationComponent from './investment-confirmation.component';

const InvestmentConfirmationPageModule = angular.module('investment-confirmation-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('investment-confirmation', {
        parent: 'root',
        url: '/investment-confirmation?investment_id',
        title: 'Investment Confirmed',
        component: 'investmentConfirmation',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('investmentConfirmation', new InvestmentConfirmationComponent());

export default InvestmentConfirmationPageModule;
