
/* injects from baggage-loader */

'use strict';

// import AboutRouteModule from './pages/about/about.route';
// console.log(AboutRouteModule)

// Include the aync "code split" html template
// import asyncTemplate from '!!file-loader?name=templates/[name].[ext]!./pages/async-page-example/async.html';

function routeConfig($urlRouterProvider, $locationProvider, $stateProvider) {
	'ngInject';

	// Default route
	$urlRouterProvider.otherwise('/');
	$locationProvider.html5Mode(true);

	let rootResolvePolicy = {
		when: "EAGER",
		async: "NOWAIT"
	}

	// Root state:
	// Any state listing this state as a "parent" decends from this state and
	// resolved data is passed on. 
	$stateProvider
		.state('root', {
			url: '',
			abstract: true,
			resolve: [
				// Fetch users roles
				{
					token: 'roles',
					deps: ['User', '$rootScope'],
					resolveFn: (User, $rootScope) => User.fetchRoles().then(roles =>
						$rootScope.appState.userRoles = roles
					),
					policy: rootResolvePolicy
				},
				// Check for unread notifications
				{
					token: 'hasNotifications',
					deps: ['Notifications'],
					resolveFn: Notifications => Notifications.checkForUnread(),
					policy: rootResolvePolicy
				},
				// Refresh user
				{
					token: 'refreshUser',
					deps: ['User', '$q'],
					resolveFn: (User, $q) => User.isUserLoggedIn() ? User.getCurrent().fetch() : $q.resolve(),
					policy: rootResolvePolicy
				}
			],
		})

		// .state('about', AboutRouteModule)

		// Setup "code split" async loading route
		// TODO: include "resolverProvider" in injections
		// $stateProvider
    //     .state('async', {
    //       url: '/async',
    //       templateUrl: asyncTemplate,
    //       controller: 'asyncController',
    //       resolve: {
    //         asyncPreloading: resolverProvider.asyncPagePrealoading
    //       }
    //     });

}

export default angular
	.module('index.routes', [])
	.config(routeConfig);
