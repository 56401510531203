
/* injects from baggage-loader */

'use strict';

export default class WireInstructionsModalComponentController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {

		// Set modal context
		if (this.resolve) {
			this.escrow_provider = this.resolve.escrow_provider;
			this.amount = this.resolve.amount;
			if (this.escrow_provider === 'prime_trust') {
				this.wire_details = this.resolve.wire_details;
			}
			this.issuerName = this.resolve.issuerName;
			this.investorName = this.resolve.investorName;
		}

	}

	isNorthCapitalInvestment() {
		return this.escrow_provider === 'north_capital';
	}

	isPrimeTrustInvestment() {
		return this.escrow_provider === 'prime_trust';
	}

	close() {
		this.dismiss({ $value: 'cancel' });
	}

}
