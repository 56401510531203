
/* injects from baggage-loader */

'use strict';

import CancelInvestmentModalComponent from './cancelinvestmentmodal.component';
import './cancelinvestmentmodal.scss';

const CancelInvestmentModalModule = angular.module('cancel-investment-modal-module', []);

CancelInvestmentModalModule
    .component('cancelInvestmentModal', new CancelInvestmentModalComponent());

export default CancelInvestmentModalModule;

