
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.directive('ngEnter', NgEnterDirective);

	function NgEnterDirective() {
		'ngInject';

		return {
			restrict: 'A',
			link: linkFn,
			require: 'ngModel'
		};

		function linkFn(scope, elem, attrs) {
			elem.bind("keydown", function (e) {
				if (e.which === 13) {
					if (!event.shiftKey) {
						scope.$apply(function () {
							scope.$eval(attrs.ngEnter, { 'e': e });
						});
						e.preventDefault();
					}
				}
			});
		}

	}
}