
/* injects from baggage-loader */

'use strict';

import LearnComponent from './learn.component';

const LearnPageModule = angular.module('learn-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('learn', {
        parent: 'root',
        url: '/learn',
        title: 'Learn',
        component: 'learn',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('learn', new LearnComponent());

export default LearnPageModule;
