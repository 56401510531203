
/* injects from baggage-loader */

'use strict';

import AboutComponent from './about.component';
import '../home/home.scss';

const AboutPageModule = angular.module('about-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('about', {
        parent: 'root',
        url: '/about',
        title: 'About',
        component: 'about',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          teamMembers: function (TeamMembers, TeamMembersSchema){
            return TeamMembers.list();
          }
        }
      });
  })
  .component('about', new AboutComponent());

export default AboutPageModule;
