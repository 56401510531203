
/* injects from baggage-loader */

'use strict';

import ProjectManagerInvestmentComponent from './projectmanager.investment.component';

const ProjectManagerInvestmentPageModule = angular.module('project-manager-investment-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.investment', {
        url: '/investment/:investment_id',
        component: 'projectManagerInvestment',
        title: 'Project Manager: Investment',
        localTitle: 'Investment',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          investment: function (Investments, $transition$) {
            return Investments.get($transition$.params('to').investment_id)
          },
          // messages: function (investment, MessageSchema) {
          //   let query = investment.chat.relation('messages').query();
          //   return query.descending('createdAt').find();
          // },
          documents: function (investment, InvestorDocumentSchema) {
            let query = investment.relation('documents').query();
            return query.descending('createdAt').find();
          }
        }
      });
  })
  .component('projectManagerInvestment', new ProjectManagerInvestmentComponent());

export default ProjectManagerInvestmentPageModule;
