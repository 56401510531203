
/* injects from baggage-loader */

'use strict';

import AnimatedSuccessCircleController from './animated-success-circle.controller';
import AnimatedSuccessCircleTemplate from './animated-success-circle.html';

export default class AnimatedSuccessCircleComponent {
		constructor() {
				this.templateUrl = AnimatedSuccessCircleTemplate;
				this.controller = AnimatedSuccessCircleController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
