
/* injects from baggage-loader */

'use strict';

export default function(app) {

    app.service('StateService', function () {

      this.investment = {
        showSummaryPanel: true
      }

        // let state = {};

        // this.init = (name, data) => {
        //   this.getState(name, data);
        // }

        // this.getState = (name, data) => {
        //   if (name === 'getState') {
        //     throw 'INVALID_STATE';
        //   }
        //   if (!this[name]) {
        //     throw 'INVALID_STATE';
        //   }
        //   if (!state[name]) {
        //     state[name] = this[name].init(data);
        //   }
        //   return this[name];
        // }

        // this.update = (name, data) => {
        //   if (name === 'getState') {
        //     throw 'INVALID_STATE';
        //   }
        //   if (!this[name]) {
        //     throw 'INVALID_STATE';
        //   }
        //   if (!state[name]) {
        //     console.log('init investment', data);
        //     return this[name].init(data);
        //   }
        //   return this[name].update(data);
        // }

        // *************************************
        // State modifiers
        // *************************************

        // Investment state
        // this.investment = {
        //   init: (data = {}) => Object.assign({
        //       showSummaryPanel: true
        //   }, data),
        //   get: prop => {
        //     if (prop) {
        //       return state.investment[prop];
        //     }
        //     return state.investment;
        //   },
        //   update: data => {
        //     console.log('update investment', data);
        //     state = Object.assign(state.investment, data);
        //     return state.investment;
        //   }
        // }

    });
}