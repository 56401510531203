
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerInvestmentsController {

	constructor($log, $filter) {
		'ngInject';

		this.$log = $log;
		this.$filter = $filter;

	}

	$onInit() {
		this.$log.debug(this.offering);

		this.widgets = [
			// {
			// 	label: `Investment${this.investments.length === 1 ? '' : 's'}`,
			// 	icon: 'fa-handshake-o',
			// 	gradient: 'widget-0',
			// 	data: this.investments.length,
			// 	visible: true
			// },
			{
				label: 'Funds Disbursable',
				icon: 'fas fa-hand-holding-usd',
				gradient: 'widget-0',
				data: this.$filter('currency')(this.offering.funds_disbursable || '0', '$', 2),
				visible: true
			}, 
			{
				label: 'Funds In Escrow',
				icon: 'fas fa-bank',
				gradient: 'widget-1',
				data: this.$filter('currency')(this.getFundsInEscrow(), '$', 2),
				visible: true
			}, 
			{
				label: 'Funds Received',
				icon: 'fas fa-dollar-sign',	
				gradient: 'widget-3',
				data: this.$filter('currency')(this.project.stats.get('funds_raised'), '$', 2),
				visible: true
			}
		];

	}

	getFundsInEscrow() {
		return this.investments.reduce(function (total, item) {
			return total + (item.fund_status === 'Settled' ? item.amount : 0)
		}, 0);
	}

}
