
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('User', function (Parse, $q, $state, $rootScope, $timeout, $location, $log, Notifications) {

		// ==================================================
		// Methods
		// ==================================================

		this.userCreds = {};


		/**
		 * Returns the current user
		 */
		this.getCurrent = () => Parse.User.current();


		/**
		 * Returns true if a User is currently logged in
		 */
		this.isUserLoggedIn = () => this.getCurrent() ? true : false;


		/**
		 * Authenticate user login credentials and grant access to the portal if successful
		 */
		this.authenticate = async (creds, destination = 'account.home') => {
			let d = $q.defer();

			if (creds.pin) {
				let token = new (Parse.Object.extend('TwoFactorTokens'))();
				await token.save({ pin: creds.pin, email: creds.email });
			}

			// Contact the server and attempt to authenticate the User
			Parse.User.logIn(creds.email, creds.password)
				.then(user => this.fetchRoles().then(roles => {
					$rootScope.appState.userRoles = roles;
					return user;
				}))
				.then(user => Notifications.checkForUnread())
				.then(user => {
					// Login successful
					d.resolve(true);
				})
				.catch(err => {
					if (err.toString().includes('REQUIRES_TWO_FACTOR_AUTH')) {
						d.resolve({
							action: 'REQUIRES_TWO_FACTOR_AUTH'
						});
					} else {
						d.reject(err)
					}
				});

			return d.promise;

		}


		/**
		 * Logout current User
		 */
		this.logout = (destination = 'home', error_destination = 'login') => {

			let d = $q.defer();

			Parse.User.logOut()
				.then(user => {
					$rootScope.appState.userRoles = [];
					d.resolve();

					// Navigate to destination page
					$state.go(destination);
					// $location.url('/account/login');
					// $location.replace();

				})
				.catch(err => {
					d.resolve();

					// Navigate to error handled destination page
					$state.go(error_destination);
					// $location.url('/account/login');
					// $location.replace();

				});

			return d.promise;
		}


		/**
		 * Create a new User account
		 */
		this.signup = user => {

			let d = $q.defer();

			// Check for current user, reject signup if true
			if (this.isUserLoggedIn === true) {
				d.reject('Cannot process signup, a user is already logged in.');
			}


			// Set username
			user.username = user.email;

			// Copy the user data
			let new_user = JSON.parse(JSON.stringify(user));

			// Remove the duplicate password
			delete new_user.password_verify;

			// Create a new Parse User object
			let newUser = new Parse.User(new_user);

			// Contact the server and tell it we want to create a new User
			newUser.signUp()
				.then(_user => {
					d.resolve(_user);
				})
				.catch(err => {
					// Signup failed, return error
					d.reject(err);
				});

			return d.promise;

		}


		/**
		 * Send an email to the user with instructions to reset their password
		 */
		this.resetPassword = email => {

			let d = $q.defer();

			Parse.User.requestPasswordReset(email)
				.then(() => {
					d.resolve(true);
				})
				.catch(err => {
					d.reject(err);
				});

			return d.promise;

		}

		this.fetchRoles = () => {
			// let roleQuery = new Parse.Query(Parse.Role).equalTo('users', this.getCurrent())
			// return roleQuery.find().then(roles => roles.map(role => role.get('name')));
			if (!this.isUserLoggedIn()) {
				return $q.resolve([]);
			}

			return Parse.Cloud.run('getUserRoles');
		}

		this.hasRole = role => $rootScope.appState.userRoles.includes(role);

		/**
		 * Returns true if the user belongs to the "Admins" role
		 */
		this.isUserAdmin = () => {
			let d = $q.defer();

			// Returns $$state.value
			if ($rootScope.appState.userRoles) {
				// $log.debug($rootScope.appState.userRoles)
				// $log.debug('isAdmin', $rootScope.appState.userRoles.includes('Admins'))
				d.resolve($rootScope.appState.userRoles.includes('Admins'));
			} else {
				$timeout(() => this.isUserAdmin(), 100);
			}

			return d.promise;
		}


		/**
		 * Returns the bookmark status of a project for the current user
		 * @param {Parse.Object.extends('Projects')} project
		 * @returns {Boolean} bookmark status
		 */
		this.isProjectSaved = project => {
			let user = this.getCurrent();
			if (user && user.project_bookmarks) {
				return user.project_bookmarks.includes(project.id);
			}
			return false;
		}


		/**
		 * Bookmarks the project for the current user
		 * @param {Parse.Object.extends('Projects')} project
		 * @returns {Boolean} bookmark status
		 */
		this.bookmarkProject = project => {
			this.getCurrent().addUnique('project_bookmarks', project.id);
			this.getCurrent().save();
			$log.debug('Bookmark', project.id)
			return true;
		}


		/**
		 * Unbookmarks the project for the current user
		 * @param {Parse.Object.extends('Projects')} project
		 * @returns {Boolean} bookmark status
		 */
		this.unbookmarkProject = project => {
			this.getCurrent().remove('project_bookmarks', project.id);
			this.getCurrent().save();
			$log.debug('Unbookmark', project.id)
			return false;
		}

		this.requestTwoFactorAuth = () => Parse.Cloud.run('enableTwoFactorAuth');

		this.verifyTwoFactorAuth = async pin => {
			let token = new (Parse.Object.extend('TwoFactorTokens'))();
			await token.save({ pin, email: this.getCurrent().getUsername() });
			return Parse.Cloud.run('enableTwoFactorAuth', { pin });
		}

		this.resendVerficationEmail = () => Parse.Cloud.run('resendVerficationEmail');

		this.getInvestmentIds = async () => {
			let user = this.getCurrent();
			await user.fetch()
			return user.fa_investor_ids;
		}

	});
}

