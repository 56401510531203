
/* injects from baggage-loader */

'use strict';

let environment = 'staging';

// node_modules
import "jquery";
import "angular";
import "@uirouter/angularjs";
import "angular-resource";
import "oclazyload";
import "angular-animate";
import "angular-cookies";
import "angular-touch";
import "angular-sanitize";
import "angular-messages";
import "angular-aria";
import "angular-resource";
import "angular-scroll";
import "angular-loading-bar";
import "angular-avatar";
import "angular-timeago";
import "ng-file-upload";
import "angular-drag-and-drop-lists";
import "angular-consent";
import "angular-local-storage";
import "./vendor/fund-america/invest-now.module";
import "angular-socialshare";
import "angular-elastic";
import "slick-carousel/slick/slick";
import "./vendor/angular-slick/angular-slick";
import "angularjs-google-recaptcha-v3";
import "ng-pattern-restrict";

// Node module CSS
import "angular-loading-bar/build/loading-bar.css";

// Font Awesome icons
import "./index.icons";

// Setup TextAngular
(() => {
  window.taTools = {};
  window.rangy = require('rangy/lib/rangy-core');
})();
import 'textangular/dist/textAngular.css';
import 'rangy/lib/rangy-selectionsaverestore';
import 'textangular/dist/textAngular-sanitize';
import 'textangular/dist/textAngularSetup';
import 'textangular/dist/textAngular';

// Setup Sentry
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

// Import sentry if not running local dev env
if (environment !== 'development') {

  // Make sure to call Sentry.init after importing AngularJS. 
  // You can also pass {angular: AngularInstance} to the Integrations.Angular() constructor.
  Sentry.init({
    dsn: 'https://b026459a2fa4409998e657a419517da3@sentry.io/1831285',
    integrations: [
      new Integrations.Angular(),
    ],
    // release: 1,
    environment: environment,
    // beforeSend: (event, hint) => {
    //   console.log('Event!', event);
    //   return event;
    // }
  });

}

// local scripts
//import "../assets/js/...";