
/* injects from baggage-loader */

'use strict';

export default class AccountDocumentsController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {
		// this.$log.debug(this.documents);
	}

}
