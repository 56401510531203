
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ErrorHandler', function ($log, $state, Parse, Snackbar, $transitions) {
		return function(error, raw) {

			// $log.debug('Error picked up by error handler');
			// $log.debug($transitions)
			// $log.debug($state)
			// $log.debug(error, raw)

			// Handle transition error
			if (raw.message === 'The transition errored') {

				if (error.code === 101) {
					// go home
					$state.go('home')
				}

				// Display snackbar alert
				Snackbar.create(error.message || raw.detail || 'An error occured.', 5000, ['bg-danger']);
				
			}

			// Handle invalid Parse session token
			if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
				// $log.debug('ErrorHandler: Processing invalid session token error');
				/*
					Problem: The users session has expired or become invalid
					Solution: Log out the user and return them to the login page
				*/

				// TODO: Clean localstorage and session storage too. A new problem has caused the original solution to stop working q

				// Log the user out and return to the login page
				$state.go('accountLogout', { ref: 'login' });

			}



		}
	});
}

