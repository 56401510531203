
/* injects from baggage-loader */

'use strict';

import UpdatePaymentMethodModalComponentComponent from './updatepaymentmethodmodalcomponent.component';
import './updatepaymentmethodmodalcomponent.scss';

const UpdatePaymentMethodModalComponentModule = angular.module('update-payment-method-modal-component-module', []);

UpdatePaymentMethodModalComponentModule
    .component('updatePaymentMethodModalComponent', new UpdatePaymentMethodModalComponentComponent());

export default UpdatePaymentMethodModalComponentModule;

