
/* injects from baggage-loader */

'use strict';

import AccountLogoutController from './account-logout.controller';
import AccountLogoutTemplate from './account-logout.html';

export default class AccountLogoutComponent {
		constructor() {
				this.templateUrl = AccountLogoutTemplate;
				this.controller = AccountLogoutController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
