
/* injects from baggage-loader */

'use strict';

import AccountWidgetComponent from './account-widget.component';
import './account-widget.scss';

const AccountWidgetModule = angular.module('account-widget-module', []);

AccountWidgetModule
    .component('accountWidget', new AccountWidgetComponent());

export default AccountWidgetModule;

