
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerCommentsController {

	constructor($log, $timeout, $stateParams, $uibModal) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.modalService = $uibModal;

		this.showCommentBox = false;

	}

	$onInit() {
		// this.$log.debug(this.comments)

		// Filter out "top level" comments
		this.topLevelComments = this.comments.filter(c => !c.parent);

		let root_id = this.$stateParams.root_id
		let comment_id = this.$stateParams.comment_id;

		// Open moderation window and highlight the comment
		if (root_id && root_id.length === 10) {
			this.moderateComment(
				this.topLevelComments.find(c => c.id === root_id),
				this.comments.find(c => c.id === comment_id)
			);
		}

	}

	markRead(comment) {
		comment.save({
			unread: false
		});
	}

	moderateComment(comment, focusComment) {

		let modalInstance = this.modalService.open({
			animation: true,
			size: 'xlg',
			component: 'moderateCommentModal',
			resolve: {
				context: () => ({ comment, comments: this.comments, focus: focusComment })
			}
		});

		modalInstance.result.then(res => {

			this.$log.debug(res);

		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	commentPosted(comment) {
		this.$log.debug(comment)
		this.showCommentBox = false;
		this.topLevelComments.unshift(comment)
		this.$timeout();
	}

}
