
/* injects from baggage-loader */

'use strict';

import * as Sentry from '@sentry/browser';

export default class SignupController {

	constructor($log, $state, $location, $timeout, $stateParams, User, gRecaptcha, Parse, EDUCATIONAL_MATERIALS_LINK) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$location = $location;
		this.$timeout = $timeout;
		this.UserService = User;
		this.Parse = Parse;
		this.recaptcha = gRecaptcha;

		// Hold user login credentials
		this.user = {
			agreements: []
		};

		this.showPassword = false;

		this.eduMaterials = EDUCATIONAL_MATERIALS_LINK;

		// Hold login error details
		this.error = {};

		// Get the referral page from state params
		this.referral = $stateParams.ref;

		// this.creating_account = true;
		this.currentStep = 1;

		this.confirmationChecklist = [{
			checked: false,
			statement: 'I understand that I may only cancel my commitment to purchase up to 48 hours before the offering period expires.'
		}, {
			checked: false,
			statement: 'I understand that the securities being offered are restricted and may only be resold under certain circumstances.'
		}, {
			checked: false,
			statement: 'I understand that investing in securities involves risks and I should not invest unless I can bear the loss of risk, including a 100% loss.'
		}, {
			checked: false,
			statement: 'I agree to receive all documents electronically.'
		}, {
			checked: false,
			statement: `I have reviewed the <a target="_blank" href="${this.eduMaterials}" ng-click="$event.stopPropagation()>educational materials</a>.`
		}];

		this.user.agreements = this.confirmationChecklist;

	}

	$onInit() {

	}

	async signup() {

		// Reset error
		this.error = {};

		if (this.securityScreen) {
			this.error.message = 'Failed to create user, please refresh and try again';
			Sentry.captureMessage('Signup security checkbox tripped!');
			return;
		}

		if (!this.user.first_name) {
			this.error.message = 'Missing "first name"';
			return;
		}
		if (!this.user.last_name) {
			this.error.message = 'Missing "last name"';
			return;
		}

		if (!this.user.email) {
			this.error.message = 'Missing "email"';
			return;
		}

		if (!this.user.password) {
			this.error.message = 'Missing "password"';
			return;
		}

		if (this.user.password.length < 8) {
			this.error.message = '"Password" must be at least 8 characters in length';
			return;
		}

		// if (!this.user.password || !this.user.password_verify) {
		// 	this.error.message = 'Missing "password"';
		// 	return;
		// }

		// if (this.user.password !== this.user.password_verify) {
		// 	this.error.message = 'Passwords do not match';
		// 	return;
		// }

		this.creating_account = true;

		// Google ReCaptcha
		try {
			// Get token
			let token = await this.recaptcha.execute({ action: 'signup' });
			// Verify token
			this.user.recaptcha_res = await this.Parse.Cloud.run('verifyReCaptcha', { token });
		} catch (err) {
			// Failed recaptcha
			this.$log.error(err);
			this.error.message = 'Failed Google ReCaptcha';
			return false;
		}

		// this.user.agreements = this.confirmationChecklist;

		this.UserService.signup(this.user)
			.then(user => {
				if (typeof this.referral !== 'undefined') {
					this.$location.path(this.referral).search({});
				} else {
					this.$state.go('account.home');
				}
			})
			.catch(err => {
				this.creating_account = false;
				if (err.code === 202) {
					err.message = "Account already exists for this email address."
				}
				this.error = err;
				this.$timeout();
			});

	}

	previousStep() {
		this.currentStep -= 1;
	}

	nextStep() {

		// Reset error
		this.error = {};

		if (this.securityScreen) {
			this.error.message = 'Failed to create user, please refresh and try again';
			Sentry.captureMessage('Signup security checkbox tripped!');
			return;
		}

		if (!this.user.first_name) {
			this.error.message = 'Missing "first name"';
			return;
		}
		if (!this.user.last_name) {
			this.error.message = 'Missing "last name"';
			return;
		}

		if (!this.user.email) {
			this.error.message = 'Missing "email"';
			return;
		}

		if (!this.user.password) {
			this.error.message = 'Missing "password"';
			return;
		}

		if (this.user.password.length < 8) {
			this.error.message = '"Password" must be at least 8 characters in length';
			return;
		}

		// if (!this.user.password || !this.user.password_verify) {
		// 	this.error.message = 'Missing "password"';
		// 	return;
		// }

		// if (this.user.password !== this.user.password_verify) {
		// 	this.error.message = 'Passwords do not match';
		// 	return;
		// }

		this.currentStep += 1;
	}

	checkboxClicked(item, event) {
		if (event) {
			event.stopPropagation();
			if (event.target.tagName === 'A') return false;
		}
		item.checked = !item.checked;
		if (item.checked) {	
			item.acceptedAt = new Date();
		} else {
			item.acceptedAt = undefined;
		}
	}

	isFormReady() {
		for (let item of this.confirmationChecklist) {
			if (!item.checked) return false;
		}
		return true;
	}


}
