
/* injects from baggage-loader */

'use strict';

export default class InvestController {

	constructor($log, $timeout, StateService) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.investmentState = StateService.investment;
		this.sidePanelData = {
			investmentAmount: '',
			additionalFees: '',
			paymentMethod: '',
			total: ''
		}

		this.updateSummaryData = function (data) {
			if (data) {
				this.sidePanelData = Object.assign(this.sidePanelData, data);
				this.computeTotal(this.sidePanelData)
			}
		}

	}

	$onInit() {
		// this.$log.debug(this.project);
	}

	computeTotal(data) {
		const amount = Number(data.investmentAmount || 0);
		// TODO: may need to recalculate fees
		const fees = Number(data.additionalFees || 0)
		const total = amount + fees;
		this.sidePanelData.total = total;
		this.$timeout();
	}

}
