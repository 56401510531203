
/* injects from baggage-loader */

'use strict';

import RaiseSubmittedConfirmationController from './raise-submitted-confirmation.controller';
import RaiseSubmittedConfirmationTemplate from './raise-submitted-confirmation.html';

export default class RaiseSubmittedConfirmationComponent {
		constructor() {
				this.templateUrl = RaiseSubmittedConfirmationTemplate;
				this.controller = RaiseSubmittedConfirmationController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
