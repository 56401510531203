
/* injects from baggage-loader */

'use strict';

import $ from 'jquery';

export default function (app) {

	app.directive('blurOffFocus', BlurOffFocusDirective);

	function BlurOffFocusDirective() {
		'ngInject';

		return {
			restrict: 'A',
			link: linkFn,
			require: 'ngModel'
		};

		function linkFn(scope, element, attrs) {

			// Hide the text by default
			$(element).attr('style', 'text-security:disc; -webkit-text-security:disc;');

			// Reveal the text on focus
			element.bind('focus', function (e) {
				$(element).attr('style', '');
			});

			// Hide the text on lost focus
			element.bind('blur', function (e) {
				$(element).attr('style', 'text-security:disc; -webkit-text-security:disc;');
			});

		}

	}
}