
/* injects from baggage-loader */

'use strict';

export default class IssuerOnboardingDetailsController {

	constructor($log, $state, $window, UsStates, FormBuilder) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$window = $window;
		this.fb = FormBuilder;
		this.Validators = this.fb.Validators;

		this.states = UsStates;
		this.intakeForm = {}
		this.savingForm = false;

		this.prevStep = '';
		this.nextStep = 'issuer-onboarding.offering-and-team';

		this.currentStepName = 'details';

		// Notes:
		// One question to the team: Under "Project Type" should we add "Business", and under 
		// "Is your project a Rehabilitation or a New Building" add "N/A" to the options?

	}

	$onInit() {

		this.$log.debug('project details form', this.onboarding)

		this.intakeForm = this.fb.group({

			// Row 1
			first_name: ['', this.Validators.required],
			middle_initial: ['', this.Validators.maxLength(1)],
			last_name: ['', this.Validators.required],

			// Row 2
			email_address: ['', this.Validators.required, this.Validators.email],

			// Row 3
			phone_number: ['', this.Validators.required],
			website: [''],

			// Row 4
			company_name: ['', this.Validators.required],
			legal_status: ['', this.Validators.required],

			// Row 5
			project_title: ['', this.Validators.required],

			// Row 6
			// address_line_1: '',
			// address_line_2: '',
			city: ['', this.Validators.required],
			state: ['', this.Validators.required],
			postal_code: ['', this.Validators.required],

			// Row 7
			total_cost: ['', this.Validators.required],

			// Row 8
			raise_amount: ['', this.Validators.required],
			amount_raised_last_12_months: ['', this.Validators.required],

			// Row 9
			description: ['', this.Validators.required],

			// Row 10
			// additionalDetails: this.fb.group({
			// 	historicTaxCredits: false,
			// 	lowIncomeHousingTaxCredits: false,
			// 	newMarketTaxCredits: false,
			// 	brownfield: false,
			// 	opportunityZone: false,
			// 	none: false
			// }, [this.Validators.requireOne]),

			// Row 11
			// otherGrantsSubsidies: ['', this.Validators.required],

			// Row 12
			// teamRelevance: ['', this.Validators.required],

			// buildType: this.fb.group({
			// 	rehab: false,
			// 	newBuild: false,
			// 	buildNa: false
			// }, [this.Validators.requireOne]),

			// Row 13
			// projectType: this.fb.group({
			// 	mixedUse: false,
			// 	commercial: false,
			// 	residential: false,
			// 	business: false
			// }, [this.Validators.requireOne]),

			// Row 14
			// businessDistrict: this.fb.group({
			// 	yes: false,
			// 	no: false,
			// 	na: false
			// }, [this.Validators.requireOne]),

			// Row 15
			// projectSize: this.fb.group({
			// 	option0: false,
			// 	option1: false,
			// 	option2: false,
			// 	option3: false,
			// 	option4: false
			// }, [this.Validators.requireOne]),

			// Row 16
			// neighborhoodType: this.fb.group({
			// 	downtown: false,
			// 	cityNeighborhood: false,
			// 	industrial: false,
			// 	suburban: false,
			// 	rural: false,
			// 	naType: false
			// }, [this.Validators.requireOne]),

			// Row 17
			// population: this.fb.group({
			// 	belowMedian: false,
			// 	aboveMedian: false,
			// 	naPopulation: false
			// }, [this.Validators.requireOne]),

			// Row 18
			// density: this.fb.group({
			// 	lowDensity: false,
			// 	mediumDensity: false,
			// 	highDensity: false,
			// 	naDensity: false
			// }, [this.Validators.requireOne]),

			// Row 19
			// vacancy: this.fb.group({
			// 	lowVacancy: false,
			// 	mediumVacancy: false,
			// 	highVacancy: false,
			// 	naVacancy: false
			// }, [this.Validators.requireOne]),

			// Row 20
			// area: this.fb.group({
			// 	bikeFriendly: false,
			// 	nearBusLine: false,
			// 	walkable: false,
			// 	none: false
			// }, [this.Validators.requireOne]),

			// Row 21
			hearAboutUs: this.fb.group({
				wordOfMouth: false,
				socialMedia: false,
				news: false,
				internet: false,
				other: false,
				otherText: ''
			}, [this.Validators.requireOne])

		});

		// Inject the details into the form
		this.intakeForm.patchValue(this.onboarding.toJSON());
		// this.$log.debug('form', this.intakeForm);

	}

	async saveForm() {

		this.intakeForm.markAllAsTouched();

		if (this.intakeForm.valid()) {
			this.savingForm = true;
			let form = this.intakeForm.value();

			// Set last completed step
			form.lastCompletedStep = this.currentStepName;
			
			this.$log.debug(form);
			await this.onboarding.save(form);
			// Take the user to the next step
			this.$state.go(this.nextStep, { id: this.onboarding.id })
			this.savingForm = false;
		} else {
			this.savingForm = false;
			this.$window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}

	}

	/*
	   Need to collect the following info
	   
	   - firstname
	   - middle initial
	   - lastname
	   - email
		 - phone number
	   - company name
	 	 - project title
	 	 - project address 
	 	 		- line 1
	 	 		- line 2
	 	 		- city
	 	 		- state
		 		- zip
	 	 - total project cost
	 	 - amount to raise
	 	 - describe project [textarea]
	 	 - Additional Details (Check all that Apply) [checkbox]
	 	 		- Are Historic Tax Credits Part of the Project?
		 		-	Are Low Income Housing Tax Credits Part of the Project? Are New Market Tax Credits Part of the Project?
	 			- Is the Property in a Brownfield?
	 			- Is the Property in an Opportunity Zone?
	 			- None of the Above
		 - Please Describe any Other Grants and/or Subsidies in your Project and the Amount [textarea]
		 - Describe the Relevant Experience of Your Team [textarea]
		 - What is your Project Type? [radio]
			 - Mixed Use
			 - Commercial
			 - Residential
		- Is your Project a Rehabilitation or New Build? [radio]
			- Rehabilitation
			- New Build
		- If Your Project is Commercial or Mixed Use, is it in an active Business District? [radio]
			- Yes
			- No
			- Non Applicable
		- What Size is your Project? [radio]
			- Less than 5,000 sq. ft.
			- Between 5,000 and 10,000 sq. ft.
			- Between 10,000 and 20,000 sq. ft.
			- Over 20,000 sq. ft.
		- Which Best Describes the Neighborhood Type? [radio]
			- Downtown Core
			- City Neighborhood
			- Industrial
			- Suburban
			- Rural
		- Which Best Describes the General Neighborhood Population? [radio]
			- Households at or below the State Median Income
			- Households above the State Median Income
		- Which Best Describes the Neighborhood Density Around your Project? [radio]
			- Low Density
			- Medium Density
			- High Density
		- Which Best Describes the Vacancy Around your Project? (Buildings & Lots) [radio]
			- Low Vacancy
			- Medium Vacancy
			- High Vacancy
		- Check All That Apply About the Project Area [checkbox]
			- Bike Friendly and/or Good Bike Infrastructure
			- On or Near a Bus and/or Light Rail Line
			- Walkable
			- None of the Above
		- How Did you Hear About Common Owner? [checkbox]
			- Word of Mouth
			- Social Media
			- News Publication
			- Internet Search
			- Other (with text field)
		
	 */

}
