
/* injects from baggage-loader */

'use strict';

export default class IssuerOnboardingOfferingAndTeamController {

	constructor($log, $state, $uibModal, $window, $timeout, $rootScope, Parse, Alerts, OnboardingDocumentSchema, UsStates) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.$window = $window;
		this.$timeout = $timeout;
		this.$rootScope = $rootScope;
		this.Parse = Parse;
		this.Alerts = Alerts;
		this.OnboardingDocumentSchema = OnboardingDocumentSchema;
		this.states = UsStates;

		// Need uploads for 
		// 	- Project budget
		//  - Pitch deck
		// 	- Development Plan/Business Plan

		this.documents = [{
			id: 'projectBudget',
			description: 'Project Budget',
			file: null,
			na: false,
		}, {
			id: 'pitchDeck',
			description: 'Pitch Deck',
			file: null,
			na: false
		}, {
			id: 'businessPlan',
			description: 'Development Plan/Business Plan',
			file: null,
			na: false
		}, {
			id: 'organizationalChart',
			description: 'Organizational Chart',
			file: null,
			na: false
		}]

		this.prevStep = 'issuer-onboarding.details';
		this.nextStep = 'issuer-onboarding.documents';

		this.currentStepName = 'offering-and-team';

		this.savingForm = false;

		// NOTE: what this stage should do
		// I think we need to add a step in between 1 and 2 for Offering Information and team. 
		// This should include uploads for "Project Budget" "Pitch Deck" "Development Plan/Business Plan" 
		// along with the option to check N/A. We should also ask them to list all equity owners of the 
		// issuer over 20% and any managers, directors, or officers, of the issuer (together with their 
		// addresses and email info).

		// TODO: insert uploaded documents into "documents" array by matching "ids"


		this.associatedPersons = [{}];
		this.ownershipSummary = "";

		// Collect associated persons info
		// - firstname
		// - lastname
		// - title
		// - address line 1
		// - address line 2
		// - city
		// - state
		// - zip
		// - email
		// - phone

	}

	$onInit() {
		this.$log.debug(this.documents)
		this.$log.debug(this.docs)

		// Merge documentStatus into documents
		for (let doc of this.onboarding.get('documentStatus') || []) {
			for (let d of this.documents) {
				if (doc.id === d.id) {
					d.na = doc.na;
				}
			}
		}

		// Merge docs into documents array
		for (let doc of this.docs || []) {
			for (let d of this.documents) {
				if (doc.get('identifier') === d.id) {
					d.file = doc;
				}
			}
		}

		if (this.onboarding.get('associatedPersons')) {
			this.associatedPersons = this.onboarding.get('associatedPersons');
		}

		this.ownershipSummary = this.onboarding.get('ownershipSummary');

	}

	/**
	 * Returns true if a file has been picked for the document or NA is checked
	 * @param {String} id 
	 */
	isDocumentComplete(doc) {
		return doc.file || doc.na !== false;
	}

	/**
	 * Method called when project documents are dropped or selected
	 */
	async processFile(doc, replace = false) {
		// console.log(doc, this.pendingFiles)

		// TODO: implement replace functionality that removed the previous file

		if (this.pendingFiles instanceof File) {
			let file = this.pendingFiles;
			this.pendingFiles = [file];
		}

		let ops = [];

		let docRelation = this.onboarding.relation("documents");

		while (this.pendingFiles.length > 0) {

			// Get a file
			let file = this.pendingFiles.shift();

			if (doc) {
				doc.processing = true;
			}

			// Sanitize the filename
			let filename = file.name.split('.');
			let ext = filename.pop();
			filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
			filename = `${filename}.${ext}`;

			// Create the Parse.File
			let pFile = new this.Parse.File(filename, file, file.type);

			// Push to the operations array
			ops.push({
				item: pFile,
				action: 'save',
				message: 'Saving file:' + filename,
				retry: 3
			});

			let document = new this.OnboardingDocumentSchema({
				file: pFile,
				name: file.name,
				original_name: file.name,
				size: file.size,
				type: file.type,
				identifier: doc.id,
				onboarding: this.onboarding
			})

			document.uploading = true;

			// Push the document to the documents array
			// this.documents.push(document);

			// Push to the operations array
			ops.push({
				item: document,
				action: 'save',
				message: 'Saving document:' + filename,
				post: () => {

					// Add the document to project relation if new
					if (docRelation) {
						docRelation.add(document);
					}

					// Set the document 
					doc.file = document;

					// Uploading complete
					document.uploading = false;
					// document.uploaded = true;
					if (doc) {
						doc.processing = false;
						// doc.uploaded = true;
					}
					this.$timeout(() => { /* empty */ }, 100);

				},
				error: () => {
					// Uploading failed
					if (doc) {
						doc.processing = false;
						doc.failed = true;
					}
					document.uploading = false;
					document.failed = true;
					this.$log.error('Failed to upload file:', document.name)
					this.$timeout(() => { /* empty */ }, 100);
				}
			});

		}

		// Remove original file reference
		// if (replace) {

		// }

		// Process pending operations
		for (let op of ops) {
			this.$log.debug(op.message);
			try {
				await op.item[op.action]();
				if (typeof op.post === 'function') {
					op.post();
				}
			} catch (err) {
				this.$log.error(err, err.response);
				if (err.response && err.response.code === 130) {
					// Try it again after a brief wait
					if (op.retry > 0) {
						// Decrement the retry value by 1
						op.retry = op.retry - 1;
						// Push the op back into the ops array
						// TODO: consider "shifting" ops out of the array so pushing them back in on a retry
						//			 is cleaner
						ops.push(op);
					}
				} else {
					op.error();
				}
			}
		}

		// Update the onboarding form
		await this.onboarding.save();
		this.$log.debug('form saved');

	}

	async removeFile(doc) {
		if (!doc.file) {
			return false;
		}
		doc.removing = true;
		// Remove relation
		let relation = this.onboarding.relation("documents");
		relation.remove(doc.file);
		// Delete document
		await doc.file.destroy();
		// doc.uploaded = false;
		doc.file = null;
		await this.onboarding.save(null, {
			documentStatus: this.documents.map(d => ({
				id: d.id,
				// uploaded: d.uploaded,
				na: d.na
			}))
		});
		this.$timeout(() => {
			this.$log.debug('Document removed:', doc.id);
			// doc.isUpdating = false;
		}, 500);
	}

	newPerson() {
		this.associatedPersons.push({})
	}

	removePerson(person, index) {
		this.associatedPersons.splice(index, 1);
	}

	async saveForm() {

		this.$log.debug(this.documents)
		this.savingForm = true;

		// Check that all documents are handled
		for (let doc of this.documents) {
			if (!this.isDocumentComplete(doc)) {
				// Show error
				this.Alerts.setLocal({
					type: 'danger',
					msg: 'Please address all the document fields before continuing.'
				});
				this.savingForm = false;
				this.$window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				return false;
			}
		}

		// Set documents status
		await this.onboarding.save({
			associatedPersons: this.associatedPersons,
			ownershipSummary: this.ownershipSummary,
			documentStatus: this.documents.map(d => ({
				id: d.id,
				uploaded: d.uploaded,
				na: d.na
			})),
			lastCompletedStep: this.currentStepName
		})

		this.$state.go(this.nextStep, { id: this.onboarding.id })
	}

}
