
/* injects from baggage-loader */

'use strict';

import SocialShareMenuComponent from './socialsharemenu.component';
import './socialsharemenu.scss';

const SocialShareMenuModule = angular.module('social-share-menu-module', []);

SocialShareMenuModule
    .component('socialShareMenu', new SocialShareMenuComponent());

export default SocialShareMenuModule;

