
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Comments', function (Parse, Profiles, ProjectCommentSchema) {

		// ==================================================
		// Load object schema
		// ==================================================
		let ProjectComment = ProjectCommentSchema;

		// ==================================================
		// Methods
		// ==================================================


		/**
		 * Create a new comment object on the database
		 */
		this.post = options => {

			// Create a new instance of a project comment
			let comment = new ProjectComment(options);

			// Find current user profile
			comment.set('author', Profiles.getCurrent());

			// Save the comment
			return comment.save();

		};

		
		this.getThreadMembers = (origin, comments) => (comments.reduce((cms, comment) => {
			if (cms.find(c => comment.parent && c.id === comment.parent.id && comment.author.id !== c.author.id)) {
				cms.push(comment)
			}
			return cms;
		}, [origin])
			.map(comment => ({
				id: comment.author.id,
				name: `${comment.author.get('first_name')} ${comment.author.get('last_name')}`
			})))


	});
}

