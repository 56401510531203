
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerController {

	constructor($log, $window, $state, $stateParams, $rootScope, User) {
		'ngInject';

		this.$log = $log;
		this.$window = $window;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$rootScope = $rootScope;
		this.userService = User;

		this.navigation = [
			{
				title: 'Project',
				items: [
					{
						title: 'Overview',
						icon: 'fas fa-columns',
						state: 'projectmanager.home'
					}, {
						title: 'Editor',
						icon: 'fas fa-list',
						state: 'projectmanager.editor'
					}, {
						title: 'Issuer',
						icon: 'far fa-user',
						state: 'projectmanager.issuer'
					}, {
						title: 'Documents',
						icon: 'far fa-file',
						state: 'projectmanager.documents'
					}, {
						title: 'Media',
						icon: 'far fa-image',
						state: 'projectmanager.media'
					}, {
						title: 'Comments',
						icon: 'far fa-comment',
						state: 'projectmanager.comments'
					}, {
						title: 'Investments',
						icon: 'fas fa-dollar-sign',
						state: 'projectmanager.investments'
					}, {
						title: 'Disbursements',
						icon: 'fas fa-wallet',
						state: 'projectmanager.disbursement'
					}
				]
			},
			// {
			// 	title: 'Data',
			// 	items: [{
			// 		title: 'Investments',
			// 		icon: 'fas fa-dollar-sign',
			// 		state: 'projectmanager.investments'
			// 	}, {
			// 		title: 'Change History',
			// 		icon: 'far fa-clock',
			// 		state: 'projectmanager.activity'
			// 	}, {
			// 		title: 'Analytics (Coming Soon)',
			// 		icon: 'fas fa-chart-bar',
			// 		state: 'projectmanager.analytics',
			// 		disabled: true
			// 	}]
			// }
		];

		// Add pages
		// - settings / notifications

		// Set page title
		this.pageTitle = this.$state.current.localTitle;

	}

	$onInit() {
		// if (this.$stateParams.id === 'newproject') {
		// 	this.newProject = true;
		// }

		// Set comments "unread comments" badge
		for (let section of this.navigation) {
			if (section.title === 'Project') {
				for (let item of section.items) {
					// Set unread comments badge
					if (item.title === 'Comments') {
						item.badge = this.unreadCommentsCount;
					}
					// Set permissions
					if (!this.permissions.includes(item.title.toLowerCase())) {
						item.disabled = true;
					}
				}
			}
		}

		// Decrement unraed comments badge when a comment is marked as read
		this.$rootScope.$on('commentMarkedRead', () => {
			if (this.commentsTab && this.commentsTab.badge > 0) {
				this.commentsTab.badge--;
			}
		});

	}

	toggleNavbar() {
		this.$window.scrollTo(0, 0);
		this.toggleNav = !this.toggleNav;
		this.$rootScope.appState.scrollLock = this.toggleNav;
	}

	setPageTitle(page) {
		this.toggleNavbar();
		this.pageTitle = page.title;
	}

	projectPreviewLink() {
		return `${this.$window.location.origin}/project-preview/${this.project.id}`
	}

}
