
/* injects from baggage-loader */

'use strict';

import WireInstructionsModalComponentController from './wireinstructionsmodalcomponent.controller';
import WireInstructionsModalComponentTemplate from './wireinstructionsmodalcomponent.html';

export default class WireInstructionsModalComponentComponent {
		constructor() {
				this.templateUrl = WireInstructionsModalComponentTemplate;
				this.controller = WireInstructionsModalComponentController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
