
/* injects from baggage-loader */

'use strict';

import RaiseSubmittedConfirmationComponent from './raise-submitted-confirmation.component';

const RaiseSubmittedConfirmationPageModule = angular.module('raise-submitted-confirmation-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('raise-submitted-confirmation', {
        url: '/raise-submitted-confirmation',
        component: 'raiseSubmittedConfirmation',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('raiseSubmittedConfirmation', new RaiseSubmittedConfirmationComponent());

export default RaiseSubmittedConfirmationPageModule;
