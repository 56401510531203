
/* injects from baggage-loader */

'use strict';

import EmailConfirmedController from './email-confirmed.controller';
import EmailConfirmedTemplate from './email-confirmed.html';

export default class EmailConfirmedComponent {
		constructor() {
				this.templateUrl = EmailConfirmedTemplate;
				this.controller = EmailConfirmedController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
