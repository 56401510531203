
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('AccountIntegrationSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let AccountIntegration = Parse.Object.extend('Account_Integrations');

		// Define scheme
		let schema = [
			'service_name',
			'connected',
			'data'
		];

		// Load the schema into the object prototype
		schema.forEach(function (field) {
			Object.defineProperty(AccountIntegration.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return AccountIntegration;

	});
}

