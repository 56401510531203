
/* injects from baggage-loader */

'use strict';

import AccountSettingsController from './account.settings.controller';
import AccountSettingsTemplate from './account.settings.html';

export default class AccountSettingsComponent {
		constructor() {
				this.templateUrl = AccountSettingsTemplate;
				this.controller = AccountSettingsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
