
/* injects from baggage-loader */

'use strict';

import ProjectCommentController from './projectcomment.controller';
import ProjectCommentTemplate from './projectcomment.html';

export default class ProjectCommentComponent {
		constructor() {
				this.templateUrl = ProjectCommentTemplate;
				this.controller = ProjectCommentController;
				this.controllerAs = '$comment';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					comment: '<',
					comments: '<',
					scope: '<'
				};
		}
}
