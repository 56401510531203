
/* injects from baggage-loader */

'use strict';

import PhotoIdUploadComponent from './photoidupload.component';
import './photoidupload.scss';

const PhotoIdUploadModule = angular.module('photo-id-upload-module', []);

PhotoIdUploadModule
    .component('photoIdUpload', new PhotoIdUploadComponent());

export default PhotoIdUploadModule;

