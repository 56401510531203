
/* injects from baggage-loader */

'use strict';

import DocumentUploadComponent from './documentupload.component';
import './documentupload.scss';

const DocumentUploadModule = angular.module('document-upload-module', []);

DocumentUploadModule
    .component('documentUploadModal', new DocumentUploadComponent());

export default DocumentUploadModule;

