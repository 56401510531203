
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsNotificationsController {

	constructor($log, User) {
		'ngInject';

		this.heading = 'Notifications';

		this.$log = $log;
		this.user = User.getCurrent();
		this.notifications = this.user.notifications || {};

		this.preferences = [
			{
				title: 'Project notifications:',
				panels: [
					{
						title: 'Funded',
						key: 'projectFunded'
					},
					{
						title: 'Commented on',
						key: 'projectCommentedOn'
					},
					{
						title: 'Ending Soon',
						key: 'projectEndingSoon'
					}
				]
			},
			{
				title: 'Investment notifications:',
				panels: [
					{
						title: 'Funds are received',
						key: 'investmentFundsReceived'
					},
					{
						title: 'Documents are uploaded',
						key: 'investmentDocumentUploaded'
					},
					{
						title: 'Message received',
						key: 'investmentMessageReceived'
					},
					{
						title: 'Funds clear',
						key: 'investmentCleared'
					}
				]
			}
		];

		this.notificationEvents = this.preferences.reduce((res, pref) => res.concat(pref.panels.map(p => p.key)), []);

	}

	$onInit() {

		for (let event of this.notificationEvents) {
			if (typeof this.notifications[event] !== 'object') {
				this.notifications[event] = {
					email: true
				}
			}
		}

	}

	preferenceChanged(event) {
		this.user.save({ notifications: this.notifications})
	}

}
