
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingRepresentationsComponent from './issuer-onboarding.representations.component';

const IssuerOnboardingRepresentationsPageModule = angular.module('issuer-onboarding-representations-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('issuer-onboarding.representations', {
        url: '/representations',
        title: 'Issuer Onboarding: Representations',
        localTitle: 'Issuer Representations',
        component: 'issuerOnboardingRepresentations',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('issuerOnboardingRepresentations', new IssuerOnboardingRepresentationsComponent());

export default IssuerOnboardingRepresentationsPageModule;
