
/* injects from baggage-loader */

'use strict';

import * as moment from 'moment-timezone';

export default function (app) {
	app.service('Projects', function (Parse, User, ProjectSchema, ProjectStatsSchema, $log) {

		// ==================================================
		// Load object schema
		// ==================================================
		let Project = ProjectSchema;

		this.PROJECT_STATUSES = {
			CREATED: 'created',
			PENDING: 'pending',
			TESTING: 'testing',

			// These are the same status
			APPROVED: 'approved',
			LIVE: 'live',

			COMPLETED: 'completed',
			DENIED: 'denied'
		}


		// ==================================================
		// API Methods
		// ==================================================


		/**
		 * Retrieve the list of projects
		 */
		this.list = ({ withCount = false, skip = 0 } = {}) => {
			let query = new Parse.Query(Project);
			query.select([
				'title',
				'slug',
				'status',
				'type',
				'description',
				'location',
				'city',
				'region',
				'end_date',
				'investment_goal_min',
				'header_media.src',
				'stats.funds_raised',
				'stats.investor_count',
				'investment_suspended'
			])
			if (skip) {
				query.skip(skip);
			}
			if (withCount) {
				query.withCount(true);
			}
			query.include(['header_media', 'stats']);
			// query.equalTo('live', true);
			query.limit(1000);
			return query.find();
		};


		/**
		 * Returns the count of all visible projects
		 */
		this.count = () => {
			let query = new Parse.Query(Project);
			query.equalTo('live', true);
			return query.count();
		}


		/**
		 * Retrieve a project by ID
		 */
		this.get = (id, options = {}) => {
			// Protect against bad slug detection
			if (id.length !== 10) {
				return this.getBySlug(id, options)
			}
			let query = new Parse.Query(Project);
			query.include(['header_media', 'stats', 'sponsor']);
			if (typeof options.include !== 'undefined') {
				query.include(options.include);
			}
			return query.get(id);
		}

		/**
		 * Retrieve a project by slug
		 */
		this.getBySlug = (slug, options = {}) => {
			let query = new Parse.Query(Project);
			query.include(['header_media', 'stats', 'sponsor']);
			if (typeof options.include !== 'undefined') {
				query.include(options.include);
			}
			query.equalTo('slug', slug);
			return query.first();
		}


		/**
		 * Retrieve a project by ID or slug
		 */
		this.getBySlugOrId = (id, options = {}) => {
			const isSlug = /^[a-z](-?[a-z])*$/.test(id);
			return isSlug ? this.getBySlug(id, options) : this.get(id, options);
		}


		/**
		 * Retrieve projects created by the user
		 */
		this.listMine = () => {
			let query = new Parse.Query(Project);
			query.include(['header_media', 'status']);
			query.equalTo('creator', User.getCurrent());
			return query.find();
		}


		/**
		 * Returns an array of project raise IDs
		 */
		this.getRaiseIds = () => (new Parse.Query(Project)).select('raise_id').find();


		// ==================================================
		// Helper Methods
		// ==================================================


		/**
		 * Returns the number of hours until a project ends
		 */
		this.hoursRemaining = (project, float = false) =>
			moment(project.get('end_date')).diff(moment(), 'hours', float);


		// TODO: Update countdown to run hours and minutes on the last day
		// TODO: verify this function outputs "1" day remaining up until midnight of the end_date
		// 			 "Midnight" as in 23:59:59 in the local tz
		/**
		 * Returns the number of days until a project ends
		 */
		this.daysRemaining = project => {
			// let days = moment(project.get('end_date')).diff(moment(), 'days');
			// let exact = moment(project.get('end_date')).diff(moment(), 'days', true);
			// if (exact > 0 && exact < 1) {
			// 	days = 1;
			// }
			// return days > 0 ? days : 0;
			let days = Math.ceil(moment(project.get('end_date')).diff(moment(), 'days', true));
			return days > 0 ? days : 0;
		}

		this.cancelationDeadline = (project, format) => {
			// let endDate = project.get('end_date');
			let date, endDate = moment(project.get('end_date')).subtract(1, 'second');
			if (moment.tz) {
				let timezone = moment.tz.guess();
				date = moment.tz(endDate, timezone).subtract(48, 'hours');
			} else {
				date = moment(endDate).subtract(48, 'hours');
			}
			return format ? date.format(format) : date;
		}

		this.getTestingMetrics = (projectId) => {
			return Parse.Cloud.run('getTestingMetrics', { projectId });
		}

	});
}

