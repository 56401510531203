
/* injects from baggage-loader */

'use strict';

import CheckMailingInstructionsModalComponent from './checkmailinginstructionsmodal.component';
import './checkmailinginstructionsmodal.scss';

const CheckMailingInstructionsModalModule = angular.module('check-mailing-instructions-modal-module', []);

CheckMailingInstructionsModalModule
    .component('checkMailingInstructionsModal', new CheckMailingInstructionsModalComponent());

export default CheckMailingInstructionsModalModule;

