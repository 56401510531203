
/* injects from baggage-loader */

'use strict';

import AccountDocumentsComponent from './account.documents.component';

const AccountDocumentsPageModule = angular.module('account-documents-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.documents', {
        url: '/documents',
        component: 'accountDocuments',
        // Requires valid login to view
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          documents: function(Documents, ProjectSchema) {
            return Documents.list();
          }
        }
      });
  })
  .component('accountDocuments', new AccountDocumentsComponent());

export default AccountDocumentsPageModule;
