
/* injects from baggage-loader */

'use strict';

import DocumentListItemController from './documentlistitem.controller';
import DocumentListItemTemplate from './documentlistitem.html';

export default class DocumentListItemComponent {
	constructor() {
		this.templateUrl = DocumentListItemTemplate;
		this.controller = DocumentListItemController;
		this.controllerAs = '$document';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			document: '<',
			button: '<',
			isEditingProject: '<'
		};
	}
}
