
/* injects from baggage-loader */

'use strict';

import AccountInvestmentsComponent from './account.investments.component';

const AccountInvestmentsPageModule = angular.module('account-investments-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.investments', {
        url: '/my-investments',
        title: 'Account My Investments',
        component: 'accountInvestments',
        // Requires valid login to visit
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          investments: function(Investments, ProjectSchema) {
            return Investments.list({
              includeCount: true
            });
          }
        }
      });
  })
  .component('accountInvestments', new AccountInvestmentsComponent());

export default AccountInvestmentsPageModule;
