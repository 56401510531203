
/* injects from baggage-loader */

'use strict';

import InvestmentCheckoutComponent from './investment-checkout.component';
import './investment-checkout.scss';

const InvestmentCheckoutModule = angular.module('investment-checkout-module', []);

InvestmentCheckoutModule
    .component('investmentCheckout', new InvestmentCheckoutComponent());

export default InvestmentCheckoutModule;

