
/* injects from baggage-loader */

'use strict';

import moment from 'moment';

export default class InvestVerifyController {

	constructor($log, $state, $window, $timeout, $stateParams, FormBuilder, User, Parse) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$window = $window;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.fb = FormBuilder;
		this.Validators = this.fb.Validators;
		this.Constraints = this.fb.Constraints;
		this.Events = this.fb.Events;
		this.User = User;
		this.Parse = Parse;

		this.kyc_id = '';
		this.kyc_exp = '';
		this.kyc_questions = [];
		this.isMissingKycQuestions = false;
		this.kycIssueText = "We were unable to automatically verify your identity. Please upload and submit a valid photo ID to complete your identity verification."

		this.savingForm = false;
		this.completionStep = 'invest.confirmation';

	}

	$onInit() {
		this.$log.debug('Investment:', this.investment);
		this.$log.debug('Investor:', this.investor);
		this.$log.debug(this.$stateParams);

		// Pass the updated data back to the component
		this.updateSummaryData({
			data: { investmentAmount: this.investment.amount }
		});

		// TODO: Check if the investment has already completed the KYC verification step, this step
		// IF true: redirect to the confirmation step
		// NOTE: alternativly we can check if the investment object isNew()

		// Handle case where this page is visited without an investment ID
		if (this.investment && this.investment.isNew()) {
			this.$state.go('invest.commitment', {}, {
				id: this.project.id
			});
			return;
		}

		this.kyc_id = this.investor.get('kyc_id');
		this.kyc_exp = this.investor.get('kyc_exp');
		this.kyc_questions = this.investor.get('kyc_questions');

		// TODO: determine a course of action if questions are not present
		// This is likely due to a KYC failure and will require manual verification
		// with the upload of documents 

		// Initialize the dynamic form
		if (this.kyc_questions instanceof Array && this.kyc_questions.length) {
			this.kycForm = this.fb.group(this.generateQuestionForm(this.kyc_questions));
			this.$timeout(() => this.kycForm.initialize(), 250);
		} else {
			// TODO: Display error and contact support
			this.isMissingKycQuestions = true;
		}

	}

	generateQuestionForm(questions) {
		const form = questions.reduce((ctx, q) => {
			ctx[q.type] = ['', this.Validators.required];
			return ctx;
		}, {});
		return form;
	}

	isFormReady() {
		if (!this.kycForm) {
			return false;
		}
		return this.kycForm.valid();
	}

	setError(err = {
		code: `UNKNOWN_ERROR`,
		message: `Unknown error`
	}) {
		this.error = {
			code: err.code,
			message: `Failed to submit questionnaire: ${err.message}`
		}
	}

	/**
	 * Returns the next navigation state for the investor to complete
	 * this investment
	 */
	async getNextNavigationStep({ investor, investment }) {

		// Possible stages
		// this.accreditationStep = 'invest.accredited';
		// this.completionStep = 'invest.completion';

		const investorAccreditation = await investor.get('accreditation').fetch();
		const isRegCF = this.project.get('type') === 'cf';
		const isInvestorAccreditationVerified = investorAccreditation && investorAccreditation.get('verified');
		const isInvestorAccreditationPending = investorAccreditation && investorAccreditation.get('submitted');

		// 506c required accreditation. Check if investor is verified or submitted 
		// Take user to accreditation verification document upload
		if (!isRegCF && !(isInvestorAccreditationVerified || isInvestorAccreditationPending)) {
			return this.accreditationStep;
		}

		// Take user to confirmation page
		return this.completionStep;
	}

	async saveForm() {

		// Reset any errors
		this.error = {};

		// Prepare and validate form
		this.kycForm.markAllAsTouched();

		// Scroll to form error if not valid
		if (!this.kycForm.valid()) {
			this.savingForm = false;
			this.$window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			return;
		}

		const formData = {
			investorId: this.investor.id,
			investmentId: this.investment.id,
			questions: this.kycForm.value()
		}

		this.savingForm = true;
		try {

			// Create the investment commitment
			const {
				investment,
				investor
			} = await this.Parse.Cloud.run('verifyKyc', formData);
			// this.savingForm = false;
			// this.$timeout();

			const nextStep = await this.getNextNavigationStep({
				investor, investment
			});

			// Take the user to the next step
			this.$state.go(nextStep, {
				investmentId: this.investment.id
			}, { id: this.investment.id });

		} catch (err) {
			this.$log.error(err);
			this.savingForm = false;

			// Display error to user
			this.setError(err);
			this.$timeout();
		}

	}

}
