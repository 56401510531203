
/* injects from baggage-loader */

'use strict';

import NorthCapitalCheckMailingInstructionsController from './north-capital-check-mailing-instructions.controller';
import NorthCapitalCheckMailingInstructionsTemplate from './north-capital-check-mailing-instructions.html';

export default class NorthCapitalCheckMailingInstructionsComponent {
		constructor() {
				this.templateUrl = NorthCapitalCheckMailingInstructionsTemplate;
				this.controller = NorthCapitalCheckMailingInstructionsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					amount: '<',
					offering: '<'
				};
		}
}
