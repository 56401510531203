
/* injects from baggage-loader */

'use strict';

import CommentBoxController from './commentbox.controller';
import CommentBoxTemplate from './commentbox.html';

export default class CommentBoxComponent {
		constructor() {
				this.templateUrl = CommentBoxTemplate;
				this.controller = CommentBoxController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					parent: '<',
					comments: '=',
					onComplete: '&'
				};
		}
}
