
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.filter('commas', function (Utilities) {
		// args: (input, param)
		return function (input) {
			return Utilities.formatNumberWithCommas(input);
		};
	});
}