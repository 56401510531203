
/* injects from baggage-loader */

'use strict';

import ProjectManagerInvestmentsController from './projectmanager.investments.controller';
import ProjectManagerInvestmentsTemplate from './projectmanager.investments.html';

export default class ProjectManagerInvestmentsComponent {
		constructor() {
				this.templateUrl = ProjectManagerInvestmentsTemplate;
				this.controller = ProjectManagerInvestmentsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					investments: '<',
					offering: '<'
				};
		}
}
