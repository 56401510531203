
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('DisbursementRequests', function (Parse, DisbursementRequestSchema) {

		// ==================================================
		// Load object schema
		// ==================================================
		const DisbursementRequest = DisbursementRequestSchema;

		this.class = DisbursementRequest;

		// ==================================================
		// Methods
		// ==================================================

		/**
		 * Retrieve the list of DisbursementRequests
		 */
		this.list = () => {
			let query = new Parse.Query(DisbursementRequest);
			return query.find();
		};

		/**
		 * Retrieve the list of DisbursementRequests by Project
		 */
		this.listByProject = project => {
			let query = new Parse.Query(DisbursementRequest);
			query.equalTo('project', project);
			return query.find();
		};


		/**
		 * Retrieve a DisbursementRequest by ID
		 */
		this.get = id => {
			let query = new Parse.Query(DisbursementRequest);
			return query.get(id);
		}

	});
}

