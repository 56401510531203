
/* injects from baggage-loader */

'use strict';

import AccountHomeController from './account.home.controller';
import AccountHomeTemplate from './account.home.html';

export default class AccountHomeComponent {
		constructor() {
				this.templateUrl = AccountHomeTemplate;
				this.controller = AccountHomeController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					stats: '<',
					profile: '<',
					onboarding: '<'
				};
		}
}
