
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectInterestSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let ProjectInterest = Parse.Object.extend('Project_Interest');

		// Define 'ProjectInterest' scheme
		let schema = [
			'first_name',
			'last_name',
			'email',
			'phone',
			'investment_amount',
			'send_alerts'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(ProjectInterest.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProjectInterest;

	});
}

