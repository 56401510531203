
/* injects from baggage-loader */

'use strict';

export default class HomeController {

	constructor($log, $scope, $location, $window, Common, User, EDUCATIONAL_MATERIALS_LINK, INVESTOR_COUNT_BUFFER) {
		'ngInject';

		this.$log = $log;
		this.$scope = $scope;
		this.$location = $location;
		this.$window = $window;
		this.common = Common;
		this.userService = User;

		// Empty projects array for now, load from module resolver when DB tables setup
		this.projects = [];

		this.eduMaterialsLink = EDUCATIONAL_MATERIALS_LINK;
		this.INVESTOR_COUNT_BUFFER = INVESTOR_COUNT_BUFFER;

		// ACC - Tab Functionality
		this.tabs = [
			{ title: 'Dynamic Title1', content: 'Dynamic content 1' },
			{ title: 'Dynamic Title2', content: 'Dynamic content 2', disabled: true }
		];

		this.modal = {
			name: 'Tabs'
		};
		// ACC - End
	}

	$onInit() {

		this.projects = this.projects.slice(0, 3);

		this.$window.scrollTo(0, 0);

	}

	getIsTestingWaters(project) {
		return project.status === 'testing';
	}

	getFormattedProjectType(project) {
		if (this.getIsTestingWaters(project)) {
			return 'Gauging Interest for Reg CF'
		}
		if (project.type && project.type.indexOf('cf') > -1) {
			return 'Reg CF'
		} else {
			return project.type;
		}
	}

	shouldShowInvestorCount(project) {
		return project.stats.investor_count >= this.INVESTOR_COUNT_BUFFER;
	}

	/**
	 * Returns the project "slug" or fallback to the ID of the current project
	 */
	getProjectSlugOrId(project) {
		return project.slug || project.id;
	}

	getIsProjectFunded(project) {
		return project.get('stats').funds_raised > project.get('investment_goal_min');
	}

	getIsProjectConcluded(project) {
		const daysRemaining = this.common.daysRemaining(project.get('end_date'));
		return !daysRemaining || daysRemaining < 1;
	}

	getDaysRemainingFormatted(project) {
		const daysRemaining = this.common.daysRemaining(project.get('end_date'));
		return daysRemaining ? `${daysRemaining} Days` : `Closed`;
	}

	getFormattedFundedMessage(project) {
		return `Successfully Funded!`;
	}

}
