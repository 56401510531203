
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerEditorController {

	constructor($log, $timeout, $state, $stateParams, $window, $document, $uibModal, Alerts, Countries, Parse, Navigation) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$document = $document;
		this.$stateParams = $stateParams;
		this.$window = $window;
		this.Alerts = Alerts;
		this.Parse = Parse;
		this.navigation = Navigation;
		this.modalService = $uibModal;

		this.routeData = this.$state.current.data || {};

		if (this.$stateParams.id === 'newproject' || this.routeData.intakeForm) {
			this.newProject = true;
		}

		// Set countries filtered to only the US and Canada
		this.countries = Countries.filter(country => ['US', 'CA'].includes(country.code));

		this.dateOpts = {
			altInput: true,
			altFormat: "F j, Y",
			dateFormat: "Z"
		};

		this.projectTitle = '';

		this.savingProject = false;

	}

	$onInit() {
		// this.$log.debug(this.project)
		this.projectTitle = this.project.title;

		// Select the first section
		this.selectBodySection(this.project.body[0]);

		// Setup a navigation guard on the project data dirty() function
		this.navigation.setGuard({
			message: 'Are you sure you want to abandon these changes?',
			fn: () => this.project.dirty()
		});

	}

	$onDestroy() {

		// Release the navigation guard
		this.navigation.releaseGuard();

		// Discard cahnges
		this.discardChanges();

	}

	/**
	 * Called when flatpicker is initialized, sets up the date according to ng-model
	 * @param {Object} fpItem 
	 * @param {Object} options 
	 */
	datePostSetup(fpItem, options) {
		fpItem.setDate(options.date);
	}

	selectBodySection(section) {
		this.selectedSection = section;
	}

	newBodySection() {
		this.project.body.push({});
		this.selectBodySection(this.project.body[this.project.body.length - 1]);
	}

	removeBodySection() {

		// Remove empty section
		if (!this.selectedSection.title && !this.selectedSection.body) {
			let index = this.project.body.indexOf(this.selectedSection);
			this.project.body.splice(index, 1);

			// Select the next section in line
			if (typeof this.project.body[index] !== 'undefined') {
				this.selectBodySection(this.project.body[index]);
			} else {
				this.selectBodySection(this.project.body[this.project.body.length - 1]);
			}
			return false;
		}

		(this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: () => ({
					title: 'Delete section?',
					body: 'Are you sure you want to delete this section?',
					list: [this.selectedSection.title || '[Untitled section]'],
					confirmBtnText: 'Delete',
					cancelBtnText: 'Cancel'
				})
			}
		})).result.then(() => {
			let index = this.project.body.indexOf(this.selectedSection);
			this.project.body.splice(index, 1);

			// Select the next section in line
			if (typeof this.project.body[index] !== 'undefined') {
				this.selectBodySection(this.project.body[index]);
			} else {
				this.selectBodySection(this.project.body[this.project.body.length - 1]);
			}
		}, angular.noop);

	}

	newKeyPoint() {
		this.project.key_points.push({});
	}

	removeKeyPoint(kp) {
		let index = this.project.key_points.indexOf(kp);
		this.project.key_points.splice(index, 1);
	}

	async processHeaderMedia(file) {

		// Create a new file reader
		let reader = new FileReader();

		reader.addEventListener('load', () => {
			this.projectMediaPreview = reader.result;
			this.$timeout(() => { /* empty */ }, 100);
		}, false);

		if (file) {
			reader.readAsDataURL(file);
		}

	}

	getHeaderMediaPreview() {
		if (this.projectMediaPreview) {
			return `{'background-image':'url(${this.projectMediaPreview})'}`;
		} else if (this.project.header_media.src) {
			return `{'background-image':'url(${this.project.header_media.src})'}`;
		}
	}
	hasHeaderMediaPreview() {
		return this.projectMediaPreview ? true : false;
	}


	/**
	 * Returns true if the project or any section has been modified
	 */
	isProjectDirty() {
		return this.project.dirty();
	}


	/**
	 * Discards any changes to the object
	 */
	async discardChanges() {

		// Discard any changes to the project
		this.project.revert();
		this.Alerts.clearLocal();

		// Reload the project if these arrays were edited
		if (this.project.dirty('body') || this.project.dirty('key_points')) {
			// this.$state.reload();
			// this.$window.location.reload();

			this.project.clear();
			if (this.project.id) {
				this.project = await this.project.fetch();
				this.selectBodySection(this.project.body[0]);
				this.$timeout();
			}
		}

	}

	async saveChanges() {

		this.savingProject = true;

		// Filter out empty body sections
		this.project.body = this.project.body.filter(section => section.title || section.body);

		// Save the project changes
		this.project.save()
			.then(project => {
				// Reload page with new project id if new project
				if (this.$stateParams.id === 'newproject' || this.newProject) {
					// TODO: figure out how to show confirmation project was created successfully
					this.$state.transitionTo('projectmanager.home', {
						id: project.id
					});
					return false;
				} else {
					return project;
				}
			})
			// Refresh the project object
			.then(project => project.fetch())
			.then(project => {
				this.project = project;
				this.projectTitle = project.title;
				this.savingProject = false;
				this.selectBodySection(this.project.body[0]);
				this.Alerts.setLocal({
					type: 'success',
					msg: 'Project saved'
				});
				this.$document.scrollTopAnimated(0);
			})
			.catch(err => {
				this.savingProject = false;
				this.$timeout();

				err = err.toString().replace('ParseError: 141', '').trim();

				this.Alerts.setLocal({
					type: 'danger',
					msg: err
				});

				this.$document.scrollTopAnimated(0);
			});

	}

}
