
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingRepresentationsController from './issuer-onboarding.representations.controller';
import IssuerOnboardingRepresentationsTemplate from './issuer-onboarding.representations.html';

export default class IssuerOnboardingRepresentationsComponent {
		constructor() {
				this.templateUrl = IssuerOnboardingRepresentationsTemplate;
				this.controller = IssuerOnboardingRepresentationsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
