
/* injects from baggage-loader */

'use strict';

import InvestVerifyController from './invest.verify.controller';
import InvestVerifyTemplate from './invest.verify.html';

export default class InvestVerifyComponent {
		constructor() {
				this.templateUrl = InvestVerifyTemplate;
				this.controller = InvestVerifyController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					investment: '<',
					investor: '<',
					sidePanelData: '<',
					updateSummaryData: '&'
				};
		}
}
