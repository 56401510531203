
/* injects from baggage-loader */

/*global NODE_ENV*/
'use strict';

function config($logProvider, $compileProvider, cfpLoadingBarProvider, $provide, slickCarouselConfig, ENVIRONMENT, GOOGLE_ANALYTICS_TRACKING_TAG) {
	'ngInject';

	$logProvider.debugEnabled(true);

	if (ENVIRONMENT === 'production') {
		$logProvider.debugEnabled(false);
		$compileProvider.debugInfoEnabled(false);
	}

	// Configure Google Analytics
	// if (GOOGLE_ANALYTICS_TRACKING_TAG) {
	// 	AnalyticsProvider.setAccount(GOOGLE_ANALYTICS_TRACKING_TAG);
	// }

	// Loading bar config
	cfpLoadingBarProvider.includeSpinner = false;
	cfpLoadingBarProvider.includeBar = true;

	// Fix deprecated lowercase function
	angular.lowercase = angular.$$lowercase;

	// Customize tool icons
	$provide.decorator('taTools', ['$delegate', function (taTools) {
		taTools.redo.iconclass = 'fas fa-redo';
		taTools.insertVideo.iconclass = 'fab fa-youtube';
		taTools.insertImage.iconclass = 'far fa-image';
		return taTools;
	}]);

	$provide.decorator('taOptions', ['taRegisterTool', '$delegate', function (taRegisterTool, taOptions) {

		// Customize insertImage action
		// taRegisterTool('insertImageAlt', {
		// 	iconclass: 'far fa-image',
		// 	tooltiptext: 'Insert image',
		// 	action: function () {
		// 		let imageLink;
		// 		imageLink = $window.prompt('Please enter an image URL to insert', 'https://');
		// 		if (imageLink && imageLink !== '' && imageLink !== 'https://') {
		// 			if (imageLink.toLowerCase().indexOf('javascript') === -1) {
		// 				if (taSelection.getSelectionElement().tagName && taSelection.getSelectionElement().tagName.toLowerCase() === 'a') {
		// 					taSelection.setSelectionAfterElement(taSelection.getSelectionElement());
		// 				}
		// 				let embed = '<img src="' + imageLink + '">';
		// 				return this.$editor().wrapSelection('insertHTML', embed, true);
		// 			}
		// 		}
		// 	},
		// 	onElementSelect: {
		// 		element: 'img',
		// 		action: taToolFunctions.imgOnSelectAction
		// 	}
		// });

		// Text editor toolbar config
		taOptions.toolbar = [
			['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p'],
			['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo'],
			['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
			['insertImage', 'insertLink', 'insertVideo']
		];

		// 'html'

		return taOptions;

	}]);

	// Slick image slider config
	slickCarouselConfig.dots = true;
	slickCarouselConfig.autoplay = false;

}

export default config;