
/* injects from baggage-loader */

'use strict';

export default function (app) {

  app.controller('genericState', ['$scope', 'ss', '$ins', '$timeout', '$filter', '$sce',
    function ($scope, ss, $ins, $timeout, $filter, $sce) {
      var _this = this;

      $ins.loading = _this.loading = true;

      var autofill = function (step, investment) {
        // Make sure the autofill step exists and that we haven't completed this step in the investment
        if ($ins.autofill[step] && investment.states_completed.indexOf(step) >= 0) {
          // When we have completed the step, delete the autofill
          delete $ins.autofill[step];
        } else if ($ins.autofill[step] && investment && investment.states_completed.indexOf(step) === -1) {
          var step_autofill = $ins.autofill[step];
          for (var key in step_autofill) {
            var value = step_autofill[key];
            // Only autofill values that exist
            if (value) {
              _this.resource[key] = value;
            }
          }
        }
      }

      this.setDefaults = function (callback) {
        _this.boolean = $ins.boolean;

        // Add some state specific things here
        var investment = $ins.investment(),
          state = investment.state;

        switch (state) {
          case 'investment':
            // On the investmnt step, asssign a share_price to _this so it can be used for calculations
            _this.share_price = $ins.invest_now_investment.unit_price;
            _this.unit_type = $ins.invest_now_investment.unit_type;
            _this.unit_type_plural = $ins.invest_now_investment.unit_type_plural;

            // Can set client_data, url, referrer, and syndication_id
            // Will probably eventually set things like investor_id and such too
            for (var key in $ins.data) {
              _this.resource[key] = $ins.data[key];
            }
            autofill(state, investment);

            if (_this.resource.amount && (ss.toNumber(_this.resource.amount) > 0)) {
              this.calculateShare(_this.resource.amount);
            }

            // Always set the user agent
            _this.resource.user_agent = navigator.userAgent;

            if (_this.form && _this.form.investor_id.options) {
              if (!_this.resource.investor_id && investment.states_completed.indexOf('investment') === -1) {
                // Default to first option
                for (var key in _this.form.investor_id.options) {
                  _this.resource.investor_id = key;
                  break;
                }
              } else if (!_this.resource.investor_id) {
                // set to empty so that "A new investor" is selected
                _this.resource.investor_id = '';
              }
            }

            if (_this.form && _this.form.ach_authorization_id.options) {
              if (!_this.resource.ach_authorization_id && investment.states_completed.indexOf('investment') === -1) {
                // Default to first option
                for (var key in _this.form.ach_authorization_id.options) {
                  _this.resource.ach_authorization_id = key;
                  break;
                }
              } else if (!_this.resource.ach_authorization_id) {
                // set it to empty string because of the option's key
                _this.resource.ach_authorization_id = '';
              }
            }

            var investor_id = _this.resource.investor_id;
            if (_this.resource.new_investor === true && _this.form.investor_id.options && _this.resource.name && investor_id && !_this.form.investor_id.options[investor_id]) {
              // Merge the new investor's investor_id to the existing options object
              var new_value = {};
              new_value[investor_id] = angular.copy(_this.resource.name);
              _this.form.investor_id.options = angular.merge({}, new_value, _this.form.investor_id.options);
            }

            if (_this.resource.issuer_rollover) {
              if (_this.resource.rollover_data.length === 0) {
                _this.addAdditionalProceeds();
              } else if (_this.resource.rollover_data.length === undefined) {
                _this.resource.rollover_data = [];
              } else if (_this.resource.rollover_data.length > 0) {
                // calculate if there additional funds outside the rollover
                var total_amount = ss.toNumber(_this.resource.amount),
                  rollover_amount = _this.getRolloverAmount(),
                  new_capital = total_amount - rollover_amount;
                if (new_capital > 0) {
                  _this.resource.new_capital = new_capital;
                }
              }
            }

            break;
          case 'associated_person':
            autofill(state, investment);

            break;
          case 'joint_entity':
            autofill(state, investment);

            break;
          case 'investor_accreditation':
            _this.resource.work_with_verify_investor = true;
            break;
          case 'vesting_entity':
            autofill(state, investment);

            break;
          case 'investor':
            // Watch for
            var unbindWatch = $scope.$watch(function () {
              return _this.errors ? _this.errors.amount : undefined;
            },
              function (amount_errors) {
                if (amount_errors !== undefined) {
                  _this.show_amount = true;
                  unbindWatch();
                }
              });

            break;
          case 'cryptocurrency':
            _this.estimating = false;
            break;
          case 'credit_card_payment':

            _this.resource.hosted_payments_url = $sce.trustAsResourceUrl(_this.resource.hosted_payments_url);

            break;
          default:
            break;
        }
      };

      this.addAdditionalProceeds = function () {
        _this.resource.rollover_data.push({});
      };

      // This will help calculate shares for people, like 2.0 did
      this.calculateAmount = function (quantity) {
        // get current amount and convert to real number
        var current_amount = ss.toNumber(_this.resource.amount);
        if (_this.share_price) {
          if (quantity === '' || quantity === null) {
            if (current_amount !== '' && current_amount !== 0) {
              // Set it back to zero
              _this.resource.amount = 0;
            }

          } else if (quantity !== undefined) {
            // Need a share price from the invest_now_investment object?
            var new_amount = _this.share_price * quantity;
            if (new_amount !== current_amount) {
              _this.resource.amount = new_amount;
              // Set this flag so that when they move away from the shares field, it'll try to validate amount
              _this.validate_amount_on_blur = true;
            }
          }
        }
      };

      this.calculateShare = function (amount) {
        // convert amount to a number
        // Make sure there is a share price, zero should never happen, so this should always be a positive number, otherwise it'll be undefined
        if (amount !== undefined && amount !== '' && _this.share_price) {
          var amount = ss.toNumber(amount);
          _this.resource.shares = (typeof (amount) === 'number') ? amount / _this.share_price : '';
        }
      };

      this.estimateWithUsd = function () {
        var success = function (data) {
          _this.estimating = false;
          // Update the crypto amount
          _this.resource.crypto_amount = data.crypto_amount;
        };

        var failure = function (data) {
          _this.estimating = false;
        };

        var params = {
          amount: ss.toNumber(_this.resource.invest_now_investment.amount),
          id: _this.resource.invest_now_investment.id,
          type: _this.resource.crypto_type
        };

        _this.estimating = true;
        ss.call('investNowInvestments', 'estimateWithUsd', params, success, failure);
      };

      this.getRolloverAmount = function () {
        var total = 0;
        for (var i = 0; i < _this.resource.rollover_data.length; i++) {
          var amount = ss.toNumber(_this.resource.rollover_data[i].amount);
          total += amount;
        }
        return total;
      };

      this.recalculateRollover = function () {
        var rollover_amount = _this.getRolloverAmount(),
          new_capital = ss.toNumber(_this.resource.new_capital) || 0;

        _this.resource.amount = rollover_amount + new_capital;
        // Remove Amount errors
        _this.removeErrorsFor('amount');
        // Trigger a share calculation?
        _this.calculateShare(_this.resource.amount);
      };

      this.removeAdditionalProceeds = function (index) {
        if (_this.resource.rollover_data.length <= 1) {
          _this.resource.rollover_data[0] = {};
        } else {
          _this.resource.rollover_data.splice(index, 1);
        }
        _this.recalculateRollover();
        _this.removeErrorsFor('rollover_data');
      };

      this.removeErrorsFor = function (field) {
        if (_this.errors && _this.errors[field]) {
          delete _this.errors[field];
        }
      };

      this.showFundsTransferMethods = function () {
        if (_this.form && _this.form.funds_transfer_method) {
          if (!_this.resource.issuer_rollover) {
            return true;
          } else if (_this.resource.rollover === false || (_this.resource.rollover === true && ss.toNumber(_this.resource.new_capital) > 0)) {
            return true;
          }
        }
      };

      this.submit = function () {
        _this.submitted = true;
        // s is the invest_now_investment
        var success = function (s) {
          // On the investment step, s IS the invest_now_investment, while other steps have the invest_now_investment as a value
          var investment = s.invest_now_investment ? s.invest_now_investment : s;
          $ins.processing = false;
          $ins.investment(investment);
        };

        var failure = function () {
          $ins.processing = false;
        };

        $ins.processing = true;
        ss.submit(_this, 'investNowInvestments', 'update', $ins.access(), _this.resource, success, failure);
      };

      this.toggleMultivalue = function (field, key) {
        ss.toggleMultivalue(_this.resource, field, key, _this.validate);
      };

      this.updateAmount = function (bool) {
        if (bool) {
          _this.recalculateRollover();
        }
      };

      this.validate = function (field) {
        // NOTE: Mitch - each fields is validated here after input "blur" event
        // console.log('validate field', field)

        // This exists outside of validation, so just always do it
        if ($ins.invest_now_investment.state === 'investment' && field === 'amount') {
          _this.calculateShare(_this.resource.amount);
        }

        if (field === 'crypto_type') {
          _this.estimateWithUsd();
        }

        if (_this.submitted || field === 'routing_number') {
          var update = function (s, valid) {
            if (field === 'routing_number') {
              if (!_this.errors.routing_number) {
                var bank_success = function (bank) {
                  _this.bank_name = bank.resources[0].name;
                };

                var query = {
                  '_query':
                  {
                    'routing_number':
                      { '$eq': _this.resource.routing_number }
                  }
                };
                // Retrieve the bank information
                ss.call('fed_ach_banks', 'query', query, bank_success);
              } else {
                _this.bank_name = undefined;
              }
            }

            // Extra hack to clear out accredited error
            if (field === 'accreditation_type' && (_this.errors && _this.errors.accredited && !s.accredited)) {
              delete _this.errors.accredited;
            }

            // Relate the building_name and building_number fields, because only one is required
            if (field === 'street_number' || field === 'building_name') {
              var current_field_errors = _this.errors[field],
                opposites = {
                  building_name: 'street_number',
                  street_number: 'building_name'
                },
                opposite_field_errors = _this.errors[opposites[field]];

              if (!current_field_errors && opposite_field_errors && !s[opposites[field]]) {
                // Have to delete the key/value pair because you can't delete by refrerence
                delete _this.errors[opposites[field]];
              }
            }

            if (field === 'country' && _this.errors.postal_code && !s.postal_code) {
              delete _this.errors.postal_code;
            }

            // Handling to make annual_income/net_worth related to amount
            if ($ins.invest_now_investment.state === 'investor' && (field === 'annual_income' || field === 'net_worth')) {
              // If we validate either of these and amount is now valid, remove its errors
              if (valid || (_this.errors && _this.errors.amount && (s.data.amount === undefined))) {
                delete _this.errors.amount;
              }
              // If you modify either of these fields but they cause amount to error out, then display errors for amount too?
              if (!valid && s.data.amount) {
                _this.errors.amount = s.data.amount;
              }

            }

          };

          ss.validate(field, _this, 'investNowInvestments', 'validateEdit', $ins.access(), _this.resource, update, update);
        }
      };

      this.validateAmount = function () {
        if (_this.validate_amount_on_blur) {
          _this.validate_amount_on_blur = false;
          _this.validate('amount');
        }
      };

      $scope.$on('reload', function () {
        // Errors don't get cleared automatically
        _this.errors = undefined;
        _this.setup();
      });

      $scope.$on('submit', function () {
        _this.submit();
      });

      this.setup = function () {
        $ins.getState(_this, { 'factory': 'investNowInvestments', 'action': 'editState', 'options': $ins.access() });
      };

      this.setup();
    }])

    .controller('subscriptionAgreement', ['$scope', 'ss', '$ins', '$timeout', '$filter',
      function ($scope, ss, $ins, $timeout, $filter) {
        var _this = this,
          access = $ins.access();

        $ins.loading = _this.loading = true;

        this.printAgreement = function () {
          ss.printHTML(_this.resource.html);
        };

        this.setDefaults = function (callback) {
          _this.boolean = $ins.boolean;

        };

        this.submit = function () {
          _this.submitted = true;
          // s is the invest_now_investment
          var success = function (s) {
            var investment = s.invest_now_investment ? s.invest_now_investment : s;
            $ins.processing = false;
            // Transmit the success event, since this investment is now done
            $ins.successEvent(investment);
            $ins.investment(investment);
          };

          var failure = function () {
            $ins.processing = false;
          };

          $ins.processing = true;

          if (_this.resource.send_notifications_to_investors) {
            // If no one is signing, then we submit the subscription agreement
            var filtered_resource = $filter('investmentSubscriptionAgreement')(_this.resource);
            ss.submit(_this, 'investNowInvestments', 'update', access, filtered_resource, success, failure);
          } else {
            var need_to_submit = [];
            // Figure out which related actions already signed or will be sent a notification
            for (var i = 0; i < _this.related_actions.length; i++) {
              if (!_this.related_actions[i].resource.signed) {
                // Create array of only the indexes that need to be submitted
                need_to_submit.push(i);
              }
            }

            _this.submitSignature(need_to_submit);
          }
        };

        this.submitSignature = function (submit_array) {
          // generally we shouldn't ever hit a case where we submit with no signatures that need to be run
          // this is basically a way to safely exit out of that state and possibly fix things
          if (submit_array.length === 0) {
            $ins.processing = false;
            return $ins.page_errors = ['Sorry, something went wrong with the signature process. Please contact the issuer to finish your investment.'];
            // return _this.setup();
          }
          var index = submit_array.shift(),
            signature = _this.related_actions[index],
            resource = signature.resource,
            options = {
              'invest_now_investment_id': access.id,
              'id': resource.id
            };

          var success = function (s) {
            update(true, s);
          };

          var failure = function (e) {
            update(false, e);
          };

          var update = function (success, data) {
            signature.processing = false;
            if (success) {
              // if we succeeded, then set the resource to signed
              // Could also add additional logic if we need to differentiate between actually signing and sending a notification
              resource.signed = true;
            }

            // Check if there are any more signatures to submit
            if (submit_array.length === 0) {
              // We've hit the last in the line
              $ins.processing = false;
              if (success && data && data.invest_now_investment && (data.invest_now_investment.state !== 'subscription_agreement')) {
                // the state has changed, so we update things
                // We've submitted all the sigantures, proceed to final step, which is done by updatting the investment
                $ins.successEvent(data.invest_now_investment);
                $ins.investment(data.invest_now_investment);
              }

            } else {
              // There's more to process
              _this.submitSignature(submit_array);
            }
          };

          signature.processing = true;
          // Send the signature as "this" so so that errors will be attached to it
          ss.submit(signature, 'investNowInvestments', 'sign', options, resource, success, failure);
        };

        this.validateSignature = function (field, index) {
          if (field === 'send_notification' && _this.related_actions[index].resource.literal) {
            _this.related_actions[index].resource.literal = '';
          }

          if (_this.submitted) {
            var signature = _this.related_actions[index],
              resource = signature.resource,
              options = {
                'invest_now_investment_id': access.id,
                'id': resource.id
              };

            var update = function () {
              signature.processing = false;
              $ins.processing = false;
            };

            signature.processing = true;
            // Send the signature as "this" so so that errors will be attached to it
            ss.validate(field, signature, 'investNowInvestments', 'validateSignature', options, resource, update, update);
          }
        };

        $scope.$on('submit', function () {
          _this.submit();
        });

        this.setup = function () {
          $ins.getState(_this, { 'factory': 'investNowInvestments', 'action': 'editState', 'options': access });
        };

        this.setup();
      }])

    .controller('signatureRequest', ['$scope', 'ss', '$ins', '$timeout', '$filter',
      function ($scope, ss, $ins, $timeout, $filter) {
        var _this = this,
          access = {
            'token': $ins.resource_token
          };

        this.printAgreement = function () {
          ss.printHTML(_this.resource.document_html);
        };

        this.setDefaults = function () {
          var s = $scope.signature_request;
          _this.form = s.form;
          _this.resource = s ? (s.resource ? s.resource : s) : {};
          _this.description = s.description;

          // loadingTimeout();
        };

        this.submit = function () {
          _this.submitted = true;
          // s is the invest_now_investment
          var success = function (s) {
            var investment = s.invest_now_investment ? s.invest_now_investment : s;
            $ins.processing = false;
            // Transmit the success event, since this investment is now done
            $ins.successEvent(investment);
            $ins.investment(investment);
          };

          var failure = function () {
            $ins.processing = false;
          };

          $ins.processing = true;

          var filtered_resource = $filter('investmentSubscriptionAgreement')(_this.resource);
          ss.submit(_this, 'signatureRequests', 'update', access, filtered_resource, success, failure);
        };

        this.validate = function (field) {
          if (_this.submitted) {
            var update = function () {
              _this.processing = false;
              // set it to false, just in case?
              $ins.processing = false;
            };

            _this.processing = true;
            // Send the signature as "this" so so that errors will be attached to it
            ss.validate(field, _this, 'signatureRequests', 'validateEdit', access, _this.resource, update, update);
          }
        };

        $scope.$on('submit', function () {
          _this.submit();
        });

        this.setDefaults();

      }])

    .controller('getState', ['$scope', 'ss', '$ins', '$timeout',
      function ($scope, ss, $ins, $timeout) {
        var _this = this,
          access = $ins.access();

        $ins.loading = _this.loading = true;

        this.setDefaults = function (callback) {

          // _this.hasIntermediary = _this.resource.country !== 'US' && (_this.body.wire_details.intermediary_bank_name !== null && _this.body.wire_details.intermediary_bank_name !== undefined);

        };

        this.setup = function () {
          var factory = $ins.resource_token ? 'signatureRequests' : 'investNowInvestments',
            // Might be able to just use a static state, but this should be more dynamic
            options = $ins.resource_token ? { 'token': $ins.resource_token, 'state': $ins.invest_now_investment.state } : access;

          $ins.getState(_this, { 'factory': factory, 'action': 'get', 'options': options });
        };

        this.setup();
      }])

}