
/* injects from baggage-loader */

'use strict';

import CookiePolicyController from './cookie-policy.controller';
import CookiePolicyTemplate from './cookie-policy.html';

export default class CookiePolicyComponent {
		constructor() {
				this.templateUrl = CookiePolicyTemplate;
				this.controller = CookiePolicyController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
