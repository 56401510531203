
/* injects from baggage-loader */

'use strict';

import AccountSettingsNotificationsComponent from './account.settings.notifications.component';

const AccountSettingsNotificationsPageModule = angular.module('account-settings-notifications-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.notifications', {
        url: '/notifications',
        title: 'Account Settings: Notifications',
        component: 'accountSettingsNotifications',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('accountSettingsNotifications', new AccountSettingsNotificationsComponent());

export default AccountSettingsNotificationsPageModule;
