
/* injects from baggage-loader */

'use strict';

export default function (app) {

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// App environment label
	app.constant('ENVIRONMENT', 'staging');

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// Default page name displayed in the browser tab
	app.constant('DEFAULT_PAGE_TITLE', 'Common Owner (Staging)');

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// Map tile server
	app.constant('MAP_TILE_SERVER', 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}');

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// Fund America Invest Now API Key
	app.constant('FUND_AMERICA_API_KEY', '7USgtRvaJjaFf4hfTCpsG11zlSH_qxZy')

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// Google ReCaptcha Client Key
	app.constant('GOOGLE_RECAPTCHA_CLIENT_KEY', '6LfPN8cUAAAAAJHiif15mhchMrA6i0KfMlFHzvXQ')

	// NOTE: TEMPALTE REPLACEMENT HANDLED BY WEBPACK, DON'T EDIT MANUALLY
	// Google Analytics Tracking Tag
	app.constant('GOOGLE_ANALYTICS_TRACKING_TAG', 'UA-152899093-1')

	app.constant('EDUCATIONAL_MATERIALS_LINK', 'https://common-owner-content.nyc3.digitaloceanspaces.com/legal%2F2023-2-18%20Educational%20Materials.pdf');
	app.constant('ISSUER_ONBOARDING_DOCUMENT_URL', 'https://common-owner-content.nyc3.digitaloceanspaces.com/common/Common-Owner-Issuer-Onboarding.pdf')
	app.constant('OFFERING_PREP_DOCUMENT_URL', 'https://common-owner-content.nyc3.digitaloceanspaces.com/common/Offering%20Prep%20Overview_2021.pdf')

	// The number of invstors before the count will show
	app.constant('INVESTOR_COUNT_BUFFER', 5);

	app.constant('INVESTMENT_PAYMENT_METHODS',
		[{
			name: 'ACH/Bank',
			code: 'ACH'
		}, {
			name: 'Wire',
			code: 'WIRE'
		}, {
			name: 'Mail a Check',
			code: 'CHECK'
		}, {
			name: 'Credit Card',
			code: 'CREDITCARD'
		}, {
			name: 'IRA',
			code: 'IRA'
		}]
	);

	app.constant('ACH_ACCOUNT_TYPES',
		[{
			name: 'Checking',
			code: 'Checking'
		}, {
			name: 'Savings',
			code: 'Savings'
		}]
	);

	app.constant('INVESTMENT_TYPES',
		[{
			name: 'Individual',
			code: 'Individual'
		}, {
			name: 'Entity',
			code: 'Entity'
		}, {
			name: 'Tenant In Common ',
			code: 'TIC'
		}, {
			name: 'Joint Tenants with Right of Survivorship',
			code: 'JTWROS'
		}, {
			name: 'IRA',
			code: 'IRA'
		}, {
			name: 'Sep IRA',
			code: 'SepIRA'
		}, {
			name: 'Roth IRA',
			code: 'ROTH'
		}, {
			name: 'Joint',
			code: 'Joint'
		}]
	);

	app.constant('INVESTOR_DOMICILE_TYPES',
		[{
			name: 'U.S. Citizen',
			code: 'U.S. Citizen'
		}, {
			name: 'U.S. Resident',
			code: 'U.S. Resident'
		}, {
			name: 'Not a U.S. Resident',
			code: 'non-resident'
		}]
	);

	app.constant('INVESTOR_ENTITY_TYPES',
		[{
			name: 'Revocable Trust',
			code: 'Revocable Trust'
		}, {
			name: 'Irrevocable Trust',
			code: 'Irrevocable Trust'
		}, {
			name: 'Limited Partnership',
			code: 'Limited Partnership'
		}, {
			name: 'LLC',
			code: 'LLC'
		}, {
			name: 'Corporation',
			code: 'Corporation'
		}]
	);

}