
/* injects from baggage-loader */

'use strict';

import ProjectComponent from './project.component';
import './project.scss';

const ProjectPageModule = angular.module('project-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('project', {
        parent: 'root',
        url: '/project/{id}',
        component: 'project',
        // TODO: update project page title with title of project in controller $onInit
        title: 'Project',
        resolve: {
          project: function (Projects, $transition$, MediaSchema, ProjectStatsSchema, ProfileSchema, ProjectUpdateSchema) {
            return Projects.getBySlugOrId($transition$.params('to').id)
              .then(project => {
                if (!project) {
                  throw 'Project not found';
                }
                return project;
              });
          },
          documents: function (project) {
            let query = project.relation('documents').query();
            query.ascending("createdAt");
            return query.find();
          },
          comments: function (project) {
            let query = project.relation('comments').query();
            query.descending("createdAt");
            query.include(['author', 'author.image']);
            query.select([
              'author.first_name', 'author.last_name', 'text', 'author_tag',
              'parent', 'project', 'isPromoter', 'isPromoterCompensated'
            ])
            return query.find();
          },
          images: function (project) {
            let query = project.get('gallery').query();
            query.ascending('position');
            return query.find();
          },
          updates: function (project) {
            let query = project.get('updates').query();
            query.descending("createdAt");
            query.equalTo("published", true);
            return query.find();
          },
          // Set dynamic metadata tags for the project page
          meta: function(project, images, MetaTags, $window) {
            MetaTags.set({
              title: project.getMetadataTitle(),
              description: project.getMetadataDescription(),
              image: project.getMetadataImageUrl(images)
            })
          },
          // Get metrics for Testing the Waters enabled offering
          testingMetrics: function(project, Projects) {
            if (!project.get('status') === 'testing') {
              return {};
            }
            return Projects.getTestingMetrics(project.id);
          }
        }
      })
      .state('project-preview', {
        parent: 'root',
        url: '/project-preview/{id}',
        component: 'project',
        // TODO: update project page title with title of project in controller $onInit
        title: 'Project Preview',
        data: {
          preview: true
        },
        resolve: {
          project: function (Projects, $transition$, MediaSchema, ProjectStatsSchema, ProfileSchema) {
            return Projects.getBySlugOrId($transition$.params('to').id)
              .then(project => {
                if (!project) {
                  throw 'Project not found';
                }
                return project;
              });
          },
          documents: function (project) {
            let query = project.relation('documents').query();
            query.ascending("createdAt");
            return query.find();
          },
          comments: function (project) {
            let query = project.relation('comments').query();
            query.descending("createdAt");
            query.include(['author', 'author.image']);
            query.select(['author.first_name', 'author.last_name', 'text', 'author_tag', 'parent', 'project'])
            return query.find();
          },
          images: function (project) {
            let query = project.get('gallery').query();
            query.ascending('position');
            return query.find();
          }
        }
      })


  })
  .run(function ($transitions, $state, $rootScope, Errors) {

    // Handle setup on success entering the project page
    $transitions.onSuccess({ to: 'project' }, transition => {

      // Unlock the page header so the project scrollspy can perform
      $rootScope.appState.unlockHeader = true;

    });

    // Handle an error encountered while entering the project page
    $transitions.onError({ to: 'project' }, transition => {

      // Get the error code 
      let code = transition.error().detail.message;

      // Retrieve the error message
      let error = Errors[code];

      // TODO: handle this better by taking the user to an erro page
      // Display the error message
      if (typeof error !== 'undefined') {
        alert(error.message);
      }

      // Redirect to handle the error
      if (typeof error !== 'undefined' && typeof error.redirectTo === 'string') {
        $state.go(error.redirectTo);
      } else {
        $state.go('projects');
      }

    });

  })
  .component('project', new ProjectComponent());

export default ProjectPageModule;
