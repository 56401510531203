
/* injects from baggage-loader */

'use strict';

export default class InvestmentReconfirmationModalController {

	constructor($log, $timeout, Parse) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.Parse = Parse;

		this.error_msg = '';

	}

	async $onInit() {

		// Set modal context
		if (this.resolve) {
			this.ctx = this.resolve.context;
		}

		this.reconfirmation = this.ctx.reconfirmation;
		this.investment = this.ctx.investment;
		this.project = this.ctx.investment.project;
		// this.termsUpdate = this.ctx.termsUpdate;

		// Opened, set ack to true
		this.reconfirmation.set('ack', true);
		this.reconfirmation.save();

		let query = new this.Parse.Query('Project_Updates_Terms');
		this.termsUpdate = await query.equalTo('project', this.investment.project).descending('createdAt').first();
		this.$timeout();

	}

	async accept() {
		this.reconfirming = true;
		this.error_msg = '';

		try {

			this.reconfirmation.set('confirmed', true);
			await this.reconfirmation.save();

			this.close({ $value: {
				success: true
			} });

		} catch (e) {
			this.reconfirming = false;
			this.error_msg = 'Failed to reconfirm investment. Please contact support if this continues.';
			this.$timeout();
		}

	}

	async deny() {
		this.close({ $value: {
			cancelInvestment: true
		} });
	}

	close() {
		this.dismiss({ $value: 'cancel' });
	}

}
