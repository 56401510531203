
/* injects from baggage-loader */

'use strict';

import OnboardingConfirmationComponent from './onboarding-confirmation.component';

const OnboardingConfirmationPageModule = angular.module('onboarding-confirmation-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('onboarding-confirmation', {
        url: '/onboarding/confirmation/{id}',
        params: {
          isExistingUser: false
        },
        component: 'onboardingConfirmation',
        title: 'Issuer Onboarding: Confirmation',
        localTitle: 'Onboarding Confirmation',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('onboardingConfirmation', new OnboardingConfirmationComponent());

export default OnboardingConfirmationPageModule;
