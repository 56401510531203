
/* injects from baggage-loader */

'use strict';

export default class HeaderController {
  constructor($log, $rootScope, User, $state, $timeout, Navigation, UserSchema, Notifications) {
    'ngInject';

    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.userService = User;
    this.navigationService = Navigation;
    this.notificationsService = Notifications;

    this.user = {};
    this.isUserAdmin = false;

    // Links shown on the header
    this.links = [
      {
        title: 'Projects',
        state: 'projects'
      },
      {
        title: 'About',
        state: 'about'
      },
      {
        title: 'Learn',
        state: 'learn'
      },
      {
        title: 'Blog',
        href: 'https://blog.commonowner.com'
      }
    ];

    // Account dropdown links
    this.account_links = [
      {
        title: 'My Account',
        state: 'account.home',
        class: 'btn-primary'
      },
      {
        title: 'Logout',
        state: 'accountLogout',
        class: 'btn-outline-primary'
      }
    ]

  }

  $onInit() {

    // Current user account
    this.user = this.userService.getCurrent();

    // Check if user is admin
    // this.isUserAdmin = false; //this.userService.isUserAdmin();

    // Listen for updates to the user profile
    this.$rootScope.$on('userProfileUpdated', async event => {
      // Refresh current user
      this.user = this.userService.getCurrent();
      if (this.user) {
        this.user = await this.user.fetch();
        this.$timeout()
      }
    });

    // this.toggleNotificationsDropdown();

  }

  /**
   * Return the current user else returns false
   */
  userLoggedIn() {
    return this.userService.isUserLoggedIn();
  }

  toggleAccountDropdown() {
    this.navigationService.toggleAccountDropdown();
  }

  toggleMobileMenu() {
    this.navigationService.toggleMobileMenu();
  }

  toggleNotificationsDropdown() {
    this.navigationService.toggleNotificationsDropdown();
  }

  getUserFullName() {
    if (!this.user && this.userLoggedIn()) {
      this.user = this.userService.getCurrent();
    }
    return this.user.get('full_name').split(' ')[0];
  }

  closeDropdowns() {
    this.navigationService.showAccountDropdown = false;
    this.navigationService.showNotificationsDropdown = false;
  }

  notificationClicked(notification) {
    if (notification.unread) {
      this.notificationsService.markRead(notification);
    }
    if (notification.target) {
      this.navigationService.toggleNotificationsDropdown();
      this.navigationService.navigate(notification.target);
    }
    this.$timeout();
  }

  markAllAsRead() {
    this.notificationsService.markAllAsRead();
    this.navigationService.toggleNotificationsDropdown();
    this.$timeout();
  }

}