
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.filter('capitalize', function () {
		return function(input, scope) {
			if (!input) {
				return input;
			}
			input = input.toLowerCase();
			return input.substring(0,1).toUpperCase()+input.substring(1);
		}
	});
}