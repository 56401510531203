
/* injects from baggage-loader */

'use strict';
import * as $ from 'jquery';

export default function (app) {
	app.service('FormBuilder', function ($log, $timeout) {

		// let FormGroup = class {

		// 	constructor() {

		// 	}

		// }

		this.merge = function (one, two) {
			return Object.assign(one, two);
		}

		this.Events = {

			onChange: function (fn, key) {
				return {
					name: 'onChange',
					update: function (value) {
						if (typeof fn === 'function') {
							fn(key, value);
						}
					}
				}
			}

		};


		this.Constraints = {

			numericOnly: {
				name: 'numericOnly',
				constrain: function (e, elem) {
					const initialVal = elem.val()
					if (e && e.key) {
						const digits = initialVal.replace(/[^0-9]/g, '');
						if (!e.key.replace(/[^0-9]/g, '').length || initialVal !== digits) {
							e.preventDefault();
							e.stopPropagation();
							elem.val(digits);
						}
						return digits;
					}
					return initialVal;
				}
			}

		}

		this.Validators = {

			/**
			 * Value is required
			 */
			required: {
				name: 'required',
				validate: function (value) {
					// $log.debug('require', value)
					return typeof value !== 'undefined' && value !== null && value !== '';
				}
			},

			/**
			 * Value should equal the defined length
			 */
			length: function (length) {
				return {
					name: 'length',
					validate: function (value) {
						return value.length === length || typeof value.length === 'undefined';
					}
				}
			},

			/**
			 * Value should not exceed the defined max length
			 */
			maxLength: function (length) {
				return {
					name: 'maxLength',
					validate: function (value) {
						// $log.debug('maxLength', value, value.length);
						return value.length <= length || typeof value.length === 'undefined';
					}
				}
			},

			maxValue: function (max) {
				return {
					name: 'maxValue',
					validate: function (value) {
						// $log.debug('maxValue', value);
						if (!value) {
							return true;
						}
						if (Number(value) > max) {
							return false;
						}
						return true;
					}
				}
			},

			minValue: function (min) {
				return {
					name: 'minValue',
					validate: function (value) {
						if (!value) {
							return true;
						}
						if (Number(value) < min) {
							return false;
						}
						return true;
					}
				}
			},

			multipleOf: function (multiple) {
				return {
					name: 'multipleOf',
					validate: function (value) {
						if (!value || value === 1) {
							return true;
						}
						if (value % multiple > 0) {
							return false;
						}
						return true;
					}
				}
			},

			/**
			 * Value should be a valid email address
			 */
			email: {
				name: 'email',
				validate: function (value) {
					// $log.debug('email', value)
					const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return re.test(String(value).toLowerCase());
				}
			},

			/**
			 * At least one value in the group must be present
			 */
			requireOne: {
				name: 'requireOne',
				validate: function (controls) {
					// $log.debug('requireOne', controls)
					for (let key in controls) {
						if (controls[key].value) return true;
					}
					return false;
				}
			},

			numericOnly: {
				name: 'numericOnly',
				validate: function (value) {
					if (typeof value === 'undefined') {
						return true;
					}
					if (value !== value.replace(/[^0-9]/g, '')) {
						return false;
					}
					return true;
				}
			},

			enum: function (values = []) {
				return {
					name: 'enum',
					validate: function (value) {
						return value ? values.includes(value) : true;
					}
				}
			},

			custom: function (fn) {
				return {
					name: 'custom',
					validate: function (value) {
						if (typeof fn !== 'function') {
							return true;
						}
						return fn(value);
					}
				}
			}

		}

		this.group = (elements, validators) => {

			// $log.debug('new group', elements, validators)

			// Create new form group
			let formGroup = {
				type: 'FormGroup',
				errors: {},
				controls: {},
				validators: validators instanceof Array ? validators.reduce((vs, current) => {
					vs[current.name] = current.validate
					return vs;
				}, {}) : {},
				valid: function (skipSettingErrors = false) {
					this.errors = {};
					for (let key in this.controls) {
						if (!this.controls[key].validate(null, null, skipSettingErrors) || Object.keys(this.controls[key].errors).length) {
							this.errors[key] = this.controls[key].errors;
						}
					}
					return Object.keys(this.errors).length === 0;
				},
				invalid: false,
				value: function () {
					return getValues(this.controls)
				},
				get: function (name) {
					return this.controls[name];
				},
				markAllAsTouched: function () {
					for (let key in this.controls) {
						// $log.debug(key, this.controls[key])
						if (this.controls[key].type === 'FormGroup') {
							this.controls[key].validate()
						} else {
							this.controls[key].validate()
						}
					}
				},
				markAsDirty: function () {

				},
				markAsPristine: function () {

				},
				markAsTouched: function () {

				},
				markAsUntouched: function () {

				},
				patchValue: function (values) {
					// $log.debug(values)
					for (let key in values) {
						if (this.controls[key]) {
							// $log.debug( this.controls[key].type, values[key])
							if (this.controls[key] && this.controls[key].type === 'FormGroup') {
								this.controls[key].patchValue(values[key])
							} else {
								this.controls[key].value = values[key];
							}
						}
					}
				},
				reset: function () {
					for (let key in this.controls) {
						this.controls[key].reset();
					}
				},
				resetWithExclusions: function (exclude = []) {
					for (let key in this.controls) {
						if (exclude.includes(key)) {
							continue;
						}
						this.controls[key].reset();
					}
				},
				validate: function (e) {

					// $log.log('validate', this.name)

					// Find the element by "formControl" attribute
					let selector = `[formGroup="${this.name}"]`;
					let element = $(selector);
					if (!element) {
						throw `Error: element ${selector} not found`;
					}

					// Reset validations
					this.errors = {};
					element.removeClass('is-invalid');
					// element.removeClass('is-valid');

					for (let key in this.validators) {
						if (!this.validators[key](this.controls)) {
							this.errors[key] = true;
							element.addClass('is-invalid')
						}
					}

					let valid = Object.keys(this.errors).length === 0;
					// if (valid) {
					// 	element.addClass('is-valid')
					// }
					return valid;

				},
				initialize: function () {
					for (let key in elements) {
						let elem;
						if (elements[key].type === 'FormGroup') {
							elem = $(`[formGroup="${key}"]`);
						} else {
							elem = $(`[formControl="${key}"]`);
						}
						// $log.log(key, elem)

						// Find the element by "formControl" attribute
						if (!elem[0]) {
							// $log.error(`Error: element [formControl="${key}"] not found`);
							continue;
						}


						if (typeof elements[key] === 'object' && elements[key].type === 'FormGroup') {
							formGroup.controls[key] = formGroup.controls[key] || elements[key];
						} else {
							formGroup.controls[key] = formGroup.controls[key] || control(key, elements[key]);

							// Setup change listeners on input elements
							if ((elem[0].tagName === 'INPUT' || elem[0].tagName === 'TEXTAREA' || elem[0].tagName === 'SELECT') && elem[0].type !== 'checkbox') {
								elem.on(`keypress`, e => {
									formGroup.controls[key].validateConstraints(e);
								})
								elem.on(`keyup`, e => {
									formGroup.controls[key].validateConstraints(e);
									formGroup.controls[key].markAsTouched(e);
								});
								elem.on(`blur`, e => {
									formGroup.controls[key].markAsTouched(e);
								});

							}
							else if (elem[0].tagName === 'INPUT' && elem[0].type === 'checkbox') {
								elem.on(`change`, e => $timeout(() => formGroup.validate(e), 10));
							}

						}

					}
				}
			}

			// Compile form group controls
			formGroup.initialize();

			// $log.debug(formGroup)

			return formGroup;

		}

		function control(name, options = []) {
			let initialValue = options instanceof Array ? options.shift() : options;
			return {
				type: 'FormControl',
				name,
				value: initialValue,
				initialValue: initialValue,
				errors: {},
				validators: options instanceof Array ? options.reduce((validators, current) => {
					if (typeof current.validate === 'function') {
						validators[current.name] = current.validate
					}
					return validators;
				}, {}) : {},
				constraints: options instanceof Array ? options.reduce((constraints, current) => {
					if (typeof current.constrain === 'function') {
						constraints[current.name] = current.constrain
					}
					return constraints;
				}, {}) : {},
				events: options instanceof Array ? options.reduce((events, current) => {
					if (typeof current.update === 'function') {
						events[current.name] = current.update
					}
					return events;
				}, {}) : {},
				reset: function () {
					this.value = this.initialValue;
					this.evaluteEvents();
				},
				addValidator: function (validator, customName) {
					if (typeof validator.validate === 'function') {
						this.validators[customName || validator.name] = validator.validate;
						this.validate();
					} else {
						$log.error('Invalid validator');
					}
				},
				removeValidator: function (validator) {
					let validatorName = typeof validator === 'string'
						? validator
						: validator.name;
					delete this.validators[validatorName];
					this.validate();
				},
				removeValidators: function (validators = []) {
					validators.forEach(validator => this.removeValidator(validator));
				},
				evaluteEvents: function (e) {
					for (let key in this.events) {
						// Evalute the event
						this.events[key](this.value);
					}
				},
				validateConstraints: function (e) {

					// Find the element by "formControl" attribute
					let selector = `[formControl="${this.name}"]`;
					let element = $(selector);
					if (!element) {
						throw `Error: element ${selector} not found`;
					}

					for (let key in this.constraints) {
						// Run the constraint
						this.value = this.constraints[key](e, element);
					}
				},
				validate: function (e, validateOnly, skipSettingErrors = false) {

					// Find the element by "formControl" attribute
					let selector = `[formControl="${this.name}"]`;
					let element = $(selector);
					if (!element) {
						throw `Error: element ${selector} not found`;
					}

					// Reset validations
					if (!skipSettingErrors) {
						this.errors = {};
						element.removeClass('is-invalid');
						// Check if the input is masked by a directive and has a input sibling element
						if (element[0] && element[0].nextElementSibling) {
							$(element[0].nextElementSibling).removeClass('is-invalid');
						}
					}
					// element.removeClass('is-valid');

					for (let key in this.validators) {
						// Only validate the passed validations
						if (validateOnly && !validateOnly.includes(key)) {
							continue;
						}

						if (skipSettingErrors && !this.validators[key](this.value)) {
							return false;
						}

						if (!this.validators[key](this.value)) {
							this.errors[key] = true;
							element.addClass('is-invalid');

							// Check if the input is masked by a directive and has a input sibling element
							if (element[0] && element[0].nextElementSibling) {
								$(element[0].nextElementSibling).addClass('is-invalid');
							}
							break;
						}
					}

					let valid = Object.keys(this.errors).length === 0;
					// NOTE: this will turn the field outline green
					// if (valid) {
					// 	element.addClass('is-valid')
					// }
					return valid;

				},
				valid: function () {
					return this.validate();
				},
				markAsTouched: function (e) {
					this.validateConstraints(e);
					this.evaluteEvents(e);
					this.validate(e);
					$timeout();
				}
			}
		}

		function getValues(controls) {
			let values = {};
			for (let key in controls) {
				// $log.debug(controls[key])
				if (controls[key].type === 'FormGroup') {
					// Return the values of the FormGroup
					values[key] = controls[key].value()
				} else if (typeof controls[key].value !== 'undefined') {
					// Return the value of the FormControl
					values[key] = controls[key].value;
				}
			}
			return values;
		}

	});
}

