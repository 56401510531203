
/* injects from baggage-loader */

'use strict';

export default class NorthCapitalWireInstructionsController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {
		// this.$log.debug('Issuer Name:', this.issuerName);
		// this.$log.debug('Investor Name:', this.investorName);
	}

}
