
/* injects from baggage-loader */

'use strict';

import OnboardingConfirmationController from './onboarding-confirmation.controller';
import OnboardingConfirmationTemplate from './onboarding-confirmation.html';

export default class OnboardingConfirmationComponent {
		constructor() {
				this.templateUrl = OnboardingConfirmationTemplate;
				this.controller = OnboardingConfirmationController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
