
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('MediaSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Media = Parse.Object.extend('Media');


		// Define 'Media' schema
		let schema = [
			'src',
			'type',
			'size',
			'tags',
			'file',
			'name',
			'original_name',
			'position'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Media.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
    });
    
    return Media;
		
	});
}