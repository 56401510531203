
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('OnboardingSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Onboarding = Parse.Object.extend('Onboarding');

		// Define 'Investment' scheme
		let schema = [
			'submitted',
			'complete',
			'completedAt',
			'documents',
			'project_title',
			'documentProgress',
			'creator'
		];

		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Onboarding.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});	
    
    return Onboarding;
		
	});
}