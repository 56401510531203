
/* injects from baggage-loader */

'use strict';

import ProjectManagerHomeComponent from './projectmanager.home.component';

const ProjectManagerHomePageModule = angular.module('manage-project-home-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.home', {
        url: '/home',
        title: 'Project Manager: Overview',
        localTitle: 'Overview',
        // Restrict to authenticated users
        protected: true,
        component: 'projectManagerHome',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          offering: function (project, Parse, $q) {
            if (project.raise_id) {
              return Parse.Cloud.run('getOffering', { id: project.raise_id, project_id: project.id })
            } else {
              return $q.resolve({});
            }
          },
          activityFeed: function(project, ProjectActivity) {
            return ProjectActivity.listFeed(project);
          }
        }
      });
  })
  .component('projectManagerHome', new ProjectManagerHomeComponent());

export default ProjectManagerHomePageModule;
