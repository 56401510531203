
/* injects from baggage-loader */

'use strict';

import ProjectManagerDocumentsComponent from './projectmanager.documents.component';

const ProjectManagerDocumentsPageModule = angular.module('project-manager-documents-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.documents', {
        url: '/documents',
        title: 'Project Manager: Documents',
        localTitle: 'Documents',
        component: 'projectManagerDocuments',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          documents: function(project, ProjectDocumentSchema) {
            let query = project.relation("documents").query();
            return query.find();
          }
        }
      });
  })
  .component('projectManagerDocuments', new ProjectManagerDocumentsComponent());

export default ProjectManagerDocumentsPageModule;
