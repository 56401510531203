
/* injects from baggage-loader */

'use strict';

import EmailConfirmedComponent from './email-confirmed.component';

const EmailConfirmedPageModule = angular.module('email-confirmed-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('email-confirmed', {
        url: '/account/email-confirmed?username',
        component: 'emailConfirmed',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .run(function ($transitions, $location, $rootScope, User) {

    // Handle entrance to login page when user is already logged in
    // $transitions.onEnter({ to: 'email-confirmed' }, transition => {
    //   if (User.isUserLoggedIn() === true) {
    //     // $location.path('/account/home');
    //     return transition.router.stateService.target('account.home');
    //   }
    // });

    $transitions.onSuccess({ to: 'email-confirmed' }, transition => {
      // Hide the header
      $rootScope.appState.hideHeader = true;
      // Hide the footer
      $rootScope.appState.hideFooter = true;
    });

  })
  .component('emailConfirmed', new EmailConfirmedComponent());

export default EmailConfirmedPageModule;
