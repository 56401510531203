
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerAdminController {

	constructor($log, $timeout, $state, $uibModal, Snackbar, Navigation) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$state = $state;
		this.snackbar = Snackbar;
		this.modalService = $uibModal;
		this.navigation = Navigation;

		this.savingData = false;

	}

	$onInit() {

		this.raiseIdFieldName = this.getUniqueName('projectRaiseId');

		// Setup a navigation guard on the project data dirty() function
		this.navigation.setGuard({
			message: 'Are you sure you want to abandon these changes?',
			fn: () => this.project.dirty()
		});

		this.assignedIds = this.projects.filter(p => p.raise_id).map(p => p.raise_id);
		this.unassignedOfferings = this.offerings //.filter(off => !this.assignedIds.includes(off.id));


	}

	$onDestroy() {

		// Release the navigation guard
		this.navigation.releaseGuard();

		// Discard cahnges
		this.discardChanges();

	}

	getUniqueName(name) {
		return `${name}-${new Date().getTime()}`
	}

	setOfferingId(offering) {
		this.project.raise_id = offering.id;
	}

	isFormDirty() {
		return this.project.dirty();
	}

	deleteProject() {
		if (!this.confirmDelete) {
			this.confirmDelete = true;
		} else {
			this.modalService.open({
				animation: true,
				component: 'modalComponent',
				resolve: {
					context: function () {
						return {
							title: 'Delete project',
							body: 'Are you sure you want to delete this project and all data associated with it?',
							alert: {
								icon: 'fas fa-exclamation-triangle',
								message: 'You are about to permanently delete this project.'
							},
							list: [
								'Project',
								'Documents',
								'Images and media',
								'Comments'
							],
							confirmBtnText: 'Delete',
							cancelBtnText: 'Cancel'
						};
					}
				}
			}).result.then(() => {
				this.confirmDelete = false;
				this.project.destroy().then(p => {
					this.snackbar.create('Project deleted', 4000, ['bg-success']);
					this.$state.go('account.projects');
				})
					.catch(err => {
						this.$log.error(err);
						this.snackbar.create(err.toString().replace('ParseError: 141', '').trim(), 5000, ['bg-danger']);
					})
			}, () => {
				this.confirmDelete = false;
			});
		}
	}


	openIssueTermsUpdateModal() {
		this.modalService.open({
			animation: true,
			component: 'issueProjectTermsUpdateModal',
			resolve: {
				context: () => ({ project: this.project })
			}
		}).result.then(res => {
			if (res.termsUpdate) {
				this.updates.push(res.termsUpdate);
				this.snackbar.create('Project terms update issued', 5000, ['bg-success']);
			}
		}, angular.noop);
	}


	/**
	 * Discards any changes to the object
	 */
	async discardChanges() {
		this.project.revert();
	}


	/**
	 * Apply changes to the object
	 */
	saveChanges() {
		this.savingData = true;
		this.project.save().then(() => {
			this.savingData = false;
			this.$timeout();
			this.snackbar.create('Changed saved', 3000, ['bg-success'])
		})
			.catch(err => {
				// this.$log.error(err);
				this.savingData = false;
				this.$timeout();
				err = err.toString().replace('ParseError: 141', '').trim();
				this.snackbar.create(err, 5000, ['bg-danger'])
			})
	}

}
