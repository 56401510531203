
/* injects from baggage-loader */

'use strict';

export default class ExpressEarlyInterestModalController {

	constructor($log, $timeout, User, ProjectInterestSchema, FormBuilder) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.User = User;
		this.ProjectInterest = ProjectInterestSchema;
		this.fb = FormBuilder;
		this.Validators = this.fb.Validators;

		this.project = {};
		this.user = this.User.getCurrent();
		this.feedbackForm = {};

		this.savingForm = false;

		this.interestRadioOptions = [{
			id: "interest_uninterested",
			name: "Not Interested",
			value: "Not Interested",
		}, {
			id: "interest_neutral",
			name: "Neutral",
			value: "Neutral",
		},{
			id: "interest_interested",
			name: "Interested",
			value: "Interested",
		},{
			id: "interest_VeryInterested",
			name: "Very Interested",
			value: "Very Interested",
		}];

		// Options:
		// 
		// $0
		// $100 - $500
		// $500 - $1,000
		// $1,000 - $2,500
		// $2,500 - $5,000
		// $5,000 - $10,000
		// $10,000 - $25,000
		this.investmentRangeRadioOptions = [{
			id: "interest_level_0",
			name: "$0",
			value: "$0",
		}, {
			id: "interest_level_1",
			name: "$100 - $500",
			value: "$100 - $500"
		}, {
			id: "interest_level_2",
			name: "$500 - $1,000",
			value: "$500 - $1,000",
		}, {
			id: "interest_level_3",
			name: "$1,000 - $2,500",
			value: "$1,000 - $2,500",
		}, {
			id: "interest_level_4",
			name: "$2,500 - $5,000",
			value: "$2,500 - $5,000",
		},{
			id: "interest_level_5",
			name: "$5,000 - $10,000",
			value: "$5,000 - $10,000",
		},{
			id: "interest_level_6",
			name: "$10,000 - $25,000",
			value: "$10,000 - $25,000",
		},{
			id: "interest_level_7",
			name: "$25,000+",
			value: "$25,000+",
		}];

		// Options: 
		//
		// Common Stock
		// Preferred Stock
		// Promissory Notes
		// Convertible Notes
		// Simple Agreements for Future Equity (SAFEs)
		// Revenue Share Agreement
		// Something else
		// I'm not sure
		this.investmentInterestRadioOptions = [{
			id: "interest_type_0",
			name: "Common Stock",
			value: "Common Stock",
		}, {
			id: "interest_type_1",
			name: "Preferred Stock",
			value: "Preferred Stock",
		}, {
			id: "interest_type_2",
			name: "Promissory Notes",
			value: "Promissory Notes",
		},{
			id: "interest_type_3",
			name: "Convertible Notes",
			value: "Convertible Notes",
		},{
			id: "interest_type_4",
			name: "Simple Agreements for Future Equity (SAFEs)",
			value: "Simple Agreements for Future Equity (SAFEs)",
		},{
			id: "interest_type_5",
			name: "Revenue Share Agreement",
			value: "Revenue Share Agreement",
		},{
			id: "interest_type_6",
			name: "Something else",
			value: "Something else",
		},{
			id: "interest_type_7",
			name: "Anything!",
			value: "Anything!",
		}];

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;

		// Set context data
		this.project = this.context.project;

		this.feedbackForm = this.fb.group({
			first_name: ['', this.Validators.required],
			last_name: ['', this.Validators.required],
			email: ['', this.Validators.required, this.Validators.email],
			phone: [''],
			interest_level: [''],
			investment_amount: [''],
			why_interested: [''],
			// preferred_investments: [''],
			// is_accredited: [''],
		});

		// Inject the current user details into the form
		if (this.user) {
			this.user.get('profile').fetch()
				.then(profile => {
					profile = profile.toJSON();
					this.feedbackForm.patchValue({
						first_name: profile.first_name,
						last_name: profile.last_name,
						email: profile.email
					});
					this.$timeout();
				});
		}

		this.$log.debug('form', this.feedbackForm);

		// Wait to re-initialize after dynamically rendered fields enter the DOM
		this.$timeout(() => {
			this.feedbackForm.initialize();
		}, 250);

	}

	ok() {

		// console.log(this.feedbackForm.value());
		// return;

		this.feedbackForm.markAllAsTouched();

		if (this.feedbackForm.valid()) {
			this.savingForm = true;
			const form = this.feedbackForm.value();

			form.project = this.project;
			form.user = this.user;

		// Create Project_Interest object, "feedback"
		let feedback = new this.ProjectInterest(form);
		feedback.save()
			.then(_feedback => {

				// TODO: create statement to show user thank you for you feedback if logged in
				// 	else: show them to shortned signup form to complete account creation

				this.close({
					$value: _feedback
				});
			})

		} else {
			this.savingForm = false;
			this.$window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
