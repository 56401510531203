
/* injects from baggage-loader */

'use strict';

import ProjectManagerIssuerComponent from './projectmanager.issuer.component';

const ProjectManagerIssuerPageModule = angular.module('project-manager-issuer-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.issuer', {
        url: '/issuer',
        // Restrict to authenticated users
        protected: true,
        component: 'projectManagerIssuer',
        title: 'Project Manager: Issuer',
        localTitle: 'Issuer',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          issuer: function (project, Profiles) {
            return Profiles.get(project.sponsor.id);
          }
        }
      });
  })
  .run(function ($transitions, Navigation) {
    // Setup navigation guard
    $transitions.onStart({ from: 'projectmanager.issuer' }, () => Navigation.checkGuard());
  })
  .component('projectManagerIssuer', new ProjectManagerIssuerComponent());

export default ProjectManagerIssuerPageModule;
