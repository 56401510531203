
/* injects from baggage-loader */

'use strict';

import InvestorDocumentModalComponent from './investordocumentmodal.component';
import './investordocumentmodal.scss';

const InvestorDocumentModalModule = angular.module('investor-document-modal-module', []);

InvestorDocumentModalModule
    .component('investorDocumentModal', new InvestorDocumentModalComponent());

export default InvestorDocumentModalModule;

