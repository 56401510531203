
/* injects from baggage-loader */

'use strict';

import InvestmentAdvisoryModalComponent from './investmentadvisorymodal.component';
import './investmentadvisorymodal.scss';

const InvestmentAdvisoryModalModule = angular.module('investment-advisory-modal-module', []);

InvestmentAdvisoryModalModule
    .component('investmentAdvisoryModal', new InvestmentAdvisoryModalComponent());

export default InvestmentAdvisoryModalModule;

