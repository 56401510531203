
/* injects from baggage-loader */

'use strict';

export default class InvestmentAdvisoryModalController {

	constructor($log, InvestmentSchema, User, EDUCATIONAL_MATERIALS_LINK) {
		'ngInject';

		this.$log = $log;
		this.Investment = InvestmentSchema;
		this.User = User;
		this.eduMaterialsLink = EDUCATIONAL_MATERIALS_LINK;

		this.processing = false;

		this.initials = '';
		this.investmentChecklist = [];

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;

		// Set the project
		this.project = this.context.project;
		this.investment = this.context.investment;

		this.investmentChecklist = [
			{
				value: '',
				statement: `"My ability to cancel an investment commitment and obtain a return of my investment is restricted."`
			},
			{
				value: '',
				statement: `"It may be difficult for me to resell Securities acquired in this, or any other investment, made in reliance on the ${this.project.type === 'cf' ? 'Crowdfunding Exemption' : 'Rule 506c'}."`
			},
			{
				value: '',
				statement: `"I understand that Investing in securities offered and sold in reliance on the ${this.project.type === 'cf' ? 'Crowdfunding Exemption' : 'Rule 506c'} involves risk, and I can afford to lose the entire amount of my investment."`
			},

		];

		// Add additional checklist item if project type "CF"
		if (this.project.type === 'cf') {
			this.investmentChecklist.push({
				html: true,
				value: '',
				statement: `"I certify that I have not exceeded my annual investment caps (including offerings on other funding portals) as permitted <a href="https://www.sec.gov/info/smallbus/secg/rccomplianceguide-051316.htm" target="_blank">by SEC rules.</a>"`
			})
		}

		// Close modal if agreements already accepted in current session and close immediatly
		if (this.investment) {
			this.close({
				$value: this.investment
			});
		}

	}

	checkboxClicked(item, event) {
		if (event) {
			event.stopPropagation();
			if (event.target.tagName === 'A') return false;
		}
		item.checked = !item.checked;
		if (item.checked) {
			item.acceptedAt = new Date();
		} else {
			item.acceptedAt = undefined;
		}
	}

	isFormReady() {
		for (let item of this.investmentChecklist) {
			// if (!item.checked) return false;
			if (item.value !== 'yes') return false;
		}
		if (!this.initials || this.initials.length !== 2) return false;
		return true;
	}

	ok() {

		this.processing = true;
		// Create Investment object
		let investment = new this.Investment({
			// Set as "incomplete"
			incomplete: true,
			// Save Investment Representation & Questionnaire form data
			investment_questionnaire: {
				ack: this.investmentChecklist,
				initials: this.initials
			},
			project: this.project,
			user: this.User.getCurrent()
		});
		investment.save()
			.then(_investment => {
				this.close({
					$value: _investment
				});
			})

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
