
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsController {

	constructor($log, $state, $rootScope) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$rootScope = $rootScope;

		this.openMobileMenu = false;
		this.routes = [
			{
				title: 'Profile',
				state: 'account.settings.profile'
			},
			{
				title: 'Password',
				state: 'account.settings.password'
			},
			{
				title: 'Notifications',
				state: 'account.settings.notifications'
			},
			{
				title: 'Security',
				state: 'account.settings.security'
			},
			// {
			// 	title: 'Payment Methods',
			// 	state: 'account.settings.payments'
			// },
			// {
			// 	title: 'Connected Accounts',
			// 	state: 'account.settings.connected-accounts'
			// }
		];

	}

	$onInit() {

		this.$rootScope.$emit('updateAccountHeading', {
			title: 'My Settings',
			subtitle: 'Manage your settings',
			showGreeting: false
		});
		
	}

	selectMobileSettings(state) {
		this.openMobileMenu = false;
		this.$state.go(state);
	}

}
