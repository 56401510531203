
/* injects from baggage-loader */

'use strict';

import ProjectManagerAdminComponent from './projectmanager.admin.component';

const ProjectManagerAdminPageModule = angular.module('project-manager-admin-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.admin', {
        url: '/admin',
        title: 'Project Manager: Admin',
        localTitle: 'Admin',
        // Restrict to authenticated users
        protected: true,
        // Restrict access by role
        requiredRole: ['Admins'],
        component: 'projectManagerAdmin',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          projects: function(Projects) {
            return Projects.getRaiseIds();
          },
          offerings: function(Parse) {
            return Parse.Cloud.run('getOfferings');
          },
          updates: function(Parse, project, $log) {
            $log.debug(project)
            let query = new Parse.Query('Project_Updates_Terms');
            query.equalTo('project', project)
            return query.find();
          }
        }
      });
  })
  .run(function ($transitions, Navigation) {
    // Setup navigation guard
    $transitions.onStart({ from: 'projectmanager.admin' }, () => Navigation.checkGuard());
  })
  .component('projectManagerAdmin', new ProjectManagerAdminComponent());

export default ProjectManagerAdminPageModule;
