
/* injects from baggage-loader */

'use strict';

export default class EnableTwoFactorAuthModalController {

	constructor($log, $timeout, User) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.userService = User;

		this.token = '';
		this.imageData;
		this.errorMessage = '';

	}

	$onInit() {

		this.imageData = this.resolve.imageData;

	}

	async ok() {

		if (this.token.length !== 6) {
			this.errorMessage = 'Please enter a valid pin';
			return false;
		}

		this.errorMessage = '';
		this.verifyingToken = true;

		// Verify pin
		try {
			// Verify the pin
			await this.userService.verifyTwoFactorAuth(this.token);
			// Refresh user
			await this.userService.getCurrent().fetch();
		} catch (err) {
			this.$log.debug(err)
			this.errorMessage = (err.toString()).replace('ParseError: 141', '').trim();
			this.verifyingToken = false;
			this.$timeout()
			return false;
		}

		// Return the document
		this.close({
			$value: 'success'
		});

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
