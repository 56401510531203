
/* injects from baggage-loader */

'use strict';

export default function (app) {

  app.directive('dirFaInvestNow', [function () {
      return {
        controller: 'faInvestNowController',
        controllerAs: 'page'
      };
    }])

    .directive('faDateInput', ['$parse', '$filter', function ($parse, $filter) {
      return {
        require: 'ngModel',
        link: function (scope, element, attrs, model) {
          var toTwoDigit = function (value) {
            if (value.length === 1) {
              return '0' + value;
            } else {
              return value;
            }
          };

          model.$formatters.unshift(function (modelValue) {
            // Filter -should- infer this as local time
            return $filter('date')(modelValue, 'MM/dd/yyyy');
          });

          model.$parsers.unshift(function (viewValue) {
            if (viewValue === '' || viewValue === null || viewValue === undefined) {
              return viewValue;
            }

            var value = viewValue.replace(/[-\.]/g, '/').trim(),
              leng = value.length;
            // Try to coerce the format if there are no slashes and it is 6 or 8 long
            if (value.indexOf('/') === -1 && (leng === 6 || leng === 8)) {
              var m = value.substring(0, 2),
                d = value.substring(2, 4),
                y = value.substring(4, leng);
              value = m + '/' + d + '/' + y;
            }

            // Check for two slashes and that the string ONLY contains numbers and slashes
            if (value.match(/\//g) !== null && value.match(/\//g).length === 2 && !value.match(/[^0-9\/]/)) {
              // Force to two digit month and day
              var d_array = value.split('/'),
                m,
                d,
                y;

              // Find the year first, then always assume they list month then day, there should never be a case where day comes before year in any format
              for (var i = 0; i < d_array.length; i++) {
                var v = d_array[i];
                if (v.length === 4) {
                  y = v;
                  d_array.splice(i, 1);
                  break;
                }
              }
              if (!y) {
                y = d_array[2];
              }

              m = toTwoDigit(d_array[0]);
              d = toTwoDigit(d_array[1]);

              var year_first = y + '-' + m + '-' + d,
                year_last = m + '/' + d + '/' + y;

              model.$setViewValue(year_last);
              model.$render();
              // Return year first style
              return year_first;
            }

            // If it failed to pass the matches, then just return what they entered and the server will handle the errors
            return viewValue;
          });
        }
      };
    }])

    .directive('faErrors', [function () {
      return {
        replace: true,
        scope: {
          errors: '='
        },
        templateUrl: 'partials/errors.html',
      }
    }])

    .directive('faMessage', [function () {
      return {
        templateUrl: 'partials/message.html',
        scope: {
          closeable: '=',
          inline: '=',
          messages: '=',
          type: '@',
          icon: '@'
        },
        replace: true,
        controller: function ($scope) {
          $scope.close = function () {
            if ($scope.closeable == true) {
              delete $scope.messages;
            }
          }
        }
      };
    }])

    // Use this as an attribute on a normal text input!
    .directive('faMoney', ['$filter', '$parse',
      function ($filter, $parse) {
        return {
          require: 'ngModel',
          link: function (scope, element, attrs, model) {
            function fixed() {
              return $parse(attrs.faMoney)(scope);
            }

            // Formats the input value in to a currency when loaded
            model.$formatters.unshift(function (viewValue) {
              return $filter('formatCurrency')(viewValue, fixed());
            });

            // Formats the currency after blur
            // The reason we do it here is because if we put this on a field without updateOn: blur, it woulc prevent people from typing real numbers!
            element.on("blur", function ($event) {
              var num = $filter('formatCurrency')(element[0].value, fixed());
              model.$setViewValue(num);
              model.$render();
            });
          }
        };
      }
    ])

    .directive('faPhone', ['$filter', '$parse',
      function ($filter, $parse) {
        return {
          require: 'ngModel',
          link: function (scope, element, attrs, model) {
            function country() {
              return $parse(attrs.faPhone)(scope);
            }

            model.$formatters.unshift(function (viewValue) {
              return $filter('tel')(viewValue, country());
            });

            element.on("blur", function ($event) {
              // Format the view value, but strip non-numeric from the model
              var num = $filter('tel')(element[0].value, country());
              model.$setViewValue(num);
              model.$render();
            });

            // Watch when the country changes, so I can format accordingly?
            // scope.$watch(function() {
            //   return country();
            // },
            // function(newValue, oldValue) {
            //   var num = $filter('tel')(element[0].value, newValue);
            //   model.$setViewValue(num);
            //   model.$render();
            // });
          }
        };
      }])

    // Use this as an attribute on a normal text input!
    .directive('faTaxId', ['$filter', '$parse',
      function ($filter, $parse) {
        return {
          require: 'ngModel',
          link: function (scope, element, attrs, model) {
            function country() {
              return $parse(attrs.country)(scope);
            }

            function type() {
              return $parse(attrs.entityType)(scope);
            }

            // I used to only format the number if there was a type, but now
            // Tax ID will be formatted as SSN style unless type is company or custodial
            model.$formatters.unshift(function (modelValue) {
              return $filter('formatTaxID')(modelValue, type(), country())
            });

            // model.$parsers.unshift(function(viewValue){
            //   return $filter('formatTaxID')(viewValue, type(), country());
            // });

            element.on("blur", function ($event) {
              var num = $filter('formatTaxID')(element[0].value, type(), country());
              model.$setViewValue(num);
              model.$render();
            });

            // These watches will watch the country and type attributes that are attached to the field
            var watches = [
              function () {
                return type();
              },
              function () {
                return country();
              }
            ];

            scope.$watchGroup(watches, function (newValues, oldValues, scope) {
              var num = $filter('formatTaxID')(element[0].value, newValues[0], newValues[1]);
              model.$setViewValue(num);
              model.$render();
            });
          }
        };
      }
    ])

    // Now smart enough to handle being block or inline!
    .directive('formField', [
      function () {
        return {
          restrict: 'E',
          templateUrl: 'partials/form-field.html',
          scope: {
            errors: '=',
            field: '@',
            help: '=',
            helpClass: '@',
            helpPlacement: '@',
            id: '=resourceId',
            index: '=',
            inline: '=',
            label: '=',
            type: '@'
          },
          link: function ($scope, element, attrs, controller) {
            // Watch for a change in errors and apply the 'has-error' class to the directive's classes
            $scope.$watch('errors', function (newVal, oldVal) {
              if (newVal) {
                attrs.$addClass('has-error');
              } else {
                attrs.$removeClass('has-error');
              }
            });
          },
          controller: ['$scope', function ($scope) {
            $scope.labelText = function () {
              switch ($scope.label) {
                case false:
                  return;
                case undefined:
                  return '&nbsp;';
                default:
                  return $scope.label;
              };
            };

            //Calculate the id
            $scope.fieldId = 'fa-form-';
            if ($scope.type) {
              $scope.fieldId = $scope.fieldId + $scope.type + '-';
            }
            $scope.fieldId = $scope.fieldId + $scope.field;
            if ($scope.index !== undefined) {
              // When there's an index appended, it uses an underscore
              $scope.fieldId += ('_' + $scope.index);
            }
          }],
          transclude: true
        };
      }
    ])

}