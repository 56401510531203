
/* injects from baggage-loader */

'use strict';

import ProjectManagerAdminController from './projectmanager.admin.controller';
import ProjectManagerAdminTemplate from './projectmanager.admin.html';

export default class ProjectManagerAdminComponent {
		constructor() {
				this.templateUrl = ProjectManagerAdminTemplate;
				this.controller = ProjectManagerAdminController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					projects: '<',
					offerings: '<',
					updates: '<'
				};
		}
}
