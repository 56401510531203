
/* injects from baggage-loader */

'use strict';

import ProjectManagerInvestmentController from './projectmanager.investment.controller';
import ProjectManagerInvestmentTemplate from './projectmanager.investment.html';

export default class ProjectManagerInvestmentComponent {
		constructor() {
				this.templateUrl = ProjectManagerInvestmentTemplate;
				this.controller = ProjectManagerInvestmentController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					investment: '<',
					messages: '<',
					documents: '<'
				};
		}
}
