
/* injects from baggage-loader */

'use strict';

import TermsOfUseComponent from './terms-of-use.component';

const TermsOfUsePageModule = angular.module('terms-of-use-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('terms-of-use', {
        parent: 'root',
        url: '/legal/terms-of-use',
        title: 'Terms of Use',
        component: 'termsOfUse',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('termsOfUse', new TermsOfUseComponent());

export default TermsOfUsePageModule;
