
/* injects from baggage-loader */

'use strict';

export default class AccountController {

	constructor($log, $rootScope) {
		'ngInject';

		this.$log = $log;
		this.$rootScope = $rootScope;

		this.user_first_name = '';

		this.page = {
			title: '',
			subtitle: 'Welcome to your account home',
			showGreeting: true
		}

	}

	$onInit() {

		// Listen for updates to the account heading
		this.$rootScope.$on('updateAccountHeading', (event, page) => {
			// this.$log.debug(page)
			this.page = page;
		});

	}


}
