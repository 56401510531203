
/* injects from baggage-loader */

'use strict';

import AccountSettingsComponent from './account.settings.component';

// Load child states
import AccountProfileSettings from './profile/account.settings.profile.module';
import AccountPasswordSettings from './password/account.settings.password.module';
import AccountNotificationSettings from './notifications/account.settings.notifications.module';
import AccountSecuritySettings from './security/account.settings.security.module';

const AccountSettingsPageModule = angular.module('account-settings-module', [
    'ui.router',

    // Inject child states
    AccountProfileSettings.name,
    AccountPasswordSettings.name,
    AccountNotificationSettings.name,
    AccountSecuritySettings.name,

  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings', {
        url: '/settings',
        // Requires valid login to view
        protected: true,
        component: 'accountSettings',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: {  }
      });
  })
  .component('accountSettings', new AccountSettingsComponent());

export default AccountSettingsPageModule;
