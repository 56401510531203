
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectStatsSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let ProjectStats = Parse.Object.extend('Project_Stats');

		// Define 'ProjectStats' scheme
		let schema = [
			'views',
			'investor_count',
			'funds_raised'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(ProjectStats.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProjectStats;

	});
}

