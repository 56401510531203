
/* injects from baggage-loader */

'use strict';

import InvestmentAdvisoryModalController from './investmentadvisorymodal.controller';
import InvestmentAdvisoryModalTemplate from './investmentadvisorymodal.html';

export default class InvestmentAdvisoryModalComponent {
	constructor() {
		this.templateUrl = InvestmentAdvisoryModalTemplate;
		this.controller = InvestmentAdvisoryModalController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			resolve: '<',
			close: '&',
			dismiss: '&'
		};
	}
}
