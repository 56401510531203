
/* injects from baggage-loader */

'use strict';

import AccountComponent from './account.component';
import './account.scss';

// Load child states
import AccountHomePageModule from './home/account.home.module';
import AccountDocumentsPageModule from './documents/account.documents.module';
import AccountInvestmentsPageModule from './investments/account.investments.module';
import AccountSettingsPageModule from './settings/account.settings.module';
import AccountProjectsPageModule from './projects/account.projects.module';
import AccountInvestmentPageModule from './investment/account.investment.module';

const AccountPageModule = angular.module('account-module', [
  'ui.router',

  // Child states
  AccountHomePageModule.name,                 // Home (investments)
  AccountDocumentsPageModule.name,            // Documents
  AccountInvestmentsPageModule.name,          // List my investments
  AccountSettingsPageModule.name,             // Account settings
  AccountProjectsPageModule.name,             // My Projects (raises)
  AccountInvestmentPageModule.name,           // View investment details

])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account', {
        parent: 'root',
        url: '/account',
        component: 'account',
        // Requires valid login to view
        protected: true,
        // Abstract parent of children states, cannot be visited directly
        abstract: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('account', new AccountComponent());

export default AccountPageModule;
