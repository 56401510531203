
/* injects from baggage-loader */

'use strict';

import ProjectManagerDisbursementController from './projectmanager.disbursement.controller';
import ProjectManagerDisbursementTemplate from './projectmanager.disbursement.html';

export default class ProjectManagerDisbursementComponent {
		constructor() {
				this.templateUrl = ProjectManagerDisbursementTemplate;
				this.controller = ProjectManagerDisbursementController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					project: '<',
					offering: '<',
					disbursementRequests: '<'
				};
		}
}
