
/* injects from baggage-loader */

'use strict';

import moment from 'moment';

export default class InvestFormController {

	constructor($log, $state, $window, $timeout, $stateParams, UsStates, FormBuilder, User, Parse, InvestmentCalculator,
		INVESTMENT_PAYMENT_METHODS, ACH_ACCOUNT_TYPES, INVESTMENT_TYPES, INVESTOR_DOMICILE_TYPES, INVESTOR_ENTITY_TYPES) {
		'ngInject';

		// TODO: remove after testing
		this.isDebugging = false;

		// Import dependencies
		this.$log = $log;
		this.$state = $state;
		this.$window = $window;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.fb = FormBuilder;
		this.Validators = this.fb.Validators;
		this.Constraints = this.fb.Constraints;
		this.Events = this.fb.Events;
		this.User = User;
		this.Parse = Parse;
		this.InvestmentCalculator = InvestmentCalculator;

		// Constants
		this.maximumAmountACH = 99850; // 100000;
		this.maximumAmountCreditCard = 4840; // 5000;
		this.allowInvestingAsEntity = false;
		this.digitsOnlyRegex = '\\d+';
		this.dateOpts = {
			altInput: true,
			altFormat: "F j, Y",
			dateFormat: "m-d-Y"
		};

		// Constant form validators
		this.formValidatorAchMax = this.Validators.maxValue(this.maximumAmountACH);
		this.formValidatorCreditCardMax = this.Validators.maxValue(this.maximumAmountCreditCard);

		// Populate selections
		this.states = UsStates;
		this.paymentMethods = INVESTMENT_PAYMENT_METHODS;
		this.achAccountTypes = ACH_ACCOUNT_TYPES;
		this.investmentTypes = INVESTMENT_TYPES;
		this.domicileTypes = INVESTOR_DOMICILE_TYPES;
		this.entityTypes = INVESTOR_ENTITY_TYPES;

		// Initialize variables
		this.investorProfiles = [];
		this.investorProfilesShown = [];
		this.selectedInvestorProfile = null;
		this.creatingNewInvestor = false;
		this.useExistingPaymentMethod = false;
		this.applicableFees = [];

		this.investmentAmount = '';
		this.investmentForm = {};
		this.requiresAccreditation = false;
		this.isInvestingAsEntity = false;
		this.investmentChecklist = [];
		this.nonAccreditedCalcValues = {
			output: {
				raw_invest_limit: '',		// Identifies variable to be used for calculations
				investor_class: '',			// Used to calculate investment limits due to level of income below accredited
				true_invest_limit: ''		// The actual dollar amount the investor is limited to investing in the offering
			}
		}
		this.agreements = {
			transactions: false,
			creditcard: false
		};

		// Form state flags
		this.savingForm = false;

		// Investment navigation states
		this.verificationStep = 'invest.verify';
		this.accreditationStep = 'invest.accredited';
		// this.completionStep = 'invest.confirmation';

		// TODO: moved completionStep to new page, outside of "invest" page
		//  child stages
		this.completionStep = 'invest-confirmation-north-cap';

	}

	$onInit() {
		this.$log.debug(this.project);
		this.$log.debug(this.investorProfiles);
		this.$log.debug('fees', this.investmentFees);
		this.$log.debug(this.$stateParams);

		// TODO: Check if the investment has already completed the form step, this step
		// IF true: redirect to the verify step
		// NOTE: alternativly we can check if the investment object isNew()

		if (this.$stateParams.investmentId) {
			this.$state.go(this.verificationStep, {
				investmentId: this.$stateParams.investmentId
			}, {
				id: this.project.id,
			});
		}

		// Set accreditation requirements
		if (this.project.get('type') === '506c') {
			this.requiresAccreditation = true;
		}

		// Get investment amount entered on the project page from params
		if (this.$stateParams.amount) {
			this.investmentAmount = this.$stateParams.amount.replace(/[^0-9]/g, '');
		}

		if (!this.investorProfiles.length) {
			this.creatingNewInvestor = true;
		} else {
			// Populate short list of visible investor profiles
			this.initExistingInvestorsList();
		}

		// Initialize forms
		this.investmentForm = this.initInvestmentForm();
		this.entityForm = this.initEntityForm();
		this.achForm = this.initAchForm();
		this.creditCardForm = this.initCreditCardForm();

		// Inject the details into the form
		this.investmentForm.patchValue(this.investment.toJSON());

		this.investmentChecklist = [
			{
				value: '',
				statement: `"My ability to cancel an investment commitment and obtain a return of my investment is restricted."`
			},
			{
				value: '',
				statement: `"It may be difficult for me to resell Securities acquired in this, or any other investment, made in reliance on the ${this.project.type === 'cf' ? 'Crowdfunding Exemption' : 'Rule 506c'}."`
			},
			{
				value: '',
				statement: `"I understand that Investing in securities offered and sold in reliance on the ${this.project.type === 'cf' ? 'Crowdfunding Exemption' : 'Rule 506c'} involves risk, and I can afford to lose the entire amount of my investment."`
			}
		];

		// Add additional checklist item if project type "CF"
		if (this.project.type === 'cf') {
			this.investmentChecklist.push({
				html: true,
				value: '',
				statement: `"I certify that I have not exceeded my annual investment caps (including offerings on other funding portals) as permitted <a href="https://www.sec.gov/info/smallbus/secg/rccomplianceguide-051316.htm" target="_blank">by SEC rules.</a>"`
			})
		}

		// Update the form with the investement amount passed
		if (this.investmentAmount) {
			this.investmentForm.patchValue({
				amount: this.investmentAmount
			});
		}

		// Update summary panel data
		this.updateSummaryData({
			data: {
				investmentAmount: this.investmentForm.get('amount').value
			}
		});
	}

	// ************************************
	// Initialization Methods
	// ************************************
	initDateFormat(fpItem, options) {
		fpItem.setDate(options.date);
	}

	initInvestmentForm() {

		// Called when the investment "amount" is updated
		function updateSummaryPanel(key, value = '') {

			// TODO: move this into a service
			// Calculate fees based on
			// - Payment method
			//   - CreditCard (2.8% - 3.5%) // $5k limit //  TODO: verify this
			//   - ACH (0.10%) // $100k limit, transfer Fees will also be charged for failed ACH transfers. ex. $25 for $25,000
			//   - Wire (??) // TODO: find out fee
			//   - Check (??)  // TODO: find out fee
			// - Amount 

			// TODO: CreditCard amount + fee cannot be more than $5000
			// so the validator must be adjusted to account for that

			// this.$log.debug(this.investmentForm.value());

			if (key === 'amount') {
				value = value.replace(/[^0-9]/g, '');
			}

			// Pass the updated data back to the component
			this.updateSummaryData({
				data: { 
					[key]: value,
					// Recalculate fees on any changes
					additionalFees: this.calculateInvestmentFees()
				}
			});
		}

		return this.fb.group({
			first_name: ['', this.Validators.required],
			middle_initial: ['', this.Validators.maxLength(1)],
			last_name: ['', this.Validators.required],
			dob: ['',
				this.Validators.required,
				this.Validators.custom(this.validateInvestorAge.bind(this))
			],
			type: [this.investmentTypes[0].code, this.Validators.required],
			// email_address: ['', this.Validators.required, this.Validators.email],
			// phone_number: ['', this.Validators.required],
			country: ['', this.Validators.required],
			domicile: ['', this.Validators.required],
			address_line_1: ['', this.Validators.required],
			address_line_2: '',
			city: ['', this.Validators.required],
			state: ['', this.Validators.required, this.Validators.maxLength(2)],
			postal_code: ['',
				this.Validators.required,
				this.Validators.maxLength(5),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			note: [''],
			amount: ['',
				this.Validators.required,
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
				// Update sidePanelData when value changes
				this.Events.onChange(updateSummaryPanel.bind(this), `investmentAmount`),
				// TODO: validate minimums
				this.Validators.custom(this.validateInvestmentLimit.bind(this)),
				this.Validators.minValue(this.project.investment_min),
				// validate amount is a valid multiple of the share price (round shares number)
				this.Validators.multipleOf(this.getSharePrice())
			],
			payment_method: ['',
				this.Validators.required,
				// Update sidePanelData when value changes
				// NOTE: this is not triggering for some unknown reason
				// this.Events.onChange(updateSummaryPanel.bind(this), `paymentMethod`)
			],
			investing_as_entity: [false],
			is_accredited: [],
			income: ['',
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly)
			],
			net_worth: ['',
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly)
			],
			invested_past_12months: ['',
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly)
			]
		});
	}

	initEntityForm() {
		return this.fb.group({
			entity_name: ['', this.Validators.required],
			entity_type: ['', this.Validators.required],
			entity_country: ['', this.Validators.required],
			// domicile: ['', this.Validators.required],
			entity_address_line_1: ['', this.Validators.required],
			entity_address_line_2: '',
			entity_city: ['', this.Validators.required],
			entity_state: ['', this.Validators.required],
			entity_postal_code: ['',
				this.Validators.required,
				this.Validators.maxLength(4),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
		});
	}

	initAchForm() {
		return this.fb.group({
			account_holder_name: ['', this.Validators.required],
			account_nickname: [''],
			account_type: ['Checking', this.Validators.required],
			routing_number: ['',
				this.Validators.required,
				this.Validators.maxLength(9),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			account_number: ['',
				this.Validators.required,
				this.Validators.maxLength(17),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			account_number_re: ['',
				this.Validators.required,
				this.Validators.maxLength(17),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
		});
	}

	initCreditCardForm() {
		return this.fb.group({
			card_holder_name: ['', this.Validators.required],
			card_number: ['',
				this.Validators.required,
				this.Validators.maxLength(16),
				this.Validators.length(16),
				this.Validators.custom(this.validateCreditCardType.bind(this)),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			cvv: ['',
				this.Validators.required,
				// this.Validators.maxLength(4),
				this.Validators.length(3),	// 3 digits only because AMEX not accepted
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			exp_date: ['',
				this.Validators.required,
				// this.Validators.maxLength(4),
				this.Validators.length(4),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			card_type: [''],
		});
	}

	initExistingInvestorsList() {
		this.investorProfilesShown = [];
		for (let i = 0; i < 2; i++) {
			if (this.investorProfiles[i]) {
				this.investorProfilesShown.push(this.investorProfiles[i]);
			}
		}
	}

	// ************************************
	// Element Visibility Methods
	// ************************************

	showInvestorSavedPaymentMethods() {
		if (!this.selectedInvestorProfile) {
			return false;
		}
		return this.selectedInvestorProfile.linked_ach || this.selectedInvestorProfile.linked_cc;
	}

	showTransactionsAgreement() {
		return this.investmentForm && this.investmentForm.get("payment_method")
			&& ['ACH', 'CREDITCARD'].includes(this.investmentForm.get("payment_method").value);
	}

	showCreditCardAgreement() {
		return this.investmentForm && this.investmentForm.get("payment_method")
			&& this.investmentForm.get("payment_method").value === 'CREDITCARD';
	}

	showElectronicFundsTransferAgreement() {
		return this.investmentForm && this.investmentForm.get("payment_method")
			&& ['CREDITCARD', 'ACH'].includes(this.investmentForm.get("payment_method").value);
	}

	// ************************************
	// Getter Methods
	// ************************************

	getPaymentMethodForm() {
		const paymentMethod = this.investmentForm.get("payment_method").value;
		switch (paymentMethod) {
			case "ACH":
				return this.achForm;
			case "CREDITCARD":
				return this.creditCardForm;
			default:
				return undefined;
		}
	}

	getIsUsingAlternatePaymentMethod() {
		const paymentMethod = this.investmentForm.get("payment_method").value;
		return ['WIRE', 'CHECK', 'IRA'].includes(paymentMethod);
	}

	getCreditCardType(value) {
		return (/^5[1-5]/.test(value))
			? "mastercard"
			: (/^4/.test(value))
				? "visa"
				: (/^3[47]/.test(value))
					? "amex"
					: (/^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/.test(value))
						? "discover"
						: undefined;
	}

	getInvestmentCalculatorValues() {
		return ['income', 'net_worth', 'invested_past_12months'].reduce((res, key) => {
			res[key] = this.investmentForm.get(key) ? this.investmentForm.get(key).value : 0;
			return res;
		}, {});
	}

	getSharePrice() {
		return this.project.get('share_price') || 0;
	}

	getInvestorName() {
		let name;
		if (this.selectedInvestorProfile) {
			return [
				this.selectedInvestorProfile.get('first_name'),
				this.selectedInvestorProfile.get('middle_initial'),
				this.selectedInvestorProfile.get('last_name')
			].filter(n => n).join(' ');
		} else {
			name = [
				this.investmentForm.get('first_name').value,
				this.investmentForm.get('middle_initial').value,
				this.investmentForm.get('last_name').value
			].filter(n => n).join(' ');
		}
		return name;
	}

	/**
	 * Returns the next navigation state for the investor to complete
	 * this investment
	 */
	async getNextNavigationStep({ investor, investment }) {

		// NOTE: KYC and Accreditation verification can take place after the
		//  investment is submitted
		// Take user to confirmation page
		return this.completionStep;

		// Possible stages
		// this.verificationStep = 'invest.verify';
		// this.accreditationStep = 'invest.accredited';
		// this.completionStep = 'invest.completion';

		// const investorAccreditation = await investor.get('accreditation').fetch();
		// const isRegCF = this.project.get('type') === 'cf';
		// const isInvestorKycVerified = investor.get('kyc_verified');
		// const isInvestorAccredited = investor.get('is_accredited');
		// const isInvestorAccreditationVerified = investorAccreditation && investorAccreditation.get('verified');
		// const isInvestorAccreditationPending = investorAccreditation && investorAccreditation.get('submitted');

		// // Investor is not KYC verifed, take to verification page
		// if (!isInvestorKycVerified) {
		// 	return this.verificationStep;
		// }

		// // 506c required accreditation. Check if investor is verified or submitted 
		// // Take user to accreditation verification document upload
		// if (!isRegCF && !(isInvestorAccreditationVerified || isInvestorAccreditationPending)) {
		// 	return this.accreditationStep;
		// }

		// // Take user to confirmation page
		// return this.completionStep;
	}

	// ************************************
	// Setter Methods
	// ************************************

	setError(err = {
		code: `UNKNOWN_ERROR`,
		message: `Unknown error`
	}) {
		this.error = {
			code: err.code,
			message: `Failed to submit investment: ${err.message}`
		}
	}

	setCreditCardType() {
		const cardNumber = this.creditCardForm.get('card_number').value;
		const cardType = this.getCreditCardType(Number(cardNumber));
		this.creditCardForm.patchValue({
			card_type: cardType
		});
	}

	// ************************************
	// Validator Methods
	// ************************************

	validateInvestmentCalculator() {
		const input = this.getInvestmentCalculatorValues();

		return typeof input.income !== 'undefined' || input.income.length
			|| typeof input.net_worth !== 'undefined' || input.net_worth.length
			|| typeof input.invested_past_12months !== 'undefined' || input.invested_past_12months.length;
	}

	validateCreditCardType(value) {
		return this.getCreditCardType(value) !== 'amex';
	}

	validateInvestorAge(value) {
		return moment().diff(moment(value, 'mm-dd-yyyy'), 'years') >= 18;
	}

	validateInvestmentLimit(value) {
		// No validation for accredited investors
		if (this.requiresAccreditation && this.investmentForm.get('is_accredited').value === true) {
			return true;
		}
		if (this.investmentForm.get('is_accredited').value === true || typeof this.investmentForm.get('is_accredited').value === 'undefined') {
			return true;
		}
		if (this.nonAccreditedCalcValues.output.true_invest_limit === 0) {
			return true;
		}
		return value <= this.nonAccreditedCalcValues.output.true_invest_limit;
	}

	validateFormReady() {

		// Validate if investor is required to be accredited for this investment
		if (this.requiresAccreditation && this.investmentForm.get('is_accredited').value !== true) {
			return false;
		}

		// Validate all questions of the investment checklist are acknowledged and "yes"
		for (let item of this.investmentChecklist) {
			// if (!item.checked) return false;
			if (item.value !== 'yes') return false;
		}

		// Validate the transaction agreement is checked when using ACH/creditcard
		if (!this.agreements.transactions && this.showTransactionsAgreement()) {
			return false;
		}

		// Validate the creditcard fee agreement is checked when using a creditcard
		if (!this.agreements.transactionFees && this.showElectronicFundsTransferAgreement()) {
			return false;
		}

		if (!this.investmentForm.get('payment_method').value) {
			return false;
		}

		if (this.getPaymentMethodForm() && !this.useExistingPaymentMethod) {
			return this.getPaymentMethodForm().valid();
		}
		
		return true;
	}

	// ************************************
	// Change Triggered Methods
	// ************************************

	onChangeAccreditedInvestor(value) {
		if (this.requiresAccreditation && !value) {
			// TODO: diable the amount field
		}
		if (value) {
			// Reset calculator output if accredited
			this.nonAccreditedCalcValues.output = {};
		}

		if (this.investmentForm.get('amount').value && this.validateInvestmentCalculator()) {
			const input = this.getInvestmentCalculatorValues();
			// Compute values
			this.nonAccreditedCalcValues.output = this.InvestmentCalculator.calculate(input);
			// Run custom validation
			this.investmentForm.get('amount').validate(null, ['custom']);
		}
		// Reload form for dynamic elements
		this.$timeout(() => {
			this.investmentForm.initialize();
		}, 100);
	}

	onChangeInvestmentCalculator() {
		// const { input } = this.nonAccreditedCalcValues;
		const input = ['income', 'net_worth', 'invested_past_12months'].reduce((res, key) => {
			res[key] = this.investmentForm.get(key).value;
			return res;
		}, {});

		if (!this.validateInvestmentCalculator()) {
			return false;
		}

		this.nonAccreditedCalcValues.output = this.InvestmentCalculator.calculate(input);
		// Run custom validation
		this.investmentForm.get('amount').validate(null, ['custom']);
		// Reset investment amount
		// this.investmentForm.get('amount').reset();
		this.$timeout();
	}

	onChangePaymentMethod() {
		const paymentMethod = this.investmentForm.get("payment_method").value;
		this.$log.debug("Changed payment method to:", paymentMethod);
		this.$timeout(() => {
			switch (paymentMethod) {
				case "ACH":
					this.achForm.initialize();
					this.creditCardForm.reset();
					this.investmentForm.get('amount').addValidator(this.formValidatorAchMax, 'achMax');
					this.investmentForm.get('amount').removeValidators(['maxValue', 'creditCardMax'])
					break;
				case "CREDITCARD":
					this.creditCardForm.initialize();
					this.achForm.reset();
					this.investmentForm.get('amount').addValidator(this.formValidatorCreditCardMax, 'creditCardMax');
					this.investmentForm.get('amount').removeValidators(['maxValue', 'achMax'])
					break;
				default:
					this.achForm.reset();
					this.creditCardForm.reset();
					this.investmentForm.get('amount').removeValidators(['maxValue', 'achMax', 'creditCardMax'])
			}
		}, 100);

		// Pass the updated data back to summary component
		this.updateSummaryData({
			data: { 
				paymentMethod,
				additionalFees: this.calculateInvestmentFees()
			}
		});
	}

	// ************************************
	// Click Triggered Methods
	// ************************************

	onCreateNewInvestor(value) {
		this.creatingNewInvestor = value;
		if (value) {
			// Reset any selected investor
			this.onSelectInvestorProfile(null);
			this.$timeout(() => this.investmentForm.initialize(), 250);
		} else {
			this.investmentForm.resetWithExclusions([
				'amount',
				'is_accredited',
				'income',
				'net_worth',
				'invested_past_12months'
			]);
		}
	}

	onSelectInvestorProfile(investor) {
		this.selectedInvestorProfile = investor;
		this.$log.debug('Investor', this.selectedInvestorProfile);

		// Switch to saved payment methods if the investor profile has one
		this.useExistingPaymentMethod = this.showInvestorSavedPaymentMethods();
		this.$timeout(() => {
			this.investmentForm.initialize();

			// reset the payment methods and render options if the investor has "linked" account
			if (this.investmentForm.get('payment_method') && this.investmentForm.get("payment_method").reset) {
				this.investmentForm.get("payment_method").reset();
			}
		}, 250);

		// TODO: display the investor info for review

		// TODO: reset and hide list of investors
	}

	onShowMoreInvestorProfiles() {
		this.investorProfilesShown = [...this.investorProfiles];
	}

	onToggleExistingPaymentMethods(value) {
		this.useExistingPaymentMethod = value;

		// NOTE: potential bug fixed using the method below
		// this.investmentForm.get("payment_method").reset();
		// this.onChangePaymentMethod();

		// Clear 
		this.investmentForm.patchValue({
			payment_method: ''
		});

		this.$timeout(() => {
			this.investmentForm.initialize();

			// reset the payment methods and render options if the investor has "linked" account
			if (this.investmentForm.get('payment_method') && this.investmentForm.get("payment_method").reset) {
				this.investmentForm.get("payment_method").reset();
			}
			this.onChangePaymentMethod();
		}, 10);
	}

	onInvestingAsEntityChecked(e) {
		const investingAsEntity = this.investmentForm.get("investing_as_entity").value;
		this.$timeout(() => {
			if (investingAsEntity) {
				this.entityForm.initialize();
			}
		}, 100);
	}

	// ************************************
	// Calculation Methods
	// ************************************

	calculateInvestmentFees() {
		let investmentFees = 0.0;

		// Calculate fees
		if (this.investmentForm.get("payment_method")) {
			const investmentAmount = parseInt(
				this.investmentForm.get("amount").value.replace(/[^0-9]/g, '')
			);
			const paymentMethod = this.investmentForm.get("payment_method").value;
			const feeItem = this.investmentFees.find(fee => fee.type === paymentMethod);
			if (feeItem && investmentAmount > 0) {
				investmentFees = (investmentAmount * feeItem.fee_percent) + feeItem.fee_flat;
				
				// Fix fee for minimum
				if (feeItem.minimum && investmentFees < feeItem.minimum) {
					investmentFees = feeItem.minimum;
				}

				// Fix fee for maximum
				if (feeItem.maximum && investmentFees > feeItem.maximum) {
					investmentFees = feeItem.maximum;
				}
				
				feeItem.amount = investmentFees;
				this.applicableFees = [feeItem];
			} else {
				this.applicableFees = [];
			}
		}

		// Return fee total fixed and parsed to 2 decimal places
		return parseFloat(investmentFees.toFixed(2));
	}

	async onSubmitForm(debug = false) {

		// console.log(this.investmentForm);

		// Reset any errors
		this.error = {};

		// Validate minimal required fields
		// TODO: add additional logic to allow checkout with a previously
		//       used payment method
		if (
			!this.investmentForm.get('amount').validate()
			|| (this.investmentForm.get('payment_method') && !this.investmentForm.get('payment_method').validate())) {
			this.savingForm = false;
			this.$window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
			return;
		}

		// Get minimal required fields
		const amount = this.investmentForm.get('amount').value;
		const paymentMethod = this.investmentForm.get('payment_method').value;

		const investmentFeeTotal = this.calculateInvestmentFees();

		// Gather default form variables
		let formData = {
			project_id: this.project.id,
			// TODO: do we actually need to save this in the DB??
			investment_questionnaire: {
				ack: this.investmentChecklist
			},
			payment_method: paymentMethod,
			amount,
			fees: this.applicableFees,
			fee_total: investmentFeeTotal,
			total: parseInt(amount) + investmentFeeTotal
		}

		// Use existing investor profile
		if (this.selectedInvestorProfile) {
			formData.investor_id = this.selectedInvestorProfile.id;
		} else {

			// Prepare and validate investor profile form
			this.investmentForm.markAllAsTouched();

			// Scroll to form error if not valid
			if (!this.investmentForm.valid()) {
				this.savingForm = false;
				this.$window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				return;
			}

			let form = this.investmentForm.value();

			// Get the entityForm if investing as an Entity
			if (form.investing_as_entity) {
				if (!this.entityForm.valid()) {
					// TODO: get anchor postion of entity form to scroll to
					this.$window.scrollTo({
						top: 0,
						left: 0,
						behavior: 'smooth'
					});
					return;
				}
				form.entity_form = this.entityForm.value();
			}

			// Join form data
			formData = Object.assign(formData, form);
		}

		// TODO: determine how to display and merge payment method for for "new" and existing payment methods

		// Get the payment method form
		const paymentMethodForm = this.getPaymentMethodForm();
		if (paymentMethodForm && !this.useExistingPaymentMethod) {
			if (!paymentMethodForm.valid()) {
				// TODO: get anchor postion of payment form to scroll to
				this.$window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
				return;
			}

			let paymentForm = paymentMethodForm.value();
			// Determine if the investor is using a new form of payment
			if ((paymentForm.card_number && paymentForm.card_number.length)
				|| (paymentForm.account_number && paymentForm.account_number.length)) {
				paymentForm.new = true;
			}

			// Join form data
			formData = Object.assign(formData, {
				payment_method_form: paymentForm
			});
		}

		this.$log.debug(formData);

		// Exit here after validations is debugging
		if (this.isDebugging || debug) {
			return;
		}

		this.savingForm = true;
		try {

			// Create the investment commitment
			const {
				investment,
				investor
			} = await this.Parse.Cloud.run('sendInvestmentCommitment', formData);
			// this.savingForm = false;
			// this.$timeout();

			// TODO: check if the result requires a verification step
			// NOTE: always go to verification step if using enhanced KYC/AML
			// NOTE: Skip if the investor is already verified and their check
			// is still valid

			const nextStep = await this.getNextNavigationStep({
				investor, investment
			});
			// console.log('Next stage:', nextStep);

			// Take the user to the next step
			this.$state.go(nextStep, {
				id: this.project.id,
				investmentId: investment.id
			}, {
				id: this.project.id,
			});

		} catch (err) {
			this.$log.error(err);
			this.savingForm = false;

			// Display error to user
			this.setError(err);
			this.$timeout();
		}

	}

}
