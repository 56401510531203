
/* injects from baggage-loader */

'use strict';

import OnboardingEntryController from './onboarding-entry.controller';
import OnboardingEntryTemplate from './onboarding-entry.html';

export default class OnboardingEntryComponent {
		constructor() {
				this.templateUrl = OnboardingEntryTemplate;
				this.controller = OnboardingEntryController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
