
/* injects from baggage-loader */

'use strict';

import InvestmentListItemController from './investmentlistitem.controller';
import InvestmentListItemTemplate from './investmentlistitem.html';

export default class InvestmentListItemComponent {
	constructor() {
		this.templateUrl = InvestmentListItemTemplate;
		this.controller = InvestmentListItemController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = { 
			project: '=',
			section: '<',
			title: '=itemTitle', 
			body: '=itemBody', 
			index: '=',
			sectionCount: '=',
			isEditingProject: '='
		};
	}
}
