
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingOfferingAndTeamController from './issuer-onboarding.offering-and-team.controller';
import IssuerOnboardingOfferingAndTeamTemplate from './issuer-onboarding.offering-and-team.html';

export default class IssuerOnboardingOfferingAndTeamComponent {
		constructor() {
				this.templateUrl = IssuerOnboardingOfferingAndTeamTemplate;
				this.controller = IssuerOnboardingOfferingAndTeamController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					onboarding: '<',
					docs: '<',
				};
		}
}
