
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('OnboardingDocument', function (Parse, OnboardingDocumentSchema) {

		// Load object schema
		const OnboardingDocument = OnboardingDocumentSchema;
		
	});
}

