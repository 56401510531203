
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.factory('Snackbar', function ($rootScope) {
		return {
			create: (content, duration, classes = []) => {
				$rootScope.$broadcast('createSnackbar', { content, duration, classes });
			}
		}
	});
}

