
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsSecurityController {

	constructor($log, $uibModal, Snackbar, User) {
		'ngInject';

		this.heading = "Security";

		this.$log = $log;
		this.modalService = $uibModal;
		this.snackbar = Snackbar;
		this.userService = User;

	}

	$onInit() {
		this.user = this.userService.getCurrent()
		// this.$log.debug(this.user);
	}

	manageTwoFactorAuth() {
		if (this.user.two_factor_enabled) {
			// Turn off 2FA
			
			// Show pin field to disable two factor

			// Verify with server

			// Turn off 2fa
			
		} else {
			// Turn on 2FA
			this.userService.requestTwoFactorAuth().then(imageData => this.modalService.open({
				animation: true,
				component: 'enableTwoFactorAuthModal',
				resolve: {
					imageData: () => imageData
				}
			}).result.then(res => {
				this.$log.debug(res);
				this.snackbar.create('Two-factor auth enabled successfully!')
			}, angular.noop))
				.catch(err => this.snackbar.create(err.toString().replace('ParseError: 141', '').trim()), 5000, ['bg-danger'])
		}

	}

}
