
/* injects from baggage-loader */

'use strict';

export default class SocialShareMenuController {

	constructor($log, $document, $timeout, SocialShareService, Common) {
		'ngInject';

		this.$log = $log;
		this.$document = $document;
		this.$timeout = $timeout;
		this.socialShareService = SocialShareService;
		this.commonService = Common;

	}

	$onInit() {
		// this.$log.debug(this.shareData)
	}

	copyLink() {
		this.commonService.copyToClipboard(this.shareData.url);
		this.linkCopied = true;
		this.$timeout(() => {
			this.linkCopied = false;
		}, 5000);
	}

}
