
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Utilities', function () {

		/**
		 * Return a number formatted with proper comma placement
		 */
		this.formatNumberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		
	});
}

