
/* injects from baggage-loader */

'use strict';

export default function (app) {

  app.filter('accountTypeahead', [function () {
    return function (object) {
      if (object) {
        return object.name + ' (' + object.email + ')';
      }
    };
  }])

    // .filter('billing', [function(){
    //   return function(resource){
    //     var billing = angular.copy(resource);

    //     if(){

    //     }

    //     return billing;
    //   };
    // }])

    .filter('entityTypeahead', [function () {
      return function (object) {
        if (object) {
          return object.name + ' - ' + object.email + ' - ' + object.tax_id_number;
        }
      };
    }])

    .filter('filterEntity', [function () {
      return function (resource, type) {
        var entity = angular.copy(resource);
        for (var key in entity) {
          switch (key) {
            case 'contact_name':
            case 'region_formed_in':
              if (type === 'person') {
                delete entity[key];
              } else if (entity.country !== 'US' && key === 'region_formed_in') {
                entity[key] = entity.region;
              }
              break;
            case 'date_of_birth':
              if (type !== 'person') {
                delete entity[key];
              }
              break;
            default:
              break;
          }
        }
        return entity;
      };
    }])

    .filter('filterMatch', [function () {
      return function (s, regex) {
        if (s && regex) {
          var r = new RegExp(regex),
            trigger = s.match(r);
          if (trigger) {
            s = s.replace(trigger[0], '').trim();
          }
        }
        return s;
      }
    }])

    .filter('filterOffering', [function () {
      return function (offering) {
        // var offering = angular.copy(resource);
        // Because this has to deal with files, we modify the actual resource
        if (offering.us_investors === false) {
          offering.restrict_to_specific_us_states = false;
        }
        if (offering.restrict_to_specific_us_states === false) {
          offering.us_states_only = [];
        }
        return offering;
      };
    }])

    .filter('filterSecurity', [function () {
      return function (security, type) {
        var results = angular.copy(security);

        if (results.use_transfer_agent !== 'other') {
          delete results['transfer_agent.name'];
          delete results['transfer_agent.email'];
          delete results['transfer_agent.phone'];
          delete results['transfer_agent.contact_name'];
        }

        if (results.restricted === false) {
          delete results.no_partial_transfer;
          delete results.first_right_of_refusal;
          delete results.original_owner_must_hold;
          delete results.original_owner_must_hold_days;
          delete results.non_accredited_must_hold;
          delete results.non_accredited_must_hold_days;
          delete results.no_transfer_to_non_accredited;
          delete results.no_transfer_to_non_accredited_days;
          delete results.never_transfer_to_non_accredited;
          delete results.no_transfer_to_non_state_resident;
          delete results.issuer_must_review_transfer;
          delete results.legends;
        }

        if (results.restricted === false || results.original_owner_must_hold === false) {
          delete results.original_owner_must_hold_days;
        }
        if (results.restricted === false || results.non_accredited_must_hold === false) {
          delete results.non_accredited_must_hold_days;
        }
        if (results.restricted === false || results.no_transfer_to_non_accredited === false || results.never_transfer_to_non_accredited === true) {
          delete results.no_transfer_to_non_accredited_days;
        }
        var nttnad = results.no_transfer_to_non_accredited_days;
        if (results.restricted === true && results.no_transfer_to_non_accredited === true && results.never_transfer_to_non_accredited === false && (nttnad === null || nttnad === undefined || nttnad === '')) {
          // We send a zero here so that they'll get the error about needing to set the days on this field
          results.no_transfer_to_non_accredited_days = 0;
        }
        if (results.restricted === false || results.no_transfer_to_non_accredited === false) {
          delete results.never_transfer_to_non_accredited;
        }
        if (results.units_sold_as === 'issuer_percentage') {
          delete results.unit_price;
        }
        if (results.traded === false) {
          delete results.exchange;
          delete results.symbol;
        }
        if (results.has_legends === false) {
          delete results.legends;
        }

        if (results.type == 'debt') {
          delete results.units_sold_as;
          delete results.unit_price;
        } else if (results.type === 'equity') {
          delete results.interest_rate;
          delete results.maturity_date;
        } else {
          delete results.units_sold_as;
          delete results.unit_price;
          delete results.interest_rate;
          delete results.maturity_date;
        }

        if (results.regulatory_exemption !== 'us_state' && results.regulatory_exemption !== 'other') {
          delete results.regulatory_exemption_details;
        }

        // custom shit, because copy screws up file uploads
        if (security.logo) {
          results.logo = security.logo;
        }

        return results;
      };
    }])

    .filter('filterSignableDocument', [function () {
      return function (resource) {
        var resource_copy = angular.copy(resource);

        delete resource_copy.html;

        if (resource.accept_sign_again_notice === false) {
          resource_copy = {};
        }

        return resource_copy;
      }
    }])

    .filter('filterSubscriptionAgreement', [function () {
      return function (resource) {
        var sat = angular.copy(resource);

        delete sat.published_content;

        return sat;
      };
    }])

    .filter('formatCurrency', ['ss', function (ss) {
      return function (amount, fixed) {
        if (!amount) {
          // Return an empty string instead of undefined!
          // If I return undefined, it doesn't submit that field
          return '';
        } else {
          return ss.formatCurrency(amount, fixed);
        }
      };
    }])

    .filter('formatTaxID', ['ss', function (ss) {
      return function (num, type, country) {
        if (!num) {
          // Return an empty string instead of undefined!
          // If I return undefined, it doesn't submit that field
          return '';
        } else {
          if (country && country != 'US') {
            return num;
          } else {
            if (type === 'company' || type === 'custodial' || type === 'trust' || type === 'ira') {
              return ss.formatTIN(num);
            } else {
              return ss.formatSSN(num);
            }
          }
        }
      };
    }])

    .filter('formatText', [function () {
      return function (label, caseType) {
        if (typeof (label) == 'string') {
          label = label.replace(/_/g, ' ');
          switch (caseType) {
            case 'chain':
              var stripped = label.split('.');
              stripped = stripped[stripped.length - 1].replace(/\./g, '_');
              return stripped.toProperCase();
            case 'enum':
              return (label.toLowerCase()).replace(/\s/g, '_');
            case 'inherit':
              // This option shouldn't adjust the capitalization of the text
              return label;
            case 'lower':
              return label.toLowerCase();
            case 'upper':
              return label.toUpperCase();
            default:
              return label.toProperCase();
          }
          // return label ? label.replace(/_/g, ' ').toProperCase(properCase) : '';
        } else {
          return label;
        }
      };
    }])

    .filter('investmentSubscriptionAgreement', [function () {
      return function (resource) {
        var subscription_agreement = angular.copy(resource);

        delete subscription_agreement.data;
        delete subscription_agreement.html;

        return subscription_agreement;
      };
    }])

    .filter('limitToObject', [function () {
      return function (object, limit, start) {
        var results = {},
          start = (start !== undefined) ? start : 0,
          i = 0,
          pushed = 0;

        for (var key in object) {
          if (i >= start && pushed < limit) {
            results[key] = object[key];
            pushed++;
            if (pushed === limit) {
              break;
            }
          }
          i++;
        }
        return results;
      };
    }])

    .filter('newLine', [function () {
      return function (object) {
        if (object !== undefined && object !== null) {
          return object.toString().replace(/\n/g, "<br/>");
        }
      };
    }])

    .filter('replaceUnderscore', [function () {
      return function (label) {
        if (typeof (label) == 'string') {
          return label ? label.replace(/_/g, ' ') : '';
        } else {
          return label;
        }
      };
    }])

    .filter('tel', [function () {
      return function (tel, country) {
        if (!tel) {
          return '';
        } else if (country && country !== 'US') {
          // Don't format non-us numbers
          return tel;
        }

        var value = tel.toString().trim().replace(/^\+/, '');
        // Check if the value has any non-numeric characters
        // If it does, then just return the number
        //if (value.match(/[^0-9]/)) {
        //   return tel;
        //}// else {
        //  value.replace(/[^0-9.]/g, '');
        //}
        // I think maybe just -always- stripping any non-numeric and formatting seems best.
        value = value.replace(/[^0-9]/g, '');
        var country, city, number;
        switch (value.length) {
          case 10: // +1PPP####### -> C (PPP) ###-####
            country = 1;
            city = value.slice(0, 3);
            number = value.slice(3);
            break;

          case 11: // +CPPP####### -> CCC (PP) ###-####
            country = value[0];
            city = value.slice(1, 4);
            number = value.slice(4);
            break;

          case 12: // +CCCPP####### -> CCC (PP) ###-####
            country = value.slice(0, 3);
            city = value.slice(3, 5);
            number = value.slice(5);
            break;
          // Just return the original value if it wasn't a series of numbers and wasn't 10, 11, or 12 characters long
          default:
            return tel;
        }

        // if (country == 1) {
        //   country = '';
        // }

        number = number.slice(0, 3) + '-' + number.slice(3);

        return (country + " (" + city + ") " + number).trim();
      };
    }])

    .filter('toArray', [function () {
      return function (object, reverse) {
        var arr = [];
        for (var key in object) {
          arr.push(key);
        }
        return reverse ? arr.reverse() : arr;
      };
    }])

}