
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.directive('timeBasedGreeting', TimeBasedGreetingDirective);

	function TimeBasedGreetingDirective() {
		'ngInject';

		return {
			restrict: 'AE',
			link: linkFn,
			// require: 'ngModel'
		};

		function linkFn(scope, elem, attrs) {

			let date = new Date();
			let hrs = date.getHours();

			if (hrs < 12) {
				elem.text('Good Morning');
			} else if (hrs >= 12 && hrs <= 17) {
				elem.text('Good Afternoon');
			} else if (hrs >= 17 && hrs <= 24) {
				elem.text('Good Evening');
			} else {
				elem.text('Welcome')
			}

		}

	}
}