
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ReturnsCalculator', function () {

		function calculateBasics() {
			
		}

		function calculateYearsReturn() {

		}
		
	});
}

