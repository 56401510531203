
/* injects from baggage-loader */

'use strict';

import ProjectManagerEditorComponent from './projectmanager.editor.component';

const ProjectManagerEditorPageModule = angular.module('project-manager-editor-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.editor', {
        url: '/editor',
        title: 'Project Manager: Editor',
        localTitle: 'Editor',
        // Restrict to authenticated users
        protected: true,
        component: 'projectManagerEditor'
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      })
      .state('project-intake-form', {
        url: '/project-intake-form',
        title: 'Project Intake Form',
        // Restrict to authenticated users
        protected: true,
        component: 'projectManagerEditor',
        data: {
          intakeForm: true
        },
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          project: function ($q, ProjectSchema) {
            return $q.resolve(new ProjectSchema({
              type: 'cf',
              body: [{
                title: 'About the project'
              }],
              key_points: []
            }));
          }
        }
      })
  })
  .run(function ($transitions, Navigation) {
    // Setup navigation guard
    $transitions.onStart({ from: 'projectmanager.editor' }, () => Navigation.checkGuard());
  })
  .component('projectManagerEditor', new ProjectManagerEditorComponent());

export default ProjectManagerEditorPageModule;
