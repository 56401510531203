
/* injects from baggage-loader */

'use strict';

import moment from 'moment';

export default class ProjectsController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {
		// this.$log.debug(this.projects)
	}

}
