
/* injects from baggage-loader */

'use strict';

import InvestConfirmationNorthCapComponent from './invest-confirmation-north-cap.component';
import './invest.confirmation.scss';

function emptyObject() {
  return {
    get: () => {},
    set: () => {},
    save: () => {
      throw "EMPTY OBJECT! CANNOT SAVE"
    }
  }
}

function safeFetch(obj) {
  return (obj && typeof obj.fetch === 'function')
    ? obj.fetch()
    : emptyObject()
}

const InvestConfirmationNorthCapPageModule = angular.module('invest-confirmation-north-cap-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('invest-confirmation-north-cap', {
        url: '/project/:id/invest/confirmation?investmentId',
        component: 'investConfirmationNorthCap',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          project: function (Projects, $transition$, MediaSchema, ProjectStatsSchema, ProfileSchema) {
            return Projects.getBySlugOrId($transition$.params('to').id)
              .then(project => {
                if (!project) {
                  throw 'Project not found';
                }
                return project;
              });
          },
          sponsor: function(project) {
            return safeFetch(project.get('sponsor'));
          },
          documents: function(project, ProjectDocumentSchema) {
            return project.relation('documents').query().find();
          },
          investment: function (Investments, $transition$, InvestmentSchema) {
            const investmentId = $transition$.params('to').investmentId;

            // TODO: get current investment from localStorage/Investment Form Service
            if (investmentId) {
              return Investments.get(investmentId)
                // .catch(err => {
                //   // TODO: redirect to a NOT FOUND page
                //   // NOTE: may need to manipulate the transition or flag something so the run block
                //   // for this transition can abort and redirect
                // });
            }

            return new InvestmentSchema();
          },
          investor: function(Investors, investment) {
            if (investment && investment.isNew()) {
              return;
            }

            // Return or fetch the investor if not here yet
            return investment.get('investor').isDataAvailable()
              ? investment.get('investor')
              : investment.get('investor').fetch();
          }
        }
      });
  })
  .component('investConfirmationNorthCap', new InvestConfirmationNorthCapComponent());

export default InvestConfirmationNorthCapPageModule;
