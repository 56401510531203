
/* injects from baggage-loader */

'use strict';

import IssueProjectTermsUpdateModalComponent from './issueprojecttermsupdatemodal.component';
import './issueprojecttermsupdatemodal.scss';

const IssueProjectTermsUpdateModalModule = angular.module('issue-project-terms-update-modal-module', []);

IssueProjectTermsUpdateModalModule
    .component('issueProjectTermsUpdateModal', new IssueProjectTermsUpdateModalComponent());

export default IssueProjectTermsUpdateModalModule;

