
/* injects from baggage-loader */

'use strict';

import moment from 'moment';

export default function (app) {
	app.service('Common', function ($log) {

		this.copyToClipboard = text => {

			// create temp element
			let copyElement = document.createElement("span");
			copyElement.appendChild(document.createTextNode(text));
			copyElement.id = 'tempCopyToClipboard';
			angular.element(document.body.append(copyElement));

			// select the text
			let range = document.createRange();
			range.selectNode(copyElement);
			window.getSelection().removeAllRanges();
			window.getSelection().addRange(range);

			// copy & cleanup
			document.execCommand('copy');
			window.getSelection().removeAllRanges();
			copyElement.remove();

		}

		// TODO: verify this function outputs "1" day remaining up until midnight of the end_date
		// 			 "Midnight" as in 23:59:59 in the local tz
		this.daysRemaining = end_date => {
			// let days = moment(end_date).diff(moment(), 'days');
			// let exact = moment(end_date).diff(moment(), 'days', true);
			// if (exact > 0 && exact < 1) {
			// 	days = 1;
			// }
			// return days > 0 ? days : 0;
			let days = Math.ceil(moment(end_date).diff(moment(), 'days', true));
			return days > 0 ? days : 0;
		}

		this.isRaiseOpen = startDate => moment().diff(moment(startDate)) > 0;

		this.getTimeTillRaiseOpen = startDate => {
			let diff = moment(startDate).diff(moment());
			return moment.duration(diff, 'milliseconds');
		}


		/**
		 * Pushes the item to the array if the condition evaluates "true"
		 * @param {Array} array
		 * @param {Any} item
		 * @param {Any} condition
		 */
		this.pushIf = (array, item, condition) => {
			if (condition) {
				array.push(item);
			}
		}


	});
}

