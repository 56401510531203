
/* injects from baggage-loader */

'use strict';

import InvestAccreditedComponent from './invest.accredited.component';

const InvestAccreditedPageModule = angular.module('invest-accredited-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('invest.accredited', {
        url: '/accredited',
        component: 'investAccredited',
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          investorDocuments: function (InvestorDocuments, investor) {
            return InvestorDocuments.getDocuments(investor);
          },
          accreditation: function (investor) {
            if (investor.has('accreditation')) {
              return investor.get('accreditation').fetch();
            }
          }
        }
      });
  })
  .run(function($transitions, StateService) {
    $transitions.onSuccess({ to: 'invest.accredited' }, transition => {
      // Show the summary panel
      StateService.investment.showSummaryPanel = true;
    });
  })
  .component('investAccredited', new InvestAccreditedComponent());

export default InvestAccreditedPageModule;
