
/* injects from baggage-loader */

'use strict';

import RecoverPasswordComponent from './recoverpassword.component';

const RecoverPasswordPageModule = angular.module('recover-password-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('recoverPassword', {
        url: '/account/recover-password',
        title: 'Recover Password',
        component: 'recoverPassword',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .run(function ($transitions, $location, $rootScope, User) {

    // Handle entrance to login page when user is already logged in
    $transitions.onEnter({ to: 'recoverPassword' }, transition => {

      if (User.isUserLoggedIn() === true) {
        // $location.path('/account/home');
        return transition.router.stateService.target('account.home');
      }

    });

    $transitions.onSuccess({ to: 'recoverPassword' }, transition => {

      // Hide the header
      $rootScope.appState.hideHeader = true;

      // Hide the footer
      $rootScope.appState.hideFooter = true;

    });

  })
  .component('recoverPassword', new RecoverPasswordComponent());

export default RecoverPasswordPageModule;
