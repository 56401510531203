
/* injects from baggage-loader */

'use strict';

import InvestKycController from './invest.kyc.controller';
import InvestKycTemplate from './invest.kyc.html';

export default class InvestKycComponent {
		constructor() {
				this.templateUrl = InvestKycTemplate;
				this.controller = InvestKycController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					investment: '<',
					investor: '<',
					sidePanelData: '<',
					updateSummaryData: '&',
					photoIdDocument: '<'
				};
		}
}
