
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerInvestmentController {

	constructor($log, $timeout, $uibModal, User, MessageSchema) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.modalService = $uibModal;
		this.userService = User;
		this.Message = MessageSchema;

		this.documents = [];
		this.messages = [];
		this.message = '';

		// File upload arrays
		this.pendingFiles = [];
		this.invalidFiles = [];

		/**
		 * Should display
		 * - Investment details
		 * - Investor documents
		 * - Messaging
		 * - Clearing status
		 * - Clearing failures/outstanding tasks
		 * - Actions to:
		 *  - Upload Accreditation document
		 * 	- Upload user document
		 *  - Refund investment?
		 *  - 
		 * 
		 */

	}

	$onInit() {
		this.investor = this.investment.get('investor');
		console.debug('investment', this.investment)
		console.debug('investor', this.investor)

		// this.chat = this.investment.chat;
		// this.chatOwners = this.chat.get('owners');

		// Reverse message order
		// this.messages = this.messages.reverse()

		// // Sort messages
		// this.messages.forEach(msg => {
		// 	if (msg.from.id !== this.userService.getCurrent().id) {
		// 		msg.incoming = true;
		// 	}
		// })

	}

	isTaskComplete(t) {
		return typeof this.investment.clearing_failures.find(f => f === t) === 'undefined' ? true : false;
	}

	getFormattedInvestorLocation() {
		if (!this.investor) {
			return '-'
		}
    return `${this.investor.get('city')}, ${this.investor.get('state')}`;
  }

	// async send(msg) {

	// 	if (!this.message.length) {
	// 		return false;
	// 	}

	// 	// Clear message box
	// 	this.message = '';
	// 	// this.resizeMessageBox('messageComposeArea', true);
	// 	// this.currentConvo.lastUpdated = new Date();

	// 	// Create a new message
	// 	let message = new this.Message({
	// 		from: this.userService.getCurrent(),
	// 		chat: this.chat,
	// 		body: msg,
	// 		investment: this.investment
	// 	});

	// 	// Set permissions the same as chat
	// 	message.setACL(this.chat.getACL());

	// 	// Save the message
	// 	message.save().then(res => {
	// 		// this.$log.debug('message send:', res.id);
	// 		// Notification.playSound('sent');
	// 	}).catch(err => {
	// 		this.$log.error('failed to send message');
	// 		this.$log.error(err);
	// 	});

	// 	// Push message
	// 	this.$timeout(() => {
	// 		this.messages.push(message);
	// 	}, 10);

	// }

	async processFiles() {

		// Create a modal instance
		let modalInstance = this.modalService.open({
			component: 'investorDocumentModal',
			animation: true,
			size: 'lg',
			resolve: {
				documents: () => this.pendingFiles,
				investment: () => this.investment
			}
		});

		// Wait for modal to exit
		modalInstance.result.then(async res => {
			this.$log.debug(res);

			// Combine 
			this.documents = [...res.documents, ...this.documents];

		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async renameDocument(doc) {
		this.$log.debug('Edit document:', doc.id);

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'editDocumentModal',
			resolve: {
				context: function () {
					return {
						document: doc
					};
				}
			}
		});

		modalInstance.result.then(async doc_edits => {

			this.$log.debug(doc_edits);

			doc.isUpdating = true;
			// Save the document
			await doc.save(doc_edits);
			this.$timeout(() => {
				doc.isUpdating = false;
			}, 500);


		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async removeDocument(doc) {
		this.$log.debug('Delete document:', doc.id);

		let modalInstance = this.modalService.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete document?',
						body: 'Are you sure you want to delete this document?',
						list: [doc.name],
						confirmBtnText: 'Yes, Delete',
						cancelBtnText: 'No, Cancel'
					};
				}
			}
		});


		modalInstance.result.then(async () => {

			// Find and remove document
			this.documents.splice(this.documents.indexOf(doc), 1);

			// doc.isUpdating = true;
			await doc.destroy();
			this.$timeout(() => {
				this.$log.debug('Document removed:', doc.id);
				// doc.isUpdating = false;
			}, 500);

		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

}
