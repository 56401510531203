
/* injects from baggage-loader */

'use strict';

export default class RaiseSubmittedConfirmationController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {

	}

}
