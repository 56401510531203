
/* injects from baggage-loader */

'use strict';

// index.html page to dist folder
import '!!file-loader?name=[name].[ext]!../favicon.ico';

// vendor files
import "./index.vendor";

// main App module
import "./index.module";

// main style
import "../assets/styles/sass/index.scss";

angular.element(document).ready(() => {

  // Bootstrap Fund America Invest Now App
  document.body.setAttribute('dir-fa-invest-now', '');

  // Bootstrap Common Owner App
  angular.bootstrap(document, ['commonOwner', 'faInvestNow'], {
  // angular.bootstrap(document.body, ['faInvestNow', 'commonOwner'], {
    strictDi: true
  });
  
});