
/* injects from baggage-loader */

'use strict';

import DocumentListItemComponent from './documentlistitem.component';
import './documentlistitem.scss';

const DocumentListItemModule = angular.module('document-list-item-module', []);

DocumentListItemModule
    .component('documentListItem', new DocumentListItemComponent());

export default DocumentListItemModule;

