
/* injects from baggage-loader */

'use strict';

import ProjectManagerEditorController from './projectmanager.editor.controller';
import ProjectManagerEditorTemplate from './projectmanager.editor.html';

export default class ProjectManagerEditorComponent {
		constructor() {
				this.templateUrl = ProjectManagerEditorTemplate;
				this.controller = ProjectManagerEditorController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<'
				};
		}
}
