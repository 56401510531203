
/* injects from baggage-loader */

'use strict';

import CommentBoxComponent from './commentbox.component';
import './commentbox.scss';

const CommentBoxModule = angular.module('comment-box-module', []);

CommentBoxModule
    .component('commentBox', new CommentBoxComponent());

export default CommentBoxModule;

