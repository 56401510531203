
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingBackgroundCheckController from './issuer-onboarding.background-check.controller';
import IssuerOnboardingBackgroundCheckTemplate from './issuer-onboarding.background-check.html';

export default class IssuerOnboardingBackgroundCheckComponent {
		constructor() {
				this.templateUrl = IssuerOnboardingBackgroundCheckTemplate;
				this.controller = IssuerOnboardingBackgroundCheckController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
