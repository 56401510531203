
/* injects from baggage-loader */

'use strict';

import InvestorProfileListItemComponent from './investorprofilelistitem.component';
import './investorprofilelistitem.scss';

const InvestorProfileListItemModule = angular.module('investor-profile-list-item-module', []);

InvestorProfileListItemModule
    .component('investorProfileListItem', new InvestorProfileListItemComponent());

export default InvestorProfileListItemModule;

