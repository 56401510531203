
/* injects from baggage-loader */

'use strict';

import axios from 'axios';

const MAX_INVESTOR_FILE_SIZE = 100 * 1024 * 1024;	// 100mb
export default class InvestAccreditedController {

	constructor($log, $timeout, $state, Parse, InvestorDocumentSchema) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$state = $state;
		this.Parse = Parse;
		this.InvestorDocument = InvestorDocumentSchema;

		this.InvestorDocumentClassName = `Investor_Documents`;

		this.pendingFiles = [];
		this.invalidFiles = [];
		this.validFiles = [];

		// TODO: this should hold uploaded files and files already uploaded
		// if the investor needs to revisit this page
		this.uploadedFiles = [];
		this.uploadingFiles = false;
		this.submittingDocuments = false;
		this.moreInfoWasRequested = false;
		this.completionStep = 'invest.confirmation';

		// TODO: ONLY NEED TO DISPLAY THIS PAGE IF RUNNING 506c

	}

	$onInit() {

		// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
		// TODO: FUTURE: Migration to AWS
		// - S3 bucket upload triggers a lambda to upload that file to the NC API
		// - On success that file is deleted from our "cowner-tmp-document" bucket
		// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

		// Uploading a file to S3
		// - User uploadsne  signed url to the S3 storage
		// - Save the upload url to an "Investor Document" storage object
		// - Call the accrediationVerificationUpload CC endpoint
		//    - Will fetch the uploaded document
		//    - Send it to North Capital doc upload
		//    - Call North Capital "requestVerification" endpoint
		//    - Confirm response to user 

		this.$log.debug('Investor documents', this.investorDocuments);
		this.$log.debug('Investor', this.invesor);
		this.$log.debug('Accreditation', this.accreditation);

		this.validFiles = this.investorDocuments.map(doc => {
			doc.isExisting = true;
			doc.isUploaded = true;
			return doc;
		});
		this.uploadedFiles = [...this.uploadedFiles, ...this.validFiles];

		this.moreInfoWasRequested = this.isMoreInfoRequested();

	}

	isMoreInfoRequested() {
		return this.accreditation.get('more_info_requested');
	}

	hasNewFiles() {
		return this.validFiles.some(file => !file.id);
	}

	disableUploadButton() {
		return !this.validFiles.length || this.submittingDocuments || !this.hasNewFiles();
	}

	// disableSubmitButton() {
	// 	return !this.uploadedFiles.length || this.submittingDocuments || !this.hasNewFiles();
	// }

	processFiles() {

		while (this.pendingFiles.length > 0) {

			// Get a file
			let file = this.pendingFiles.shift();

			// Validate file size
			if (file.size > MAX_INVESTOR_FILE_SIZE) {
				// TODO: mark file as invalid and prevent upload
			}

			// Sanitize the file name
			let fileName = file.name.split('.');
			let ext = fileName.pop();
			fileName = fileName.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
			fileName = `${fileName}.${ext}`;

			// Push to valid files ready for upload
			this.validFiles.push({
				file,
				fileName,
				fileExt: ext,
				size: file.size,
				isEditing: false,
				isUploading: false,
				isUploaded: false,
				failed: false
			});

		}
	}

	/**
	 * Removes a document from the tracked documents array and 
	 * adds it to the array of documents to delete
	 * @param {Number} index 
	 */
	removeDocument(index) {

		// Remove the document from the "validFiles" array
		this.validFiles.splice(index, 1);

	}



	// TODO: Upload the file to the presigned url
	// On completion update the document object with upload complete
	// Accredation document object should call North Capital document upload
	// - In the background? as a worker job?
	// - Should delete our copy of the investor document after upload
	// - Up webhook for confirmation of document upload?
	// Show user success on uploading their document to our systems using the
	// presigned upload URL
	uploadLargeFileToS3(document, { url, fields = {} }) {

		// create a form obj
		const formData = new FormData();

		// append the fields in presignedPostData in formData
		Object.keys(fields).forEach(key => {
			formData.append(key, fields[key]);
		});

		// append the file
		formData.append("file", document.file);

		// post the data on the s3 url
		return axios.put(url, formData, {
			headers: {
				'Content-Type': document.file.type
			}
		});

	}

	uploadFileToS3(document, { url }) {
		return axios.put(url, document.file, {
			headers: {
				'Content-Type': document.file.type
			}
		});
	}

	async uploadFiles() {

		for (let document of this.validFiles) {

			// Skip document if uploaded or in progress
			if (document.isUploaded || document.isUploading) {
				continue;
			}

			this.uploadingFiles = true;

			try {

				document.isUploading = true;

				const {
					url, path
				} = await this.Parse.Cloud.run("getInvestorDocumentUploadUrl", {
					investorId: this.investor.id,
					fileName: document.fileName,
					// TODO: should this be "document.file.type"
					fileType: document.type
				});

				// Upload the file
				await this.uploadFileToS3(document, { url });

				// Create an InvestorDocument to track the upload
				const investorDoc = new this.InvestorDocument({
					identifier: "accreditation-document",
					path,
					name: document.fileName,
					original_name: document.file.name,
					type: document.file.type,
					size: document.file.size,
					investor: this.investor,
					investment: this.investment
				});
				await investorDoc.save();

				document.isUploading = false;
				document.isUploaded = true;

				// Push to the uploaded files array
				this.uploadedFiles.push(document);

				this.$timeout();

			} catch (e) {
				document.failed = true;
				document.isUploading = false;
				this.$log.error(e);
				this.$timeout();
			}

		}

		this.uploadingFiles = false;
		this.$timeout();

	}

	showSkipButton() {
		return !this.accreditation.get('submitted');
	}

	backToAccount() {
		// Go to account page
		this.$state.go('account.investment', {
			id: this.investment.id
		});
	}

	skipStep() {
		if (this.isMoreInfoRequested()) {
			this.backToAccount();
			return;
		}

		// Continue to confirmation page
		this.$state.go(this.completionStep, {
			investmentId: this.investment.id
		}, { id: this.investment.id });

	}

	async submitFiles() {
		this.submittingDocuments = true;

		// Handle upload of unprocessed files
		if (this.hasNewFiles()) {
			try {
				await this.uploadFiles();
			} catch (error) {
				this.$log.error(error);
				this.submittingDocuments = false;
				this.$timeout();
				return;
			}
		}

		// Submit review of the uploaded documents
		try {

			// Submit investor docs to North Capital for review
			const res = await this.Parse.Cloud.run("submitAccreditedInvestorDocuments", {
				investorId: this.investor.id
			});

			// Check if user was uploading additional documents
			if (this.isMoreInfoRequested()) {
				// Show modal to return the user to their account page
				this.backToAccount();
				return;
			}

			// Continue to confirmation page 
			this.$state.go(this.completionStep, {
				investmentId: this.investment.id
			}, { id: this.investment.id });

		} catch (e) {

			// TODO: display error and allow investor to conitue to the confirmation page
			this.$log.error(e);
			this.submittingDocuments = false;
			this.$timeout();
		}
	}

}

