
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('MessageSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Message = Parse.Object.extend('Messages');


		// Define 'Message' schema
		let schema = [
			'from',
			'to',
			'body',
			'investment',
			'project',
			'sender_profile',
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Message.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
    });
    
    return Message;
		
	});
}