
/* injects from baggage-loader */

'use strict';

export default class TermsOfUseController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.contactEmailAddress = `info@commonowner.com`

	}

	$onInit() {

	}

}
