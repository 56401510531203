
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('SocialShareService', function (Socialshare, $rootScope) {

		this.showMenu = false;
		
		this.openShareMenu = () => {
			$rootScope.appState.scrollLock = true;
			this.showMenu = true;
		}

		this.closeShareMenu = () => {
			$rootScope.appState.scrollLock = false;
			this.showMenu = false;
		}

		// {
		// 	'provider': 'facebook',
		// 	'conf': {
		// 		'url':'',
		// 		'title':'',
		// 		'href':'',
		// 		'quote':'',
		// 		'hashtags':'',
		// 		'text': '',
		// 		'media': '',
		// 		'mobile_iframe': '',
		// 		'type': '',
		// 		'via': '',
		// 		'to': '',
		// 		'from': '',
		// 		'ref': '',
		// 		'display': '',
		// 		'source': '',
		// 		'caption': '',
		// 		'redirectUri': '',
		// 		'trigger': 'click',
		// 		'popupHeight': 600,
		// 		'popupWidth': 500
		// 	}
		// },

		this.share = (provider, data) => {
			Socialshare.share({
				provider,
				attrs: {
					socialshareText: data.text,
					// socialshareQuote: data.text,
					// socialshareCaption: data.text,
					socialshareUrl: data.url,
					socialshareSubject: data.subject,
					socialshareBody: data.body,
					socialshareDescription: data.description,
					socialshareSource: data.source
				}
			});
			this.closeShareMenu()
		}

	});
}

