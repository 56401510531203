
/* injects from baggage-loader */

'use strict';

import InvestmentListItemComponent from './investmentlistitem.component';
import './investmentlistitem.scss';

const InvestmentListItemModule = angular.module('investment-list-item-module', []);

InvestmentListItemModule
    .component('investmentListItem', new InvestmentListItemComponent());

export default InvestmentListItemModule;

