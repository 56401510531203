
/* injects from baggage-loader */

'use strict';

export default function (app) {

  app.factory('fed_ach_banks', ['ss', '$resource',
    function (ss, $resource) {
      return $resource(ss.apiHost + '/v2/fed_ach_banks', { id: '@id' }, {
        'query': {
          method: 'POST',
          url: ss.apiHost + '/v2/fed_ach_banks/query'
        },
        'bank': {
          url: ss.apiHost + '/v2/fed_ach_banks/:id'
        }
      });
    }])

    .factory('investNowInvestments', ['ss', '$resource',
      function (ss, $resource) {
        return $resource(ss.apiHost + '/v2/invest_now/investments/:id/:state', { 'id': '@id', 'state': '@state', 'invest_now_investment_id': '@invest_now_investment_id' }, {
          'changeState': {
            'method': 'PATCH',
            'url': ss.apiHost + '/v2/invest_now/investments/:id/state'
          },
          'estimateWithUsd': {
            'method': 'POST',
            'url': ss.apiHost + '/v2/invest_now/investments/:id/cryptocurrency/estimate_with_usd'
          },
          'editState': {
            'url': ss.apiHost + '/v2/invest_now/investments/:id/:state/edit'
          },
          'getSession': {
            'method': 'POST',
            'url': ss.apiHost + '/v2/invest_now/investment_sessions'
          },
          'sign': {
            'method': 'PATCH',
            'url': ss.apiHost + '/v2/invest_now/investments/:invest_now_investment_id/subscription_agreement/electronic_signatures/:id'
          },
          'getSignatureRequestState': {
            'url': ss.apiHost + '/v2/invest_now/investments/signature_requests/:resource_token/:state'
          },
          'resume': {
            'method': 'POST',
            'url': ss.apiHost + '/v2/invest_now/investment_sessions/resume'
          },
          'update': {
            'method': 'PATCH'
          },
          'validateEdit': {
            'method': 'PATCH',
            'url': ss.apiHost + '/v2/invest_now/investments/:id/:state?validate_only=1'
          },
          'validateSignature': {
            'method': 'PATCH',
            'url': ss.apiHost + '/v2/invest_now/investments/:invest_now_investment_id/subscription_agreement/electronic_signatures/:id?validate_only=1'
          }
        });
      }])

    .factory('signatureRequests', ['ss', '$resource',
      function (ss, $resource) {
        return $resource(ss.apiHost + '/v2/invest_now/signature_requests/:token/:state', { 'state': '@state', 'token': '@token' }, {
          'edit': {
            'url': ss.apiHost + '/v2/invest_now/signature_requests/:token/edit'
          },
          'editState': {
            'url': ss.apiHost + '/v2/invest_now/signature_requests/:token/:state/edit'
          },
          // 'sign': {
          //   'method': 'PATCH',
          //   'url': ss.apiHost + '/v2/invest_now/investments/signature_requests/:token'
          // },
          'update': {
            'method': 'PATCH'
          },
          'validateEdit': {
            'method': 'PATCH',
            'url': ss.apiHost + '/v2/invest_now/signature_requests/:token?validate_only=1'
          },
          'validateEditState': {
            'method': 'PATCH',
            'url': ss.apiHost + '/v2/invest_now/signature_requests/:token/:state?validate_only=1'
          }
        });
      }])

}