
/* injects from baggage-loader */

'use strict';

import AccountSettingsSecurityComponent from './account.settings.security.component';

const AccountSettingsSecurityPageModule = angular.module('account-settings-security-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.security', {
        url: '/security',
        title: 'Account Settings: Security',
        component: 'accountSettingsSecurity',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('accountSettingsSecurity', new AccountSettingsSecurityComponent());

export default AccountSettingsSecurityPageModule;
