
/* injects from baggage-loader */

'use strict';

import ExpressEarlyInterestModalComponent from './expressearlyinterestmodal.component';
import './expressearlyinterestmodal.scss';

const ExpressEarlyInterestModalModule = angular.module('express-early-interest-modal-module', []);

ExpressEarlyInterestModalModule
    .component('expressEarlyInterestModal', new ExpressEarlyInterestModalComponent());

export default ExpressEarlyInterestModalModule;

