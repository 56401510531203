
/* injects from baggage-loader */

'use strict';

export default class UpdatePaymentMethodModalComponentController {

	constructor($log, $timeout, FormBuilder, Parse, ACH_ACCOUNT_TYPES) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.fb = FormBuilder;
		this.Validators = this.fb.Validators;
		this.Constraints = this.fb.Constraints;
		this.Events = this.fb.Events;
		this.Parse = Parse;

		this.achAccountTypes = ACH_ACCOUNT_TYPES;

		this.updating = false;

	}

	$onInit() {

		// Set modal context
		if (this.resolve) {
			this.ctx = this.resolve.context || {};
		}

		this.project = this.ctx.project;
		this.investment = this.ctx.investment;
		this.$log.debug('Project:', this.project);
		this.$log.debug('Investment:', this.investment);

		this.achForm = this.initAchForm();
		this.creditCardForm = this.initCreditCardForm();

		// Reload form for dynamic elements
		this.$timeout(() => {
			this.getPaymentMethodForm().initialize();
		}, 100);

	}

	initAchForm() {
		return this.fb.group({
			account_holder_name: ['', this.Validators.required],
			account_nickname: [''],
			account_type: ['Checking', this.Validators.required],
			routing_number: ['',
				this.Validators.required,
				this.Validators.maxLength(9),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			account_number: ['',
				this.Validators.required,
				this.Validators.maxLength(17),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			account_number_re: ['',
				this.Validators.required,
				this.Validators.maxLength(17),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
		});
	}

	initCreditCardForm() {
		return this.fb.group({
			card_holder_name: ['', this.Validators.required],
			card_number: ['',
				this.Validators.required,
				this.Validators.maxLength(16),
				this.Validators.length(16),
				this.Validators.custom(this.validateCreditCardType.bind(this)),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			cvv: ['',
				this.Validators.required,
				// this.Validators.maxLength(4),
				this.Validators.length(3),	// 3 digits only because AMEX not accepted
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			exp_date: ['',
				this.Validators.required,
				// this.Validators.maxLength(4),
				this.Validators.length(4),
				// Validate & constrain input to numeric only
				this.fb.merge(this.Validators.numericOnly, this.Constraints.numericOnly),
			],
			card_type: [''],
		});
	}

	getCreditCardType(value) {
		return (/^5[1-5]/.test(value))
			? "mastercard"
			: (/^4/.test(value))
				? "visa"
				: (/^3[47]/.test(value))
					? "amex"
					: (/^6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5]))/.test(value))
						? "discover"
						: undefined;
	}

	setCreditCardType() {
		const cardNumber = this.creditCardForm.get('card_number').value;
		const cardType = this.getCreditCardType(Number(cardNumber));
		this.creditCardForm.patchValue({
			card_type: cardType
		});
	}

	validateCreditCardType(value) {
		return this.getCreditCardType(value) !== 'amex';
	}

	getPaymentMethodForm() {
		if (this.investment.payment_method === 'CREDITCARD') {
			return this.creditCardForm;
		}
		if (this.investment.payment_method === 'ACH') {
			return this.achForm;
		}
		return null;
	}

	isPaymentFormValid() {
		const form = this.getPaymentMethodForm();
		if (!form) {
			return false;
		}
		return form.valid(true);
	}

	async update() {

		// TODO: validate payment method form

		const updatedPaymentForm = this.getPaymentMethodForm();
		if (!updatedPaymentForm.valid()) {
			return false;
		}
		const paymentForm = updatedPaymentForm.value();

		this.updating = true;
		try {

			// Update the payment method and re-submit 
			const { investment, success } = await this.Parse.Cloud.run('updatePaymentMethod', {
				investmentId: this.investment.id,
				updatedPaymentForm: paymentForm,
				resubmit: true
			});
			console.log(investment)
			console.log(success);

			// Close the modal with success
			this.close({
				$value: {
					success: true,
					investment
				}
			});

		} catch (err) {
			this.$log.error(err);
			this.error_msg = `Failed to update payment method. Please contact support@commonowner.com`;
			this.updating = false;
			this.$timeout();
		}

	}

	close() {
		this.dismiss({ $value: 'cancel' });
	}

}
