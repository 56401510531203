
/* injects from baggage-loader */

'use strict';

import ProjectManagerHomeController from './projectmanager.home.controller';
import ProjectManagerHomeTemplate from './projectmanager.home.html';

export default class ProjectManagerHomeComponent {
		constructor() {
				this.templateUrl = ProjectManagerHomeTemplate;
				this.controller = ProjectManagerHomeController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					activityFeed: '<',
					offering: '<'
				};
		}
}
