
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingFinalizeController from './issuer-onboarding.finalize.controller';
import IssuerOnboardingFinalizeTemplate from './issuer-onboarding.finalize.html';

export default class IssuerOnboardingFinalizeComponent {
		constructor() {
				this.templateUrl = IssuerOnboardingFinalizeTemplate;
				this.controller = IssuerOnboardingFinalizeController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					onboarding: '<'
				};
		}
}
