
/* injects from baggage-loader */

'use strict';

import CookiePolicyComponent from './cookie-policy.component';

const CookiePolicyPageModule = angular.module('cookie-policy-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('cookie-policy', {
        parent: 'root',
        url: '/legal/cookie-policy',
        title: 'Cookie Policy',
        component: 'cookiePolicy',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('cookiePolicy', new CookiePolicyComponent());

export default CookiePolicyPageModule;
