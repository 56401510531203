
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('InvestorDocumentSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let InvestorDocuments = Parse.Object.extend('Investor_Documents');

		// Define 'Project_Sections' scheme
		let schema = [
			'type',
			'scope',
			'name',
			'file',
			'investment',
			'projected',			// Tells the UI it will have to call a the API to get the signed URL for download
			'original_name',
			'size',
			'tags',
			'src',
			'url',
			'path'
		];
		
		// Load schema into object prototype
		schema.forEach(field => {
			Object.defineProperty(InvestorDocuments.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return InvestorDocuments;

	});
}

