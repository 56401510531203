
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectDocument', function () {


		/**
		 * Tags the document as the "Disclosure Document" and removes that tag from all others
		 * @param {Parse.Object.extend('Project_Document')} doc 
		 * @param {Parse.Object.extend('Project_Document')} documents[]
		 */
		this.tagDisclosureDocument = async (doc, documents) => {
			// Remove tag from all other documents
			for (let d of documents) {
				if (d.tags.find(t => t.id === 'disclosure_doc')) {
					d.remove('tags', { title: "Disclosure Document", id: "disclosure_doc" })
					await d.save();
				}
			}
			// Add the tag to the document
			doc.tags.push({ title: "Disclosure Document", id: "disclosure_doc" });
			return doc.save();
		}

		
	});
}

