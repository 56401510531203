
/* injects from baggage-loader */

'use strict';

export default class InvestmentConfirmationController {

	constructor($log, $state, $stateParams, $timeout, Investments) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$timeout = $timeout;
		this.Investments = Investments;

		this.poller;
		this.isPolling = false;
		this.invalidId = false;
		this.completed = false;

	}

	$onInit() {
		
		this.investmentId = this.$stateParams.investment_id;

		if (!this.investmentId) {
			this.invalidId = true;
			return false;
		}

		// Poll db for updates to the investment object, checking for fund america data each time
		this.getInvestment()
			.then(investment => {
				if (this.isComplete(investment)) {
					this.completed = true;
					this.$timeout();
				} else {
					this.startPoller();
				}
			})
			.catch(err => {
				this.$log.error(err)
				this.invalidId = true;
			})

	}

	$onDestroy() {

		// Clear investment poller on page exit
		if (this.poller) {
			clearInterval(this.poller)
		}

	}

	isComplete(investment) {
		return !investment.incomplete && typeof investment.fund_america_id === 'string';
	}

	startPoller() {
		if (this.isPolling) {
			return false;
		}
		this.poller = setInterval(() => {
			this.getInvestment()
				.then(investment => {
					if (this.isComplete(investment)) {
						this.completed = true;
						// Stop poller
						if (this.poller) {
							clearInterval(this.poller)
						}
						this.$timeout();
					}
				})
		}, 5000);
		this.isPolling = true;
	}

	getInvestment() {
		return this.Investments.get(this.investmentId)
			.then(investment => {
				this.$log.debug(investment);
				return investment;
			})
	}

}
