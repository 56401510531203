
/* injects from baggage-loader */

'use strict';

export default class PasswordResetController {

	constructor($log, $stateParams) {
		'ngInject';

		this.$log = $log;

		this.token = $stateParams.token;
		this.username = $stateParams.username;

		this.new_password = '';
		this.new_password_confirm = '';

	}

	$onInit() {

	}

	changePassword() {

		// Clean fields
		this.new_password = this.new_password.trim();
		this.new_password_confirm = this.new_password_confirm.trim();

		// Verify passwords match
		let match = this.new_password.trim() === this.new_password_confirm.trim();

		// Throw error if passwords dont match
		if (!match) {
			throw 'Passwords dont match'
		}

		// Change password

	}

}
