
/* injects from baggage-loader */

'use strict';

// Investment agreement
// /projects/13/investments/subscription_agreement?
// - investor_city = Buffalo
// - investor_first_name = Mitch
// - investor_last_name = Skomra
// - investor_state = New York 
// - investor_street_1 = 57 Saint Felix Ave
// - investor_street_2 =
// - investor_type = individual
// - investor_zip = 14227
// - offering_type = reg_d

export default function (app) {
	app.service('InvestmentWizard', function ($state, $log) {

		this.steps = [{
			title: 'Investment Terms',
			subtitle: 'Agree to terms',
			state: 'projectinvestmentwizard.start'
		}, {
			title: 'Investor Information',
			subtitle: 'Gather personal info',
			state: 'projectinvestmentwizard.info'
		}, {
			title: 'Investment Agreement',
			subtitle: 'Agreements',
			state: 'projectinvestmentwizard.agreement'
		}];

		this.profile, this.project, this.investment;

		this.initialize = (project, profile) => {

			this.project = project;
			this.profile = profile.toJSON();

			// Clean parse fields from profile object
			['objectId', 'ACL', 'createdAt', 'updatedAt', 'image'].forEach(field => {
				delete this.profile[field];
			});

			// Convert date of birth
			if (this.profile.dob) {
				this.profile.dob = new Date(this.profile.dob.iso);
			}

			// Create a new investment object
			this.investment = {
				profile: this.profile
			};

		};


		/**
		 * Returns the array of wizard steps
		 */
		this.getSteps = () => this.steps;


		/**
		 * Returns the current step object
		 */
		this.getCurrentStep = () => this.steps.find(step => step.state === $state.current.name);


		/**
		 * Returns the step previous to the current one
		 */
		this.getPreviousStep = () => {

			// Get the index of the current step
			let cur_index = this.steps.indexOf(this.getCurrentStep());

			// Return the previous step
			if (cur_index > 0) {
				return this.steps[cur_index - 1];
			}

			return {};

		}


		/**
		 * Returns the step after to the current one
		 */
		this.getNextStep = () => {

			// Get the index of the current step
			let cur_index = this.steps.indexOf(this.getCurrentStep());

			// Return the next step
			if (cur_index + 1 < this.steps.length) {
				return this.steps[cur_index + 1];
			}

			return {};

		}


		/**
		 * Returns true if the step passed is the current step
		 */
		this.isCurrentStep = step => step.state === $state.current.name;

		
		/**
		 * Returns true if the step is complete
		 */
		this.stepCompleted = index => this.steps[index].completed;


		/**
		 * Returns the number of shares relative to the investment amount
		 */
		this.getShares = () => {
			if (this.investment.amount) {
				let share_price = this.project.share_price || this.project.get('share_price');
				return this.investment.amount / (share_price || 1);
			} else {
				return '';
			}
		};

		this.verifyForm = form => {

			$log.debug('Controls:', form.$$controls, form.$getControls());
			$log.debug('Valid:', form.$valid);
			$log.debug(this.investment);

			// Check if form is valid
			if (form.$valid) {

				// Mark step completed
				this.steps[this.steps.indexOf(this.getCurrentStep())].completed = true;

				// Advance to the next step
				$state.go(this.getNextStep().state);

			}

		}

	});
}

