
/* injects from baggage-loader */

'use strict';

import EnableTwoFactorAuthModalComponent from './enabletwofactorauthmodal.component';
import './enabletwofactorauthmodal.scss';

const EnableTwoFactorAuthModalModule = angular.module('enable-two-factor-auth-modal-module', []);

EnableTwoFactorAuthModalModule
    .component('enableTwoFactorAuthModal', new EnableTwoFactorAuthModalComponent());

export default EnableTwoFactorAuthModalModule;

