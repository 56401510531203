
/* injects from baggage-loader */

'use strict';

import axios from 'axios';

const MAX_INVESTOR_FILE_SIZE = 20 * 1024 * 1024;	// 20mb

export default class PhotoIdUploadController {

	constructor($log, $state, $timeout, $stateParams, User, Parse, InvestorDocumentSchema) {
		'ngInject';

		this.debugging = false;

		this.$log = $log;
		this.$state = $state;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;
		this.User = User;
		this.Parse = Parse;
		this.InvestorDocument = InvestorDocumentSchema;

		this.isUploadingFile = false;
		this.isUploadCompleted = false;
		this.isUploadFailed = false;

		this.selectedFile = null;
		this.imagePreview = null;
		this.document = null;

	}

	$onInit() {

		this.successCallback = typeof this.successCallback === 'function'
			? this.successCallback
			: () => { };

		this.errorCallback = typeof this.errorCallback === 'function'
			? this.errorCallback
			: () => { };


		// NOTE: Callback usage
		// this.successCallback({ result: 'test'});
		// this.errorCallback({ err: 'error'});
	}


	disableUploadButton() {
		return !this.selectedFile || !this.document || this.isUploadingFile || this.isUploadCompleted;
	}

	clearSelectedFile() {
		this.selectedFile = null;
		this.document = null;
	}

	processFile() {

		// Get the file
		let file = this.selectedFile;

		// Validate file size
		if (file.size > MAX_INVESTOR_FILE_SIZE) {
			// TODO: mark file as invalid and prevent upload
		}

		// Sanitize the file name
		let fileName = file.name.split('.');
		let ext = fileName.pop();
		fileName = fileName.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
		fileName = `${fileName}.${ext}`;

		// The the document to upload
		this.document = {
			file,
			fileName,
			fileExt: ext,
			size: file.size,
			isEditing: false,
			isUploading: false,
			isUploaded: false,
			failed: false
		};

		// Generate immediate preview
		let reader = new FileReader();
		reader.addEventListener('load', () => {
			this.imagePreview = reader.result;
			this.$timeout();
		}, false);
		reader.readAsDataURL(file);

	}

	uploadFileToS3(document, { url }) {
		return axios.put(url, document.file, {
			headers: {
				'Content-Type': document.file.type
			}
		});
	}

	async uploadFile() {

		this.isUploadingFile = true;

		try {

			const {
				url, path
			} = await this.Parse.Cloud.run("getInvestorDocumentUploadUrl", {
				investorId: this.investor.id,
				fileName: this.document.fileName,
				// TODO: should this be "document.file.type"
				fileType: this.document.type
			});

			if (this.debugging) {
				return;
			}

			// Upload the file
			await this.uploadFileToS3(this.document, { url });

			// Create an InvestorDocument to track the upload
			const investorDoc = new this.InvestorDocument({
				identifier: "kyc-photo-document",
				path,
				name: this.document.fileName,
				original_name: this.document.file.name,
				type: this.document.file.type,
				size: this.document.file.size,
				investor: this.investor,
				investment: this.investment
			});
			await investorDoc.save();

			this.isUploadingFile = false;
			this.isUploadCompleted = true;

			this.successCallback({
				result: investorDoc
			});

		} catch (e) {
			this.isUploadFailed = true;
			this.isUploadingFile = false;
			this.$log.error(e);
			this.$timeout();

			this.errorCallback({
				error: e
			});
		}

		this.$timeout();

	}

}
