
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('OnboardingDocumentSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let OnboardingDocument = Parse.Object.extend('Onboarding_Documents');

		// Define 'Investment' scheme
		let schema = [
			'name',
			'original_name',
			'type',
			'size',
			'file',
			'onboarding',
			'identifier'
		];

		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(OnboardingDocument.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});	
    
    return OnboardingDocument;
		
	});
}