
/* injects from baggage-loader */

'use strict';

const shared = angular.module('core.shared', []);

// Constants and Factories
import constants from './constants';
import staticPageMetadata from './static-metadata';
import errors from './errors';
import usStates from './services/usstates.factory';
import countries from './services/countries.factory';
import faqs from './faqs';
import Snackbar from './services/snackbar.factory';
import StateService from './services/state.service';

// Parse Objects
import ParseService from './services/parse.service';
import UserService from './services/user.service';
import ProjectService from './services/projects.service';
import ProfileService from './services/profiles.service';
import InvestmentService from './services/investments.service';
import MediaService from './services/media.service';
import DocumentService from './services/documents.service';
import CommentService from './services/comments.service';
import ProjectDocumentSerice from './services/project-document.service';
import ProjectActivityService from './services/project-activity.service';
import ImagePreloader from './services/imagepreloader.service';
import TeamMembersService from './services/team-members.service';
import NotificationService from './services/notification.service';
import EventsService from './services/events.service';
import Onboarding from './services/onboarding.service';
import OnboardingDocument from './services/onboarding-documents.service';
import DisbursementRequestService from './services/disbursement-requests.service';
import ProjectInterestService from './services/project-interest.service';
import InvestorService from './services/investors.service';
import InvestorDocumentsService from './services/investor-documents.service';

// Parse Schemas
import ProfileSchema from './schemas/profile.schema';
import ProjectSchema from './schemas/project.schema';
import ProjectSectionSchema from './schemas/project-section.schema';
import InvestmentSchema from './schemas/investment.schema';
import InvestorDocumentSchema from './schemas/investment-document.schema';
import AccountIntegrationSchema from './schemas/account-integration.schema';
import MediaSchema from './schemas/media.schema';
import ProjectStatusSchema from './schemas/project-status.schema';
import ProjectStatsSchema from './schemas/project-stats.schema';
import AccreditationSchema from './schemas/accreditation.schema';
import UserSchema from './schemas/user.schema';
import TeamMembersSchema from './schemas/team-members.schema';
import ProjectActivitySchema from './schemas/project-activity.schema';
import NotificationsSchema from './schemas/notifications.schema';
import ProjectCommentSchema from './schemas/project-comment.schema';
import MessageSchema from './schemas/message.schema';
import ProjectDocumentSchema from './schemas/project-document.schema';
import OnboardingSchema from './schemas/onboarding.schema';
import OnboardingDocumentSchema from './schemas/onboarding-document.schema';
import DisbursementRequestSchema from './schemas/disbursement-request.schema';
import ProjectInterestSchema from './schemas/project-interest.schema';
import InvestorSchema from './schemas/investor.schema';
import ProjectUpdateSchema from './schemas/project-update.schema';

// General Services
import AlertsService from './services/alerts.service';
import ErrorHandlerService from './services/errorhandler.service';
import NavigationService from './services/navigation.service';
import FormBuilderService from './services/formbuilder.service';
import CommonService from './services/common.service';
import SocialShareService from './services/socialshare.service';
import InvestmentWizardService from './services/investment-wizard.service';
import UtilitiesService from './services/utilities.service';
import ReturnsCalculatorService from './services/returns-calculator.service';
import MetaService from './services/meta.service';
import investmentCalculatorService from './services/investment-calculator.service';

// Filters
import PercentageFilter from './filters/percentage.filter';
import TelephoneFilter from './filters/telephone.filter';
import CapitalizeFilter from './filters/capitalize.filter';
import AbbreviateFilter from './filters/abbreviate.filter';
import FilesizeFilter from './filters/filesize.filter';
import PhoneNumberFilter from './filters/phonenumber.filter';
import CommasFilter from './filters/commas.filter';

// Directives
import SocialSecurityDirective from './directives/ssninput.directive';
import DynamicHeadingDirective from './directives/dynamicHeading/dynamicheading.directive';
import TimeBasedGreetingDirective from './directives/timeBasedGreeting/timebasedgreeting.directive';
import FormatDirective from './directives/format/format.directive';
import NgEnterDirective from './directives/ngEnter/ngenter.directive';
import SpinnerDirective from './directives/spinner/spinner.directive';
import PasswordStrengthDirective from './directives/passwordStrength/passwordstrength.directive';
import DynamicformDirective from './directives/dynamicForm/dynamicform.directive';
import BlurofffocusDirective from './directives/blurOffFocus/blurofffocus.directive';
import ScrollshowDirective from './directives/scrollShow/scrollshow.directive';
import ScrollClassDirective from './directives/scrollClass/scrollclass.directive';
import DatePickerDirective from './directives/date-picker/date-picker.directive';
import SnackbarDirective from './directives/snackbar/snackbar.directive';



// ====================================================
// Connect services to the app
// ====================================================

// Directives
SocialSecurityDirective(shared);
BlurofffocusDirective(shared);
DynamicformDirective(shared);
ScrollshowDirective(shared);
DynamicHeadingDirective(shared);
TimeBasedGreetingDirective(shared);
FormatDirective(shared);
ScrollClassDirective(shared);
DatePickerDirective(shared);
NgEnterDirective(shared);
SnackbarDirective(shared);
SpinnerDirective(shared);
PasswordStrengthDirective(shared);

// Services
ParseService(shared);
UserService(shared);
ProjectService(shared);
ProfileService(shared);
InvestmentService(shared);
MediaService(shared);
DocumentService(shared);
CommentService(shared);
InvestmentWizardService(shared);
AlertsService(shared);
ErrorHandlerService(shared);
NavigationService(shared);
EventsService(shared);
SocialShareService(shared);
CommonService(shared);
ProjectDocumentSerice(shared);
Snackbar(shared);
ProjectActivityService(shared);
NotificationService(shared);
ImagePreloader(shared);
TeamMembersService(shared);
Onboarding(shared);
OnboardingDocument(shared);
UtilitiesService(shared);
ReturnsCalculatorService(shared);
DisbursementRequestService(shared);
MetaService(shared);
ProjectInterestService(shared);
InvestorService(shared);
investmentCalculatorService(shared);
InvestorDocumentsService(shared);

// Schemas
ProfileSchema(shared);
ProjectSchema(shared);
ProjectSectionSchema(shared);
InvestmentSchema(shared); 
InvestorDocumentSchema(shared);
AccountIntegrationSchema(shared);
MediaSchema(shared);
ProjectStatusSchema(shared);
ProjectStatsSchema(shared);
AccreditationSchema(shared);
ProjectDocumentSchema(shared);
UserSchema(shared);
ProjectCommentSchema(shared);
MessageSchema(shared);
ProjectActivitySchema(shared);
NotificationsSchema(shared);
TeamMembersSchema(shared);
OnboardingSchema(shared);
OnboardingDocumentSchema(shared);
DisbursementRequestSchema(shared);
ProjectInterestSchema(shared);
InvestorSchema(shared);
ProjectUpdateSchema(shared);

// Filters
PercentageFilter(shared);
TelephoneFilter(shared);
CapitalizeFilter(shared);
FilesizeFilter(shared);
AbbreviateFilter(shared);
PhoneNumberFilter(shared);
CommasFilter(shared);

// Others
constants(shared);
staticPageMetadata(shared);
errors(shared);
usStates(shared);
countries(shared);
faqs(shared);
FormBuilderService(shared);
StateService(shared);



export default shared;