
/* injects from baggage-loader */

'use strict';

import ProjectListItemComponent from './projectlistitem.component';
import './projectlistitem.scss';

const ProjectListItemModule = angular.module('project-list-item-module', []);

ProjectListItemModule
    .component('projectListItem', new ProjectListItemComponent());

export default ProjectListItemModule;

