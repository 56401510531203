
/* injects from baggage-loader */

'use strict';

import ProjectUpdateComponent from './projectupdate.component';
import './projectupdate.scss';

const ProjectUpdateModule = angular.module('project-update-module', []);

ProjectUpdateModule
    .component('projectUpdate', new ProjectUpdateComponent());

export default ProjectUpdateModule;

