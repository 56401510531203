
/* injects from baggage-loader */

'use strict';

export default class IssuerOnboardingController {

	/*

		Onboarding process (3 phases)
			1. Issuer expresses interest in hosting a raise
				- They Click the "Issue a Raise" button and are taken to a page giving them details
					with download links to our onboarding materials.
			2. The issuer begins the onboarding process by filling out the "Project Intake Form" 
				 and uploading documents in phase 1 of the onboarding process.

	*/

	constructor($log, $window, $state, $stateParams, $rootScope, User) {
		'ngInject';

		this.$log = $log;
		this.$window = $window;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$rootScope = $rootScope;
		this.userService = User;

		this.navigation = [
			{
				title: 'Onboarding',
				items: [
					{
						title: 'Issuer Details',
						icon: 'fas fa-list',
						state: 'issuer-onboarding.details'
					},
					{
						title: 'Offering & Team Info',
						icon: 'fas fa-users',
						state: 'issuer-onboarding.offering-and-team'
					},
					{
						title: 'Organizational Documents',
						icon: 'far fa-file',
						state: 'issuer-onboarding.documents'
					},
					// {
					// 	title: 'Issuer Representations',
					// 	icon: 'far fa-file',
					// 	state: 'issuer-onboarding.representations'
					// },
					{
						title: 'Records Explanation',
						icon: 'far fa-clipboard',
						state: 'issuer-onboarding.records'
					},
					// {
					// 	title: 'Schedule Interviews',
					// 	icon: 'far fa-user',
					// 	state: 'issuer-onboarding.interview'
					// },
					// {
					// 	title: 'Background Checks',
					// 	icon: 'far fa-user',
					// 	state: 'issuer-onboarding.background-check'
					// },
					{
						title: 'Certifications',
						icon: 'fas fa-pencil-alt',
						state: 'issuer-onboarding.finalize'
					}
				]
			}
		];

		// Set page title
		this.pageTitle = this.$state.current.localTitle;

	}

	$onInit() {
		this.$log.debug('onboarding', this.onboarding)
	}

	toggleNavbar() {
		this.$window.scrollTo(0, 0);
		this.toggleNav = !this.toggleNav;
		this.$rootScope.appState.scrollLock = this.toggleNav;
	}

	setPageTitle(page) {
		this.toggleNavbar();
		this.pageTitle = page.title;
	}

	// projectPreviewLink() {
	// 	return `${this.$window.location.origin}/project-preview/${this.project.id}`
	// }

}
