
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectDocumentSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let ProjectDocument = Parse.Object.extend('Project_Documents');

		// Define 'ProjectStatus' scheme
		let schema = [
			'type',
			'scope',
			'name',
			'file',
			'investment',
			'project',
			'original_name',
			'size',
			'tags',
			'src',
			'visible'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(ProjectDocument.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProjectDocument;

	});
}

