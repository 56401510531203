
/* injects from baggage-loader */

'use strict';

import SocialShareMenuController from './socialsharemenu.controller';
import SocialShareMenuTemplate from './socialsharemenu.html';

export default class SocialShareMenuComponent {
		constructor() {
				this.templateUrl = SocialShareMenuTemplate;
				this.controller = SocialShareMenuController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					shareData: '<'
				};
		}
}
