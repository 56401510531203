
/* injects from baggage-loader */

'use strict';

import moment from 'moment';

export default class ProjectListItemController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.isDeleted = false;

	}

	$onInit() {
		if (!this.data || !this.data.createdAt) {
			this.isDeleted = true;
		}
		this.$log.debug(this.data);
	}

	getIsInvestmentSuspended() {
		return this.data.investment_suspended;
	}

	getIsTestingWaters() {
		return this.data.status === 'testing';
	}

	/**
	 * Returns the number of days remianing until the end of the raise
	 * @param {Object} project 
	 */
	daysRemaining(project) {
		// let days = moment(project.get('end_date')).diff(moment(), 'days');
		// let exact = Math.ceil(moment(project.get('end_date')).diff(moment(), 'days', true));
		// this.$log.log('Days remaining:', days, exact);
		// if (exact > 0 && exact < 1) {
		// 	days = 1;
		// }
		// return days > 0 ? days : 0;

		let days = Math.ceil(moment(project.get('end_date')).diff(moment(), 'days', true));
		return days > 0 ? days : 0;
	}

	getFormattedProjectType() {
		if (this.getIsTestingWaters()) {
			return 'Gauging Interest for Reg CF'
		}
		if (this.data.type && this.data.type.includes('cf')) {
			return 'Reg CF'
		} else {
			return this.data.type;
		}
	}

	getActionButtonText() {
		if (this.daysRemaining(this.data) > 1 && this.ctx !== 'account' && !this.getIsTestingWaters() && !this.getIsInvestmentSuspended()) {
			return 'Invest now';
		} else {
			return 'View Details';
		}
	}

	getProjectLocation() {
		if (this.data.location !== "undefined, undefined") {
			return this.data.location;
		}
		return `${this.data.city}, ${this.data.region}`;
	}

	/**
	 * Returns the project "slug" or fallback to the ID of the current project
	 */
	getProjectSlugOrId() {
		return this.data.slug || this.data.id;
	}

	getIsProjectFunded() {
		return this.data.get('stats').funds_raised > this.data.get('investment_goal_min');
	}

	getIsProjectConcluded() {
		const daysRemaining = this.daysRemaining(this.data);
		return !daysRemaining || daysRemaining < 1;
	}

	getDaysRemainingFormatted() {
		const daysRemaining = this.daysRemaining(this.data);
		return daysRemaining ? `${daysRemaining} Days` : `Closed`;
	}

	getFormattedFundedMessage() {
		return `Successfully Funded!`;
	}



}
