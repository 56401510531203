
/* injects from baggage-loader */

'use strict';

import InvestKycComponent from './invest.kyc.component';

const InvestKycPageModule = angular.module('invest-kyc-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('invest.kyc', {
        url: '/kyc',
        component: 'investKyc',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          photoIdDocument: function (investor, InvestorDocuments) {
            return InvestorDocuments.getPhotoIdDocument(investor);
          }
        }
      });
  })
  .run(function($transitions, StateService) {
    $transitions.onSuccess({ to: 'invest.kyc' }, transition => {
      // Show the summary panel
      StateService.investment.showSummaryPanel = false;
    });
  })
  .component('investKyc', new InvestKycComponent());

export default InvestKycPageModule;
