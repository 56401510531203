
/* injects from baggage-loader */

'use strict';

import LearnController from './learn.controller';
import LearnTemplate from './learn.html';

export default class LearnComponent {
		constructor() {
				this.templateUrl = LearnTemplate;
				this.controller = LearnController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
