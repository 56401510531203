
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectActivitySchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let ProjectActivity = Parse.Object.extend('Project_Activity');

		// Define 'ProjectActivity' scheme
		let schema = [
			'message',
			'isMaster',
			'user',
			'project',
			'icon',
			'updated',
			'details'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(ProjectActivity.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return ProjectActivity;

	});
}

