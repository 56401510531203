
/* injects from baggage-loader */

'use strict';

import EditDocumentModalComponent from './editdocumentmodal.component';
import './editdocumentmodal.scss';

const EditDocumentModalModule = angular.module('edit-document-modal-module', []);

EditDocumentModalModule
    .component('editDocumentModal', new EditDocumentModalComponent());

export default EditDocumentModalModule;

