
/* injects from baggage-loader */

'use strict';

import headerModule from './components/header/header.module';
import footerModule from './components/footer/footer.module';
import projectListItem from './components/projectListItem/projectlistitem.module';
import investmentListItem from './components/investmentListItem/investmentlistitem.module';
import projectComment from './components/projectComment/projectcomment.module';
import documentListItem from './components/documentListItem/documentlistitem.module';
import commentBox from './components/commentBox/commentbox.module';
import accountWidget from './components/account-widget/account-widget.module';
import modalComponent from './components/modalComponent/modalcomponent.module';
import projectPledgeRewardComponent from './components/projectPledgeReward/projectpledgereward.module';
import wireInstructionsModalComponent from './components/wireInstructionsModalComponent/wireinstructionsmodalcomponent.module';
import socialShareMenu from './components/SocialShareMenu/socialsharemenu.module';
import documentUploadModalComponent from './components/documentUpload/documentupload.module';
import EditDocumentModalComponent from './components/editDocumentModal/editdocumentmodal.module';
import investorDocumentModalComponent from './components/investorDocumentModal/investordocumentmodal.module';
import moderateCommentModal from './components/moderateCommentModal/moderatecommentmodal.module';
import imageGalleryModalComponent from './components/imageGalleryModal/imagegallerymodal.module';
import EnableTwoFactorAuthModalComponent from './components/enableTwoFactorAuthModal/enabletwofactorauthmodal.module';
import InvestmentReconfirmationModalComponent from './components/investmentReconfirmationModal/investmentreconfirmationmodal.module';
import IssueProjectTermsUpdateModalComponent from './components/issueProjectTermsUpdateModal/issueprojecttermsupdatemodal.module';
import InvestmentAdvisoryModalComponent from './components/investmentAdvisoryModal/investmentadvisorymodal.module';
import CancelInvestmentModalComponent from './components/cancelInvestmentModal/cancelinvestmentmodal.module';
import CheckMailingInstructionsModalComponent from './components/checkMailingInstructionsModal/checkmailinginstructionsmodal.module';
import InvestmentReturnsCalculatorComponent from './components/returns-calculator/returns-calculator.module';
import ExpressEarlyInterestModalComponent from './components/expressEarlyInterestModal/expressearlyinterestmodal.module';
import InvestmentCheckoutComponent from './components/investment-checkout/investment-checkout.module';
import InvestorProfileListItem from './components/investorProfileListItem/investorprofilelistitem.module';
import AnimatedSuccessCircle from './components/animated-success-circle/animated-success-circle.module';
import NorthCapitalWireInstructionsComponent from './components/north-capital-wire-instructions/north-capital-wire-instructions.module';
import NorthCapitalCheckMailingInstructionsComponent from './components/north-capital-check-mailing-instructions/north-capital-check-mailing-instructions.module';
import PhotoIdUploadComponent from './components/photoIdUpload/photoidupload.module';
import ProjectUpdateComponent from './components/projectUpdate/projectupdate.module';
import UpdatePaymentMethodModalComponent from './components/updatePaymentMethodModalComponent/updatepaymentmethodmodalcomponent.module';

export default angular.module('index.components', [
	headerModule.name,
	footerModule.name,
	projectListItem.name,
	investmentListItem.name,
	projectComment.name,
	documentListItem.name,
	commentBox.name,
	accountWidget.name,
	modalComponent.name,
	projectPledgeRewardComponent.name,
	wireInstructionsModalComponent.name,
	socialShareMenu.name,
	documentUploadModalComponent.name,
	EditDocumentModalComponent.name,
	investorDocumentModalComponent.name,
	moderateCommentModal.name,
	imageGalleryModalComponent.name,
	EnableTwoFactorAuthModalComponent.name,
	InvestmentReconfirmationModalComponent.name,
	IssueProjectTermsUpdateModalComponent.name,
	InvestmentAdvisoryModalComponent.name,
	CancelInvestmentModalComponent.name,
	CheckMailingInstructionsModalComponent.name,
	InvestmentReturnsCalculatorComponent.name,
	ExpressEarlyInterestModalComponent.name,
	InvestmentCheckoutComponent.name,
	InvestorProfileListItem.name,
	AnimatedSuccessCircle.name,
	NorthCapitalWireInstructionsComponent.name,
	NorthCapitalCheckMailingInstructionsComponent.name,
	PhotoIdUploadComponent.name,
	ProjectUpdateComponent.name,
	UpdatePaymentMethodModalComponent.name,
]);
