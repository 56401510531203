
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.directive('dynamicHeading', DynamicHeadingDirective);

	function DynamicHeadingDirective() {
		'ngInject';

		return {
			restrict: 'E',
			scope: {
				text: '<'
			},
			controllerAs: '$ctrl',
			controller: controller,
			template: `
			<!-- <h1 class="display-4 d-none d-lg-block">{{$ctrl.text}}</h1>
			<h4 class="d-lg-none">{{$ctrl.text}}</h4> -->
			<h2>{{$ctrl.text}}</h2>
			`
		};


		function controller($scope) {
			this.text = $scope.text;
		}

	}
}