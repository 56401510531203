
/* injects from baggage-loader */

'use strict';

import ProjectPledgeRewardController from './projectpledgereward.controller';
import ProjectPledgeRewardTemplate from './projectpledgereward.html';

export default class ProjectPledgeRewardComponent {
		constructor() {
				this.templateUrl = ProjectPledgeRewardTemplate;
				this.controller = ProjectPledgeRewardController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					reward: '<',
					isRaiseOpen: '=',
					isEditingProject: '='
				};
		}
}
