
/* injects from baggage-loader */

'use strict';

import ProjectManagerActivityComponent from './projectmanager.activity.component';

const ProjectManagerActivityPageModule = angular.module('project-manager-activity-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.activity', {
        url: '/activity',
        title: 'Project Manager: Change History',
        localTitle: 'Change History',
        component: 'projectManagerActivity',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          events: function(project, ProjectActivity) {
            return ProjectActivity.list(project);
          }
        }
      });
  })
  .component('projectManagerActivity', new ProjectManagerActivityComponent());

export default ProjectManagerActivityPageModule;
