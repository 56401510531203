
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('DisbursementRequestSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		const DisbursementRequest = Parse.Object.extend('Disbursement_Requests');

		// Define 'DisbursementRequest' scheme
		const schema = [
			'withdrawlAmount',
			'user',
			'project',
			'status',
			'pending',
			'acknowledged',
			'submitted',
			'approved',
			'completed',
			'denied'
		];
		
		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(DisbursementRequest.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});
    
    return DisbursementRequest;
		
	});
}

