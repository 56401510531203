
/* injects from baggage-loader */

'use strict';

export default class CommentBoxController {

	constructor($log, $timeout, Alerts, Comments) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.Alerts = Alerts;
		this.Comments = Comments;

		this.showCommentBox = true;
		this.postingComment = false;
		this.commentBody = '';
		this.isRaisePromoter = false;
		this.isPromoterCompensated = false;
		this.comments = [];

	}

	$onInit() {
		// this.$log.debug(this.comments)
	}


	/**
	 * Close the comment box and clear any text
	 */
	cancelComment() {
		this.showCommentBox = false;
		this.postingComment = false;
		this.commentBody = '';
		this.isRaisePromoter = false;
	}


	/**
	 * Posts a comment to the project comments section
	 */
	postComment() {

		// Show progress indicator
		this.postingComment = true;
		this.Alerts.clearLocal();

		// this.$log.debug(this.project)
		// this.$log.debug(this.parent)
		// this.$log.debug(this.commentBody)

		// Post the comment
		this.Comments.post({
			project: this.project,
			parent: this.parent,
			text: this.commentBody,
			isPromoter: this.isRaisePromoter,
			isPromoterCompensated: this.isPromoterCompensated
		})
			.then(async comment => {

				if (!comment.get('author').isDataAvailable()) {
					await comment.get('author').fetch();
				}
				
				// Clear the comment box
				this.commentBody = '';
				this.showCommentBox = false;
				
				// Display local alert
				// this.Alerts.setLocal({
				// 	msg: 'Comment posted successfully',
				// 	type: 'success'
				// });

				// Push to comments
				if (typeof this.comments !== 'undefined') {
					this.comments.unshift(comment);
				}

				if (typeof this.onComplete === 'function') {
					this.onComplete({comment});
				}
				this.$timeout();

			})
			.catch(err => {
				this.Alerts.setLocal({
					type: 'danger',
					msg: err.toString().replace('ParseError: 141', '').trim()
				});
				this.postingComment = false;
				this.$timeout();
			})
	}

}
