
/* injects from baggage-loader */

'use strict';

import { capitalize } from 'lodash';
export default class AccountInvestmentsController {

	constructor($log, $rootScope, $timeout, Investments) {
		'ngInject';

		this.$log = $log;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.Investments = Investments;

		this.pagination = {
			page: 1,
			pageSize: 10,
			pageStart: 1,
			pageEnd: 10,
			morePrevious: false,
			moreNext: true
		}

		this.reconfirmations_required = [];

	}

	$onInit() {

		this.total_investments = this.investments.count;
		this.investments = this.investments.results;

		if (this.total_investments === this.investments.length) {
			this.pagination.moreNext = false;
		}

		this.reconfirmations_required = this.investments.filter(i => i.get('reconfirmation_required'));

		this.$rootScope.$emit('updateAccountHeading', {
			title: 'My Investments',
			subtitle: 'Manage your investments',
			showGreeting: false
		});

	}

	getEscrowProvider(project) {
		return project.get('escrow_provider');
	}

	isEscrowNorthCapital(project) {
		return this.getEscrowProvider(project) === 'north_capital';
	}

	isEscrowPrimeTrust(project) {
		return this.getEscrowProvider(project) === 'prime_trust';
	}

	getFormattedPaymentMethod(investment) {
		return capitalize(investment.get('payment_method'));
	}

	async nextPage() {
		if (!this.pagination.moreNext) {
			return false;
		}

		this.pagination.page += 1;
		this.investments = await this.Investments.list({
			skip: (this.pagination.page - 1) * this.pagination.pageSize
		});
		this.pagination.pageStart = (this.pagination.page - 1) * this.pagination.pageSize + 1 || 1;
		this.pagination.pageEnd = (this.pagination.page - 1) * this.pagination.pageSize + this.investments.length;
		if (this.pagination.pageEnd === this.total_investments) {
			this.pagination.moreNext = false;
		}
		this.pagination.morePrevious = true;
		this.$timeout();

	}

	async previousPage() {
		if (!this.pagination.morePrevious) {
			return false;
		}

		this.pagination.page -= 1;
		this.investments = await this.Investments.list({
			skip: (this.pagination.page - 1) * this.pagination.pageSize
		});
		this.pagination.pageStart = (this.pagination.page - 1) * this.pagination.pageSize + 1 || 1;
		this.pagination.pageEnd = (this.pagination.page - 1) * this.pagination.pageSize + this.investments.length;
		if (this.pagination.pageStart === 1) {
			this.pagination.morePrevious = false;
		}
		this.pagination.moreNext = true;
		this.$timeout();

	}

}
