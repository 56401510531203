
/* injects from baggage-loader */

'use strict';

import ProjectCommentComponent from './projectcomment.component';
import './projectcomment.scss';

const ProjectCommentModule = angular.module('project-comment-module', []);

ProjectCommentModule
    .component('projectComment', new ProjectCommentComponent());

export default ProjectCommentModule;

