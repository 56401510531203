
/* injects from baggage-loader */

'use strict';

let environment = 'staging';

import config from './index.config';
import run from './index.run';
import stateTransitions from './index.transitions';

import uiRouter from '@uirouter/angularjs';

import coreModule from './core/core.module';
import indexComponents from './index.components';
import indexRoutes from './index.routes';

// Import ui bootstrap modules
import alert from 'ui-bootstrap4/src/alert';
import modal from 'ui-bootstrap4/src/modal';
import accordian from 'ui-bootstrap4/src/accordion';
import tabs from 'ui-bootstrap4/src/tabs';
import dropdown from 'ui-bootstrap4/src/dropdown';
import timepicker from 'ui-bootstrap4/src/timepicker';
import tooltip from 'ui-bootstrap4/src/tooltip';
import collapse from 'ui-bootstrap4/src/collapse';

// Import pages
import homeModule from './pages/home/home.module';
import loginModule from './pages/login/login.module';
import signupModule from './pages/signup/signup.module';
import recoverPassword from './pages/recoverPassword/recoverpassword.module';
import passwordReset from './pages/passwordReset/passwordreset.module';
import aboutModule from './pages/about/about.module';
import projectsModule from './pages/projects/projects.module';
import projectModule from './pages/project/project.module';
// import projectDonationWizardPageModule from './pages/project-donation-wizard/project-donation-wizard.module';
import accountModule from './pages/account/account.module';
import accountLogoutModule from './pages/account-logout/account-logout.module';
import projectManagerModule from './pages/projectManager/projectmanager.module';
import donationConfirmationModule from './pages/donation-confirmation/donation-confirmation.module';
import raiseSubmittedConfirmationModule from './pages/raise-submitted-confirmation/raise-submitted-confirmation.module';
import learnModule from './pages/learn/learn.module';
import investmentConfirmation from './pages/investment-confirmation/investment-confirmation.module';
import emailVerifiedModule from './pages/email-confirmed/email-confirmed.module';
import issuerOnboardingModule from './pages/issuer-onboarding/issuer-onboarding.module';
import onboardingEntryModule from './pages/onboarding-entry/onboarding-entry.module';
import onboardingConfirmationModule from './pages/onboarding-confirmation/onboarding-confirmation.module';
import investModule from './pages/invest/invest.module';
import InvestmentConfirmationNorthCapitalPageModule from './pages/invest-confirmation-north-cap/invest-confirmation-north-cap.module';
import OnboardingApplicationPageModule from './pages/onboarding-application/onboarding-application.module';
import OnboardingConfirmationPageModule from './pages/onboarding-confirmation/onboarding-confirmation.module';

// Terms and Policy pages
import cookiePolicyModule from './pages/legal/cookie-policy/cookie-policy.module';
import termsOfUseModule from './pages/legal/terms-of-use/terms-of-use.module';
import privacyPolicy from './pages/legal/privacy-policy/privacy-policy.module';


let dependencyInjections = [
	uiRouter,
	stateTransitions.name,
	"ngAnimate",
	"ngCookies",
	"ngTouch",
	"ngSanitize",
	"ngMessages",
	"ngAria",
	"ngResource",
	"oc.lazyLoad",
	"angular-loading-bar",
	"ngAvatar",
	"yaru22.angular-timeago",
	"textAngular",
	"duScroll",
	"ngFileUpload",
	"dndLists",
	"angularConsent",
	'720kb.socialshare',
	'monospaced.elastic',
	'slickCarousel',
	'fayzaan.gRecaptcha.v3',
	'ngPatternRestrict',

	// core
	coreModule.name,
	indexComponents.name,
	indexRoutes.name
];


// UI Bootstrap modules
let uiBootstrapDependencies = [
	alert,
	modal,
	accordian,
	tabs,
	dropdown,
	timepicker,
	tooltip,
	collapse,
];


// App module imports
let pageDependencies = [
	homeModule.name,
	loginModule.name,
	signupModule.name,
	recoverPassword.name,
	passwordReset.name,
	aboutModule.name,
	projectsModule.name,
	projectModule.name,
	projectManagerModule.name,
	donationConfirmationModule.name,
	raiseSubmittedConfirmationModule.name,
	learnModule.name,
	investmentConfirmation.name,
	emailVerifiedModule.name,
	issuerOnboardingModule.name,
	onboardingEntryModule.name,
	onboardingConfirmationModule.name,
	investModule.name,
	InvestmentConfirmationNorthCapitalPageModule.name,
	OnboardingApplicationPageModule.name,
	OnboardingConfirmationPageModule.name,

	// pages: User account
	accountModule.name,
	accountLogoutModule.name,

	// pages: terms and policy
	cookiePolicyModule.name,
	termsOfUseModule.name,
	privacyPolicy.name,

];

// Import sentry if not running local dev env
if (environment !== 'development') {
	dependencyInjections.push("ngSentry")
}

const App = angular.module("commonOwner", [
	...dependencyInjections,
	...uiBootstrapDependencies,
	...pageDependencies
]);

App
	.config(config)
	.run(run);

export default App;