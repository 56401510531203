
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerMediaController {

	constructor($log, $uibModal, $timeout, $rootScope, MediaService, Alerts) {
		'ngInject';

		this.$log = $log;
		this.$uibModal = $uibModal;
		this.$timeout = $timeout;
		this.$rootScope = $rootScope;
		this.mediaService = MediaService;
		this.Alerts = Alerts;

	}

	$onInit() {
		// this.$log.debug(this.media)
	}


	/**
	 * Tags the media as the "Header Image" and removes that tag from all others
	 * @param {Parse.Object.extend('Media')} media 
	 */
	async assignAsHeaderImage(media) {
		await this.mediaService.tagHeaderImage(media, this.media, this.project);
		this.$timeout();
	}



	async openEditMediaModal(media) {
		this.$log.debug('Edit media:', media.id);

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'editDocumentModal',
			resolve: {
				context: function () {
					return {
						document: media,
						class: "Media"
					};
				}
			}
		});

		modalInstance.result.then(async media_edits => {

			this.$log.debug(media_edits);

			media.isUpdating = true;
			// Save the media
			await media.save(media_edits);
			this.$timeout(() => {
				media.isUpdating = false;
			}, 500);


		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async removeMedia(media) {
		this.$log.debug('Delete media:', media.id);

		if (media.id === this.project.header_media.id) {
			this.Alerts.setLocal({
				type: 'danger',
				msg: 'Cannot remove the header image'
			});
			return false;
		}

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete media?',
						body: 'Are you sure you want to delete this?',
						list: [media.name],
						confirmBtnText: 'Yes, Delete',
						cancelBtnText: 'No, Cancel'
					};
				}
			}
		});


		modalInstance.result.then(async () => {

			// Find and remove media
			this.media.splice(this.media.indexOf(media), 1);

			// media.isUpdating = true;
			await media.destroy();
			this.$timeout(() => {
				this.$log.debug('Media removed:', media.id);
				// media.isUpdating = false;
			}, 500);

			// Update media positions
			this.media.forEach((m, index) => {
				if (m.get('position') !== index) {
					this.$log.debug('updating media position', m.id, index);
					m.save({ position: index })
				}
			});

		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async openMediaUploadModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			size: 'xlg',
			backdrop: 'static',
			component: 'documentUploadModal',
			resolve: {
				context: () => ({
					project: this.project,
					class: 'Media'
				})
			}
		});

		// Lock scroll
		// this.$rootScope.appState.scrollLock = true;

		modalInstance.result.then(async res => {

			// Concatenate new with existing media
			this.media = [...this.media, ...res.documents];

			// Set header media
			if (!this.project.header_media) {
				this.assignAsHeaderImage(this.media[0], this.media)
			}

			// Update the project
			this.project.save();

			// Set new media positions
			this.media.forEach((media, index) => {
				if (typeof media.get('position') === 'undefined') {
					this.$log.debug('saving media', media.id, index)
					media.save({ position: index })
				}
			});

			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;

		}, () => {
			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;
			this.$log.info('modal-component dismissed at: ' + new Date())
		});

	}

	async uploadNewDocumentVersion(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			// size: 'xlg',
			backdrop: 'static',
			component: 'documentUploadModal',
			resolve: {
				context: () => ({
					project: this.project,
					document_scope: 'project',
					document: doc,
					update_document: true
				})
			}
		});

		// Lock scroll
		// this.$rootScope.appState.scrollLock = true;

		modalInstance.result.then(async res => {

			// Refresh the document
			await doc.fetch();
			this.$timeout();
			this.$log.debug('Document version updated', doc.id)

		}, () => {
			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;
			this.$log.info('modal-component dismissed at: ' + new Date())
		});

	}

	onDrop(srcIndex, targetIndex, media) {
		this.media.splice(srcIndex, 1);
		this.media.splice(targetIndex, 0, media);

		this.media.forEach((o, index) => {
			if (o.position !== index) o.position = index;
			if (o.dirty()) o.save();
		})

		// By returning true from dnd-drop we signalize we already inserted the item.
		return true;
	}

}
