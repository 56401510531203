
/* injects from baggage-loader */

'use strict';

export default class IssuerOnboardingInterviewController {

	constructor($log, $state, $window, FormBuilder) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$window = $window;
		this.fb = FormBuilder;
		this.Validators = FormBuilder.Validators;

		this.form = {}
		this.savingForm = false;

		this.prevStep = 'issuer-onboarding.records';
		this.nextStep = 'issuer-onboarding.background-check'

		this.dateOpts = {
			altInput: true,
			altFormat: "F j, Y",
			dateFormat: "Z"
		};

	}

	$onInit() {

		this.form = this.fb.group({
			interviewDate1: ['', this.Validators.required],
			interviewDate2: [''],
			interviewDate3: [''],
		});

		// Fill existing form info
		this.form.patchValue(this.onboarding.toJSON());

	}

	/**
	 * Called when flatpicker is initialized, sets up the date according to ng-model
	 * @param {Object} fpItem 
	 * @param {Object} options 
	 */
	datePostSetup(fpItem, options) {
		fpItem.setDate(options.date);
	}

	async saveForm() {

		this.form.markAllAsTouched();

		if (this.form.valid()) {
			this.savingForm = true;
			let form = this.form.value();
			this.$log.debug(form);

			// Convert any Date objects to strings
			for (let key in form) {
				if (form[key] instanceof Date) {
					form[key] = form[key].toString()
				}
			}

			await this.onboarding.save(form);
			// Take the user to the next step
			this.$state.go(this.nextStep, { id: this.onboarding.id })
			this.savingForm = false;
		} else {
			this.savingForm = false;
			this.$window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}

	}

}
