
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerDocumentsController {

	constructor($log, $uibModal, $timeout, $rootScope, ProjectDocument) {
		'ngInject';

		this.$log = $log;
		this.$uibModal = $uibModal;
		this.$timeout = $timeout;
		this.$rootScope = $rootScope;
		this.projectDocumentService = ProjectDocument;

	}

	$onInit() {
		this.$log.debug(this.documents)
	}


	/**
	 * Tags the document as the "Disclosure Document" and removes that tag from all others
	 * @param {Parse.Object.extend('Project_Document')} doc 
	 */
	async assignAsDisclosureDoc(doc) {
		await this.projectDocumentService.tagDisclosureDocument(doc, this.documents);
		this.$timeout();
	}



	async openEditDocumentModal(doc) {
		this.$log.debug('Edit document:', doc.id);

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'editDocumentModal',
			resolve: {
				context: function () {
					return {
						document: doc
					};
				}
			}
		});

		modalInstance.result.then(async doc_edits => {

			this.$log.debug(doc_edits);

			doc.isUpdating = true;
			// Save the document
			await doc.save(doc_edits);
			this.$timeout(() => {
				doc.isUpdating = false;
			}, 500);


		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async removeDocument(doc) {
		this.$log.debug('Delete document:', doc.id);

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete document?',
						body: 'Are you sure you want to delete this document?',
						list: [doc.name],
						confirmBtnText: 'Yes, Delete',
						cancelBtnText: 'No, Cancel'
					};
				}
			}
		});


		modalInstance.result.then(async () => {

			// Find and remove document
			this.documents.splice(this.documents.indexOf(doc), 1);

			// doc.isUpdating = true;
			await doc.destroy();
			this.$timeout(() => {
				this.$log.debug('Document removed:', doc.id);
				// doc.isUpdating = false;
			}, 500);

		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async openDocumentUploadModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			size: 'xlg',
			backdrop: 'static',
			component: 'documentUploadModal',
			resolve: {
				context: () => ({
					project: this.project,
					document_scope: 'project'
				})
			}
		});

		// Lock scroll
		// this.$rootScope.appState.scrollLock = true;

		modalInstance.result.then(async res => {

			// Concatenate new with existing documents
			this.documents = [...this.documents, ...res.documents];

			// Update the project
			this.project.save();

			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;

		}, () => {
			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;
			this.$log.info('modal-component dismissed at: ' + new Date())
		});

	}

	async uploadNewDocumentVersion(doc) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			// size: 'xlg',
			backdrop: 'static',
			component: 'documentUploadModal',
			resolve: {
				context: () => ({
					project: this.project,
					document_scope: 'project',
					document: doc,
					update_document: true
				})
			}
		});

		// Lock scroll
		// this.$rootScope.appState.scrollLock = true;

		modalInstance.result.then(async res => {

			// Refresh the document
			await doc.fetch();
			this.$timeout();
			this.$log.debug('Document version updated', doc.id)

		}, () => {
			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;
			this.$log.info('modal-component dismissed at: ' + new Date())
		});

	}

	onDrop(srcIndex, targetIndex, doc) {
		this.documents.splice(srcIndex, 1);
		this.documents.splice(targetIndex, 0, doc);

		this.documents.forEach((o, index) => {
			if (o.position !== index) o.position = index;
			if (o.dirty()) o.save();
		})

		// By returning true from dnd-drop we signalize we already inserted the item.
		return true;
	}

}
