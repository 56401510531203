
/* injects from baggage-loader */

'use strict';

import AccountSettingsPasswordComponent from './account.settings.password.component';

const AccountSettingsPasswordPageModule = angular.module('account-settings-password-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.password', {
        url: '/password',
        title: 'Account Settings: Password',
        component: 'accountSettingsPassword',
        // Requires valid login to visit
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          user: function(User) {
            return User.getCurrent();
          }
        }
      });
  })
  .component('accountSettingsPassword', new AccountSettingsPasswordComponent());

export default AccountSettingsPasswordPageModule;
