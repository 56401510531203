
/* injects from baggage-loader */

'use strict';

import WireInstructionsModalComponentComponent from './wireinstructionsmodalcomponent.component';
import './wireinstructionsmodalcomponent.scss';

const WireInstructionsModalComponentModule = angular.module('wire-instructions-modal-component-module', []);

WireInstructionsModalComponentModule
    .component('wireInstructionsModalComponent', new WireInstructionsModalComponentComponent());

export default WireInstructionsModalComponentModule;

