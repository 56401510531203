
/* injects from baggage-loader */

'use strict';

import ProjectManagerDocumentsController from './projectmanager.documents.controller';
import ProjectManagerDocumentsTemplate from './projectmanager.documents.html';

export default class ProjectManagerDocumentsComponent {
		constructor() {
				this.templateUrl = ProjectManagerDocumentsTemplate;
				this.controller = ProjectManagerDocumentsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					documents: '<'
				};
		}
}
