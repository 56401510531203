
/* injects from baggage-loader */

'use strict';

import AccountSettingsProfileComponent from './account.settings.profile.component';

const AccountSettingsProfilePageModule = angular.module('account-settings-profile-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.settings.profile', {
        url: '/profile',
        title: 'Account Settings: Profile',
        component: 'accountSettingsProfile',
        // Requires valid login to view
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          profile: function (Profiles) {
            try {
              return Profiles.fetchOwn();
            } catch (err) {
              throw err;
            }
          }
        }
      });
  })
  .component('accountSettingsProfile', new AccountSettingsProfileComponent());

export default AccountSettingsProfilePageModule;
