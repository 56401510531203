
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('MediaService', function (MediaSchema) {

		// ==================================================
		// Load object schema
		// ==================================================
		let Media = MediaSchema;

		this.tagHeaderImage = async (media, allMedia, project) => {
			// Remove tag from all other media
			for (let d of allMedia) {
				if (d.tags.find(t => t.id === 'header_image')) {
					d.remove('tags', { title: "Header Image", id: "header_image" })
					await d.save();
				}
			}

			// Add the tag to the media
			media.tags.push({ title: "Header Image", id: "header_image" });
			await media.save();

			// Update project "header_media"
			project.set('header_media', media);
			return project.save();
		}

	});
}

