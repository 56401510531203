
/* injects from baggage-loader */

'use strict';

import ProjectManagerCommentsComponent from './projectmanager.comments.component';

const ProjectManagerCommentsPageModule = angular.module('project-manager-comments-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.comments', {
        url: '/comments?root_id?comment_id',
        title: 'Project Manager: Comments',
        localTitle: 'Comments',
        component: 'projectManagerComments',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          comments: function(project, ProjectCommentSchema, ProfileSchema) {
            let query = project.relation('comments').query();
            query.include(['author.first_name', 'author.last_name']);
            query.descending('createdAt');
            return query.find();
          }
        }
      });
  })
  .component('projectManagerComments', new ProjectManagerCommentsComponent());

export default ProjectManagerCommentsPageModule;
