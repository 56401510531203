
/* injects from baggage-loader */

'use strict';

export default function (app) {

  app.service('helpers', ['localStorageService',
    function (localStorageService) {
      var _this = this;

      this.setLS = function (key, value) {
        return localStorageService.set(key, value);
      };

      this.getLS = function (key) {
        return localStorageService.get(key);
      };

      this.clearLS = function (key) {
        return localStorageService.remove(key);
      };

      this.ls = function (key, value) {
        if (value) {
          return _this.setLS(key, value);
        } else {
          return _this.getLS(key);
        }
      };
    }]);

}