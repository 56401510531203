
/* injects from baggage-loader */

'use strict';

import AccountInvestmentComponent from './account.investment.component';
import "./chat-item.scss";

const AccountInvestmentPageModule = angular.module('account-investment-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.investment', {
        url: '/my-investments/:id',
        title: 'Account Investment',
        component: 'accountInvestment',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: {
          investment: function (Investments, $transition$, $state, ProjectSchema, AccreditationSchema) {
            return Investments.get($transition$.params('to').id)
              .catch(err => {
                // Handle investment object not found error
                $state.go('account.investments');
                throw 'Investment not found';
              })
          },
          project: function(investment, InvestmentSchema) {
            return investment.project;
          },
          // messages: function (investment, MessageSchema, ProfileSchema) {
          //   let query = investment.chat.relation('messages').query();
          //   query.include(['sender_profile.first_name'])
          //   return query.descending('createdAt').find();
          // },
          subscriptionAgreement: function (Parse, investment) {
            if (!investment.has('subscription_agreement_id')) {
              return undefined;
            }
              return Parse.Cloud.run('getSubscriptionAgreement', {
                investmentId: investment.id
              })
                .catch(e => { });
          },
          documents: function (investment, InvestorDocumentSchema) {
            let query = investment.relation('documents').query();
            return query.descending('createdAt').find();
          },
          project_image: function (investment, MediaSchema) {
            return investment.project.header_media.fetch();
          },
          reconfirmations: function (investment) {
            return investment.relation('reconfirmations').query().find()
          },
          accreditation: function(investment, AccreditationSchema) {
            const investor = investment.get('investor');
            if (investor && investor.has('accreditation')) {
              return investor.get('accreditation').fetch();
            } else {
              // NOTE: fallback to empty object to not break logic if a user does not
              // have an accreditation object for some reason
              return {
                get() {
                  return false;
                }
              }
            }
          }
        }
      });
  })
  .component('accountInvestment', new AccountInvestmentComponent());

export default AccountInvestmentPageModule;
