
/* injects from baggage-loader */

'use strict';

import EnableTwoFactorAuthModalController from './enabletwofactorauthmodal.controller';
import EnableTwoFactorAuthModalTemplate from './enabletwofactorauthmodal.html';

export default class EnableTwoFactorAuthModalComponent {
		constructor() {
				this.templateUrl = EnableTwoFactorAuthModalTemplate;
				this.controller = EnableTwoFactorAuthModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
