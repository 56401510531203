
/* injects from baggage-loader */

'use strict';

export default function (app) {

	// Static page metadata
	app.constant('STATIC_PAGE_METADATA', {
		'_default': {
			title: 'Home | Common Owner',
			description: 'Investment opportunities for everyone. Common Owner facilitates broad-based real estate ownership opportunities, especially: Historic Rehabilitation & Adaptive Re-use Projects, Infill Development and Neighborhood Businesses.',
			image_url: ''
		},
		'projects': {
			title: 'Projects | Common Owner',
			description: 'Projects',
			image_url: ''
		},
		'about': {
			title: 'About | Common Owner',
			description: 'About',
			image_url: ''
		},
		'learn': {
			title: 'Learn | Common Onwer',
			description: 'Learn',
			image_url: ''
		},
		'raise-funds': {
			title: 'Raise Funds | Common Onwer',
			description: 'Raise Funds',
			image_url: ''
		},
		'signup': {
			title: 'Sign Up | Common Owner'
		},
		'login': {
			title: 'Login | Common Owner'
		},
		'recoverPassword': {
			title: 'Forgot Password | Common Owner'
		},
		'account.home': {
			title: 'Account Home | Common Owner'
		},
		'account.investments': {
			title: 'My Investments | Common Owner'
		},
		'account.projects': {
			title: 'My Projects | Common Owner'
		},
		'account.settings': {
			title: 'Account Settings | Common Owner'
		},
		'projectmanager': {
			title: 'Project Manager | Common Owner'
		}
	});

}