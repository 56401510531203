
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.directive('snackbar', SnackbarDirective);

	function SnackbarDirective($rootScope, $compile, $timeout) {
		'ngInject';

		return {
			restrict: 'A',
			link: linkFn
		};

		function linkFn(scope, elem, attrs) {

			//snackbar container
			let snackbarContainer = angular.element(elem);

			//snackbar duration time (ms)
			let snackbarDuration = attrs.snackbarDuration || 3000;

			//delay time to remove from DOM after hide (ms)
			let snackbarRemoveDelay = attrs.snackbarRemoveDelay || 200;

			//receive broadcating
			$rootScope.$on('createSnackbar', function (event, received) {

				//snackbar template
				let template = `<div class="${["snackbar", "snackbar-opened", ...received.classes].join(' ')}"><span class="snackbar-content">${received.content}</span></div>`;

				//template compile
				let snackbar = angular.element($compile(template)(scope));

				//add snackbar
				snackbarContainer.append(snackbar);

				//snackbar duration time
				$timeout(function () {

					//hide snackbar
					snackbar.removeClass("snackbar-opened");

					//remove snackbar
					$timeout(function () { snackbar.remove(); }, snackbarRemoveDelay, false);

				}, received.duration || snackbarDuration, false);

			});
		}
	}

}
