
/* injects from baggage-loader */

'use strict';

import ProjectManagerIssuerController from './projectmanager.issuer.controller';
import ProjectManagerIssuerTemplate from './projectmanager.issuer.html';

export default class ProjectManagerIssuerComponent {
	constructor() {
		this.templateUrl = ProjectManagerIssuerTemplate;
		this.controller = ProjectManagerIssuerController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			project: '<',
			issuer: '<'
		};
	}
}
