
/* injects from baggage-loader */

'use strict';

export default class DocumentUploadController {

	constructor($log, $timeout, Parse, ProjectDocumentSchema, MediaSchema) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.Parse = Parse;
		this.ProjectDocumentSchema = ProjectDocumentSchema;
		this.MediaSchema = MediaSchema;

		this.documents = [];
		this.pendingFiles = [];
		this.invalidFiles = [];
		this.unsavedDocuments = [];

		this.savingDocuments = false;

		this.modalTitle = 'Upload Documents';
		this.saveBtnText = 'Save Documents';
		this.cancelBtnText = 'Discard Documents';

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;

		// Set the project
		this.project = this.context.project;
		this.$log.debug(this.project)

		if (this.context.update_document) {
			this.modalTitle = 'Update Document Version';
			this.saveBtnText = 'Update Document';
			this.cancelBtnText = 'Discard';
		}

		if (this.context.class === 'Media') {
			this.modalTitle = 'Upload Media';
			this.saveBtnText = 'Upload Media';
			this.cancelBtnText = 'Discard Media';
		}

	}

	// $onDestroy() {

	// 	// Revert any changes
	// 	this.project.revert();

	// 	// Revert all document name changes
	// 	for (let doc of this.documents) {
	// 		doc.revert();
	// 	}

	// 	// Delete any unlinked documents
	// 	this.Parse.Object.destroyAll(this.unsavedDocuments);

	// }


	async ok() {

		this.savingDocuments = true;

		// Verify each document has a name set
		for (let doc of this.documents) {
			if (!doc.name) {
				alert('Missing file name!');
				return false;
			}
		}

		// Clear unrelated documents
		this.unsavedDocuments = [];

		// Save each document
		for (let doc of this.documents) {
			await doc.save();
		}

		// Save the project
		this.$log.debug('Saving project:', this.project.title);
		await this.project.save();

		this.close({
			$value: {
				documents: this.documents
			}
		});
	}

	cancel() {

		// Delete any unlinked documents
		this.Parse.Object.destroyAll(this.unsavedDocuments);

		// Close the modal
		this.dismiss({ $value: 'cancel' });

	}


	updateDocument() {

	}

	/**
	 * Method called when project documents are dropped or selected
	 */
	async processFiles() {

		if (this.context.update_document) {
			this.updateDocument()
			return false;
		}

		let ops = [];

		let docRelation;

		if (this.project) {
			docRelation = this.project.relation("documents");
		}

		if (this.context.class === 'Media') {
			docRelation = this.project.relation("gallery");
		}

		while (this.pendingFiles.length > 0) {

			// Get a file
			let file = this.pendingFiles.shift();

			// Sanitize the filename
			let filename = file.name.split('.');
			let ext = filename.pop();
			filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
			filename = `${filename}.${ext}`;

			// Create the Parse.File
			let pFile = new this.Parse.File(filename, file, file.type);

			// Push to the operations array
			ops.push({
				item: pFile,
				action: 'save',
				message: 'Saving file:' + filename,
				retry: 3
			});

			let document;


			if (this.context.class === 'Media') {

				// Create a new "Media"
				document = new this.MediaSchema({
					file: pFile,
					name: file.name,
					original_name: file.name,
					size: file.size,
					type: file.type
				})

			} else {

				// Create a new "Project_Document"
				document = new this.ProjectDocumentSchema({
					name: file.name,
					file: pFile,
					original_name: file.name,
					size: file.size,
					type: file.type,
					scope: this.context.document_scope
				});

				if (this.context.document_scope && this.context.project) {
					document.set('project', this.context.project);
				}
			}

			document.uploading = true;

			// Push the document to the documents array
			this.documents.push(document);

			// Push the document to the "unsavedDocuments" array
			this.unsavedDocuments.push(document);

			// Push to the operations array
			ops.push({
				item: document,
				action: 'save',
				message: 'Saving document:' + filename,
				post: () => {

					// Add the document to project relation if new
					if (docRelation) {
						docRelation.add(document);
					}

					// Uploading complete
					document.uploading = false;
					document.uploaded = true;
					this.$timeout(() => { /* empty */ }, 100);

				},
				error: () => {
					// Uploading failed
					document.uploading = false;
					document.failed = true;
					this.$log.error('Failed to upload file:', document.name)
					this.$timeout(() => { /* empty */ }, 100);
				}
			});

		}

		// Process pending operations
		for (let op of ops) {
			this.$log.debug(op.message);
			try {
				await op.item[op.action]();
				if (typeof op.post === 'function') {
					op.post();
				}
			} catch (err) {
				this.$log.error(err, err.response);
				if (err.response && err.response.code === 130) {
					// Try it again after a brief wait
					if (op.retry > 0) {
						// Decrement the retry value by 1
						op.retry = op.retry - 1;
						// Push the op back into the ops array
						// TODO: consider "shifting" ops out of the array so pushing them back in on a retry
						//			 is cleaner
						ops.push(op);
					}
				} else {
					op.error();
				}
			}
		}

		// Save the project
		await this.project.save();
		this.$log.debug('project saved');

	}


	/**
	 * Removes a document from the tracked documents array and 
	 * adds it to the array of documents to delete
	 * @param {Object} doc 
	 */
	removeDocument(doc) {

		// Remove the document from the "documents" array
		this.documents.splice(this.documents.indexOf(doc), 1);

		// Add the document to the array of documents to remove
		// this.removeDocuments.push(doc);

		try {
			// Remove document relation
			if (this.context.class === 'Media') {
				this.project.relation("gallery").remove(doc);
			} else {
				this.project.relation("documents").remove(doc);
			}
		} catch (err) {
			// accept failure without a fight 
		}

	}
}
