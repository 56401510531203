
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.filter('percentage', function ($filter) {
		return function (input, decimals) {
			return $filter('number')(input * 100, decimals || 0) + '%';
		};
	});
}