
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingOfferingAndTeamComponent from './issuer-onboarding.offering-and-team.component';

const IssuerOnboardingOfferingAndTeamPageModule = angular.module('issuer-onboarding-offering-and-team-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('issuer-onboarding.offering-and-team', {
        url: '/offering-and-team',
        component: 'issuerOnboardingOfferingAndTeam',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          docs: function(onboarding, OnboardingDocumentSchema) {
            let query = onboarding.relation("documents").query();
            query.exists('identifier')
            return query.find();
          }
        }
      });
  })
  .component('issuerOnboardingOfferingAndTeam', new IssuerOnboardingOfferingAndTeamComponent());

export default IssuerOnboardingOfferingAndTeamPageModule;
