
/* injects from baggage-loader */

'use strict';

import OnboardingEntryComponent from './onboarding-entry.component';
import './onboarding-entry.scss';

const OnboardingEntryPageModule = angular.module('onboarding-entry-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('raise-funds', {
        url: '/raise-funds',
        component: 'onboardingEntry',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('onboardingEntry', new OnboardingEntryComponent());

export default OnboardingEntryPageModule;
