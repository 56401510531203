
/* injects from baggage-loader */

'use strict';

import TermsOfUseController from './terms-of-use.controller';
import TermsOfUseTemplate from './terms-of-use.html';

export default class TermsOfUseComponent {
		constructor() {
				this.templateUrl = TermsOfUseTemplate;
				this.controller = TermsOfUseController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
