
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('UserSchema', function (Parse) {

		// Define 'User' scheme
		let schema = [
			'email',
			'username',
			'full_name',
			'profile',
			'notifications',
			'two_factor_enabled',
			'emailVerified',
			'block_investments',
			'fa_investor_ids'
		];

		// Load the schema into the object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Parse.User.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});

		return Parse.User;

	});
}

