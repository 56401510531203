
/* injects from baggage-loader */

'use strict';

import ProjectPledgeRewardComponent from './projectpledgereward.component';
import './projectpledgereward.scss';

const ProjectPledgeRewardModule = angular.module('project-pledge-reward-module', []);

ProjectPledgeRewardModule
    .component('projectPledgeReward', new ProjectPledgeRewardComponent());

export default ProjectPledgeRewardModule;

