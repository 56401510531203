
/* injects from baggage-loader */

'use strict';

export default function (app) {

  app.service('$ins', ['$http', 'ENV', 'ss', 'helpers',
    function ($http, ENV, ss, helpers) {
      var _this = this,
        localstorage_name = 'invest_now.' + ENV.environment;

      this.access = function () {
        var investment = _this.investment();
        if (investment) {
          return {
            id: investment.id,
            state: investment.state
          }
        }
      };

      this.boolean = {
        'Yes': true,
        'No': false
      };

      this.destroySession = function (send_event) {
        // This will clear out the current stuff and start the investment over
        // So clear the session token from the headers
        ss.inject('$http').defaults.headers.common['X-FA-User-Session-Token'] = undefined;
        if (send_event !== false) {
          // set the clear event, since it's being watched
          _this.clearEvent = true;
        }
        // clear the invest_now_investment from _this
        _this.invest_now_investment = undefined;
        // and remove the session from localstorage
        return helpers.clearLS(localstorage_name);
      };

      this.getState = function (that, params) {
        var $timeout = ss.inject('$timeout');
        that.loading = _this.loading = true;

        var loadingTimeout = function () {
          $timeout(function () {
            that.loading = _this.loading = false;
          });
        };

        var success = function (s) {
          delete _this.page_errors;
          that.form = s.form;

          if (s.related_actions) {
            that.related_actions = s.related_actions;
          }

          // set the resource to the object is there is no resource object
          that.resource = s ? (s.resource ? s.resource : s) : {};
          that.description = s.description;

          // Test out updating the invest_now_investment, in case things have changed
          if (s && s.resource && s.resource.invest_now_investment) {
            _this.investment(s.resource.invest_now_investment);
          } else if (s && s.resource && s.title === 'investment') {
            _this.investment(s.resource);
          } else if (s && s.invest_now_investment) {
            _this.investment(s.invest_now_investment);
          }

          if (typeof (that.setDefaults) === 'function') {
            that.setDefaults();
          }
          // Doing this here for now, so I don't have to repeat this all the time in the states
          loadingTimeout();
        };

        var failure = function (e) {
          if (e) {
            if (e.status === 401 || e.status === 403) {
              // When they're unauthorized, destroy the session in local storage
              // But allow the error message to at least temporarily appear while we reload things
              _this.destroySession();
            } else {
              // Only show these when it's not a 401/403, because it's shown for less than a second
              _this.page_errors = ['Resource returned status: ' + e.status + ' ' + e.statusText];
              if (e.data && e.data.status_message) {
                _this.page_errors[0] += (', ' + e.data.status_message);
              }
            }
          } else {
            _this.page_errors = ['The resource timed out or could not be found.'];
          }
          loadingTimeout();
        };

        // Support for supplying investor_ids and ach_authorizations
        if (params.options.state === 'investment') {
          // check for ids
          if (_this.investor_id_options) {
            params.options.investor_id_options = _this.investor_id_options;
          }
          if (_this.ach_authorization_id_options) {
            params.options.ach_authorization_id_options = _this.ach_authorization_id_options;
          }
        }

        ss.call(params.factory, params.action, params.options, success, failure);
      };

      this.investment = function (invest_now_investment) {

        // NOTE: Mitch 08/19/2020 - Called on each step advancement

        // Dispatch data event
        var session = _this.session();
        if (invest_now_investment) {
          // Update both the session value and the temporary service value
          _this.invest_now_investment = invest_now_investment;

          // NOTE: Mitch 08/19/20 - Dispatch data event with investment data
          // _this.dataEvent(invest_now_investment);

          if (session) {
            session.invest_now_investment = invest_now_investment;
            return helpers.ls(localstorage_name, session);
          }
        } else {
          // Try to return the session investment, otherwise try to return _this' investment
          return session ? session.invest_now_investment : _this.invest_now_investment ? _this.invest_now_investment : false;
        }
      };

      this.loadTheme = function (theme, set_from_attribute) {
        // So long as the theme wasn't set from the attribute, allow dynamic theme, so that it'll change based on the invest_now_investment.theme
        if (theme && (theme !== _this.theme) && !_this.theme_set_from_attribute) {
          // Inject $rootScope so we can assign the them to it
          var $rootScope = ss.inject('$rootScope');
          theme = theme.toLowerCase();
          $rootScope.theme = _this.theme = theme;
          // flag that the theme_is_set
          _this.theme_is_set = true;
          _this.theme_set_from_attribute = set_from_attribute;
        }
      };

      this.processing = false;

      this.session = function (session) {
        if (session) {
          return helpers.ls(localstorage_name, session);
        } else {
          return helpers.ls(localstorage_name);
        }
      };

      this.templates = {
        'ach_authorization': 'partials/ach-authorization.html',
        'associated_person': 'partials/associated-person.html',
        'credit_card': 'partials/credit-card.html',
        'credit_card_payment': 'partials/credit-card-payment.html',
        'credit_card_setup': 'partials/credit-card-setup.html',
        'crypto_disclosures': 'partials/crypto-disclosures.html',
        'cryptocurrency': 'partials/cryptocurrency.html',
        'customer_agreement': 'partials/customer-agreement.html',
        'funds_transfer': 'partials/funds-transfer.html',
        'investment': 'partials/investment.html',
        'investor': 'partials/investor-questionairre.html',
        'investor_accreditation': 'partials/investor-accreditation.html',
        'investor_suitability': 'partials/investor-suitability.html',
        'joint_entity': 'partials/joint-entity.html',
        'signature_request': 'partials/signature-request.html',
        'subscription_agreement': 'partials/subscription-agreement.html',
        'testing_the_waters': 'partials/testing-the-waters.html',
        'transaction_fee': 'partials/transaction-fee.html',
        'vesting_entity': 'partials/vesting-entity.html'
      };

      //*************************************
      // Events
      //*************************************

      // Object to hold various parts of miscellaneous data
      this.data = {};

      this.addListeners = function (options) {
        _this.bindButtonsEventListener(options.bindButtons);
        _this.updateEventListener();
        _this.autofillEventListener();
        _this.clearAchAuthorizationsListener();
        _this.clearDataListener();
        _this.clearEventListener();
        _this.clearInvestorsListener();
        _this.closeModalEventListener();
        _this.getDataListener();
        _this.resumeInvestmentListener(options.openModal);
        _this.setAchAuthorizationsListener();
        _this.setInvestorsListener();
      };

      //***************************************************************
      // EVENTS (BROADCASTS)
      //***************************************************************

      this.closeEvent = function (options) {
        var closeEvent = document.createEvent('Event');
        closeEvent.initEvent('fa.investnow.close', true, false);
        closeEvent.data = {};
        for (var key in options) {
          closeEvent.data[key] = options[key];
        }
        document.body.dispatchEvent(closeEvent);
        // CUSTOM COMMON OWNER UPDATE - 04/21/2020
        // Force removal of modal open class from body (prevents scroll)
        // $('body').removeClass('modal-open');
        let body = document.getElementsByTagName("BODY")[0];
        body.classList.remove("modal-open");
      };

      this.openEvent = function ($event) {
        // console.log($event)
        var button = $event ? $event.target : undefined,
          optional_field = ss.findAttribute(button, 'data-fa-invest-now-data'),
          referrer_button = ss.findAttribute(button, 'data-fa-invest-now-referrer'),
          syndication_id = ss.findAttribute(button, 'data-fa-invest-now-syndication-id'),
          urlParams = _this.urlParams ? _this.urlParams : ss.urlParams(),
          referrer_query = urlParams['_fa_referrer'],
          syndication_query = urlParams['_fa_syndication_id'],
          client_data = $event.detail.data,
          button_json;

        _this.data.url = window.location.href;

        // The query string should take precedence over the button
        if (referrer_query) {
          _this.data.referrer = referrer_query;
        } else if (referrer_button) {
          _this.data.referrer = referrer_button;
        } else {
          // Otherwise remove the referrer data
          delete _this.data.referrer;
        }

        if (syndication_query) {
          _this.data.syndication_id = syndication_query;
        } else if (syndication_id) {
          // Attatch the referrer to the custom data from the button
          _this.data.syndication_id = syndication_id;
        } else {
          // Otherwise remove the syndication_id
          delete _this.data.syndication_id;
        }

        // COMMON OWNER CUSTOM
        // The data attribute field will always win out against the update event
        // Ideally they probably shouldn't be using both the event and attribute, so this isn't a problem
        // if (optional_field !== undefined) {
        //   // Replace single with double quotes, in case they had it backwards
        //   optional_field = (typeof (optional_field) === 'string') ? optional_field.replace(/'/g, '"') : optional_field;
        //   try {
        //     button_json = JSON.parse(optional_field);
        //   }
        //   catch (error) {
        //     console.error('Button clicked has invalid data-fa-invest-now-data JSON.');
        //   }
        //   finally {
        //     client_data = button_json ? button_json : undefined;
        //   }

        //   if (client_data) {
        //     _this.data.client_data = client_data;
        //   }
        // }

        if (client_data) {
          _this.data.client_data = client_data;
        }

        var openEvent = document.createEvent('Event');
        openEvent.initEvent('fa.investnow.open', true, false);
        // This should pass the custom_data that was set either through the data field
        // or that was set from the update event
        openEvent.detail = client_data;
        document.dispatchEvent(openEvent);
      };

      this.readyEvent = function () {
        var readyEvent = document.createEvent('Event');
        readyEvent.initEvent('fa.investnow.ready', true, false);
        document.body.dispatchEvent(readyEvent);
      };

      this.successEvent = function (investment) {
        var successEvent = document.createEvent('Event');
        successEvent.initEvent('fa.investnow.success', true, false);
        successEvent.data = {
          investment: investment
        }
        document.getElementById('fa-invest-now-modal').dispatchEvent(successEvent);
      };

      this.dataEvent = function (data) {
        var dataEvent = document.createEvent('Event');
        dataEvent.initEvent('fa.investnow.data', true, false);
        dataEvent.data = data
        document.body.dispatchEvent(dataEvent);
      };

      //***************************************************************
      // EVENT LISTENERS
      //***************************************************************

      this.autofill = {};
      this.autofillEventListener = function () {
        // Temporary support but eventually will remove this.

        // This will autofill the investor and associated person if data is received
        document.addEventListener('fa.investnow.autofill', function (e) {
          if (e.investor) {
            var investor = e.investor;

            if (investor.type === 'person') {
              investor.joint_account = investor.joint_account ? investor.joint_account : false;
              investor.joint_type = investor.joint_type ? investor.joint_type : null;
              investor.tax_id_number = ss.formatSSN(investor.tax_id_number);
            } else {
              investor.tax_id_number = ss.formatTIN(investor.tax_id_number);
            }

            if (investor.amount) {
              var num = (investor.amount).replace(/[^0-9.]/g, '');
              investor.amount = ss.addCommas(parseFloat(num).toFixed(2));
            }

            if (investor.date_of_birth) {
              if (!isNaN(Date.parse(investor.date_of_birth))) {
                var dob = new Date(investor.date_of_birth);
                investor.date_of_birth = ss.inject('$filter')('date')(dob, 'MM/dd/yyyy');
              } else {
                investor.date_of_birth = undefined;
              }
            }

            _this.autofill.vesting_entity = angular.copy(investor);

            if (investor.other_entities && investor.other_entities.length > 0) {
              _this.autofill.joint_entity = investor.other_entities[0];
              delete _this.autofill.vesting_entity.other_entities;
            }

          }

          if (e.associated_person && (e.investor.type === 'company' || e.investor.type === 'custodial')) {
            var person = e.associated_person;
            person.tax_id_number = ss.formatSSN(person.tax_id_number);
            if (person.type) {
              delete person.type;
            }
            _this.autofill.associated_person = person;
          }

          _this.autofill.investment = {
            'amount': _this.autofill.vesting_entity ? _this.autofill.vesting_entity.amount : undefined,
            'name': _this.autofill.associated_person ? _this.autofill.associated_person.name : _this.autofill.vesting_entity ? _this.autofill.vesting_entity.name : undefined,
            'email': _this.autofill.associated_person ? _this.autofill.associated_person.email : _this.autofill.vesting_entity ? _this.autofill.vesting_entity.email : undefined,
            'financial_advisor_email': _this.autofill.vesting_entity ? _this.autofill.vesting_entity.financial_advisor_email : undefined,
            'financial_advisor_name': _this.autofill.vesting_entity ? _this.autofill.vesting_entity.financial_advisor_name : undefined,
            'funds_transfer_method': _this.autofill.vesting_entity ? _this.autofill.vesting_entity.payment_method : undefined,
            // Type was used for the old form, now it's investor_type
            'investor_type': _this.autofill.vesting_entity ? _this.autofill.vesting_entity.type : undefined,
            'joint_investor': _this.autofill.vesting_entity ? ((_this.autofill.vesting_entity.joint_investor !== undefined) ? _this.autofill.vesting_entity.joint_investor : _this.autofill.vesting_entity.joint_account) : undefined,
            'joint_investor_type': _this.autofill.vesting_entity ? ((_this.autofill.vesting_entity.joint_investor_type !== undefined) ? _this.autofill.vesting_entity.joint_investor_type : _this.autofill.vesting_entity.joint_type) : undefined,
            'promo_code': _this.autofill.vesting_entity ? _this.autofill.vesting_entity.promo_code : undefined
          };

          if (_this.autofill.investment.financial_advisor_email || _this.autofill.investment.financial_advisor_name) {
            _this.autofill.investment.financial_advisor = true;
          }
          // Because the investor type is set automatically on Investor Details/Associated Person, we want to make sure we don't pass them for autofill
          if (_this.autofill.vesting_entity && _this.autofill.vesting_entity.type) {
            delete _this.autofill.vesting_entity.type;
          }
        });
      };

      this.bindButtonsEventListener = function (method) {
        document.addEventListener('fa.investnow.bindButtons', function (e) {
          if (typeof (method) == 'function') {
            method();
          }
        });
      };

      this.clearAchAuthorizationsListener = function () {
        document.addEventListener('fa.investnow.clearAchAuthorizations', function (e) {
          if (_this.ach_authorization_id_options) {
            _this.ach_authorization_id_options = undefined;
          }
        });
      };

      this.clearDataListener = function () {
        document.addEventListener('fa.investnow.clearData', function (e) {
          _this.data.client_data = undefined;
        });
      };

      this.clearEventListener = function () {
        document.addEventListener('fa.investnow.clear', function (e) {
          // This event needs to celear the invest now data and start over?
          var broadcastService = ss.inject('broadcastService'),
            isOpened = ss.inject('$uibModalStack').getTop();
          if (isOpened) {
            broadcastService.send('clearEvent');
          } else {
            _this.destroySession(false);
          }
        });
      };

      this.closeModalEventListener = function () {
        document.addEventListener('fa.investnow.closeModal', function (e) {
          // Close the modal (all modals, but should only be one)
          var $uibModalStack = ss.inject('$uibModalStack');
          $uibModalStack.dismissAll();
        });
      };

      this.clearInvestorsListener = function () {
        document.addEventListener('fa.investnow.clearInvestors', function (e) {
          if (_this.investor_id_options) {
            _this.investor_id_options = undefined;
          }
        });
      };

      // I really only see this as useful for debugging, but maybe they'll want this as a feature
      this.getDataListener = function () {
        document.addEventListener('fa.investnow.getData', function (e) {
          var client_data;

          // Send them the client data from _this.data
          // Don't rely on the invest_now_investment because it might not be updated yet
          // and I don't have a good way to keep the two synced
          if (_this.data && _this.data.client_data) {
            client_data = _this.data.client_data;
          }

          // console.log(client_data);
        });
      };

      this.resumeInvestmentListener = function (openModal) {
        document.addEventListener('fa.investnow.resume', function (e) {
          _this.investment_token = e.token;
          // _this.invest_now_investment_id = e.invest_now_investment_id;
          openModal(undefined, { 'investment_token': true });
        });
      };

      this.setAchAuthorizationsListener = function () {
        document.addEventListener('fa.investnow.setAchAuthorizations', function (e) {
          // Takes a comma separated string of ids
          if (e.ids) {
            _this.ach_authorization_id_options = e.ids;
          }
        });
      };

      this.setInvestorsListener = function () {
        document.addEventListener('fa.investnow.setInvestors', function (e) {
          // Takes a comma separated string of ids
          if (e.ids) {
            _this.investor_id_options = e.ids;
          }
        });
      };

      this.updateEventListener = function () {
        // This event will update the custom data that gets sent in the investment
        document.addEventListener('fa.investnow.update', function (e) {
          // Default to empty object, same as the API sends
          var client_data = e.detail ? e.detail : {};
          _this.data.client_data = client_data;

          /*
            Don't try to mess with the local storage. This could potentially cause state issues
            Also, the client shouldn't be trying to modify the custom data after progressing past the first step
            Since the client data is held in the _this.data.client_data object, it'll always be automatically set on the investment step
    
            I could potentially try to set custom data after the first step, but I still don't like that idea. So few people apparently are using this method,
            because it was only until now that someone found out it was broken
          */
        //  console.log('Client data', client_data)

          var invest_now_investment = _this.investment();
          if (invest_now_investment && client_data) {
            invest_now_investment.client_data = client_data;
            // Update the client data object.
            _this.investment(invest_now_investment);
          }
        });
      };

    }])

    .factory('broadcastService', ['$rootScope',
      function ($rootScope) {
        return {
          send: function (msg, data) {
            $rootScope.$broadcast(msg, data);
          }
        };
      }]);

}