
/* injects from baggage-loader */

'use strict';

export default function (app) {
  app.service('Navigation', function ($state, $rootScope, $timeout, $location, Notifications) {

    this.current = undefined;
    this.state = {};
    this.intendedPath = undefined;
    this.navGuard = {};

    this.showMobileMenu = false;
    this.showAccountDropdown = false;
    this.showNotificationsDropdown = false;

    this.loadingNotifications = false;

    this.toggleAccountDropdown = () => {
      this.showAccountDropdown = !this.showAccountDropdown;
    }

    this.toggleMobileMenu = () => {
      this.showMobileMenu = !this.showMobileMenu;
      $rootScope.appState.scrollLock = this.showMobileMenu;
      // Lock the navbar in place when the mobile menu is opened on pages where the nav is unlocked by default
      if ($state.current.name === 'project') {
        $rootScope.appState.unlockHeader = this.showMobileMenu ? false : true;
      }
    }

    this.toggleNotificationsDropdown = async () => {
      this.showNotificationsDropdown = !this.showNotificationsDropdown;
      if (Notifications.notifications.length === 0) {
        this.loadingNotifications = true;
        await Notifications.fetchSome();
        this.loadingNotifications = false;
        $timeout()
      }
    }

    this.navigate = target => {
      $location.path(target).search({});
		}

    this.setupMenu = function (config) {
      this.current = config;
    };

    this.teardownMenu = function () {
      this.current = undefined;
    };

    this.setCurrentState = function (state) {
      this.state = state;
    }

    this.currentState = {
      getTitle: () => {
        if (this.state.data) {
          return this.state.data.navTitle || this.state.data.title || '';
        } else {
          return '';
        }
      }
    }

    this.setIntendedPath = path => {
      this.intendedPath = path;
    }

    this.getIntendedPath = clear => {
      let path = this.intendedPath;
      if (clear) {
        this.intendedPath = undefined;
      }
      return path;
    }

    // Navigation guard system
    this.setGuard = guard => this.navGuard = guard;
    this.releaseGuard = () => this.navGuard = { fn: angular.noop };
    this.checkGuard = () => {
      if (this.navGuard.fn()) {
        let answer = confirm(this.navGuard.message)
        if (!answer) {
          return false;
        }
      }
      return true;
    }

  });
}

