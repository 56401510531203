
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectInterest', function (ProjectInterestSchema) {

		// Load object schema
		const ProjectInterest = ProjectInterestSchema;

		// ==================================================
		// Methods
		// ==================================================
		
	});
}

