
/* injects from baggage-loader */

'use strict';

import moment from 'moment';

export default class ReturnsCalculatorController {

	constructor($log, ReturnsCalculator) {
		'ngInject';

		this.$log = $log;
		this.calculator = ReturnsCalculator;

		this.amount = 20000;
		this.currentYear = moment().format('YYYY');

		this.constants = {
			numberOfYears: 10,
			raiseTotal: 1000000,
			minInvestment: 20000,
			returns: [
				0,
				451325,
				65376,
				68986,
				72659, 
				3830,
				91927,
				92221, 
				92578,
				1852396 
			],
			investmentNote: "This calculator only represents projection estimates in increments of $20,000 for this project. Minimum investment is $20,000."
		}

		this.rate = this.getRate();

		this.tableHeaders = [
			"Calendar Year",
			"Operations Year",
			"Return Amount"
		];

		this.tableRows = [];

		this.renderTable(this.amount);

	}

	$onInit() {

	}

	onAmountChanged() {
		this.renderTable(this.amount);
	}

	getRate() {
		return (this.amount / this.constants.raiseTotal);
	}

	calculateReturn(index) {
		if (!index) return 0;
		return this.constants.returns[index] * this.rate;
	}

	renderTable(amount) {
		this.$log.debug(amount % this.constants.minInvestment);
		if (amount % this.constants.minInvestment !== 0) {
			this.tableRows = [];
			return;
		}
		this.rate = this.getRate();
		this.tableRows = [];
		let cumulativeReturn = 0;
		for (let year = 0; year < this.constants.numberOfYears; year++) {
			cumulativeReturn += this.calculateReturn(year);
			this.tableRows.push({
				calendarYear: parseInt(this.currentYear) + year,
				operationsYear: `Year ${year + 1}`,
				returnAmount: this.calculateReturn(year),
				cumulativeReturn
			})
		}
		this.tableRows.push({
			calendarYear: "Total Projected Return",
			operationsYear: '',
			returnAmount: cumulativeReturn
		})
	}

}
