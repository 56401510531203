
/* injects from baggage-loader */

'use strict';

export default class FooterController {
    constructor(EDUCATIONAL_MATERIALS_LINK) {
        'ngInject';
        
        this.eduMaterialsLink = EDUCATIONAL_MATERIALS_LINK;
        this.year = '2020';
    }

    $onInit() {
       this.year = new Date().getFullYear();
    }
}