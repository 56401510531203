
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingComponent from './issuer-onboarding.component';
import './issuer-onboarding.scss'

import ProjectDetailsModule from './details/issuer-onboarding.details.module';
import OfferingAndTeamInfoModule from './offering-and-team/issuer-onboarding.offering-and-team.module';
import DocumentsModule from './documents/issuer-onboarding.documents.module';
import RepresentationsModule from './representations/issuer-onboarding.representations.module';
import RecordsModule from './records/issuer-onboarding.records.module';
import InverviewModule from './interview/issuer-onboarding.interview.module';
import BackgroundCheckModule from './background-check/issuer-onboarding.background-check.module';
import FinalizeModule from './finalize/issuer-onboarding.finalize.module';

const IssuerOnboardingPageModule = angular.module('issuer-onboarding-module', [
    'ui.router',
    ProjectDetailsModule.name,
    DocumentsModule.name,
    RepresentationsModule.name,
    RecordsModule.name,
    InverviewModule.name,
    BackgroundCheckModule.name,
    FinalizeModule.name,
    OfferingAndTeamInfoModule.name,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('issuer-onboarding', {
        parent: 'root',
        url: '/issuer-onboarding/{id}',
        component: 'issuerOnboarding',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          onboarding: function (Onboarding, $transition$, $q, OnboardingSchema) {
            return Onboarding.get($transition$.params('to').id);
          }
        }
      });
  })
  // .run(function ($transitions, $rootScope) {
  //   // Hide the footer
  //   $transitions.onSuccess({ to: 'issuer-onboarding.*' }, () => $rootScope.appState.hideFooter = true);
  // })
  .component('issuerOnboarding', new IssuerOnboardingComponent());

export default IssuerOnboardingPageModule;
