
/* injects from baggage-loader */

'use strict';

import FooterComponent from './footer.component';
import './footer.scss';

const footerModule = angular.module('footer-module', []);

footerModule
    .component('footer', new FooterComponent());

export default footerModule;
