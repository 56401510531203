
/* injects from baggage-loader */

'use strict';

export default class LearnController {

	constructor($log, FAQS, HTCTERMS1, HTCTERMS2, QOZTERMS1, QOZTERMS2) {
		'ngInject';

		this.$log = $log;

		this.faqs = FAQS;
		this.htcterms1 = HTCTERMS1;
		this.htcterms2 = HTCTERMS2;

		this.qozterms1 = QOZTERMS1;
		this.qozterms2 = QOZTERMS2;

	}

	$onInit() {

	}

}
