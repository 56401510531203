
/* injects from baggage-loader */

'use strict';

export default class AboutController {

	constructor($log, $scope, $location, $anchorScroll, FAQS) {
		'ngInject';

		this.$log = $log;

		this.faqs = FAQS;

		this.$scope = $scope;
		this.$location = $location;
		this.$anchorScroll = $anchorScroll;
		
		this.teamMembers = [];

		//JA - UI Scroll
		$scope.scrollingtohtcs = function() {
			$location.hash('htcs');
			$anchorScroll();
		};
		$scope.scrollingtoqozs = function() {
			$location.hash('qozs');
			$anchorScroll();
		};
	}

	$onInit() {
		this.$log.debug(this.teamMembers);
	}

}
