
/* injects from baggage-loader */

'use strict';

import InvestComponent from './invest.component';

// Invest page modules
import InvestFormModule from './form/invest.form.module';
import InvestVerifyModule from './verify/invest.verify.module';
import InvestAccreditedModule from './accredited/invest.accredited.module';
// import InvestCompletionModule from './confirmation/invest.confirmation.module';
import InvestKycModule from './kyc/invest.kyc.module';

const InvestPageModule = angular.module('invest-module', [
    'ui.router',
    InvestFormModule.name,
    InvestVerifyModule.name,
    InvestAccreditedModule.name,
    // InvestCompletionModule.name,
    InvestKycModule.name,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('invest', {
        url: '/project/:id/invest?investmentId',
        abstract: true,
        component: 'invest',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          project: function (Projects, $transition$, MediaSchema, ProjectStatsSchema, ProfileSchema) {
            return Projects.getBySlugOrId($transition$.params('to').id)
              .then(project => {
                if (!project) {
                  throw 'Project not found';
                }
                return project;
              });
          },
          documents: function(project, ProjectDocumentSchema) {
            return project.relation('documents').query().find();
          },
          investment: function (Investments, $transition$, InvestmentSchema) {
            const investmentId = $transition$.params('to').investmentId;

            // TODO: get current investment from localStorage/Investment Form Service
            if (investmentId) {
              return Investments.get(investmentId)
                // .catch(err => {
                //   // TODO: redirect to a NOT FOUND page
                //   // NOTE: may need to manipulate the transition or flag something so the run block
                //   // for this transition can abort and redirect
                // });
            }

            return new InvestmentSchema();
          },
          investor: function(Investors, investment) {
            if (investment && investment.isNew()) {
              return;
            }

            // Return or fetch the investor if not here yet
            return investment.get('investor').isDataAvailable()
              ? investment.get('investor')
              : investment.get('investor').fetch();
          }
        }
      });
  })
  .run(function ($transitions, $state, $rootScope, Errors) {

    // Handle an error encountered while entering the project page
    $transitions.onError({ to: 'invest.confirmation' }, transition => {

      // console.error('Error', transition.error())

      // Get the error details 
      let message = transition.error().detail.message;
      let code = transition.error().detail.code;

      // Retrieve the error message
      let error = Errors[message];

      // TODO: handle this better by taking the user to an erro page
      // Display the error message
      if (typeof error !== 'undefined') {
        alert(error.message);
      }

      // Redirect to handle the error
      if (typeof error !== 'undefined' && typeof error.redirectTo === 'string') {
        $state.go(error.redirectTo);
      } else {
        $state.go('projects');
      }

    });

  })
  .component('invest', new InvestComponent());

export default InvestPageModule;
