
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingController from './issuer-onboarding.controller';
import IssuerOnboardingTemplate from './issuer-onboarding.html';

export default class IssuerOnboardingComponent {
		constructor() {
				this.templateUrl = IssuerOnboardingTemplate;
				this.controller = IssuerOnboardingController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					onboarding: '<'
				};
		}
}
