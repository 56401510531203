
/* injects from baggage-loader */

'use strict';

import InvestFormComponent from './invest.form.component';

const InvestFormPageModule = angular.module('invest-form-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('invest.commitment', {
        url: '',
        component: 'investForm',
        params: {
          amount: ''
        },
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          countryList: function(Parse) {
            return Parse.Cloud.run('getCountryList');
          },
          investorProfiles: function(Investors) {
            return Investors.fetch();
          },
          investmentFees: function(Parse) {
            return Parse.Cloud.run('getInvestmentFees');
          }
        }
      });
  })
  .run(function($transitions, StateService) {
    $transitions.onSuccess({ to: 'invest.commitment' }, transition => {
      // Show the summary panel
      StateService.investment.showSummaryPanel = true;
    });
  })
  .component('investForm', new InvestFormComponent());

export default InvestFormPageModule;
