
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerHomeController {

	constructor($log, $filter, Common) {
		'ngInject';

		this.$log = $log;
		this.$filter = $filter;
		this.commonService = Common;

	}

	$onInit() {

		this.widgets = [
			{
				label: `Investment${this.project.stats.investor_count === 1 ? '' : 's'}`,
				icon: 'far fa-handshake',
				gradient: 'widget-0',
				data: this.project.stats.investor_count,
				visible: true
			},
			{
				label: 'Project views',
				icon: 'far fa-eye',	// 'fa-bank'
				gradient: 'widget-1',
				data: this.project.stats.views,
				visible: true
			},
			{
				// label: 'Total Dollars invested',
				label: 'Funds Received',
				icon: 'fas fa-dollar-sign',	// 'fa-bank'
				gradient: 'widget-3',
				data: this.$filter('currency')(this.project.stats.get('funds_raised'), '$', 2),
				// data: this.$filter('currency')(this.offering.funds_received || 0, '$', 2),
				visible: true
			}
		];

		// this.$log.debug(this.offering)
		// this.$log.debug(this.activityFeed)
	}


	/**
	 * Returns the number of days remianing until the end of the raise
	 */
	daysRemaining() {
		return this.commonService.daysRemaining(this.project.get('end_date'));
	}

	isMinimumMet() {
    return this.project.stats.get('funds_raised') >= this.project.investment_goal_min
			|| (this.offering && this.offering.minimum_met);
  }
}
