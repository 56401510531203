
/* injects from baggage-loader */

'use strict';

export default function (app) {

  var script_tag = document.getElementById('fa-invest-now-launcher'),
    faScriptHost;
  if (script_tag) {
    var src = script_tag.getAttribute('src');
    if (/^https?:\/\//.test(src)) {
      faScriptHost = src.replace(/^((https?:)?\/\/.*?)\/.*$/, '$1');
    } else {
      faScriptHost = '';
    }
  }

  app.controller('faInvestNowController', ['ss', 'ENV', '$http', '$filter', '$scope', '$ins',
    function (ss, ENV, $http, $filter, $scope, $ins) {
      var _this = this,
        css = [],
        open = false;

      /********************************************************************************************/

      // css.push(ENV.assetHost + '/shared/css/invest_now.css', '//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
      // css.push('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

      var theme = script_tag ? script_tag.getAttribute('data-theme') : undefined;
      $ins.loadTheme(theme, true);

      ss.appendCSS(css);
      $ins.scriptHost = faScriptHost;
      // This method will take the querystring and parse it out in to an object and assign it to $ins
      $ins.urlParams = ss.urlParams();

      var clickFunction = function ($event) {
        // Prevent Default if used on a link
        $event.preventDefault();
        if (open === false) {
          setDefaults($event);
        }
      }

      // Send extra params in, in case of special handling
      this.openModal = function ($event, params) {
        var session = $ins.session(),
          params = params ? params : {};
        $ins.openEvent($event);
        // Set a variable to track when the opening is triggered from a query string
        $ins.triggeredOpening = false;

        var options = {
          backdrop: 'static',
          backdropClass: 'fa-widget-backdrop',
          controller: 'investNowModal',
          controllerAs: 'modal',
          windowTopClass: 'invest-now-modal',
          keyboard: false,
          resolve: {
            invest_now: function () {
              var success = function (s) {
                if (!params.signing_link) {
                  // remove the signing token so it'll open correctly
                  // I figure if they prematurely close the modal, they can reload the page, since there's no other good way to handle reopening the modal for a signing link
                  if ($ins.resource_token) {
                    delete $ins.resource_token;
                  }

                  $ins.session(s);
                  // For the moment, we'll just directly set the token from the response
                  $http.defaults.headers.common['X-FA-User-Session-Token'] = s.serialized_token;
                }
              };
              var failure = function (e) {
                if (e.status === 422) {
                  // Gotta combine all the arrays, just in case there's more than offering_id that comes back
                  var errors = [];
                  for (var key in e.data) {
                    errors = errors.concat(e.data[key]);
                  }

                  $ins.page_errors = errors;
                } else if (params.signing_link && e.status === 404) {
                  $ins.page_errors = ['We\'re sorry, this one-time link has already been used to sign this document or the link is no longer valid. Please contact customer service for further assistance.'];
                } else {
                  var message = 'Resource returned status: ' + e.status + ' ' + e.statusText;
                  if (e && e.data && e.data.status_message) {
                    message += (', ' + e.data.status_message);
                  }
                  $ins.page_errors = [message];
                }
              };

              // Add logic to see if there's a signing link token
              // Signing tokens don't use/have a session
              // Need to disable the clear button, they're not clearing on the first step and the finish step won't allow coming back
              // Offering_id will definitely be available from the invest_now_investment session, but we're not going to do anything with it

              if (params.signing_link) {
                // Maybe we should clear the session after all, just as a precaution when dealing with a signing link
                $ins.destroySession(false);
                $ins.triggeredOpening = true;
                return ss.call('signatureRequests', 'edit', { 'token': $ins.resource_token }, success, failure);
              } else if (params.investment_token) {
                // Destroy any possible, current, invest now session information
                $ins.destroySession(false);
                $ins.triggeredOpening = true;
                // Call the resume session, using the provided token
                return ss.call('investNowInvestments', 'resume', { 'token': params.investment_token }, success, failure);
              } else if (session && session.invest_now_investment.offering_id === $ins.offering_id) {
                success(session);
                return session;
              } else {
                return ss.call('investNowInvestments', 'getSession', { 'offering_id': $ins.offering_id }, success, failure);
              }
            }
          },
          // size: 'lg',
          templateUrl: 'partials/modal.html'
        };

        open = true;
        // Always toggle back to false when the modal closes
        ss.createModal(options).result.then(
          function (s) {
            // Right now success is never called because we let them sit at the final step and all the information is returned on dismiss anyway
            open = false;
            // can pass extra options for the data
            $ins.closeEvent();
          }, function (e) {
            open = false;
            // Put together some data to return when the modal is closed
            var investment = $ins.investment(),
              data = {
                'amount': investment.amount,
                'completed': investment.state === 'funds_transfer',
                'investment_id': investment.investment_id,
                'investor_id': investment.investor_id,
                'offering_id': investment.offering_id,
                'state': investment.state,
                'states_completed': investment.states_completed
              };

            $ins.closeEvent(data);
            // This is when they dismissed the modal with no return
          });
      };

      function bindButtons() {
        ss.bindButtons(clickFunction, '[data-fa-invest-now]');
      }

      function setDefaults($event) {

        // CUSTOM COMMON OWNER UPDATE - 04/15/2020
        var data, public_key, offering_id;
        if ($event.detail && $event.detail.token) {
          data = $event.detail.token;
          public_key = data ? data.split(':')[0] : undefined;
          offering_id = data ? data.split(':')[1] : undefined;
        } else {
          data = $event.detail;
          public_key = data ? data.split(':')[0] : undefined;
          offering_id = data ? data.split(':')[1] : undefined;
        }

        $ins.public_key = public_key;
        $ins.offering_id = offering_id;

        // Check if the public key is different, and then set it.
        $http.defaults.headers.common['X-FA-Public-Key'] = public_key;

        _this.openModal($event);
      }

      // Bind the buttons to open our modal
      bindButtons();

      var listenerOptions = {};
      listenerOptions.bindButtons = bindButtons;
      listenerOptions.openModal = this.openModal;
      // Only doing the nesting because I might need to pass extra things in the future
      $ins.addListeners(listenerOptions);
      // Now tell them that our app is ready
      $ins.readyEvent();

      // After everything is ready, let's check if there's a signing token and open the modal to finish their investment
      if ($ins.urlParams['_fa_resource_token']) {

        $ins.resource_token = $ins.urlParams['_fa_resource_token'];
        // Call the open event but send undefined for the event
        _this.openModal(undefined, { 'signing_link': true });
      } else if ($ins.urlParams['_fa_investment_token']) {
        _this.openModal(undefined, { 'investment_token': $ins.urlParams['_fa_investment_token'] });
      }
    }])


    .controller('investNowModal', ['$scope', '$uibModalInstance', '$filter', 'ss', '$ins', 'invest_now', '$http',
      function ($scope, $uibModalInstance, $filter, ss, $ins, invest_now, $http) {
        var _this = this,
          templates = $ins.templates;

        var invest_now_investment;
        if ($ins.resource_token && invest_now) {
          $scope.signature_request = invest_now;
          invest_now_investment = ($scope.signature_request && $scope.signature_request.resource) ? $scope.signature_request.resource.invest_now_investment : undefined;

          if (invest_now_investment && $scope.signature_request.resource.resource_type === 'electronic_signature' && !$scope.signature_request.resource.signed) {
            // Set our state, so it'll load correctly
            invest_now_investment.state = 'signature_request';
          }

        } else {
          invest_now_investment = invest_now && invest_now.invest_now_investment ? invest_now.invest_now_investment : undefined;
        }
        // If they've already signed, then just allow the invest_now_investment.state do it's thing
        $ins.invest_now_investment = invest_now_investment;

        this.cancel = function () {
          if (_this.completed()) {
            // console.log('Investment complete');

            // Close the modal, clear the data
            $uibModalInstance.dismiss();
            this.clearEvent();

            // Emit event back to common-owner app that investment is complete
            // postMessage({
            //   type: 'common-owner',
            //   event: 'investment-complete'
            //   // data: {}
            // }, location.origin);

          } else {
            $uibModalInstance.dismiss();
          }
        };

        this.clear = function () {
          var c = confirm('Are you sure?');
          if (c) {
            _this.clearEvent();
          }
        };

        this.closeAlert = function (index) {
          _this.invest_now_investment.notices.splice(index, 1);
        };

        this.clearEvent = function () {
          var success = function (s) {
            var current_template = _this.templateUrl;
            $ins.session(s);
            $ins.investment(s.invest_now_investment);
            // For the moment, we'll just directly set the token from the response
            $http.defaults.headers.common['X-FA-User-Session-Token'] = s.serialized_token;
            // Need to "reload" the state when we're on the first step, because clearing on other steps will handle this otherwise
            if (current_template === 'partials/investment.html') {
              $scope.$broadcast('reload');
            }

            // COMMON OWNER CUSTOM
            // Tell the project controller that the investment has been cleared. Delete and start over
            postMessage({
              type: 'common-owner',
              event: 'investment-cleared'
            }, location.origin);

          };
          var failure = function (e) {
            $ins.page_errors = ['Resource returned status: ' + e.status + ' ' + e.statusText + ', ' + e.data.status_message];
          };
          ss.call('investNowInvestments', 'getSession', { 'offering_id': $ins.offering_id }, success, failure);
        };

        this.completed = function () {
          return _this.invest_now_investment && _this.invest_now_investment.states.length > 1 && ((_this.stateIndex + 1) === _this.invest_now_investment.states.length);
        };

        this.changeTemplates = function (name, update) {
          // Remove any page errors when changing states
          if ($ins.page_errors) {
            delete $ins.page_errors;
          }
          // if($ins.alert){
          //   delete $ins.alert;
          // }

          var success = function (s) {
            _this.templateUrl = templates[name];
            ss.goTo('fa-invest-now-modal');
          };

          // We pass in update when changing states from the select
          // this means we should patch the state so it'll remember it for when we come back later!
          if (update) {
            var updated = function (s) {
              $ins.investment(s);
            };

            var failure = function (e) {
              // In case the state change fails, display page_errors?
            };

            ss.submit(_this, 'investNowInvestments', 'changeState', { 'id': $ins.invest_now_investment.id }, { 'state': name }, updated, failure);
          } else {
            success();
          }
        };

        this.getStateIndex = function () {
          if (_this.invest_now_investment && _this.invest_now_investment.states) {
            for (var i = 0; i < _this.invest_now_investment.states.length; i++) {
              if (_this.invest_now_investment.states[i].name === _this.invest_now_investment.state) {
                return i;
              }
            }
          } else {
            return -1;
          }
        };

        this.next = function () {
          $scope.$broadcast('submit');
        };

        this.percentComplete = function () {
          var current = _this.stateIndex ? _this.stateIndex + 1 : 0,
            total = _this.invest_now_investment ? _this.invest_now_investment.states.length : 0,
            completed = current / total;
          return isNaN(completed) ? 0 : (completed * 100).toFixed(0);
        };

        this.prev = function () {
          if (_this.processing === false) {
            _this.changeTemplates(_this.invest_now_investment.states[_this.stateIndex - 1].name, true);
          }
        };

        this.showClear = function () {
          // Show clear if we have an invest_now_investment AND we're not dealing with a resource_token AND it's not the first step
          return _this.invest_now_investment && !$ins.triggeredOpening && _this.invest_now_investment.state !== 'investment';
        };

        this.showNext = function () {
          if (_this.invest_now_investment && _this.invest_now_investment.states) {
            // Need to always show next UNLESS last step
            return _this.invest_now_investment.states[_this.stateIndex + 1] !== undefined || _this.invest_now_investment.state === 'investment' || _this.invest_now_investment.state === 'signature_request';
          } else {
            return false;
          }
        };

        this.showPrev = function () {
          if (_this.invest_now_investment && _this.invest_now_investment.states) {
            return _this.invest_now_investment.state !== 'credit_card'
              && _this.invest_now_investment.state !== 'credit_card_setup'
              && _this.invest_now_investment.states[_this.stateIndex - 1] !== undefined
              && !_this.completed();
          } else {
            return false;
          }
        };

        this.stateInfo = function () {
          if (_this.invest_now_investment.state !== 'credit_card_payment' && _this.stateIndex + 1 === _this.invest_now_investment.states.length) {
            return 'Finished!';

          } else {
            return 'Step ' + (_this.stateIndex + 1) + ' of ' + _this.invest_now_investment.states.length;
          }
        };

        //****************************************
        // $scope methods
        //****************************************
        $scope.toBool = function (string) {
          return ss.toBool(string);
        };

        // Set these here so they're inherited by all the child scopes
        $scope.metaIf = function (meta, resource) {
          return ss.metaIf(meta, resource);
        };

        $scope.metaLabel = function (options) {
          return ss.metaLabel(options);
        };

        $scope.toTrustedHTML = function (html) {
          return ss.toTrustedHTML(html);
        };

        $scope.$watch(function () {
          return $ins.page_errors;
        }, function (e, oldVal) {
          if (e) {
            _this.page_errors = e;
          } else {
            _this.page_errors = null;
          }
        });

        $scope.$watch(function () {
          return $ins.clearEvent;
        }, function (newVal, oldVal) {
          if (newVal === true) {
            $ins.clearEvent = undefined;
            _this.clearEvent();
          }
        });

        // Watch the widget service invest_now_investment object
        $scope.$watch(function () {
          return $ins.invest_now_investment;
        }, function (newVal, oldVal) {
          _this.invest_now_investment = newVal;

          if (_this.invest_now_investment) {
            // All we need to do is make sure there's an invest_now_investment and the method handles the rest
            $ins.loadTheme(_this.invest_now_investment.css_theme);
          }
          // only change templates if there's a state passed
          // this also prevents clearing out page_errors
          if (newVal && newVal.state) {
            _this.changeTemplates(newVal.state);
          }
          _this.stateIndex = _this.getStateIndex();
        });

        $scope.$watch(function () {
          return $ins.processing;
        }, function (newVal, oldVal) {
          _this.processing = newVal;
        });

        $scope.$watch(function () {
          return $ins.loading;
        }, function (newVal, oldVal) {
          _this.isLoading = newVal;
        });

        // ON EVENTS
        // Triggering this event allows us to actually have the modal change back to the first step and clearing the investment data.
        $scope.$on('clearEvent', function (event) {
          _this.clearEvent();
        });

      }])

}