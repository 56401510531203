
/* injects from baggage-loader */

'use strict';

export default function (app) {
    app.service('TeamMembersSchema', function(Parse) {
        // instantiate Objects
        let TeamMembers = Parse.Object.extend('Team_Members');

        // define 'TeamMembers' scheme
        let schema = [
            'name',
            'role',
            'description',
            'imageCDN',
            'linkedInURL'
        ];

        // load schema into object prototype
        schema.forEach(function (field) {
            Object.defineProperty(TeamMembers.prototype, field, {
                get: function () {return this.get(field); },
                set: function(value) {return this.set(field, value); }
            });
        });
        
        return TeamMembers;
    });
}