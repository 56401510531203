
/* injects from baggage-loader */

'use strict';

const AppTransitions = angular.module('transitions-module', [])
  .run(function ($log, $rootScope, $timeout, $window, $location, $transitions, User, Alerts, ErrorHandler, Navigation,
    DEFAULT_PAGE_TITLE, ENVIRONMENT, MetaTags) {

    $rootScope.metaService = MetaTags;

    // let dataLayer = $window.dataLayer = $window.dataLayer || [];

    // =========================================
    // UI Router Global Transition Hooks
    // =========================================

    // Called when any state transition is about to begin
    $transitions.onStart({}, transition => {

      // Show the loading bar
      let current_state = transition._targetState._definition;
      if (current_state.resolvables.length) {
        $rootScope.loading_state = true;
      }

      // Set the tags for the static page if exists
      MetaTags.setStaticPageData(transition.to().name);

      // Listen for notifying transitions
      if (transition.options().notify !== false) {

        // Check if to state is set as previous in state data
        let previous = transition.from().data ? transition.from().data.previous : '';

        // Update state transition flags
        $rootScope.appState.transitions.back = transition.to().name === previous;
        $rootScope.appState.transitions.toHome = (transition.to().name === 'home');

      }

      // Redirect to login page if route is protected and no current user
      if (transition.to().protected === true && User.isUserLoggedIn() === false) {

        // Grab the intended path and save it
        // Navigation.setIntendedPath($location.path());

        // Add alert to what happened to global alerts
        Alerts.add({
          type: 'info',
          msg: 'Please log in to perform this action.',
          timeout: 60000
        });

        // State hack method [Disabled]
        // $location.path('/account/login?ref=' + transition.to().name);
        // return transition.router.stateService.target('login', { ref: transition.to().name });

        // Use full url method
        // $log.debug(JSON.parse(JSON.stringify($location)))
        // let query = decodeURIComponent($location.url());
        // $log.debug(query)
        return transition.router.stateService.target('login', { ref: $location.url().toString() });
      }

    });

    // Called when any state transition is entered
    $transitions.onEnter({}, transition => {

      // Clear any local alerts
      Alerts.clearLocal();

      // Un-hide the header incase it's been hidden by the previous state
      $rootScope.appState.hideHeader = false;

      // Re-lock the header incase it's been modified by the previous state
      $rootScope.appState.unlockHeader = false;

      // Unlock scoll incase it is stuck
      $rootScope.appState.scrollLock = false;

      // Un-hide the footer incase it's been hidden by the previous state
      $rootScope.appState.hideFooter = false;

      // Close menus
      Navigation.showAccountDropdown = false;
      Navigation.showNotificationsDropdown = false;
      Navigation.showMobileMenu = false;

      // Reset header options
      $rootScope.appState.transparentHeader = false;
      $rootScope.appState.animateHeader = false;

      // Set the current state
      $rootScope.appState.current = transition.to();

      if (transition.to().transparent_nav) {
        $rootScope.appState.transparentHeader = true;
        $rootScope.appState.animateHeader = true;
      }

    });

    // Called when any state transition succeeds
    $transitions.onSuccess({}, transition => {


      // Log page view to google analytics
      // if (ENVIRONMENT === 'production') {
      // if ($window.gtag) $window.gtag('event', 'page_view', {
      //   page_path: $location.path()
      // });
      // // eslint-disable-next-line no-undef
      // else if (typeof gtag !== 'undefined') gtag('event', 'page_view', {
      //   page_path: $location.path()
      // });
      // else $log.debug('Cannot log gtag')

      // $window.gtag('config', 'UA-152899093-1', {
      //   page_path: $location.path()
      // });
      // }

      // Log page view to google analytics
      // if (ENVIRONMENT === 'production') {
        // $window.dataLayer.push({
        //   event: 'ngRouteChange',
        //   attributes: {
        //     route: $location.path()
        //   }
        // });
      // }

      // Hide loading bar
      $timeout(() => {
        $rootScope.loading_state = false;
      }, 250);

      // Always scroll to the top of the page on route change
      $window.scrollTo(0, 0);

      // Update document title
      $timeout(() => {

        // NOTE: Removed in favor of the new metadata service handling title
        // if (typeof transition.to().title !== 'undefined' && transition.to().title.length > 0) {
        //   // Set document title for the current page
        //   $window.document.title = `${transition.to().title} | ${DEFAULT_PAGE_TITLE}`;
        // } else {
        //   // Set the default page title
        //   $window.document.title = DEFAULT_PAGE_TITLE;
        // }

        // Set the page URL meta tag
        MetaTags.set({
          url: $window.location.href
        }, false);

      }, 250);

    });

    // Called when any state transition encounters an error
    $transitions.onError({}, (transition, state) => {
      // Handle transition errors

      // Remove the loading bar
      // Hide loading bar
      $timeout(() => {
        $rootScope.loading_state = false;
      }, 250);

    });

    // Set UI Router default route error handler
    $transitions._router.stateService.defaultErrorHandler(function (error) {

      if (ENVIRONMENT !== 'production') {
        $log.error(error);
      }

      // Pass it to the error handler
      if (typeof error.detail !== 'undefined') {
        ErrorHandler(error.detail, error);
      }

    });

  })

export default AppTransitions;

