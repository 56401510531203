
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Investors', function (Parse, User, InvestorSchema) {

		// ==================================================
		// Methods
		// ==================================================

		let Investor = InvestorSchema;

		/**
		 * Retrieve an Investor by ID
		 */
		this.get = id => {
			let query = new Parse.Query(Investor);
			return query.get(id);
		}


		/**
		 * Retrieve the users Investor profiles
		 */
		this.fetch = () => {
			let currentUser = User.getCurrent();
			let query = new Parse.Query(Investor);
			query.equalTo("user", currentUser);
			query.descending("lastInvestedAt");
			return query.find();
		};
		
	});
}

