
/* injects from baggage-loader */

'use strict';

export default class LoginController {

	constructor($log, $state, $location, $timeout, $stateParams, User, Alerts) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$location = $location;
		this.$timeout = $timeout;
		this.UserService = User;
		this.Alerts = Alerts;

		// Hold user login credentials
		this.user = {
			email: $stateParams.email || '',
			password: ''
		};

		this.isLoggingIn = false;
		this.usingTwoFactorAuth = false;

		// Hold login error details
		this.error = {};
		this.alert = {};

		// Get the referral page from state params
		this.referral = $stateParams.ref;
		this.alert = $stateParams.alert;

	}

	$onInit() {
		// this.$log.debug(this.$stateParams);

		if (this.referral && this.alert === "true") {
			// Add alert to what happened to global alerts
			this.alert = {
				type: 'info',
				msg: 'Please log in to perform this action.'
			}
		}

	}

	login() {

		// Reject another login if the user is already being logged in
		if (this.isLoggingIn) {
			return false;
		}

		this.isLoggingIn = true;

		// Reset error
		this.error = {};

		// Attempt to authenticate the user
		this.UserService.authenticate(this.user)
			.then(res => {
				// this.$log.debug(res)
				if (res.action === 'REQUIRES_TWO_FACTOR_AUTH') {
					this.usingTwoFactorAuth = true;
					this.isLoggingIn = false;
				} else if (typeof this.referral !== 'undefined') {
					this.$location.path(this.referral).search({});
				} else {
					this.$state.go('account.home');
				}
				this.$timeout();
			})
			.catch(err => {
				this.error = err;
				this.isLoggingIn = false;
				this.$timeout();
			});

	}

}
