
/* injects from baggage-loader */

'use strict';

import _ from 'lodash';

export default class InvestmentCheckoutController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		// this.data = {};

		this.feesToolTipHtml = `
		<p>Fees are based on investment amount and type.</p>
		<p>ACH Transaction Fee: 0.15% with a $5 minimum fee and $120 maximum.</p>
		<p>Credit Card Transaction Fee: 3.3% with a $5 minimum and no maximum.</p>`;

	}

	$onInit() {
		// this.$log.debug(this.data);
	}

	getOfferingRegulation() {
		return this.project.get('type') === 'cf'
			? "Reg CF"
			: this.project.get('type');
	}

	getSharePrice() {
		return this.project.get('share_price');
	}

	getSecurityType() {
		return _.capitalize(this.project.get('security_type'));
	}

}
