
/* injects from baggage-loader */

'use strict';

import InvestmentReconfirmationModalComponent from './investmentreconfirmationmodal.component';
import './investmentreconfirmationmodal.scss';

const InvestmentReconfirmationModalModule = angular.module('investment-reconfirmation-modal-module', []);

InvestmentReconfirmationModalModule
    .component('investmentReconfirmationModal', new InvestmentReconfirmationModalComponent());

export default InvestmentReconfirmationModalModule;

