
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsProfileController {

	constructor($log, $state, $document, $rootScope, UsStates, Countries, Alerts) {
		'ngInject';

		this.heading = 'My Profile';

		this.$log = $log;
		this.$state = $state;
		this.$document = $document;
		this.$rootScope = $rootScope;
		this.Alerts = Alerts;
		this.usStates = UsStates;
		this.countries = Countries;

		this.savingProfile = false;

	}

	$onInit() {

		// Clear local alerts
		this.Alerts.clearLocal();

	}

	$onDestroy() {

		// Clear local alerts
		this.Alerts.clearLocal();

	}

	save() {

		// Clear error message
		this.Alerts.clearLocal();

		this.savingProfile = true;

		// Save the profile
		this.profile.save()
			.then(profile => {

				this.savingProfile = false;

				// Emit an event to inform listeners that the user profile has been updated
				this.$rootScope.$emit('userProfileUpdated');

				// Password updated successfully
				this.Alerts.setLocal({
					type: 'success',
					msg: 'Profile updated successfully!'
				});

				this.$document.scrollTopAnimated(0);

			})
			.catch(err => {

				this.savingProfile = false;

				this.Alerts.setLocal({
					type: 'danger',
					msg: 'Failed to update profile. Please contact support if the problem persists.'
				});

				this.$document.scrollTopAnimated(0);

			})

	}

}
