
/* injects from baggage-loader */

'use strict';

// Variables set from webpack build
const APP_ID = 'common-owner';
const SERVER_URL = 'https://api-staging.commonowner.com/parse';

function runBlock($rootScope, $window, Parse, ENVIRONMENT) {
	'ngInject';

	// Initialize parse
	Parse.initialize(APP_ID);
	Parse.serverURL = SERVER_URL;

	// Create the main application state
	$rootScope.appState = {
		current: {},				// Hold the current state
		transitions: {},
		userRoles: []
	};

	// How to track a custom event, ex. clicking invest now
	// [eventCategory], [eventAction], [eventLabel], [eventValue], [non-interaction flag], [custom data]
	// Analytics.trackEvent('test', 'mitch', 'Is a developer', 26, true, { environment: ENVIRONMENT });

	// $window.gtag('config', 'UA-152899093-1');

}

export default runBlock;