
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerDisbursementController {

	constructor($log, $filter, $timeout, DisbursementRequests) {
		'ngInject';

		this.$log = $log;
		this.$filter = $filter;
		this.$timeout = $timeout;
		this.DisbursementRequest = DisbursementRequests;

		this.widgets = [];

		// Resolvables
		this.project = {};
		this.offering = {};

		// Default offering values
		this.escrowStatus = "";
		this.escrowCloseDate = "";
		this.fundsTransferMethods = [];
		this.isMinimumMet = false;
		this.isDisbursable = false;
		this.isEscrowOpen = false;
		this.fundsDisbursable = "0.00";
		this.fundsInEscrow = "0.00";
		this.fundsReceived = "0.00";
		this.fundsWithdrawn = "0.00";
		this.fundsUnavailable = "0.00";

		// Default form values
		this.withdrawlAmount = '';
		this.withdrawlError = '';
		this.successMessage = '';
		this.processingRequest = false;

	}

	$onInit() {

		// Escrow values
		this.escrowStatus = this.offering.escrow_status;
		this.escrowCloseDate = this.offering.escrow_closes_at;
		this.fundsTransferMethods = this.offering.funds_transfer_methods;
		this.isMinimumMet = this.offering.minimum_met;
		this.isDisbursable = this.offering.can_disburse;
		this.isEscrowOpen = this.escrowStatus === "opened";

		// Extract funds amounts from offering
		this.fundsDisbursable = this.offering.funds_disbursable;
		this.fundsInEscrow = this.offering.funds_in_escrow;
		this.fundsReceived = this.offering.funds_received;

		this.fundsWithdrawn = this.offering.funds_invested;
		this.fundsUnavailable = this.offering.funds_unavailable;

		this.widgets = [
			{
				label: 'Funds Disbursable',
				icon: 'fas fa-hand-holding-usd',
				gradient: 'widget-0',
				data: this.getFormattedCurrency(this.fundsDisbursable),
				visible: true
			}, 
			{
				label: 'Funds In Escrow',
				icon: 'fas fa-bank',
				gradient: 'widget-1',
				data: this.getFormattedCurrency(this.fundsInEscrow),
				visible: true
			}, 
			{
				label: 'Funds Received',
				icon: 'fas fa-dollar-sign',	
				gradient: 'widget-3',
				data: this.getFormattedCurrency(this.fundsReceived),
				visible: true
			}
		];

	}

	getFormattedCurrency(value) {
		return this.$filter('currency')(value || '0', '$', 2);
	}

	validateWithdrawl(amount) {

		if (!this.isDisbursable) {
			return "Funds not disbursable at this time";
		}

		if (amount > this.fundsDisbursable) {
			return `Cannot withdrawl more than $${this.fundsDisbursable}`;
		}

	}

	async requestWithdrawl() {

		if (!this.withdrawlAmount) {
			return;
		}

		// Get the numeric value of the amount requested
		const amountString = this.withdrawlAmount.replace(/\D/g,'');
		const amount = parseFloat(amountString);
		this.processingRequest = true;
		this.withdrawlError = '';

		this.$log.debug('Withdrawl amount:', amount, typeof amount);

		const error = this.validateWithdrawl(amount);
		if (error) {
			this.withdrawlError = error;
			this.processingRequest = false;
			return;
		}

		// Create a new DisbursementRequest
		const request = new this.DisbursementRequest.class({
			withdrawlAmount: amountString,
			project: this.project
		});

		try {
			await request.save();
		} catch(e) {
			this.withdrawlError = 'Failed to submit request. Please contact support.';
			this.processingRequest = false;
			this.$timeout();
		}

		// Show message
		this.successMessage = "Request submitted successfully! You can track the progress below and will also receive email updates on the status."
		this.processingRequest = false;
		this.withdrawlAmount = '';

		// Reload list of disbursement requests
		this.disbursementRequests = await this.DisbursementRequest.listByProject(this.project);
		this.$timeout();

	}

}
