
/* injects from baggage-loader */

'use strict';

import ProjectsComponent from './projects.component';

const ProjectsPageModule = angular.module('projects-module', [
  'ui.router'
])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projects', {
        parent: 'root',
        url: '/projects?id',
        component: 'projects',
        title: 'Projects',
        resolve: {
          _projects: function(Projects, MediaSchema) {
            return Projects.list({ skip: 0, withCount: 1 });
          }, 
          // The total number of projects on visible on the database
          totalProjectCount: function (_projects) {
            return _projects.count;
          },
          // The current page of projects
          projects: function (_projects, MediaSchema) {
            const activeProjects = [];
            const concludedProjects = [];
            for (let project of _projects.results) {
              if (project.concluded || project.hasEnded()) {
                concludedProjects.push(project);
              } else {
                activeProjects.push(project);
              }
            }
            return [...activeProjects, ...concludedProjects];
          }
        }
      });
  })
  .run(function ($transitions) {
    $transitions.onStart({ to: 'projects' }, transition => {
      if (transition.params('to').id && transition.params('to').id.length === 10) {
        return transition.router.stateService.target('project', { id: transition.params('to').id });
      }
    });
  })
  .component('projects', new ProjectsComponent());

export default ProjectsPageModule;
