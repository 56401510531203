
/* injects from baggage-loader */

'use strict';

import ProjectManagerMediaComponent from './projectmanager.media.component';

const ProjectManagerMediaPageModule = angular.module('project-manager-media-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.media', {
        url: '/media',
        component: 'projectManagerMedia',
        title: 'Project Manager: Media',
        localTitle: 'Media',
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          media: function(project, MediaSchema) {
            let query = project.relation('gallery').query();
            query.ascending('position');
            return query.find()
          }
        }
      });
  })
  .component('projectManagerMedia', new ProjectManagerMediaComponent());

export default ProjectManagerMediaPageModule;
