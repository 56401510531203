
/* injects from baggage-loader */

'use strict';

export default class InvestmentListItemController {

	constructor($log, $interval, $scope, User) {
		'ngInject';

		this.$log = $log;
		this.$interval = $interval;
		this.$scope = $scope;
		this.User = User;

		this.isNew = false;
		this.editing = false;

	}

	$onInit() {

		if (this.isNew) {
			// Launch right into the editor
			this.editing = true;
		}

	}


	/**
	 * Returns true when the current user is the owner of the project
	 */
	isProjectOwner() {
		let currentUser = this.User.getCurrent();
		let ACL = this.project.getACL();
		if (currentUser && ACL) {
			return ACL.getWriteAccess(currentUser.id);
		}
	}


	/**
	 * Toggle the editing state of this info panel
	 */
	toggleEdit() {
		this.editing = !this.editing;

		// Start watching editing
		if (this.editing) {
			this.editingWatcher = this.$interval(() => {
				if (this.isEditingProject === false && this.editing) {
					this.toggleEdit();
				}
			}, 100);
		} else {
			this.$interval.cancel(this.editingWatcher);
		}

	}

	moveSectionUp() {
		this.$scope.$emit('projectSectionOrderChanged', {
			id: this.section.id,
			direction: 'up',
			index: this.index
		});
	}
	moveSectionDown() {
		this.$scope.$emit('projectSectionOrderChanged', {
			id: this.section.id,
			direction: 'down',
			index: this.index
		});
	}

	undoDelete() {

		// Add the section back to the project sections relation
		let relation = this.project.relation('sections');
		relation.add(this.section);

		// Update flags
		this.section.shouldDelete = false;

	}

	delete() {

		// Delete the section immediately if it has an empty title and body
		if (!this.section.title && !this.section.body) {
			this.$scope.$emit('removeProjectSection', this.section.id);
			return false;
		}

		// Remove the section from the project
		let relation = this.project.relation('sections');
		relation.remove(this.section);

		// Update flags
		this.section.shouldDelete = true;
		this.editing = false;

	}

}
