
/* injects from baggage-loader */

'use strict';

import ModalComponentComponent from './modalcomponent.component';
import './modalcomponent.scss';

const ModalComponentModule = angular.module('modal-component-module', []);

ModalComponentModule
    .component('modalComponent', new ModalComponentComponent());

export default ModalComponentModule;

