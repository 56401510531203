
/* injects from baggage-loader */

'use strict';

export default class OnboardingConfirmationController {

	constructor($log, $state, $location, $timeout, $stateParams, User, EDUCATIONAL_MATERIALS_LINK) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.$location = $location;
		this.$timeout = $timeout;
		this.User = User;

		this.isAnonymousUser = !User.isUserLoggedIn();
		this.hasPartialUser = !!localStorage.getItem('partialUser');
		this.isExistingUser = $stateParams.isExistingUser;
		this.showAccountSignup = this.isAnonymousUser && this.hasPartialUser && !this.isExistingUser;

		// Hold user login credentials
		this.user = {
			agreements: [],
			onboardingId: $stateParams.id
		};

		this.requiredFields = [
			'first_name',
			'last_name',
			'email',
			'password'
		];

		if (this.isAnonymousUser && this.hasPartialUser) {
			this.user = { ...this.user, ...JSON.parse(localStorage.getItem('partialUser')) };
		}
		// console.log('User', this.user);
		// console.log('isExistingUser:', this.isExistingUser);

		this.showPassword = false;
		this.creatingAccount = false;

		this.eduMaterials = EDUCATIONAL_MATERIALS_LINK;

		this.confirmationChecklist = [{
			checked: false,
			statement: 'I understand that I may only cancel my commitment to purchase up to 48 hours before the offering period expires.'
		}, {
			checked: false,
			statement: 'I understand that the securities being offered are restricted and may only be resold under certain circumstances.'
		}, {
			checked: false,
			statement: 'I understand that investing in securities involves risks and I should not invest unless I can bear the loss of risk, including a 100% loss.'
		}, {
			checked: false,
			statement: 'I agree to receive all documents electronically.'
		}, {
			checked: false,
			statement: `I have reviewed the <a target="_blank" href="${this.eduMaterials}" ng-click="$event.stopPropagation()>educational materials</a>.`
		}];

		this.user.agreements = this.confirmationChecklist;

	}

	checkboxClicked(item, event) {
		if (event) {
			event.stopPropagation();
			if (event.target.tagName === 'A') return false;
		}
		item.checked = !item.checked;
		if (item.checked) {	
			item.acceptedAt = new Date();
		} else {
			item.acceptedAt = undefined;
		}
	}

	isFormReady() {
		for (let item of this.confirmationChecklist) {
			if (!item.checked) return false;
		}
		if (this.requiredFields.some(key => !this.user[key])) {
			return false;
		}
		return true;
	}

	$onInit() {

	}

	async signup() {

		// Reset error
		this.error = {};

		if (this.securityScreen) {
			this.error.message = 'Failed to create user, please refresh and try again';
			Sentry.captureMessage('Signup security checkbox tripped!');
			return;
		}

		if (!this.user.first_name) {
			this.error.message = 'Missing "first name"';
			return;
		}
		if (!this.user.last_name) {
			this.error.message = 'Missing "last name"';
			return;
		}

		if (!this.user.email) {
			this.error.message = 'Missing "email"';
			return;
		}

		if (!this.user.password) {
			this.error.message = 'Missing "password"';
			return;
		}

		if (this.user.password.length < 8) {
			this.error.message = '"Password" must be at least 8 characters in length';
			return;
		}

		this.creatingAccount = true;

		// Google ReCaptcha
		// try {
		// 	// Get token
		// 	let token = await this.recaptcha.execute({ action: 'signup' });
		// 	// Verify token
		// 	this.user.recaptcha_res = await this.Parse.Cloud.run('verifyReCaptcha', { token });
		// } catch (err) {
		// 	// Failed recaptcha
		// 	this.$log.error(err);
		// 	this.error.message = 'Failed Google ReCaptcha';
		// 	return false;
		// }

		// TODO: properly implement this if needed
		this.user.recaptcha_res = {};

		// this.user.agreements = this.confirmationChecklist;

		this.User.signup(this.user)
			.then(user => {
				// Remove the partial user data
				localStorage.removeItem('partialUser');

				// Take user to next page
				if (typeof this.referral !== 'undefined') {
					this.$location.path(this.referral).search({});
				} else {
					this.$state.go('account.home');
				}
			})
			.catch(err => {
				this.creatingAccount = false;
				if (err.code === 202) {
					err.message = "Account already exists for this email address."
				}
				this.error = err;
				this.$timeout();
			});

	}

}
