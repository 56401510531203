
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingInterviewController from './issuer-onboarding.interview.controller';
import IssuerOnboardingInterviewTemplate from './issuer-onboarding.interview.html';

export default class IssuerOnboardingInterviewComponent {
		constructor() {
				this.templateUrl = IssuerOnboardingInterviewTemplate;
				this.controller = IssuerOnboardingInterviewController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					onboarding: '<'
				};
		}
}
