
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Profiles', function (Parse, User, ProfileSchema) {

		// ==================================================
		// Methods
		// ==================================================

		let Profile = ProfileSchema;

		/**
		 * Retrieve a profile by ID
		 */
		this.get = id => {
			let query = new Parse.Query(Profile);
			// query.include('image');
			return query.get(id);
		}


		/**
		 * Retrieve the current users profile
		 */
		this.fetchOwn = () => {
			let currentUser = User.getCurrent();
			return this.get(currentUser.get('profile').id);
		};


		/**
		 * Return the current users profile id
		 */
		this.getCurrent = () => User.getCurrent().get('profile');
		
	});
}

