
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingBackgroundCheckComponent from './issuer-onboarding.background-check.component';

const IssuerOnboardingBackgroundCheckPageModule = angular.module('issuer-onboarding-background-check-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('issuer-onboarding.background-check', {
        url: '/background-check',
        title: 'Issuer Onboarding: Background Check',
        localTitle: 'Background Check',
        component: 'issuerOnboardingBackgroundCheck',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('issuerOnboardingBackgroundCheck', new IssuerOnboardingBackgroundCheckComponent());

export default IssuerOnboardingBackgroundCheckPageModule;
