
/* injects from baggage-loader */

'use strict';

import ReturnsCalculatorComponent from './returns-calculator.component';
import './returns-calculator.scss';

const ReturnsCalculatorModule = angular.module('returns-calculator-module', []);

ReturnsCalculatorModule
.config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('returns-calculator', {
        url: '/test/returns-calculator',
        component: 'returnsCalculator',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
    .component('returnsCalculator', new ReturnsCalculatorComponent());

export default ReturnsCalculatorModule;

