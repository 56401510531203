
/* injects from baggage-loader */

'use strict';

import RecoverPasswordController from './recoverpassword.controller';
import RecoverPasswordTemplate from './recoverpassword.html';

export default class RecoverPasswordComponent {
	constructor() {
		this.templateUrl = RecoverPasswordTemplate;
		this.controller = RecoverPasswordController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		// this.bindings = { };
	}
}
