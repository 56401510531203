
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ImagePreloader', function () {

		this.simple = urls => {
			let images = [];
			for (let i = 0; i < urls.length; i++) {
				images[i] = new Image();
				images[i].src = urls[i];
			}
			return images;
		}

		// this.observable = urls => rx.Observable.create(observer => {
		// 	let images = [];
		// 	let completed = 0;
		// 	for (let i = 0; i < urls.length; i++) {
		// 		images[i] = new Image();
		// 		images[i].src = urls[i];
		// 		images[i].onload = () => {
		// 			// image  has been loaded
		// 			observer.onNext(urls[i]);
		// 			completed += 1;
		// 			if (completed === urls.length) {
		// 				observer.onCompleted();
		// 			}
		// 		};
		// 	}
		// })

	});
}

