
/* injects from baggage-loader */

'use strict';

export default class ImageGalleryModalController {

	constructor($log, $window) {
		'ngInject';

		this.$log = $log;

		this.currentImage = '';
		this.index = 0;

		// this.thumbnailSliderInitial = 28; // 8
		this.thumbnailSliderInitial = -18;
		this.thumbnailSliderTranform = this.thumbnailSliderInitial;
		this.thumbnailSliderIncrement = 80;

		// TODO: determine step counts for smaller screen sizes
		// Next size down = 46 then 56
		//
		// Must grab screen size from service

		// $log.debug($window.innerWidth)
		if ($window.innerWidth < 1440) {
			this.thumbnailSliderInitial = -14;
			this.thumbnailSliderIncrement = 56;
		}

	}

	$onInit() {
		this.images = this.resolve.images;
		this.index = this.resolve.index || 0;
		this.selectSlide(this.index);
	}

	next(e) {
		e.preventDefault();
		this.selectSlide(this.index + 1);
	}

	previous(e) {
		e.preventDefault();
		this.selectSlide(this.index - 1);
	}

	selectSlide(index) {
		// // Use with: this.thumbnailSliderInitial = 28;
		// if (index > 0 && index < this.images.length - 1) {
		// 	// Calculate position normal case
		// 	this.$log.debug('Case 1: default')
		// 	this.thumbnailSliderTranform = this.thumbnailSliderInitial - (this.thumbnailSliderIncrement * (index - 1));
		// } else if (index === 0 || index === this.images.length) {
		// 	// Reset the slider to initial position
		// 	this.thumbnailSliderTranform = this.thumbnailSliderInitial;
		// 	index = 0;
		// 	this.$log.debug('Case 2: start/reset')
		// } else if (index < 0) {
		// 	// Wrap around to last image		
		// 	this.$log.debug('Case 3: wrap')
		// 	return this.selectSlide(this.images.length - 1);
		// } else if (index === this.images.length - 1) {
		// 	// Last image
		// 	this.$log.debug('Case 4: last')
		// 	this.thumbnailSliderTranform = this.thumbnailSliderInitial - (this.thumbnailSliderIncrement * (index - 2));
		// }

		if (this.images.length === 1 && index !== 0) {
			return;
		}

		// // Use with: this.thumbnailSliderInitial = -18;
		if (index > 1 && index < this.images.length - 1) {
			// this.$log.debug('Case 1: default')
			this.thumbnailSliderTranform = this.thumbnailSliderInitial - (this.thumbnailSliderIncrement * (index - 1) - (this.thumbnailSliderIncrement / 2));
		} else if (index === 1) {
			// this.$log.debug('Case 2: start buffer')
			this.thumbnailSliderTranform = this.thumbnailSliderInitial - (this.thumbnailSliderIncrement * (index - 1));
		} else if (index === 0 || index === this.images.length) {
			// Reset the slider to initial position
			this.thumbnailSliderTranform = this.thumbnailSliderInitial;
			index = 0;
			// this.$log.debug('Case 3: start/reset')
		} else if (index === this.images.length - 1) {
			// Last image
			// this.$log.debug('Case 4: last')
			this.thumbnailSliderTranform = this.thumbnailSliderInitial - (this.thumbnailSliderIncrement * (index - 3) + this.thumbnailSliderIncrement / 2);
		} else {
			// Wrap around to last image		
			// this.$log.debug('Case 5: wrap')
			return this.selectSlide(this.images.length - 1);
		}

		this.index = index;
		this.currentImage = this.images[this.index];
	}

	cancel() {
		this.dismiss({ $value: 'ok' });
	}

}
