
/* injects from baggage-loader */

'use strict';

export default class ProjectPledgeRewardController {

	constructor($log, $interval, $scope) {
		'ngInject';

		this.$log = $log;
		this.$interval = $interval;
		this.$scope = $scope;

		this.pledge_button_text = 'Select this reward';
		this.pledge_button_closed_text = 'Raise concluded';
		this.pledge_button_out_of_stock_text = 'Reward no longer available';

	}

	$onInit() {

		// this.$log.debug(this.reward);

		if (this.reward.isNew()) {

			// Launch right into the editor
			this.isEditing = true;

			// Save the new section and add it to the projects "sections" relation
			this.reward.save()
				.then(reward => {
					// Assign to project reward relation
					let relation = this.project.relation('pledge_rewards');
					relation.add(reward);
				});
		}
	}

	/**
	 * Toggle the editing state of this info panel
	 */
	toggleEdit() {
		this.isEditing = !this.isEditing;

		// Start watching "isEditing"
		if (this.isEditing) {

			// Setup watcher for global project editing
			this.editingWatcher = this.$interval(() => {
				if (this.isEditingProject === false && this.isEditing) {
					this.toggleEdit();
				}
			}, 100);

		} else {

			// Fix reward cost value
			if (typeof this.reward.amount === 'string') {
				this.reward.amount = parseInt(this.reward.amount.replace(/\D/g, ''));
			}

			// Cancel the watcher interval
			this.$interval.cancel(this.editingWatcher);
		}

	}

	finishEditingReward() {
		if (typeof this.reward.amount === 'string') {
			this.reward.amount = parseInt(this.reward.amount.replace(/\D/g, ''));
		}
		this.isEditing = false;
	}

	undoDelete() {

		// Add the section back to the project sections relation
		let relation = this.project.relation('pledge_rewards');
		relation.add(this.reward);

		// Update flags
		this.reward.shouldDelete = false;

	}

	delete() {

		// Delete the section immediately if it has an empty title and body
		if (!this.reward.title) {
			this.$scope.$emit('removePledgeReward', this.reward.id);
			return false;
		}

		// Remove the reward from the project
		let relation = this.project.relation('pledge_rewards');
		relation.remove(this.reward);

		// Update flags
		this.reward.shouldDelete = true;
		this.isEditing = false;


	}

}
