
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Events', function (Parse) {

		// ==================================================
		// Load object schema
		// ==================================================
		let ProjectEvent = Parse.Object.extend('Event_Heap');
		
		this.logView = function(project) {
			return (new ProjectEvent({ type: 'view',	project })).save();
		}
		
		this.logAction = function(project, type, options = {}) {
			return (new ProjectEvent({ type, project, parent: options.parent })).save();
		}

	});
}

