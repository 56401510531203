
/* injects from baggage-loader */

'use strict';

import ProjectsController from './projects.controller';
import ProjectsTemplate from './projects.html';

export default class ProjectsComponent {
	constructor() {
		this.templateUrl = ProjectsTemplate;
		this.controller = ProjectsController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			totalProjectCount: '<',
			projects: '<'	
		};
	}
}
