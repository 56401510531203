
/* injects from baggage-loader */

'use strict';

import AccountHomeComponent from './account.home.component';

const AccountHomePageModule = angular.module('account-home-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('account.home', {
        url: '/home?action',
        title: 'Account Home',
        component: 'accountHome',
        reloadOnSearch: false,
        // Requires valid login to visit
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          profile: function (Profiles) {
            return Profiles.fetchOwn();
          },
          stats: function (Investments, ProjectSchema, MediaSchema) {
            return Investments.getStats();
          },
          onboarding: function(Onboarding, User) {
            return Onboarding.list();
          }
        }
      });
  })
  .component('accountHome', new AccountHomeComponent());

export default AccountHomePageModule;
