
/* injects from baggage-loader */

'use strict';

import NorthCapitalCheckMailingInstructionsComponent from './north-capital-check-mailing-instructions.component';
import './north-capital-check-mailing-instructions.scss';

const NorthCapitalCheckMailingInstructionsModule = angular.module('north-capital-check-mailing-instructions-module', []);

NorthCapitalCheckMailingInstructionsModule
    .component('northCapitalCheckMailingInstructions', new NorthCapitalCheckMailingInstructionsComponent());

export default NorthCapitalCheckMailingInstructionsModule;

