
/* injects from baggage-loader */

'use strict';

import ProjectManagerComponent from './projectmanager.component';
import './projectmanager.scss';

// Child states
import HomeModule from './home/projectmanager.home.module';
import EditorModule from './editor/projectmanager.editor.module';
import DocumentsModule from './documents/projectmanager.documents.module';
import MediaModule from './media/projectmanager.media.module';
import CommentsModule from './comments/projectmanager.comments.module';
import InvestmentsModule from './investments/projectmanager.investments.module';
import AnalyticsModule from './analytics/projectmanager.analytics.module';
// import MessagingModule from './messaging/projectmanager.messaging.module';
import InvestmentModule from './investment/projectmanager.investment.module';
import AdminModule from './admin/projectmanager.admin.module';
import IssuerModule from './issuer/projectmanager.issuer.module';
import ActivityModule from './activity/projectmanager.activity.module';
import DisbursementModule from './disbursement/projectmanager.disbursement.module';

const ProjectManagerPageModule = angular.module('manage-project-module', [
    'ui.router',
    HomeModule.name,
    EditorModule.name,
    DocumentsModule.name,
    MediaModule.name,
    CommentsModule.name,
    InvestmentsModule.name,
    AnalyticsModule.name,
    // MessagingModule.name,
    InvestmentModule.name,
    AdminModule.name,
    IssuerModule.name,
    ActivityModule.name,
    DisbursementModule.name,
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager', {
        parent: 'root',
        url: '/project-manager/{id}',
        component: 'projectManager',
        // Abstract parent of children states, cannot be visited directly
        abstract: true,
        // Restrict to authenticated users
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          project: function (Projects, $transition$, $q, ProjectSchema, MediaSchema, ProfileSchema) {
            let id = $transition$.params('to').id;
            if (id === 'newproject') {
              return $q.resolve(new ProjectSchema({
                type: '506c',
                body: [{
                  title: 'About the project'
                }],
                key_points: []
              }));
            } else {
              return Projects.get(id);
            }
          },
          unreadCommentsCount: function(project) {
            if (project.isNew()) {
              return 0;
            } else {
              return project.relation('comments').query().equalTo('unread', true).count()
                .catch(function(err) {
                  return 0;
                })
            }
          },
          permissions: function(Parse, project) {
            if (!project.id || project.isNew()) {
              return {
                permissions: [
                  "overview",
                  "editor",
                  "issuer",
                  "documents",
                  "media",
                  "comments",
                  "investments"
                ]
              }
            }
            return Parse.Cloud.run('getUserProjectPermissions', {
              projectId: project.id,
            })
              .then(function(result) {
                if (!result || !result.permissions || !result.permissions.length) {
                  throw `You are not authorized to view this page`
                }
                return result.permissions;
              })
          }
        }
      });
  })
  .run(function ($transitions, $rootScope) {
    // Hide the footer
    $transitions.onSuccess({ to: 'projectmanager.*' }, () => $rootScope.appState.hideFooter = true);
  })
  .component('projectManager', new ProjectManagerComponent());

export default ProjectManagerPageModule;
