
/* injects from baggage-loader */

'use strict';

import DocumentUploadController from './documentupload.controller';
import DocumentUploadTemplate from './documentupload.html';

export default class DocumentUploadComponent {
		constructor() {
				this.templateUrl = DocumentUploadTemplate;
				this.controller = DocumentUploadController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
