
/* injects from baggage-loader */

'use strict';

import HomeController from './home.controller';
import HomeTemplate from './home.html';

export default class HomeComponent {
		constructor() {
				this.templateUrl = HomeTemplate;
				this.controller = HomeController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					projects: '='
				};
		}
}
