
/* injects from baggage-loader */

'use strict';

export default class IssuerOnboardingDocumentsController {

	constructor($log, $state, $uibModal, $timeout, $rootScope, Parse, OnboardingDocumentSchema) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$uibModal = $uibModal;
		this.$timeout = $timeout;
		this.$rootScope = $rootScope;
		this.Parse = Parse;
		this.OnboardingDocumentSchema = OnboardingDocumentSchema;

		this.documents = [];

		this.prevStep = 'issuer-onboarding.details';
		this.nextStep = 'issuer-onboarding.records';

	}

	$onInit() {
		// this.$log.debug(this.documents)
	}



	/**
	 * Method called when project documents are dropped or selected
	 */
	async processFiles() {

		let ops = [];

		let docRelation = this.onboarding.relation("documents");

		while (this.pendingFiles.length > 0) {

			// Get a file
			let file = this.pendingFiles.shift();

			// Sanitize the filename
			let filename = file.name.split('.');
			let ext = filename.pop();
			filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
			filename = `${filename}.${ext}`;

			// Create the Parse.File
			let pFile = new this.Parse.File(filename, file, file.type);

			// Push to the operations array
			ops.push({
				item: pFile,
				action: 'save',
				message: 'Saving file:' + filename,
				retry: 3
			});

			let document = new this.OnboardingDocumentSchema({
				file: pFile,
				name: file.name,
				original_name: file.name,
				size: file.size,
				type: file.type,
				onboarding: this.onboarding
			})

			document.uploading = true;

			// Push the document to the documents array
			this.documents.push(document);

			// Push to the operations array
			ops.push({
				item: document,
				action: 'save',
				message: 'Saving document:' + filename,
				post: () => {

					// Add the document to project relation if new
					if (docRelation) {
						docRelation.add(document);
					}

					// Uploading complete
					document.uploading = false;
					document.uploaded = true;
					this.$timeout(() => { /* empty */ }, 100);

				},
				error: () => {
					// Uploading failed
					document.uploading = false;
					document.failed = true;
					this.$log.error('Failed to upload file:', document.name)
					this.$timeout(() => { /* empty */ }, 100);
				}
			});

		}

		// Process pending operations
		for (let op of ops) {
			this.$log.debug(op.message);
			try {
				await op.item[op.action]();
				if (typeof op.post === 'function') {
					op.post();
				}
			} catch (err) {
				this.$log.error(err, err.response);
				if (err.response && err.response.code === 130) {
					// Try it again after a brief wait
					if (op.retry > 0) {
						// Decrement the retry value by 1
						op.retry = op.retry - 1;
						// Push the op back into the ops array
						// TODO: consider "shifting" ops out of the array so pushing them back in on a retry
						//			 is cleaner
						ops.push(op);
					}
				} else {
					op.error();
				}
			}
		}

		// Update the onboarding form
		await this.onboarding.save();
		this.$log.debug('form saved');

	}




	async openEditDocumentModal(doc) {
		this.$log.debug('Edit document:', doc.id);

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'editDocumentModal',
			resolve: {
				context: function () {
					return {
						document: doc
					};
				}
			}
		});

		modalInstance.result.then(async doc_edits => {

			this.$log.debug(doc_edits);

			doc.isUpdating = true;
			// Save the document
			await doc.save(doc_edits);
			this.$timeout(() => {
				doc.isUpdating = false;
			}, 500);


		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async removeDocument(doc) {
		this.$log.debug('Delete document:', doc.id);

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'modalComponent',
			resolve: {
				context: function () {
					return {
						title: 'Delete document?',
						body: 'Are you sure you want to delete this document?',
						list: [doc.name],
						confirmBtnText: 'Yes, Delete',
						cancelBtnText: 'No, Cancel'
					};
				}
			}
		});


		modalInstance.result.then(async () => {

			// Find and remove document
			this.documents.splice(this.documents.indexOf(doc), 1);

			// doc.isUpdating = true;
			await doc.destroy();
			this.$timeout(() => {
				this.$log.debug('Document removed:', doc.id);
				// doc.isUpdating = false;
			}, 500);

		}, () => this.$log.info('modal-component dismissed at: ' + new Date()));

	}

	async openDocumentUploadModal() {

		let modalInstance = this.$uibModal.open({
			animation: true,
			size: 'xlg',
			backdrop: 'static',
			component: 'documentUploadModal',
			resolve: {
				context: () => ({
					project: this.project,
					document_scope: 'project'
				})
			}
		});

		// Lock scroll
		// this.$rootScope.appState.scrollLock = true;

		modalInstance.result.then(async res => {

			// Concatenate new with existing documents
			this.documents = [...this.documents, ...res.documents];

			// Update the project
			this.project.save();

			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;

		}, () => {
			// Unlock scroll
			// this.$rootScope.appState.scrollLock = false;
			this.$log.info('modal-component dismissed at: ' + new Date())
		});

	}

	saveForm() {
		this.$state.go(this.nextStep, { id: this.onboarding.id })
	}

}
