
/* injects from baggage-loader */

'use strict';

import DonationConfirmationComponent from './donation-confirmation.component';

const DonationConfirmationPageModule = angular.module('donation-confirmation-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('donation-confirmation', {
        url: '/donation-confirmation',
        component: 'donationConfirmation',
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('donationConfirmation', new DonationConfirmationComponent());

export default DonationConfirmationPageModule;
