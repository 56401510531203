
/* injects from baggage-loader */

'use strict';

export default class EditDocumentModalController {

	constructor($log) {
		'ngInject';

		this.$log = $log;
		this.modalTitle = 'Edit document';

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;
		this.document = this.context.document.toJSON();

		// Filter keys needed to edit
		this.document = Object.keys(this.document).reduce((obj, key) => {
			if (['name'].includes(key)) {
				obj[key] = this.document[key];
			}
			return obj;
		}, {})
		// this.$log.debug(this.document);

		if (this.context.class === 'Media') {
			this.modalTitle = 'Edit Media';
		}

	}

	setProtected(value) {
		this.document.protected = value;
	}

	ok() {

		this.error_msg = '';

		// Trim values
		['name'].forEach(v => {
			this.document[v] = this.document[v].trim();
		})

		if (!this.document.name) {
			this.error_msg = 'Document name is required';
			return false;
		}

		// Return the document
		this.close({
			$value: this.document
		});

	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
