
/* injects from baggage-loader */

'use strict';

export default class IssueProjectTermsUpdateModalController {

	constructor($log, Parse) {
		'ngInject';

		this.$log = $log;
		this.Parse = Parse;

		this.changes = '';
		this.link = '';

		this.error_msg = '';
		this.processing = false;
		this.promptAction = false;

	}

	$onInit() {

		this.project = this.resolve.context.project;

	}

	async submit() {

		if (!this.promptAction) {
			this.promptAction = true;
			return false;
		}

		this.error_msg = '';
		this.processing = true;

		try {

			let ProjectTermsUpdate = this.Parse.Object.extend('Project_Updates_Terms');
			let termsUpdate = new ProjectTermsUpdate({
				changes: this.changes,
				project: this.project,
				link: this.link
			})
			await termsUpdate.save()

			this.close({
				$value: {
					termsUpdate
				}
			});

		} catch (err) {
			this.processing = false;
			this.error_msg = err;
		}

	}

	close() {
		this.dismiss({ $value: 'cancel' });
	}

}
