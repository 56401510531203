
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('InvestmentSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Investment = Parse.Object.extend('Investments');

		// Define 'Investment' scheme
		let schema = [
			'user',
			'project',
			'amount',
			'status',
			'cleared',
			'fund_america_id',
			'payment_method',
			'payment_reference',
			'fa_data',
			'clearing_failures',
			'equity_share_count',
			'equity_share_price',
			'accreditation',
			'investor_name',
			'investor_type',
			// 'chat',
			'reconfirmation_required',
			'reconfirmations',
			'incomplete',
			'cancelledAt',
			'cancelled',
			'offering_id',
			'share_price',
			'shares',
			'reference_number',
			'fund_status',
			'trx_id',
			'trx_status',
			'trx_voided',
			'trx_error',
			'trade_id',
			'trade_status',
			'complete',
			'is_accredited',
			'issues',
			'documentsSignedAt',
			'document_status',
			'alternateFundingCompletedAt',
			'total_fees',
			'total_amount'
		];

		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Investment.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});
    
    return Investment;
		
	});
}