
/* injects from baggage-loader */

'use strict';

import $ from 'jquery';

export default function (app) {
	app.directive('ssnInput', function ($log) {
		// return {
		// 	require: 'ngModel',
		// 	link: function (scop, elem, attr, ngModel) {
		// 		$(elem).mask("xxx-xx-xxx");
		// 		let temp;
		// 		let regxa = /^(\d{3}-?\d{2}-?\d{4})$/;
		// 		$(elem).focusin(function () {
		// 			$(elem).val(temp);
		// 		});
		// 		$(elem).on('blur', function () {
		// 			temp = $(elem).val();
		// 			if (regxa.test($(elem).val())) {
		// 				$(elem).val("xxx-xx" + temp.slice(6));
		// 			}
		// 		});
		// 	}
		// }
		function makeSsn(value) {
			let result = value;

			let ssn = value ? value.toString() : '';
			if (ssn.length > 3) {
				result = ssn.substr(0, 3) + '-';
				if (ssn.length > 5) {
					result += ssn.substr(3, 2) + '-';
					result += ssn.substr(5, 4);
				}
				else {
					result += ssn.substr(3);
				}
			}

			return result;
		}

		return {
			restrict: 'A',
			require: 'ngModel',
			link: function (scope, element, attrs, ngModel) {
				ngModel.$formatters.push(function (value) {
					return makeSsn(value);
				});

				// clean output as digits
				ngModel.$parsers.push(function (value) {
					let cursorPosition = element[0].selectionStart;
					let oldLength = value.toString().length;
					let nonDigits = /[^0-9]/g;
					let intValue = value.replace(nonDigits, '');
					if (intValue.length > 9) {
						intValue = intValue.substr(0, 9);
					}
					let newValue = makeSsn(intValue);
					ngModel.$setViewValue(newValue);
					ngModel.$render();
					element[0].setSelectionRange(cursorPosition + newValue.length - oldLength, cursorPosition + newValue.length - oldLength);
					return intValue;
				});

				// Hide the text by default
				$(element).attr('style', 'text-security:disc; -webkit-text-security:disc;');

				// Reveal the text on focus
				element.bind('focus', function (e) {
					$(element).attr('style', '');
				});

				// Hide the text on lost focus
				element.bind('blur', function (e) {
					$(element).attr('style', 'text-security:disc; -webkit-text-security:disc;');
				});

			}
		};
	});
}