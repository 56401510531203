
/* injects from baggage-loader */

'use strict';

export default class AccountSettingsPasswordController {

	constructor($log, $state, $document, $timeout, User, Parse, Alerts) {
		'ngInject';

		this.heading = 'Change Password';

		this.$log = $log;
		this.$state = $state;
		this.$document = $document;
		this.$timeout = $timeout;
		this.User = User;
		this.Parse = Parse;
		this.Alerts = Alerts;

		// Title displayed on the simple form template
		this.formTitle = 'Change password';

		this.password = {};
		this.disableSave = true;

	}

	$onInit() {

		// Clear local alerts
		this.Alerts.clearLocal();

	}

	$onDestroy() {

		// Clear local alerts
		this.Alerts.clearLocal();

	}


	/**
	 * Function called each time the password inputs are modified.
	 * Checks for the following cases to evaluate as true
	 *  - All fields are filled out
	 */
	checkPasswordFields() {
		if (this.password.current && this.password.new && this.password.confirm) {
			this.disableSave = false;
		} else {
			this.disableSave = true;
		}
	}


	/**
	 * Calls the cloudcode function to save the users new password
	 */
	updatePassword() {

		// Clear error message
		this.Alerts.clearLocal();

		// Check that new passwords are matching
		if (this.password.new !== this.password.confirm) {
			this.Alerts.setLocal({
				type: 'danger',
				msg: 'New passwords do not match.'
			});
			this.enableSaveButton = false;
			this.$document.scrollTopAnimated(0);
			return false;
		}

		// Run cloud code function to save new password
		this.Parse.Cloud.run('changePassword', {
			p: this.password.current,
			np: this.password.new
		}).then(() => {

			// Password updated successfully
			this.Alerts.setLocal({
				type: 'success',
				msg: 'Password updated successfully'
			});

			this.$document.scrollTopAnimated(0);

			// Reauthenticate the user with new password
			this.User.authenticate({
				email: this.user.getUsername(),
				password: this.password.new
			})
				.then(() => {
					/* empty */
				})
				.catch(err => {
					this.$log.error(err);
					// Something went wrong, logout user to manaully reauthenticate
					this.$state.go('logout');
				})

			// Clear fields
			this.password = {
				current: '', new: '', confirm: ''
			};
			this.$timeout();

		})
			.catch(err => {

				// Something went wrong
				this.$log.error(err);

				if (err.code === 101) {
					this.Alerts.setLocal({
						type: 'danger',
						msg: 'Failed to update password, the current password was entered incorrectly.'
					});
				} else {
					this.Alerts.setLocal({
						type: 'danger',
						msg: 'Failed to update password! Please contact support if the problem persists.'
					});
				}

				this.$document.scrollTopAnimated(0);

			});

	}

}
