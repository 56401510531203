
/* injects from baggage-loader */

'use strict';

import AccountSettingsProfileController from './account.settings.profile.controller';
import AccountSettingsProfileTemplate from './account.settings.profile.html';

export default class AccountSettingsProfileComponent {
		constructor() {
				this.templateUrl = AccountSettingsProfileTemplate;
				this.controller = AccountSettingsProfileController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					profile: '<'
				};
		}
}
