
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Onboarding', function (Parse, OnboardingSchema) {

		// Load object schema
		const Onboarding = OnboardingSchema;

		/**
		 * Retrieve a Onboarding application by ID
		 */
		this.get = (id, options = {}) => {
			let query = new Parse.Query(Onboarding);
			// query.include(['header_media', 'stats', 'sponsor']);
			if (typeof options.include !== 'undefined') {
				query.include(options.include);
			}
			return query.get(id);
		}


		/**
		 * Retrieve the list of projects
		 */
		this.list = ({ withCount = false, skip = 0, user } = {}) => {
			let query = new Parse.Query(Onboarding);
			// query.select([
			// 	'title',
			// 	'type',
			// 	'description',
			// 	'location',
			// 	'end_date',
			// 	'header_media.src',
			// 	'stats.funds_raised',
			// 	'stats.investor_count'
			// ])
			if (skip) {
				query.skip(skip);
			}
			if (withCount) {
				query.withCount(true);
			}
			if (user) {
				query.equalTo('creator', user)
			}
			// query.include(['header_media', 'stats']);
			return query.find();
		};
		
	});
}

