
/* injects from baggage-loader */

'use strict';

import UpdatePaymentMethodModalComponentController from './updatepaymentmethodmodalcomponent.controller';
import UpdatePaymentMethodModalComponentTemplate from './updatepaymentmethodmodalcomponent.html';

export default class UpdatePaymentMethodModalComponentComponent {
		constructor() {
				this.templateUrl = UpdatePaymentMethodModalComponentTemplate;
				this.controller = UpdatePaymentMethodModalComponentController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
