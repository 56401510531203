
/* injects from baggage-loader */

'use strict';

import SignupComponent from './signup.component';

const SignupPageModule = angular.module('signup-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('signup', {
        url: '/account/signup?ref',
        component: 'signup',
        title: 'Sign Up',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          // Prevent page load until google reCaptcha is initialized
          initRecaptcha: function(gRecaptcha, GOOGLE_RECAPTCHA_CLIENT_KEY) {
            return gRecaptcha.initialize({ key: GOOGLE_RECAPTCHA_CLIENT_KEY })
          }
        }
      });
  })
  .run(function ($transitions, $rootScope, $location, User) {

    // Handle entrance to signup page when user is already logged in
    $transitions.onEnter({ to: 'signup' }, transition => {

      if (User.isUserLoggedIn() === true) {
        $location.path('/account/home');
        return transition.router.stateService.target('account.home');
      }

    });

    $transitions.onSuccess({ to: 'signup' }, transition => {

      // Hide the header
      $rootScope.appState.hideHeader = true;

      // Hide the footer
      $rootScope.appState.hideFooter = true;

    });

  })
  .component('signup', new SignupComponent());

export default SignupPageModule;
