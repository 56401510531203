
/* injects from baggage-loader */

'use strict';

export default class RecoverPasswordController {

	constructor($log, User) {
		'ngInject';

		this.$log = $log;
		this.User = User;

		this.email = '';
		this.request_sending = false;
		this.request_sent = false;

	}

	$onInit() {

	}

	requestPasswordReset() {

		// Verify email address fits regex model

		this.request_sending = true;
		this.User.resetPassword(this.email)
			.then(res => {
				this.$log.debug(res);

				// Show the user a confirmation and instructions to check their email for a link
				this.request_sent = true;

			});

	}

}
