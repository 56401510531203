
/* injects from baggage-loader */

'use strict';

import ProjectManagerAnalyticsController from './projectmanager.analytics.controller';
import ProjectManagerAnalyticsTemplate from './projectmanager.analytics.html';

export default class ProjectManagerAnalyticsComponent {
		constructor() {
				this.templateUrl = ProjectManagerAnalyticsTemplate;
				this.controller = ProjectManagerAnalyticsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
