
/* injects from baggage-loader */

'use strict';

export default class AccountHomeController {

	constructor($log, $filter, $rootScope, $timeout, $state, $stateParams, Parse, User, Snackbar) {
		'ngInject';

		this.$log = $log;
		this.$filter = $filter;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.$state = $state;
		this.$stateParams = $stateParams;
		this.Parse = Parse;
		this.userService = User;
		this.snackbar = Snackbar;

		this.projects = [];

		// Action landings
		this.emailVerifiedSuccessfully = false;

	}

	$onInit() {

		// this.$log.debug(this.stats);
		this.user = this.userService.getCurrent()
		// this.$log.debug('user', this.user);
		// this.$log.debug('profile', this.profile);
		// this.$log.debug('stats', this.stats);
		this.$log.debug('onboarding', this.onboarding);

		this.$rootScope.$emit('updateAccountHeading', {
			title: '',
			subtitle: 'Welcome to your account home',
			showGreeting: true,
			name: this.profile.first_name
		});

		this.widgets = [{
			label: `Investment${this.stats.count === 1 ? '' : 's'} Made`,
			icon: 'far fa-handshake',
			gradient: 'widget-0',
			data: this.stats.count,
			visible: true
		}, {
			label: 'Total Dollars invested',
			icon: 'fas fa-dollar-sign',	// 'fa-bank'
			gradient: 'widget-1',
			data: this.$filter('currency')(this.stats.dollar_amount, '$', 2),
			visible: true
		}, {
			label: 'Total Dollars pending',
			icon: 'fas fa-dollar-sign',	// 'fa-bank'
			gradient: 'widget-2',
			data: this.$filter('currency')(this.stats.pending_amount, '$', 2),
			visible: this.stats.pending_amount
		}];

		// Set projects from stats
		this.projects = this.stats.projects;

		// Handle incoming actions
		if (this.$stateParams.action) {
			let action = this.$stateParams.action;
			if (action === 'verifyEmailSuccess') {
				this.emailVerifiedSuccessfully = true;
				this.$state.go('.', { action: null }, {
					notify: false
				});
			}
		}

	}

	resendVerficationEmail() {
		this.verificationEmailSent = true;
		this.userService.resendVerficationEmail();
	}


}
