
/* injects from baggage-loader */

'use strict';

export default class EmailConfirmedController {

	constructor($log, $stateParams) {
		'ngInject';

		this.$log = $log;
		// $log.debug($stateParams)

	}

	$onInit() {

	}

}
