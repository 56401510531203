
/* injects from baggage-loader */

'use strict';

export default class ProjectManagerIssuerController {

	constructor($log, $timeout, $state, $uibModal, Snackbar, Navigation, Parse) {
		'ngInject';

		this.$log = $log;
		this.$timeout = $timeout;
		this.$state = $state;
		this.snackbar = Snackbar;
		this.modalService = $uibModal;
		this.navigation = Navigation;
		this.Parse = Parse;

		this.savingData = false;
		this.uploadingImage = false;
		this.pendingFile = null;
		this.invalidFile = null;

		this.hasSponsorImage = false;
		this.sponsorImage = `url(https://common-owner-content.nyc3.digitaloceanspaces.com/common/user_placeholder.png)`;
		this.updatedImage = null;

	}

	$onInit() {

		// Setup a navigation guard on the sponsor.dirty() function
		this.navigation.setGuard({
			message: 'Are you sure you want to abandon these changes?',
			fn: () => this.issuer.dirty()
		});

		// Set sponsor image 
		if (this.issuer.image) {
			this.hasSponsorImage = true;
			this.sponsorImage = `url(${this.issuer.image.url()})`;
		}

	}

	$onDestroy() {

		// Release the navigation guard
		this.navigation.releaseGuard();

		// Discard cahnges
		this.discardChanges();

	}

	async uploadImage() {


		if (this.invalidFile) {
			this.invalidFile = null;
			this.snackbar.create('Cannot upload file', 3000, ['bg-danger'])
			return false;
		}

		this.uploadingImage = true;
		let file = this.pendingFile;

		// Sanitize the filename
		let filename = file.name.split('.');
		let ext = filename.pop();
		filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
		filename = `${filename}.${ext}`;

		// Create the Parse.File
		let pFile = new this.Parse.File(filename, file, file.type);
		await pFile.save();

		this.issuer.set('image', pFile);
		await this.issuer.save();

		// Generate immediate preview
		let reader = new FileReader();
		reader.addEventListener('load', () => {
			this.sponsorImage = `url(${reader.result})`;
			this.uploadingImage = false;
			this.$timeout();
		}, false);
		reader.readAsDataURL(file);

		// Update sponsor image
		// this.sponsorImage = `url(${this.issuer.image.url()})`;
		// this.uploadingImage = false;
		// this.$timeout();
		this.$log.debug('File uploaded', filename);

	}

	/**
	 * Stage the updated profile image, do not save just yet
	 */
	async stageImage() {

		if (this.invalidFile) {
			this.invalidFile = null;
			this.snackbar.create('Cannot process file', 3000, ['bg-danger'])
			return false;
		}

		let file = this.pendingFile;

		if (file) {

			// Sanitize the filename
			let filename = file.name.split('.');
			let ext = filename.pop();
			filename = filename.join('_').replace(/[^a-z0-9]/gi, '_').toLowerCase();
			filename = `${filename}.${ext}`;

			// Create the Parse.File
			this.updatedImage = new this.Parse.File(filename, file, file.type);
			this.issuer.set('image', this.updatedImage);

			// Generate immediate preview
			let reader = new FileReader();
			reader.addEventListener('load', () => {
				this.sponsorImage = `url(${reader.result})`;
				this.uploadingImage = false;
				this.$timeout();
			}, false);
			reader.readAsDataURL(file);

		}

	}

	isFormDirty() {
		return this.issuer.dirty();
	}


	/**
	 * Discards any changes to the object
	 */
	async discardChanges() {
		this.issuer.revert();
		if (this.issuer.image) {
			this.sponsorImage = `url(${this.issuer.image.url()})`;
		} else {
			this.sponsorImage = `url(https://common-owner-content.nyc3.digitaloceanspaces.com/common/user_placeholder.png)`;
		}
	}


	/**
	 * Apply changes to the object
	 */
	async saveChanges() {
		this.savingData = true;

		if (!this.issuer.first_name) {
			this.savingData = false;
			this.snackbar.create('First name required', 3000, ['bg-danger'])
			return false;
		}

		if (!this.issuer.last_name) {
			this.savingData = false;
			this.snackbar.create('Last name required', 3000, ['bg-danger'])
			return false;
		}

		if (this.updatedImage) {
			this.uploadingImage = true;
			await this.updatedImage.save()
		}
		this.issuer.save().then(() => {
			this.savingData = false;
			this.uploadingImage = false;
			this.$timeout();
			this.snackbar.create('Changed saved', 3000, ['bg-success'])
		})
	}

}


// $http.get('https://placeholdit.imgix.net/~text?txtsize=15&txt=image1&w=120&h=120', { responseType: "arraybuffer" }).success(function (data) {

// 	var arrayBufferView = new Uint8Array(data);
// 	var blob = new Blob([arrayBufferView], { type: "image/png" });
// 	var urlCreator = window.URL || window.webkitURL;
// 	var imageUrl = urlCreator.createObjectURL(blob);
// 	var img = document.querySelector("#photo");
// 	img.src = imageUrl;
// 	// code to download image here
// }).error(function (err, status) { })
