
/* injects from baggage-loader */

'use strict';

export default class IssuerOnboardingRecordsController {

	constructor($log, $state, $window, FormBuilder) {
		'ngInject';

		this.$log = $log;
		this.$state = $state;
		this.$window = $window;
		this.fb = FormBuilder;
		this.Validators = FormBuilder.Validators;

		this.form = {}
		this.savingForm = false;

		this.prevStep = 'issuer-onboarding.documents';
		this.nextStep = 'issuer-onboarding.finalize';

		this.currentStepName = 'records';

	}

	$onInit() {

		this.form = this.fb.group({

			// Row 1
			explaination: ['', this.Validators.required],

		});

		// Fill existing form info
		this.form.patchValue(this.onboarding.toJSON());

	}

	async saveForm() {

		this.form.markAllAsTouched();

		if (this.form.valid()) {
			this.savingForm = true;
			let form = this.form.value();
			form.lastCompletedStep = this.currentStepName;
			this.$log.debug(form);
			await this.onboarding.save(form);
			// Take the user to the next step
			this.$state.go(this.nextStep, { id: this.onboarding.id })
			this.savingForm = false;
		} else {
			this.savingForm = false;
			this.$window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}

	}

}
