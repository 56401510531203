
/* injects from baggage-loader */

'use strict';

import ProjectManagerController from './projectmanager.controller';
import ProjectManagerTemplate from './projectmanager.html';

export default class ProjectManagerComponent {
		constructor() {
				this.templateUrl = ProjectManagerTemplate;
				this.controller = ProjectManagerController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					project: '<',
					unreadCommentsCount: '<',
					permissions: '<'
				};
		}
}
