
/* injects from baggage-loader */

'use strict';

import ProjectManagerDisbursementComponent from './projectmanager.disbursement.component';

const ProjectManagerDisbursementPageModule = angular.module('project-manager-disbursement-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('projectmanager.disbursement', {
        url: '/disbursement',
        component: 'projectManagerDisbursement',
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          offering: function (project, Parse, $q) {
            if (project.raise_id) {
              return Parse.Cloud.run('getOffering', { id: project.raise_id, project_id: project.id })
            } else {
              return $q.resolve({});
            }
          },
          disbursementRequests: function(project, DisbursementRequests) {
            return DisbursementRequests.listByProject(project);
          }
        }
      });
  })
  .component('projectManagerDisbursement', new ProjectManagerDisbursementComponent());

export default ProjectManagerDisbursementPageModule;
