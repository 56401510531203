
/* injects from baggage-loader */

'use strict';

export default class InvestConfirmationNorthCapController {
	
		constructor($log, $window, $timeout, Comments, SocialShareService) {
			'ngInject';
	
			this.$log = $log;
			this.$timeout = $timeout;
			this.Comments = Comments;
			this.socialShareService = SocialShareService;
	
			this.pageOrigin = $window.location.origin;
			this.shareData = {
				source: this.pageOrigin
			};
	
			// Why did you invest? message data
			this.postingMessage = false;
			this.messagePosted = false;
			this.investorResponse = '';
	
		}
	
		$onInit() {
			this.$log.debug('Investment:', this.investment);
			this.$log.debug('Project:', this.project);
			this.$log.debug('Sponsor:', this.sponsor);
	
			this.$log.debug('Summary data:', this.sidePanelData);
	
			// Set sponsor image 
			if (this.sponsor && this.sponsor.image) {
				this.sponsorImage = `url(${this.sponsor.image.url()})`;
			}
	
			// Pass the updated data back to the component
			this.updateSummaryData({
				data: { investmentAmount: this.investment.amount }
			});
	
			// Fill share data, General
			this.shareData.url = `${this.pageOrigin}${this.getProjectPath()}`;
			this.shareData.href = `${this.pageOrigin}${this.getProjectPath()}`;
			this.shareData.text = `Check out ${this.project.title} by ${this.project.issuer_name} on Common Owner!`;
	
			// LinkedIn 
			this.shareData.description = this.project.description || this.shareData.text;
	
		}

		isRegCF() {
			return this.project.get('type') === "cf";
		}
	
		shouldShowAccreditedNote() {
			return !this.isRegCF() 
				&& this.investment.get('is_accredited')
				&& !this.investor.get('acc_verified');
		}
	
		/**
		 * Returns the project "slug" or fallback to the ID of the current project
		 */
		getProjectSlugOrId() {
			return this.project.slug || this.project.id;
		}
	
		/**
		 * Returns the URL of the current project
		 */
		getProjectPath() {
			return `/project/${this.getProjectSlugOrId()}`;
		}
	
		async postMessage() {
			this.postingMessage = true;
	
			// Post comment publically to the project page
			try {
				await this.Comments.post({
					project: this.project,
					text: this.investorResponse,
					// isPromoter: this.isRaisePromoter,
					// isPromoterCompensated: this.isPromoterCompensated
				});
	
				this.messagePosted = true;
				this.$timeout();
			} catch (err) {
				this.$log.error(err);
				this.postingMessage = false;
				this.$timeout();
			}
	
		}
	
	}
	