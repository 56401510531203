
/* injects from baggage-loader */

'use strict';


export default function (app) {
	app.service('Investments', function (Parse, InvestmentSchema, User, Common, $log) {

		// Load object schema
		const Investment = InvestmentSchema;

		// ==================================================
		// API Methods
		// ==================================================


		/**
		 * Retrieve the list of Investments belonging to the current user
		 */
		this.list = (options = {}) => {
			let query = new Parse.Query(Investment);
			query.withCount(options.includeCount || false)
			query.include('project');
			query.select('amount', 'project.title', 'project.escrow_provider', 'status', 'cleared', 'complete', 'reconfirmation_required', 'trx_status', 'fund_status', 'payment_method');
			query.equalTo('user', User.getCurrent());
			query.skip(options.skip || 0);
			query.limit(10);
			query.descending('createdAt')
			return query.find();
		};


		/**
		 * List the investments made on a selected project
		 */
		this.listForProject = project => {
			let query = new Parse.Query(Investment);
			query.equalTo('project', project);
			query.descending('createdAt')
			return query.find();
		}

		/**
		 * Return the number of investments
		 */
		this.count = () => {
			let query = new Parse.Query(Investment);
			query.equalTo('user', User.getCurrent());
			return query.count();
		}

		/**
		 * Retrieve an Investment by ID
		 */
		this.get = id => {
			let query = new Parse.Query(Investment);
			query.include(['project', 'accreditation', 'investor']);	// , 'chat']);
			return query.get(id);
		}


		/**
		 * Retrieve an Investment by ID with the chat object
		 */
		// this.getWithChat = id => {
		// 	let query = new Parse.Query(Investment);
		// 	query.include(['chat']);
		// 	return query.get(id);
		// }


		/**
		 * Fetch investments and compute stats
		 */
		this.getStats = () => {
			let stats = [];
			let query = new Parse.Query(Investment);
			query.select('amount', 'status', 'project', 'cleared');
			query.equalTo('user', User.getCurrent())
			return query.find()
				.then(async investments => {
					stats.count = investments.length;
					stats.dollar_amount = investments.reduce((total, o) => o.cleared ? total + Number(o.amount) : total, 0);
					stats.pending_amount = investments.reduce((total, o) => o.cleared ? total : total + Number(o.amount), 0);
					stats.projects = investments.reduce((list, o) => {
						Common.pushIf(list, o.project, !list.find(p => p.id === o.project.id))
						return list;
					}, []);
					stats.projects = await Parse.Object.fetchAllIfNeededWithInclude(stats.projects, ['header_media']);
					// Filter out deleted projects
					stats.projects = stats.projects.filter(p => p.createdAt)
					return stats;
				})
		}

		// ==================================================
		// Helper Methods
		// ==================================================

		
	});
}

