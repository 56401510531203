
/* injects from baggage-loader */

'use strict';

export default class InvestKycController {

	constructor($log, $state, $timeout, $stateParams) {
		'ngInject';

		this.debugging = true;

		this.$log = $log;
		this.$state = $state;
		this.$timeout = $timeout;
		this.$stateParams = $stateParams;

		this.isDocumentUploadedSuccessfully = false;

	}

	$onInit() {
		this.$log.debug('Investment:', this.investment);
		this.$log.debug('Investor:', this.investor);
		this.$log.debug('Current document:', this.photoIdDocument);

		// Validate conditions if the user should see this page
		this.validateStateConditions();
	}

	validateStateConditions() {
		// Handle case where this page is visited without an investment ID
		if (this.investment && this.investment.isNew()) {
			this.$state.go('invest.commitment', {}, {
				id: this.project.id
			});
			return;
		}

		// Handle case where investor KYC already verified
		if (this.investor.kyc_verified) {
			this.goToInvestmentPage();

			// TODO: alternativly diplay a message showing they are already verified 
			// with an action button to return back to the investment page
			return;
		}
	}

	goToInvestmentPage() {
		this.$state.go('account.investment', {
			id: this.investment.id
		});
	}

	async handleSuccess(result) {
		this.$log.debug(result);

		// TODO: send email to investor that we have received their document upload

		// TODO: send email to investor once we have verified their document

		// Show success message and button to return to investment page
		this.isDocumentUploadedSuccessfully = true;
	}

	handleError(err) {
		this.$log.error(err);
	}

}
