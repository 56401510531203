
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Notifications', function ($log, $q, NotificationSchema, Parse) {

		// ==================================================
		// Load object schema
		// ==================================================
		let Notification = NotificationSchema;

		this.hasNotifications = false;
		this.unreadCount = 0;
		this.notifications = [];

		this.fetchSome = () => (new Parse.Query(Notification)).descending('createdAt').find()
			.then(notifications => {
				this.notifications = notifications;
				this.unreadCount = notifications.reduce((ct, n) => n.unread ? ct += 1 : ct, 0);
				return notifications;
			})

		this.checkForUnread = () => {
			if (Parse.User.current()) {
				return (new Parse.Query(Notification)).equalTo('unread', true).first()
					.then(n => this.hasNotifications = (n ? true : false))
			}
			return $q.resolve();
		};

		this.markRead = notification => {
			this.unreadCount = this.unreadCount - 1;
			notification.save({ unread: false });
			if (this.unreadCount < 1) {
				this.hasNotifications = false;
			}
		}

		this.markAllAsRead = () => {
			this.unreadCount = 0;
			this.hasNotifications = false;
			this.unread = this.notifications.filter(n => n.unread)
				.map(n => {
					n.unread = false;
					return n;
				});
			Parse.Object.saveAll(this.unread);
		}

	});
}

