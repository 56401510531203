
/* injects from baggage-loader */

'use strict';

import IssueProjectTermsUpdateModalController from './issueprojecttermsupdatemodal.controller';
import IssueProjectTermsUpdateModalTemplate from './issueprojecttermsupdatemodal.html';

export default class IssueProjectTermsUpdateModalComponent {
		constructor() {
				this.templateUrl = IssueProjectTermsUpdateModalTemplate;
				this.controller = IssueProjectTermsUpdateModalController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = {
					resolve: '<',
					close: '&',
					dismiss: '&'
				};
		}
}
