
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingRecordsController from './issuer-onboarding.records.controller';
import IssuerOnboardingRecordsTemplate from './issuer-onboarding.records.html';

export default class IssuerOnboardingRecordsComponent {
		constructor() {
				this.templateUrl = IssuerOnboardingRecordsTemplate;
				this.controller = IssuerOnboardingRecordsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					onboarding: '<'
				};
		}
}
