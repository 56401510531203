
/* injects from baggage-loader */

'use strict';

export default class ModalComponentController {

	constructor($log) {
		'ngInject';

		this.$log = $log;

	}

	$onInit() {

		// Set modal context
		this.context = this.resolve.context;

		this.confirmBtnClass = this.context.confirmBtnClass || 'btn-danger';
		
	}

	ok() {
		this.close({ $value: true });
	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
