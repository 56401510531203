
/* injects from baggage-loader */

'use strict';

import $ from 'jquery';

export default function (app) {

	app.directive('scrollClass', ScrollClassDirective);

	function ScrollClassDirective($window, $rootScope) {
		'ngInject';

		return {
			restrict: 'A',
			link: linkFn,
			bindings: {
				// ID of element to track ending position of
				trackElement: '<',
				// Amount of pixels to offset the end of the tracked element
				offset: '@'
			}
		};

		function linkFn(scope, elem, attrs) {

			let op_order = ['addClass', 'removeClass'];

			// Establish offset from the top of the page
			let class_name = attrs.scrollClass;
			let offset = Number(attrs.offset || 0);

			if (class_name.indexOf('-') === 0) {
				class_name = class_name.slice(1);
				op_order.reverse();
			}

			// Listen for window scroll events
			angular.element($window).bind("scroll", function () {

				if ($rootScope.appState.current.transparent_nav) {

					if (attrs.trackElement) {

						let trackElement = $('#' + attrs.trackElement);

						// Update the offset value based on the tracked element
						if (trackElement) {
							offset = trackElement.position().top + trackElement.outerHeight();
							offset += typeof attrs.offset !== 'undefined' ? Number(attrs.offset) : 0;
						}

					}

					if (this.pageYOffset >= offset) {
						$(elem)[op_order[0]](class_name);
					} else {
						$(elem)[op_order[1]](class_name);
					}
					scope.$apply();

				}

			});

		}

	}
}