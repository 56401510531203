
/* injects from baggage-loader */

'use strict';

import run from './invest-now.run';

// Controllers
import InvestNowModalController from './controllers/invest-now-modals.controller';
import StatesController from './controllers/states.controller';

// Services
import InvestNowService from './services/invest-now.service';
import HelpersService from './services/helpers.service';
import SharedService from './services/shared.service';

// Factories
import InvestmentsFactory from './factories/investments.factory';

// Filters 
import SharedFilters from './filters/shared.filters';

// Directives
import InvestmentDirectives from './directives/investment.directive';

import modal from 'ui-bootstrap4/src/modal';
import tooltip from 'ui-bootstrap4/src/tooltip';
import popover from 'ui-bootstrap4/src/popover';

var app = angular.module('faInvestNow', [
  'LocalStorageModule',
  'ngAnimate',
  'ngResource',
  'ngSanitize',
  modal,
  tooltip,
  popover
]);


/* UI-Bootstrap config */
app.config(['$httpProvider', '$compileProvider', 'ENV', '$sceDelegateProvider', '$locationProvider',
  function ($httpProvider, $compileProvider, ENV, $sceDelegateProvider, $locationProvider) {
    // Disabled debugging for production
    $compileProvider.debugInfoEnabled(ENV.debugInfo);
    // Fix the hash stuff
    $locationProvider.hashPrefix('');
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'AngularXMLHttpRequest';
    $sceDelegateProvider.resourceUrlWhitelist(['self', ENV.apiHost + "/**"]);
  }]);

var environment = 'staging';
// console.log('Fund america env:', environment)
if (environment === 'production') {
  // Production settings
  app.constant("ENV", { "apiHost": "https://api.fundamerica.com", "assetHost": "https://my.fundamerica.com", "debugInfo": false, "environment": "production" });
} else {
  // Sandbox settings
  app.constant("ENV", { "apiHost": "https://sandbox.fundamerica.com", "assetHost": "https://my-sandbox.fundamerica.com", "debugInfo": true, "environment": "sandbox" });
}
// app.constant("ENV", { "apiHost": "https://sandbox.fundamerica.com", "assetHost": "https://my-sandbox.fundamerica.com", "debugInfo": true, "environment": "sandbox" });



// App run block
app.run(run)


// Controllers
InvestNowModalController(app);
StatesController(app);

// Services
InvestNowService(app);
HelpersService(app);
SharedService(app);

// Factories
InvestmentsFactory(app);

// Filters
SharedFilters(app);

// Directives
InvestmentDirectives(app)



/* Filters */

String.prototype.toProperCase = function (bool) {
  // if (bool == false) {return this;}
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

String.prototype.endsWith = function (suffix) {
  return this.indexOf(suffix, this.length - suffix.length) !== -1;
};


export default app;