
/* injects from baggage-loader */

'use strict';

import IssuerOnboardingInterviewComponent from './issuer-onboarding.interview.component';

const IssuerOnboardingInterviewPageModule = angular.module('issuer-onboarding-interview-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('issuer-onboarding.interview', {
        url: '/interview',
        title: 'Issuer Onboarding: Interview',
        localTitle: 'Interview',
        component: 'issuerOnboardingInterview',
        protected: true,
        // Resolve promises into controller bindings, see component for bindings
        // resolve: { }
      });
  })
  .component('issuerOnboardingInterview', new IssuerOnboardingInterviewComponent());

export default IssuerOnboardingInterviewPageModule;
