
/* injects from baggage-loader */

'use strict';

// import $ from 'jquery';
import moment from 'moment';

export default class ProjectController {

	constructor(
		$log, $timeout, $interval, $state, $rootScope, $scope, $window, $uibModal, $document,
		User, Alerts, Events, Parse, SocialShareService, Common, InvestmentSchema, ImagePreloader,
		FUND_AMERICA_API_KEY, INVESTOR_COUNT_BUFFER
	) {
		'ngInject';

		this.fa_api_key = FUND_AMERICA_API_KEY;
		this.INVESTOR_COUNT_BUFFER = INVESTOR_COUNT_BUFFER;

		this.$log = $log;
		this.$timeout = $timeout;
		this.$interval = $interval;
		this.$state = $state;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.$window = $window;
		this.$document = $document;
		this.User = User;
		this.Alerts = Alerts;
		this.Events = Events;
		this.Parse = Parse;
		this.socialShareService = SocialShareService;
		this.commonService = Common;
		this.modalService = $uibModal;
		this.imagePreloader = ImagePreloader;
		this.Investment = InvestmentSchema;

		this.routeData = this.$state.current.data || {};
		this.activeTab = 0;

		// Smallest amount allowed to invest
		this.default_investment_min = 5;

		// Initalize comments
		this.comments = [];
		this.topLevelComments = [];
		this.showCommentBox = false;

		// Initialize documents
		this.documents = [];

		// Investment button text
		this.invest_button_text = 'Invest Now';
		this.invest_button_closed_text = 'Raise concluded';
		this.invest_button_suspended_text = 'Investment Suspended';
		this.login_button_text = 'Login to Invest';

		// Hold the status if a user is logged in
		this.isUserLoggedIn = User.isUserLoggedIn();
		this.isAdmin = User.hasRole('Admins');
		this.hasRaiseOpened = false;
		this.isInvestmentSuspended = false;

		this.viewEvent = null;
		this.investmentAmount = '';

		this.shareData = {
			source: 'https://commonowner.com'
		}

		this.isInvestmentTermsCollapsed = true;
		this.hasMoreImages = false;
		this.projectBookmarked = false;

		this.sponsorImage = `url(https://common-owner-content.nyc3.digitaloceanspaces.com/common/user_placeholder.png)`;

		// Mobile slider config
		// this.slickConfig = {
		// 	enabled: true,
		// 	draggable: true
		// }

		this.sliderImages = [];

		this.raiseOpenCountdown = '';
		this.dispatchInvestNowOpenEvents = [];

		this.currentInvestment;

	}

	async $onInit() {

		// Close share menu
		this.socialShareService.closeShareMenu();

		// Check if the raise is open (start date has passed)
		// this.hasRaiseOpened = this.commonService.isRaiseOpen(this.project.get('start_date'));
		this.hasRaiseOpened = true;

		// Prevent investment if raise suspended
		this.isInvestmentSuspended = this.project.isInvestmentSuspended();

		// Log project view event
		this.Events.logView(this.project)
			.then(e => this.viewEvent = e);

		// Override the "Invest Now" public key if set on the project
		if (this.project.invest_now_key) {
			this.fa_api_key = this.project.invest_now_key;
		}

		if (this.images.length > 1) {
			// Preload the first 5 images in the gallery
			// this.imagePreloader.observable(this.images.map(i => i.src).slice(0, 5))
			// .subscribe(
			// 	data => this.$log.debug(`Loaded image ${data}`),
			// 	err => this.$log.error(err),
			// 	() => this.$log.debug('done'),
			// )
		}

		this.slickConfig = {
			enabled: true,
			draggable: true,
			event: {
				afterChange: (event, slick, current) => {
					if (this.swipeDirection === 'left') {
						if (this.images[current + 1] && !this.sliderImages[current + 1].src) {
							// this.$log.debug('load', current + 1)
							this.sliderImages[current + 1].src = this.images[current + 1].src;
							this.$timeout()
						}
					} else {
						if (this.images[current - 1] && !this.sliderImages[current - 1].src) {
							// this.$log.debug('load', current - 1)
							this.sliderImages[current - 1].src = this.images[current - 1].src;
							this.$timeout()
						}
					}
				},
				swipe: (event, slick, direction) => this.swipeDirection = direction
			}
		}

		// Listen for events from the swipe image gallery
		// this.imageSlider = angular.element(document.querySelector('#gallerySlider'));
		// this.imageSlider = $('#gallerySlider');
		// this.imageSlider.on('swipe', (event, slick, direction) => {
		// 	this.swipeDirection = direction;
		// });
		// this.imageSlider.on('afterChange', (event, slick, current) => {
		// 	if (this.swipeDirection === 'left') {
		// 		if (this.images[current + 1] && !this.sliderImages[current + 1].src) {
		// 			// this.$log.debug('load', current + 1)
		// 			this.sliderImages[current + 1].src = this.images[current + 1].src;
		// 			this.$timeout()
		// 		}
		// 	} else {
		// 		if (this.images[current - 1] && !this.sliderImages[current - 1].src) {
		// 			// this.$log.debug('load', current - 1)
		// 			this.sliderImages[current - 1].src = this.images[current - 1].src;
		// 			this.$timeout()
		// 		}
		// 	}
		// });

		// Load the last, first and second images
		this.sliderImages = this.images.map((img, i) => {
			if (i === 0 || i === 1 || i === this.images.length - 1) return { src: img.src };
			return { src: '' };
		})

		// Set sponsor image 
		if (this.project.sponsor && this.project.sponsor.image) {
			this.sponsorImage = `url(${this.project.sponsor.image.url()})`;
		}

		// Remove "header_media" from image gallery
		this.gallery = this.images.filter(img => img.id !== this.project.header_media.id).slice(0, 2);

		// Find all top level comments (comments without parents)
		this.topLevelComments = this.comments.filter(comment => typeof comment.get('parent') === 'undefined');

		// Fill share data, General
		this.shareData.url = `${this.$window.location.origin}${this.getProjectPath()}`;
		this.shareData.text = this.project.title;

		// Email
		this.shareData.subject = `Common Owner Investment Opportunity - ${this.project.title}`;
		this.shareData.body = `${this.$window.location.origin}/project/${this.project.id}`;

		// LinkedIn 
		this.shareData.description = this.project.description;

		// Check if project is saved
		this.projectBookmarked = this.User.isProjectSaved(this.project);

		// Start countdown to raise open if start date is in the future
		if (!this.hasRaiseOpened) {
			this.timeUntilRaiseOpen = this.commonService.getTimeTillRaiseOpen(this.project.start_date);
			this.updateRaiseCountdown();
			this.raiseOpenInterval = this.$interval(() => this.updateRaiseCountdown(), 1000);
		}

		// // Find "invest_now" data
		// let investNowLsKey = Object.keys(localStorage).find(key => key.indexOf('invest_now') > -1);
		// let investNowData = localStorage.getItem(investNowLsKey);
		// if (investNowData) {
		// 	investNowData = JSON.parse(investNowData)
		// }
		// this.$log.debug(investNowData)

		// Bind "this" to Invest Now event listener handlers
		this._investNowOpen = this.investNowOpen.bind(this)
		this._investNowSuccess = this.investNowSuccess.bind(this);
		this._investNowClose = this.investNowClose.bind(this);
		// this._investNowData = this.investNowData.bind(this);

		// Sutup Invest Now event listeners
		document.addEventListener('fa.investnow.success', this._investNowSuccess);
		document.addEventListener('fa.investnow.open', this._investNowOpen);
		document.addEventListener('fa.investnow.close', this._investNowClose);
		// document.addEventListener('fa.investnow.data', this._investNowData);

		// Clear invest now localStorage data
		let faStorageKey = Object.keys(localStorage).find(key => key.indexOf('invest_now') > -1)
		if (faStorageKey) localStorage.removeItem(faStorageKey);

	}

	$onDestroy() {
		// Clear raise open countdown interval 
		if (this.raiseOpenInterval) {
			this.$interval.cancel(this.raiseOpenInterval)
		}

		// Remove event listeners
		document.removeEventListener('fa.investnow.success', this._investNowSuccess);
		document.removeEventListener('fa.investnow.open', this._investNowOpen);
		document.removeEventListener('fa.investnow.close', this._investNowClose);
		// document.removeEventListener('fa.investnow.data', this._investNowData);
	}

	onScrollToSection(id, offset, tabIndex) {
		this.activeTab = tabIndex;
		this.$timeout(() => {
			const scrollElem = angular.element(document.getElementById(id));
			this.$document.scrollToElement(scrollElem, offset, 500);
		}, 100);
	}

	getIsTestingWaters() {
		return this.project.status === 'testing';
	}

	getTestingWatersInterestRange() {
		if (this.testingMetrics && this.testingMetrics.range) {
			return this.testingMetrics.range;
		}
		return {
			min: 0,
			max: 0
		};
	}
	getTestingWatersInterestRangeFormatted() {
		if (this.testingMetrics && this.testingMetrics.formattedRange) {
			return this.testingMetrics.formattedRange;
		}
		return {
			min: `$0`,
			max: `$0`
		};
	}

	getTestingWatersInterestFormattedString() {
		if (this.testingMetrics && this.testingMetrics.formattedString) {
			return this.testingMetrics.formattedString;
		}
		return `$0`;
	}

	investNowOpen(e) {
		// Dispatch events after modal is opened
		this.dispatchInvestNowOpenEvents.forEach(event => document.dispatchEvent(event));
	}

	investNowClose(e) {
		// this.$log.debug('Invest now modal closed');
		if (this.investment_id) {
			// Take user to see their investment after the modal closes
			// this.$state.go('account.investment', { id: this.currentInvestment.id });
			this.$state.go('investment-confirmation', { investment_id: this.currentInvestment.id });
		}
	}

	investNowSuccess(e) {
		// Get investment ID from event
		let investment = e.data.investment;
		this.investment_id = investment.investment_id;
		// this.$log.debug('Investment complete', investment);

		// TODO: remove investment "create" webhook and query for the fund america investment id. Merge response data 
		// 			 into our investment object
		if (this.currentInvestment) {
			this.currentInvestment.set('fund_america_id', this.investment_id);
			this.currentInvestment.set('amount', parseFloat(investment.amount));
			this.currentInvestment.set('payment_method', investment.funds_transfer_method);
			this.currentInvestment.set('equity_share_price', investment.unit_price);
			this.currentInvestment.save();
		}

	}

	// investNowData(e) {
	// 	// this.$log.debug(e.data)
	// }

	/**
	 * Update the countdown until the raise opens each second
	 */
	updateRaiseCountdown() {
		this.timeUntilRaiseOpen = moment.duration(this.timeUntilRaiseOpen - 1000, 'milliseconds');
		let found = false;
		this.raiseOpenCountdown = ['years', 'months', 'days', 'hours', 'minutes', 'seconds']
			.filter(measure => {
				if (this.timeUntilRaiseOpen[measure]()) found = true;
				if (found) return true;
			}).map(m => `${this.timeUntilRaiseOpen[m]()} ${m}`).join(' ');
	}


	/**
	 * Returns the number of days remianing until the end of the raise
	 */
	daysRemaining() {
		return this.commonService.daysRemaining(this.project.get('end_date'));
	}


	/**
	 * Returns true if the raise is still open to investment
	 */
	isRaiseOpen() {
		// if (!this.isAdmin && (!this.project.live || !this.project.raise_id)) {
		// 	this.invest_button_closed_text = 'Raise Offline';
		// 	return false;
		// }
		if (this.isInvestmentSuspended) {
			return false;
		}
		return this.daysRemaining() > 0 ? true : false;
	}

	/**
	 * Returns true if the raise is using TigerMark insurance
	 * @returns bool
	 */
	isUsingTigerMarkInsurance() {
		return this.project.get('is_tigermark_enabled');
	}

	/**
	 * Returns the project "slug" or fallback to the ID of the current project
	 */
	getProjectSlugOrId() {
		return this.project.slug || this.project.id;
	}

	/**
	 * Returns the URL of the current project
	 */
	getProjectPath() {
		return `/project/${this.getProjectSlugOrId()}`;
	}

	openImageGallery(index) {
		this.modalService.open({
			animation: false,
			size: 'lighbox',
			component: 'imageGalleryModal',
			resolve: {
				images: () => this.images,
				index: () => index
			}
		}).result.then(angular.noop, angular.noop);
	}

	/**
	 * Show the comment box
	 */
	revealCommentBox() {
		this.showCommentBox = true;
	}

	/**
	 * Method called after a top level comment is posted
	 */
	commentPosted() {
		// Display local alert
		this.Alerts.setLocal({
			msg: 'Comment posted successfully',
			type: 'success'
		});
	}

	/**
	 * Logs an action taken on the project page to the event heap
	 * @param {String} type 
	 */
	actionClicked(type) {
		if (this.project.escrow_provider === `prime_trust`) {
			this.investNowClicked();
		} else {
			this.goToInvestmentPage();
		}
		// Log project action event
		this.Events.logAction(this.project, type, {
			parent: this.viewEvent
		});
	}

	validateUserToInvest() {

		if (this.project.isInvestmentSuspended()) {
			return false;
		}

		if (!this.isUserLoggedIn) {
			this.$state.go('login', { ref: this.getProjectPath() });
			return false;
		}

		if (this.User.getCurrent().block_investments) {
			alert('You are currently banned from making investments. Please contact support for more information.')
			return false;
		}

		if (!this.User.getCurrent().emailVerified) {
			alert('You must verify your email address before you can make your first investment. Check your inbox for an email from us!')
			return false;
		}

		return true;
	}

	/**
	 * Direct the user to the investment form page
	 */
	async goToInvestmentPage() {

		if (!this.validateUserToInvest()) {
			this.$log.debug('User failed investment validation')
			return;
		}

		this.$state.go('invest.commitment', {
			id: this.getProjectSlugOrId(),
			amount: this.investmentAmount
		});
	}

	async investNowClicked() {

		if (!this.validateUserToInvest()) {
			return false;
		}

		let autofillEvent = document.createEvent('Event');
		autofillEvent.initEvent('fa.investnow.autofill', true, false);
		autofillEvent.investor = {
			amount: this.investmentAmount
		};
		this.dispatchInvestNowOpenEvents.push(autofillEvent)

		// let setInvestorsEvent = document.createEvent('Event');
		// setInvestorsEvent.initEvent('fa.investnow.setInvestors', true, false);
		// // Fetch User fa_investment_ids
		// let investment_ids = await this.User.getInvestmentIds();
		// if (investment_ids && investment_ids.length) {
		// 	setInvestorsEvent.ids = investment_ids.join(',');
		// 	this.dispatchInvestNowOpenEvents.push(setInvestorsEvent);
		// }

		// Open "investmentAdvisoryModal"
		this.modalService.open({
			animation: true,
			size: 'lg',
			component: 'investmentAdvisoryModal',
			resolve: {
				context: () => ({
					project: this.project,
					investment: this.currentInvestment
				})
			}
		}).result.then(investment => {

			// Set the current investment
			this.currentInvestment = investment;

			// Open the Invest Now modal
			let openModalEvent = new CustomEvent('fa.investnow.openModal', {
				detail: {
					token: this.getInvestNowToken(),
					data: this.getInvestNowData({
						investment_id: investment.id
					})
				}
			})
			document.dispatchEvent(openModalEvent);

		}, angular.noop);

	}

	/**
	 * Returns a string including the Fund America Invest Now API key and the project raise ID
	 */
	getInvestNowToken() {
		// console.log('Invest now token:', `${this.fa_api_key}:${this.project.raise_id}`);
		return `${this.fa_api_key}:${this.project.raise_id}`;
	}

	/**
	 * Return user data for Invest Now modal
	 */
	getInvestNowData(data = {}) {
		return Object.assign({
			// Our user ID
			user_id: this.User.isUserLoggedIn() ? this.User.getCurrent().id : '',
			// Our user name
			username: this.User.isUserLoggedIn() ? this.User.getCurrent().getUsername() : '',
			// Project ID
			project_id: this.project.id,
			// Optional referrer
			//ref: ''
		}, data);
	}


	/**
	 * Returns true when the current user is the owner of the project
	 */
	isProjectOwner() {
		let currentUser = this.User.getCurrent();
		let ACL = this.project.getACL();
		if (currentUser && ACL) {
			return ACL.getWriteAccess(currentUser.id);
		}
	}


	calculateGalleryHeight() {
		return this.gallery.length === 1 ? 'height: calc(70vh - 50px)' : 'calc(35vh - 25px)';
	}


	/**
	 * Toggle bookmarked state of the project for the current user
	 */
	toggleBookmarkedState() {
		if (this.User.isUserLoggedIn()) {
			// Toggle bookmark state
			this.projectBookmarked = this.User[this.projectBookmarked ? 'unbookmarkProject' : 'bookmarkProject'](this.project);
		} else {
			// Take user to login screen
			this.$state.go('login', { ref: '/project/' + this.project.id, alert: true });
		}
	}

	getFormattedProjectType() {
		// if (this.project.type.includes('cf')) {
		if (this.project.type && this.project.type.indexOf('cf') > -1) {
			return 'Reg CF'
		} else {
			return this.project.type;
		}
	}

	canShowDocuments() {
		return true;
		// return this.project.type === 'cf' || this.User.isUserLoggedIn();
	}

	shouldShowInvestorCount() {
		return this.project.stats.investor_count >= this.INVESTOR_COUNT_BUFFER;
	}

	openExpressInterestModal() {

		const context = {
			project: this.project
		};

		if (this.isUserLoggedIn) {
			context.user = this.User.getCurrent();
		}

		// Open "expressEarlyInterestModal"
		this.modalService.open({
			animation: true,
			size: 'lg',
			component: 'expressEarlyInterestModal',
			resolve: {
				context: () => context
			}
		}).result.then(feedback => {


		}, angular.noop);

	}

	useNorthCapitalInvestmentForm() {
		return this.project.escrow_provider === 'north_capital';
	}

	getProjectLocation() {
		return this.project.location && this.project.location.includes('undefined')
			? `${this.project.city}, ${this.project.region}`
			: this.project.location;
	}

	shouldShowInvestmentTerms() {
		return !!this.project.investment_terms && !!this.project.valuation_cap
			|| !!this.project.investment_terms && !!this.project.interest_rate;
	}

}
