
/* injects from baggage-loader */

'use strict';

import DonationConfirmationController from './donation-confirmation.controller';
import DonationConfirmationTemplate from './donation-confirmation.html';

export default class DonationConfirmationComponent {
		constructor() {
				this.templateUrl = DonationConfirmationTemplate;
				this.controller = DonationConfirmationController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
