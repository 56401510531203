
/* injects from baggage-loader */

'use strict';

import InvestorProfileListItemController from './investorprofilelistitem.controller';
import InvestorProfileListItemTemplate from './investorprofilelistitem.html';

export default class InvestorProfileListItemComponent {
		constructor() {
				this.templateUrl = InvestorProfileListItemTemplate;
				this.controller = InvestorProfileListItemController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					profile: '<',
					selected: '<'
				};
		}
}
