
/* injects from baggage-loader */

'use strict';

import AccountProjectsController from './account.projects.controller';
import AccountProjectsTemplate from './account.projects.html';

export default class AccountProjectsComponent {
		constructor() {
				this.templateUrl = AccountProjectsTemplate;
				this.controller = AccountProjectsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					projects: '='
				};
		}
}
