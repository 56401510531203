
/* injects from baggage-loader */

'use strict';

import OnboardingApplicationComponent from './onboarding-application.component';

const OnboardingApplicationPageModule = angular.module('onboarding-application-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('onboarding-application', {
        url: '/onboarding/application/{id}',
        params: {
          email: null
        },
        component: 'onboardingApplication',
        title: 'Issuer Onboarding: Application',
        localTitle: 'Onboarding Application',
        // NOTE: allow users to signup at the end of the form
        // protected: true,
        resolve: { 
          onboarding: function (Onboarding, $transition$, $q, OnboardingSchema) {
            const onboardingId = $transition$.params('to').id;
            const emailAddress = $transition$.params('to').email;

            // Return existing onboarding object
            if (onboardingId && onboardingId !== 'new') {
              return Onboarding.get($transition$.params('to').id);
            }
            
            // Return a new onboarding object
            return new OnboardingSchema({
              email_address: emailAddress
            });
          }
        }
      });
  })
  .component('onboardingApplication', new OnboardingApplicationComponent());

export default OnboardingApplicationPageModule;
