
/* injects from baggage-loader */

'use strict';

import AboutController from './about.controller';
import AboutTemplate from './about.html';
// import AboutTemplate from '!!file-loader?name=templates/[name].[ext]!./about.html';

export default class AboutComponent {
	constructor() {
		this.templateUrl = AboutTemplate;
		this.controller = AboutController;
		this.controllerAs = 'ctrl';
		// Bind resolved items to the controller, see module state for resolve
		this.bindings = {
			teamMembers: '<'
		};
	}
}
