
/* injects from baggage-loader */

'use strict';

import ImageGalleryModalComponent from './imagegallerymodal.component';
import './imagegallerymodal.scss';

const ImageGalleryModalModule = angular.module('image-gallery-modal-module', []);

ImageGalleryModalModule
    .component('imageGalleryModal', new ImageGalleryModalComponent());

export default ImageGalleryModalModule;

