
/* injects from baggage-loader */

'use strict';

export default function (app) {

	app.directive('format', FormatDirective);

	function FormatDirective($filter, $log) {
		'ngInject';

		return {
			restrict: 'A',
			link: linkFn,
			require: 'ngModel',
			scope: {
				defaultValue: '=',
				onBlur: '&'
			}
		};

		function linkFn(scope, elem, attrs, ctrl) {
			if (!ctrl) return;

			// $log.debug(ctrl)

			if (attrs.format === 'currency-better') {

				elem.bind('propertychange keypress paste blur', function (e) {
					const initialVal = elem.val();
					if (e && e.key) {
						const digits = initialVal.replace(/[^0-9]/g, '');
						if (!e.key.replace(/[^0-9]/g, '').length || initialVal !== digits) {
							e.preventDefault();
							e.stopPropagation();
							elem.val(digits);
						}
						return digits;
					}
				});

			} else if (attrs.format === 'currency') {

				ctrl.$formatters.unshift(function (a) {
					return $filter(attrs.format)(ctrl.$modelValue, '$', 0)
				});

				elem.bind('propertychange keyup paste blur', function (event) {
					let plainNumber = elem.val().replace(/[^\d|\-+|.+]/g, '').replace(/\D/g, '');
					// $log.debug('Value:', plainNumber);
					if (event.type === 'blur') {
						if (!plainNumber || plainNumber < scope.defaultValue) {
							elem.val($filter(attrs.format)(scope.defaultValue, '$', 0));
							scope.onBlur({ amount: scope.defaultValue });
						} else {
							scope.onBlur({ amount: plainNumber });
						}
					} else {
						if (plainNumber) {
							elem.val($filter(attrs.format)(plainNumber, '$', 0));
						}
					}
				});

			} else if (attrs.format === 'currency-cents') {

				elem.bind('propertychange keyup paste blur', function (event) {
					let number = elem.val().replace('$0.', '').replace(/\D/g, '');
					elem.val('$0.' + number || '');
				});

				// } else if (attrs.format === 'numbers-only') {
				// 	const number = elem.val().replace(/[^\d|\-+|.+]/g, '').replace(/\D/g, '');
				// 	if (number) {
				// 		const digits = number.replace(/[^0-9]/g, '');

				// 		if (digits !== number) {
				// 			elem.val(digits|| '');
				// 		}
				// 		// return parseInt(digits,10);
				// 	}
				// } 
			} else {
				/* do nothing */
			}

		}

	}
}