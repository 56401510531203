
/* injects from baggage-loader */

'use strict';

export default function (app) {

  app.service('ss', function (ENV, $injector, $filter, $uibModal, $document) {
    var _this = this;

    /********************************************************************************************
    DOM METHODS
    ********************************************************************************************/

    this.appendCSS = function (urls) {
      if (!urls) { return; }
      // Check if they already have font-awesome
      // Might also check if they already have our CSS
      var ss = document.styleSheets,
        ssHrefs = [],
        faHrefs = [];

      faHrefs = faHrefs.concat(urls);

      var faString = 'font-awesome';
      var hasFontAwesome = false;
      // Create an array out of just the page's existing stylesheets
      // Then I can loop through my values and check for index of
      for (var i = 0, max = ss.length; i < max; i++) {
        var link = ss[i].href;
        ssHrefs.push(link);
        // Check if there's font awesome in any form and that there is actually a link
        if (hasFontAwesome === false &&
          link &&
          (link.indexOf(faString) > -1 ||
            link.indexOf('fontawesome.com') > -1)) {
          hasFontAwesome = true;
        }
      }

      for (var i = 0; i < faHrefs.length; i++) {
        if (ssHrefs.indexOf(faHrefs[i]) === -1) {
          // Not passing an element here, because we always want CSS in the HEAD

          // If we're on font awesome, then just check for the general existence of ANY font awesome
          // this includes scripts

          var isFontAwesome = faHrefs[i].indexOf(faString) > -1;
          if (hasFontAwesome && isFontAwesome) {
            continue;
          }

          _this.appendToElement(faHrefs[i], 'css');
        }
      }
    };

    this.appendToElement = function (filename, filetype, element) {
      var fileref;
      if (filetype == 'css') { //if filename is an external CSS file
        fileref = document.createElement('link');
        fileref.setAttribute('rel', 'stylesheet');
        fileref.setAttribute('type', 'text/css');
        fileref.setAttribute('href', filename);
      }

      if (filetype == 'js') { //if filename is an external CSS file
        fileref = document.createElement('script');
        fileref.setAttribute('type', 'text/javascript');
        fileref.setAttribute('src', filename);
      }

      if (fileref !== undefined) {
        if (element !== undefined) {
          element.appendChild(fileref);
        } else {
          document.getElementsByTagName('head')[0].appendChild(fileref);
        }
      }
    };

    this.appendScripts = function (urls) {
      if (!urls) { return; }
      var pageScripts = document.scripts,
        pageScriptSrc = [];

      // Create an array out of just the page's existing scripts with urls
      // Then I can loop through my values and check for index of
      for (var i = 0, max = pageScripts.length; i < max; i++) {
        var src = pageScripts[i].src;
        if (src) {
          pageScriptSrc.push(src);
        }
      }

      for (var i = 0; i < urls.length; i++) {
        // Check against the url with and without the protocol
        if (pageScriptSrc.indexOf(window.location.protocol + urls[i]) === -1 && pageScriptSrc.indexOf(urls[i]) === -1) {
          _this.appendToElement(urls[i], 'js');
        }
      }
    };

    this.appendStylesTo = function (element, styles) {
      if (element !== undefined) {
        for (var i = 0; i < styles.length; ++i) {
          var style = document.createElement('style'),
            text = document.createTextNode(styles[i]);
          style.appendChild(text);
          element.appendChild(style);
        }
      }
    };

    this.bindButtons = function (clickFunction, selector) {
      var buttons = document.querySelectorAll(selector);
      for (var i = 0; i < buttons.length; ++i) {
        if (!buttons[i].attributes['data-fa-button-bound']) {
          buttons[i].setAttribute('data-fa-button-bound', 'true');
          // Add event listeners to open modal from button click listener
          buttons[i].addEventListener('click', function (e) {
            if (typeof (clickFunction) == 'function') {
              clickFunction(e);
            }
          });
        }
      }

      // CUSTOM COMMON OWNER UPDATE - 04/15/2020
      // Add event listener to open modal from event dispatch
      document.addEventListener('fa.investnow.openModal', function (e) {
        if (typeof (clickFunction) == 'function') {
          clickFunction(e);
        }
      })
    };

    this.createAnchorListener = function (element) {
      // This requires being supplied with an actual DOM element and not a string
      if (element && !element.hasAttribute('fixed')) {
        element.setAttribute('fixed', true);
        element.addEventListener('click', function (e) {
          e.preventDefault();
          var target = document.getElementById(element.hash);
          if (target) {
            target.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        });
      }
    };

    // can always expand this method in the future
    this.createElement = function (params) {
      if (params) {
        var markup = params.markup,
          classes = params.classes,
          attributes = params.attributes,
          element = angular.element(markup);

        if (classes) {
          // Right now classes is just a string, probably add support for supplying an array of classes in the future
          element.addClass(classes);
        }

        if (attributes) {
          // attributes is an object
          for (var key in attributes) {
            var value = attributes[key];
            if (value !== undefined && value !== null) {
              element.attr(key, attributes[key]);
            }
          }
        }
        var new_element_string = element.prop('outerHTML');
        return new_element_string;
      }
    };

    this.findStyles = function (object, styles, element, count) {
      var re = /<style>[\s\S]*?<\/style>/g;
      // Find any html resources
      for (var key in object) {
        count++;
        if (typeof (object[key]) == 'object') {
          _this.findStyles(object[key], styles, element, count);
          // Check if the key is or contains "html"
        } else if (key == 'html' || key.indexOf('html') != -1) {
          var style = object[key].match(re);
          if (style) {
            for (var i = 0; i < style.length; ++i) {
              styles.push(style[i].replace(/(<style>|<\/style>)/g, '').trim());
            }
          }
        }
        count--;
      }
      if (count == 0) {
        // Callback that'll only be called once after the chain is complete
        _this.appendStylesTo(element, styles);
      }
    };

    this.fixAnchors = function (query) {
      var links = document.querySelectorAll(query);
      for (var i = 0; i < links.length; i++) {
        var element = links[i];
        // Invoke a method, supplying the element, because looping and such without it would cause all the anchors to use the last element value
        _this.createAnchorListener(element);
      }
    };

    // Should depricate this, because among other things it has the same name as an element method
    this.getAttribute = function (element, attribute) {
      // This will try to get an attribute value, otherwise it'll crawl up the element chain
      // Pass in the element, not a query
      if (element && attribute) {
        var attr = element ? element.getAttribute(attribute) : undefined;
        if (attr === undefined || attr === null) {
          var parent = element.parentElement;
          if (parent) {
            return _this.getAttribute(parent, attribute);
          }
        } else {
          return attr;
        }
      }
    };

    // Probably superior to getAttribute method above
    this.findAttribute = function (element, attribute) {
      if (element && attribute) {
        var attr,
          parent;
        do {
          // Make sure the element has the getAttribute method
          attr = (typeof (element.getAttribute) === 'function') ? element.getAttribute(attribute) : undefined;
          // After trying to set attr, we then replace element with its parent, in case we have to traverse up another level
          element = element.parentElement;
        }
        while (!attr && element);
        return attr;
      }
    }

    this.goTo = function (element_id) {
      var modal = document.getElementById(element_id);
      modal.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    };

    this.setStyles = function (resource, element) {
      var styles = [],
        count = 0;
      this.findStyles(resource, styles, element, count);
    };

    /********************************************************************************************
    FORM METHODS
    ********************************************************************************************/

    this.filterById = function (options, id, filter) {
      if (!filter || !options || !id) { return; }

      for (var key in options) {
        if (options[key].id == id) {
          return $filter(filter)(options[key]);
        }
      }
    };

    this.filterObject = function (filter, object) {
      return $filter(filter)(object);
    };

    this.formatCurrency = function (amount, fixed) {
      if (amount) {
        var num = (amount).replace(/[^0-9\.]/g, ''),
          isNegative = amount.substring(0, 1) === '-',
          decimals = typeof (fixed) === 'number' ? fixed : 2,
          fixed_num = parseFloat(num).toFixed(decimals);

        if (decimals > 2) {
          // parse the float again, because it might have too many zeroes
          fixed_num = parseFloat(fixed_num);

          var fn_array = fixed_num.toString().split('.');
          if (fn_array.length === 1 || (fn_array.length === 2 && fn_array[1].length < 2)) {
            // If the string parsed with less than two decimals then we want to set it back to two
            fixed_num = fixed_num.toFixed(2);
          }
        }

        return num ? (isNegative ? '-' : '') + _this.addCommas(fixed_num) : amount;
      }
    };

    this.formatSSN = function (ssn) {
      if (ssn) {
        var exp = /[^0-9\-\s]/g;
        if (!exp.test(ssn)) {
          var s_ssn = ssn.replace(/\-|\s/g, ''),
            formattedSSN = s_ssn.slice(0, 3);
          if (s_ssn.length > 3) {
            formattedSSN += '-' + s_ssn.slice(3, 5);
          }
          if (s_ssn.length > 5) {
            formattedSSN += '-' + s_ssn.slice(5, s_ssn.length);
          }
          return formattedSSN;
        } else {
          return ssn;
        }
      }
    };

    this.formatTaxId = function (type, id) {
      if (id) {
        if (type == 'person') {
          return _this.formatSSN(id);
        } else {
          return _this.formatTIN(id);
        }
      }
    };

    this.formatTIN = function (tin) {
      if (tin) {
        var exp = /[^0-9\-\s]/g;
        if (!exp.test(tin)) {
          var s_ssn = tin.replace(/\-|\s/g, ''),
            formattedSSN = s_ssn.slice(0, 2);
          if (s_ssn.length > 2) {
            formattedSSN += '-' + s_ssn.slice(2, s_ssn.length);
          }
          return formattedSSN;
        } else {
          return tin;
        }
      } else {
        return;
      }
    };

    this.getHeight = function (source, offset) {
      var source_object = document.getElementById(source),
        offset_h = 0,
        sub_offset = false;

      if (offset.substring(0, 1) === '-') {
        offset = offset.substr(1);
        sub_offset = true;
      }

      var offset_object = document.getElementById(offset);

      if (offset_object) {
        if (sub_offset) {
          return source_object.offsetHeight - offset_object.offsetHeight;
        } else {
          return source_object.offsetHeight + offset_object.offsetHeight;
        }
      } else {
        return source_object.offsetHeight;
      }
    };

    // Since we're only concerned with show_if and hide_if, this eliminates excess looping
    this.metaIf = function (meta, resource) {
      // Meta is the actual meta tag
      var hide = [],
        show = [];
      var results = [];
      for (var type in meta) {
        if (type === 'show_if' || type === 'hide_if' || type === 'require_if') {
          var criteria = meta[type];
          for (var field in criteria) {
            switch (typeof (criteria[field])) {
              case 'object':
                // Objects can actually be arrays, JSON objects, or null (perhaps more?)
                if (criteria[field] === null) {
                  // Compare if the resource value is null or an empty string (basically, not true because I'm inversing it)
                  results.push(type === 'hide_if' ? !!resource[field] : !resource[field]);
                } else if (criteria[field].constructor === Array) {
                  // Index works on arrays
                  if (type === 'hide_if') {
                    results.push(criteria[field].indexOf(resource[field]) === -1);
                  } else {
                    results.push(criteria[field].indexOf(resource[field]) !== -1);
                  }
                } else if (criteria[field].constructor === Object) {
                  // This hasn't happened before, so just do an object comparision I think
                  if (type === 'hide_if') {
                    results.push(!angular.equals(criteria[field], resource[field]));
                  } else {
                    results.push(angular.equals(criteria[field], resource[field]));
                  }
                } else {
                  // Not sure what would even be else so lets say it is true
                  results.push(type === 'hide_if' ? false : true);
                }
                break;
              default:
                // Always convert the values to strings for equality comparision
                var resource_value = String(resource[field]),
                  meta_value = String(criteria[field]);
                // Then we compare the two string values
                if (type === 'hide_if') {
                  results.push(resource_value !== meta_value);
                } else {
                  results.push(resource_value === meta_value);
                }
                break;
            }
            // Added this here so that we break out of the loop right away in case we throw a false
            // Also, in either case we return false so that the ng-if won't show it, because
            // for hide_if, the idea is that we WANT to throw a false, so that it never shows in the ng-if

            if (results.indexOf(false) > -1) {
              return false;
            }
          }
        }
      }
      // In theory if you made it this far then it's probably true? Will have to consider if there's any edge cases where that doesn't happen
      return results.indexOf(false) === -1;
    };

    this.metaLabel = function (options) {
      if (options && options.form_field) {
        var form_field = options.form_field,
          meta = options.form_field.meta,
          country = options.country,
          person = options.person, // Boolean
          type = options.type;

        if (type !== undefined) {
          var label = form_field.label;
          for (var key in meta.label) {
            if (type === key) {
              // The key in the label matches the supplied type
              label = meta.label[key];
              break;
            }
          }
          return label;
        } else {
          switch (country) {
            case 'US':
              var label = form_field.label;
              if (person && meta.us_label && meta.us_label.person) {
                label = meta.us_label.person;
              } else if (meta.us_label && meta.us_label.non_person) {
                label = meta.us_label.non_person;
              }
              return label;
            default:
              // set the default label value to return, then decide if it meets special criteria
              var label = form_field.label;
              if (typeof (meta.non_us_label) === 'object') {
                if (meta.non_us_label[country]) {
                  label = meta.non_us_label[country];
                } else if (meta.non_us_label.else) {
                  // If we're non-us and there's an "else" for when it's not on the list, then use that
                  label = meta.non_us_label.else;
                }
              } else if (typeof (meta.non_us_label) === 'string') {
                label = meta.non_us_label;
              }
              return label;
          }
        }
      }
    };

    this.toggleMultivalue = function (resource, field, key, validate, i) {
      if (resource[field] == undefined) {
        resource[field] = [];
      }

      var index = resource[field].indexOf(key);
      if (index > -1) {
        resource[field].splice(index, 1);
      } else {
        resource[field].push(key);
      }
      if (typeof (validate) == 'function') {
        validate(field, i);
      }
    };

    this.toQueryString = function (obj, urlEncode) {
      //
      // Helper function that flattens an object, retaining key structure as a path array:
      //
      // Input: { prop1: 'x', prop2: { y: 1, z: 2 } }
      // Example output: [
      //     { path: [ 'prop1' ],      val: 'x' },
      //     { path: [ 'prop2', 'y' ], val: '1' },
      //     { path: [ 'prop2', 'z' ], val: '2' }
      // ]
      //
      function flattenObj(x, path) {
        var result = [];

        path = path || [];
        Object.keys(x).forEach(function (key) {
          if (!x.hasOwnProperty(key)) return;

          var newPath = path.slice();
          newPath.push(key);

          var vals = [];
          if (typeof x[key] == 'object') {
            vals = flattenObj(x[key], newPath);
          } else {
            vals.push({ path: newPath, val: x[key] });
          }
          vals.forEach(function (obj) {
            return result.push(obj);
          });
        });

        return result;
      } // flattenObj

      // start with  flattening `obj`
      var parts = flattenObj(obj); // [ { path: [ ...parts ], val: ... }, ... ]

      // convert to array notation:
      parts = parts.map(function (varInfo) {
        if (varInfo.path.length == 1) varInfo.path = varInfo.path[0]; else {
          var first = varInfo.path[0];
          var rest = varInfo.path.slice(1);
          varInfo.path = first + '[' + rest.join('][') + ']';
        }
        return varInfo;
      }); // parts.map

      // join the parts to a query-string url-component
      var queryString = parts.map(function (varInfo) {
        return varInfo.path + '=' + varInfo.val;
      }).join('&');
      if (urlEncode) return encodeURIComponent(queryString); else return queryString;
    }

    this.toTrustedHTML = function (html) {
      return _this.inject('$sce').trustAsHtml(html);
    };

    this.typeaheadIsSet = function (options, id) {
      if (!options || !id) { return; }

      for (var key in options) {
        if (options[key].id === id) {
          return true;
        }
      }
    };

    this.urlParams = function () {
      var urlParams = {};
      (window.onpopstate = function () {
        var match,
          pl = /\+/g,  // Regex for replacing addition symbol with a space
          search = /([^&=]+)=?([^&]*)/g,
          decode = function (s) { return decodeURIComponent(s.replace(pl, ' ')); },
          query = window.location.search.substring(1);

        urlParams = {};
        while (match = search.exec(query))
          urlParams[decode(match[1])] = decode(match[2]);
      })();
      return urlParams;
    };

    /********************************************************************************************
    MODAL METHODS
    ********************************************************************************************/

    // If we have enough methods that a modal needs to use, we'll put them all in here and then they'll be appending to "that"
    this.modalActions = function (that, $modalInstance) {
      that.cancel = function () {
        $modalInstance.dismiss('cancel');
      };
    };

    this.createModal = function (options) {
      /*  PARAMS
        controller    - required
        templateUrl   - required
        animation     - optional
        backdrop      - pass true if you want to be able to click the background to close (set default static, since it's probably annoying to accidentaly close your modal)
        controllerAs  - optional
        resolve       - optional
      */
      // if(!options){ return; };

      let parent = $document[0].querySelector('.main-content');
      // console.log(parent, angular.element(parent))
      var modal = _this.inject('$uibModal'),
        params = {
          animation: true,
          // backdrop: 'static',
          size: 'lg',
          appendTo: angular.element(parent)
        };

      for (var key in options) {
        params[key] = options[key];
      }

      return $uibModal.open(params);
      // return modal.open(params);
    };

    /********************************************************************************************
    RESOURCE METHODS
    ********************************************************************************************/
    this.apiHost = ENV.apiHost;

    this.call = function (factory, action, options, success, failure) {
      var f = _this.inject(factory);
      return f[action](options).$promise.then(
        function (data) {
          if (data && typeof (data.toJSON) === 'function') {
            data = data.toJSON();
          }
          if (typeof success == 'function') { success(data) };
          return data;
        },
        function (e) {
          if (typeof failure == 'function') { failure(e) };
          return e;
        });
    };

    this.formDataObject = function (data) {
      var fd = new FormData(),
        _json = {};
      angular.forEach(data, function (value, key) {
        if (value && value.constructor === File) {
          fd.append(key, value);
        } else {
          _json[key] = value;
        }
      });
      fd.append('_json', JSON.stringify(_json));
      return fd;
    };

    this.host = ENV.host;

    this.objectLength = function (object) {
      var l = 0;
      if (Object.keys === 'function') {
        l = Object.key(object).length;
      } else {
        for (var key in object) {
          ++l;
        }
      }
      return l;
    };

    this.printHTML = function (html) {
      var WinPrint = window.open('', '', 'left=0,top=0,width=600,height=600,toolbar=0,scrollbars=1,status=0');
      WinPrint.document.write(html);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    };

    this.submit = function (that, factory, action, options, resource, success, failure) {
      var service = _this.inject(factory);

      // CUSTOM COMMON OWNER 
      // Send investment data back to out project controller
      // send (resource)
      // postMessage({
      //   type: 'common-owner',
      //   // event: 'investment-created',
      //   event: 'invest-now-update',
      //   data: resource
      // }, location.origin);

      // console.log('submit', factory, action, options)
      return service[action](options, resource).$promise.then(
        function (data) {
          that.errors = {};
          // Instead of having to rely on doing this in the success methods, we'll do it right here
          if (data && typeof (data.toJSON) === 'function') {
            data = data.toJSON();
          }

          if (typeof success === 'function') { success(data) }
          return data;
        },
        function (e) {
          _this.setErrors(that, e);
          if (typeof failure === 'function') { failure(e) }
          return e;
        });
    };

    this.submitById = function (that, factory, action, options, resource, success, failure) {
      var service = _this.inject(factory),
        id = options.id ? options.id : resource.id;

      if (that.errors == undefined) { that.errors = {}; }
      if (id && that.errors[id] === undefined) { that.errors[id] = {}; }

      // console.log('submitById', factory, action, options)
      return service[action](options, resource).$promise.then(
        function (data) {
          that.errors = {};
          // Instead of having to rely on doing this in the success methods, we'll do it right here
          if (data && typeof (data.toJSON) === 'function') {
            data = data.toJSON();
          }
          if (typeof success == 'function') { success(data) };
          return data;
        },
        function (e) {
          if (e.data) {
            var data = e.data;
            that.errors[id] = {};
            for (var key in data) {
              that.errors[id][key] = data[key];
            }
          }
          if (typeof failure == 'function') { failure(e) };
          return e;
        });
    };

    this.submitByIndex = function (that, i, target, action, options, object, success, failure, update) {
      // console.log('submitByIndex', factory, action, options)
      var factory = _this.inject(target);
      return factory[action](options, object).$promise.then(
        function (data) {
          that.errors = {};
          _this.setStatusErrors(that, data.status);
          if (typeof success == 'function') { success(data) };
        },
        function (e) {
          _this.setErrors(that, e, i);
          if (typeof failure == 'function') { failure(e) };
        });
    };

    // When using, pass in $scope, the errors object from the promise
    this.setErrors = function (that, errors, i) {
      // Since I'm passing in $scope, there's no need for a return, I'm manipulating the page's scope directly.
      var data = errors.data,
        index = 0;
      // I'm setting a rule that when using the JSON form data, always assign it to $scope.form
      that.errors = {};
      angular.forEach(data, function (value, key) {
        that.errors[key] = value;
        if (index == 0) {
          var id = key;
          if (i != undefined) { id = id + '_' + i; }
          // We look for the "for" for the appropriate id, otherwise go to the ID
          // We look for the first id that matches the query based on the key (with optional index)
          var fuzzy_divid = document.querySelector('[id$="' + id + '"]'),
            fuzzy_for = document.querySelector('[for$="' + id + '"]');
          if (fuzzy_for) {
            fuzzy_for.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          } else if (fuzzy_divid) {
            fuzzy_divid.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        }
        index++;
      });
    };

    this.setStatusErrors = function (that, status) {
      // Sometimes that is undefined, so let's make it an object otherwise
      if (that === undefined) {
        that = {};
      }
      if (that.errors === undefined) {
        that.errors = {};
      }
      if (status === 404) {
        that.errors.response = ['The requested resource could not be found.'];
      } else if (status === 403) {
        that.errors.response = ['You are unauthorized to access this resource.'];
      } else if (status === -1 || status === '') {
        that.errors.response = ['The resource timed out or could not be found, please try again.'];
      } else if (status === 200 || status === 422) {
        delete that.errors.response;
      }
    };

    //pass in the field being validated, the $scope, the target service/factory, the action, options, and object to be validated.
    this.validate = function (field, that, factory, action, options, object, success, failure) {
      var f = _this.inject(factory);

      if (that.errors == undefined) {
        that.errors = {};
      }

      return f[action](options, object).$promise.then(
        function (data) {
          delete that.errors[field];
          if (typeof success == 'function') { success(data, true) };
          return data;
        },
        function (e) {
          if (e.data) {
            var data = e.data;
            that.errors[field] = data[field];
          }
          if (typeof failure == 'function') { failure(e, false) };
          return e;
        });
    };

    this.validateById = function (field, that, factory, action, options, resource, success, failure) {
      var service = _this.inject(factory),
        id = options.id ? options.id : resource.id;

      function createErrors() {
        if (that.errors == undefined) { that.errors = {}; }
        if (id && that.errors[id] === undefined) { that.errors[id] = {}; }
      }
      // Create the error objects if they don't exist
      createErrors();

      return service[action](options, resource).$promise.then(
        function (data) {
          delete that.errors[id][field];
          if (typeof success == 'function') { success(data) };
          return data;
        },
        function (e) {
          if (e.data) {
            var data = e.data;
            // Due to a timing bug when changing rows AND doing a validation for the page-row directive,
            // we're making sure the error object exists here
            // createErrors();
            if (that.errors && that.errors[id]) {
              that.errors[id][field] = data[field];
            }
          }
          if (typeof failure == 'function') { failure(e) };
          return e;
        });
    };

    /********************************************************************************************
    SETTINGS METHODS
    ********************************************************************************************/

    this.ckOptions = function () {
      // This stuff should eventually let us upload images through the editor!
      // var csrf_token = $('meta[name=csrf-token]').attr('content'),
      //     csrf_param = $('meta[name=csrf-param]').attr('content');

      return {
        height: 500,
        extraPlugins: 'justify',//,uploadimage
        removeButtons: 'About,Image,Scayt,Strike,Indent,Outdent',
        removePlugins: 'scayt, wsc',
        allowedContent: true,
        // filebrowserUploadUrl: '/ckeditor/pictures?'
        // filebrowserUploadUrl: '/ckeditor/pictures?' + csrf_param + '=' + encodeURIComponent(csrf_token)
      }
    };

    /********************************************************************************************
    UTILITY METHODS
    ********************************************************************************************/

    this.addCommas = function (nStr) {
      nStr += '';
      var x = nStr.split('.');
      var x1 = x[0];
      var x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
      }
      return x1 + x2;
    };

    // This method takes an object and a string
    // The string is a dot concatinated string of keys
    this.byString = function (obj, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      var a = s.split('.');
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        // "in" blows up when the object is null
        if (obj !== null && k in obj) {
          obj = obj[k];
        } else {
          return;
        }
      }
      return obj;
    };

    this.toBool = function (string) {
      return string ? string.match(/^(true|yes|t|y|1)$/i) : false;
    };

    this.inject = function (target) {
      var service;
      try { service = $injector.get(target); }
      catch (e) { return console.log(target + ' not found or spelt wrong'); }
      finally { return service; }
    };

    this.multiQuery = function (object, query) {
      // query is an array of objects
      var isTrue = false
      if (object) {
        var resource = angular.copy(object);
        for (var i = 0; i < query.length; i++) {
          for (var key in query[i]) {
            for (var operator in query[i][key]) {
              if (_this.operators[operator](_this.byString(resource, key), query[i][key][operator])) {
                isTrue = true;
              } else {
                isTrue = false;
                break;
              }
            }
          }
        }
      }
      return isTrue;
    };

    this.objQuery = function (resources, query) {
      if (resources.length > 0) {
        // i is the index of the array
        for (var i in query) {
          // Key of the field to compare to.
          for (var key in query[i]) {
            // operator is the actual operator to use
            for (var operator in query[i][key]) {
              // call the operators service
              // passing in operator as the key, then the two values to compare
              if (_this.operators[operator](resources[0][key], query[i][key][operator])) {
                return i;
              }
            }
          }
        }
      }
    };

    this.operators = {
      '+': function (a, b) { return a + b },
      '-': function (a, b) { return a - b },
      '*': function (a, b) { return a * b },
      '/': function (a, b) { return a / b },
      '<': function (a, b) { return a < b },
      '>': function (a, b) { return a > b },
      '<=': function (a, b) { return a <= b },
      '>=': function (a, b) { return a >= b },
      '==': function (a, b) { return a == b },
      '===': function (a, b) { return a === b },
      '!=': function (a, b) { return a != b },
      '!==': function (a, b) { return a !== b }
    };

    this.origin = function () {
      var origin;
      if (typeof (window.location.origin) === 'string') {
        origin = window.location.origin;
      } else {
        origin = window.location.protocol + '//' + window.location.host;
      }
      return origin
    };

    this.remap = function (resources, remapObj) {
      if (resources) {
        var newResources = angular.copy(resources);
        // Recursive method that'll continue to dive in to objects, and remap values to the base object
        var remapToParent = function (resource, remapObj, key, baseObj, x) {
          if (typeof (remapObj[key][x]) == 'object') {
            var newRemap = remapObj[key][x];
            for (var k in newRemap) {
              for (var y = 0; y < newRemap[k].length; ++y) {
                remapToParent(resource[key], newRemap, k, baseObj, y);
              }
            }
          } else {
            var keyName = key + '.' + remapObj[key][x];
            if (resource && resource[key]) {
              baseObj[keyName] = resource[key][remapObj[key][x]];
            }
          }
        }

        // Loop through the resources, it's an array of objects
        for (var i = 0; i < resources.length; i++) {
          // Loop through the keys in the remap object
          for (var key in remapObj) {
            // Loop through the array of strings for the key, remapping the child to its parent
            for (var x = 0; x < remapObj[key].length; x++) {

              remapToParent(newResources[i], remapObj, key, newResources[i], x);
            }
          }
        }
        return newResources;
      }
    };

    // This allows you to reorder the keys in an object, in the case that order matters
    this.reorder = function (resources, orderArray) {
      if (resources) {
        var newResources = [];

        for (var i = 0; i < resources.length; i++) {
          newResources[i] = {};
          for (var key in orderArray) {
            newResources[i][orderArray[key]] = resources[i][orderArray[key]];
          }
        }
        return newResources;
      }
    };

    // Recursive method for setting a nested value in a hash/object
    this.setValue = function (obj, s, value) {
      if (typeof (s) == 'string') {
        s = s.split('.');
      }
      if (obj[s[0]] == undefined || obj[s[0]] == null) {
        obj[s[0]] = {};
      }
      if (s.length > 1) {
        _this.setValue(obj[s.shift()], s, value);
      } else {
        obj[s[0]] = value;
      }
    };

    this.tofixedNum = function (num, decimals) {
      // This method returns a string version of a number to a fixed decimal place
      var isNegative = (num && num.substring(0, 1) === '-') ? true : false;
      return num ? (isNegative ? '-' : '') + parseFloat((num).replace(/[^0-9\.]/g, '')).toFixed((decimals !== undefined) ? Number(decimals) : 2) : undefined;
    };

    this.toNumber = function (amount) {
      if (amount === '') {
        return amount;
      } else if (amount !== undefined) {
        var num = (typeof (amount.replace) === 'function') ? Number(amount.replace(/[^0-9\.]/g, '')) : amount,
          isNegative = (typeof (amount.substring) === 'function') ? amount.substring(0, 1) === '-' : false;
        // If number is amount (basically it wasn't modified), then just return amount, otherwise return the new number
        return (num === amount) ? amount : (isNegative ? (num * -1) : num);
      }
    };

    // This method creates a "backup" of an object, then allows editing. If you toggle it back, it'll restore the original object
    // Success shortcircuts the first if so that readonly gets set to false but we keep the existing data and update the backup data
    this.toggleReadOnly = function (that, key, success) {
      that.readOnly = !that.readOnly;
      if (that.readOnly == false || success) {
        that.template_backup = angular.copy(that[key]);
      } else {
        that[key] = angular.copy(that.template_backup);
        that.errors = {};
      }
    };

  })

    .factory('broadcastService', ['$rootScope',
      function ($rootScope) {
        return {
          send: function (msg, data) {
            $rootScope.$broadcast(msg, data);
          }
        };
      }]);

}