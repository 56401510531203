
/* injects from baggage-loader */

'use strict';

export default class AccountProjectsController {

	constructor($log, $rootScope, User) {
		'ngInject';

		this.$log = $log;
		this.$rootScope = $rootScope;
		this.userService = User;

	}

	$onInit() {
		
		this.$rootScope.$emit('updateAccountHeading', {
			title: 'My Offerings',
			subtitle: 'Manage your projects',
			showGreeting: false
		});

	}
	
}
