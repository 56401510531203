
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('InvestmentCalculator', function () {

		this.NET_WORTH_THRESHHOLD = 124000;
		this.INVESTMENT_CAP = 2500;

		// how to get [raw_invest_limit]
		// IF [income] > [net_worth] THEN [raw_invest_limit] = [income] ELSE [raw_invest_limit] = [net_worth]

		// how to get [investor_class]
		// IF [income] < 107,000 OR [net_worth] < 107,000 THEN [investor_class] = A ELSE [investor_class] = B

		// how to get [true_invest_limit]
		// IF [Investor_class] = A THEN... 
		// IF [raw_invest_limit] * 0.05 >= $2,200 THEN [true_invest_limit] = [raw_invest_limit] * 0.05 - [invested_past_12months] 
		// ELSE [true_invest_limit] = $2,200 - [invested_past_12months]

		// IF [Investor_class] = B THEN...
		// [true_invest_limit] = [raw_invest_limit] * 0.1 - [invested_past_12months] 
		
		this.calculate = (input = {}) => {
			const income = parseInt(input.income) || 0;
			const net_worth = parseInt(input.net_worth) || 0;
			const invested_past_12months = parseInt(input.invested_past_12months) || 0;

			let raw_invest_limit, investor_class, true_invest_limit;

			// 1. Calculate the "raw_invest_limit"
			raw_invest_limit = income > net_worth ? income : net_worth;

			// 2. Calculate the investor class
			if (this.NET_WORTH_THRESHHOLD > raw_invest_limit) {
				investor_class = "A";
			} else {
				investor_class = "B";
			}

			// 3. Calcuate the "true_invest_limit"
			if (investor_class === "A") {
				if (raw_invest_limit * 0.05 >= this.INVESTMENT_CAP) {
					true_invest_limit = (raw_invest_limit * 0.05) - invested_past_12months;
				} else {
					true_invest_limit = this.INVESTMENT_CAP - invested_past_12months;
				}
			}

			if (investor_class === "B") {
				true_invest_limit = (raw_invest_limit * 0.1) - invested_past_12months
			}

			if (true_invest_limit < 0) {
				true_invest_limit = 0;
			}

			return {
				raw_invest_limit,
				true_invest_limit,
				investor_class
			}

		}
		
	});
}

