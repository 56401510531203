
/* injects from baggage-loader */

'use strict';

import AccountInvestmentController from './account.investment.controller';
import AccountInvestmentTemplate from './account.investment.html';

export default class AccountInvestmentComponent {
		constructor() {
				this.templateUrl = AccountInvestmentTemplate;
				this.controller = AccountInvestmentController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					investment: '<',
					project: '<',
					messages: '<',
					documents: '<',
					reconfirmations: '<',
					subscriptionAgreement: '<',
					accreditation: '<'
				};
		}
}
