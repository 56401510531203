
/* injects from baggage-loader */

'use strict';

import AccountController from './account.controller';
import AccountTemplate from './account.html';

export default class AccountComponent {
		constructor() {
				this.templateUrl = AccountTemplate;
				this.controller = AccountController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = {};
		}
}
