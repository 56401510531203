
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('Documents', function (Parse, InvestorDocumentSchema) {

		// ==================================================
		// Load object schema
		// ==================================================
		let InvestorDocument = InvestorDocumentSchema;

		// ==================================================
		// Methods
		// ==================================================

		/**
		 * Retrieve the list of Investments
		 */
		this.list = () => {
			let query = new Parse.Query(InvestorDocument);
			query.include('Project');
			query.select('name', 'type', 'file', 'Project.title');
			return query.find();
		};


		/**
		 * Retrieve a document by ID
		 */
		this.get = id => {
			let query = new Parse.Query(InvestorDocument);
			return query.get(id);
		}
		
	});
}

