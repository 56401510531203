
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('ProjectActivity', function (ProjectActivitySchema, Parse) {

		// Load object schema
		const ProjectActivity = ProjectActivitySchema;

		// ==================================================
		// Methods
		// ==================================================


		/**
		 * Retrieve the list of ProjectActivity for the project change history
		 */
		this.list = project => {
			let query = new Parse.Query(ProjectActivity);
			query.equalTo('project', project);
			query.notEqualTo('feed', true);
			query.descending('createdAt');
			return query.find().then(res => {
				res.push({
					message: 'Project created',
					user: project.creator,
					createdAt: project.createdAt
				})
				return res;
			})
		};


		/**
		 * Retrieve the list of ProjectActivity for the project activity feed
		 */
		this.listFeed = project => {
			let query = new Parse.Query(ProjectActivity);
			query.equalTo('project', project);
			query.equalTo('feed', true);
			query.descending('createdAt');
			return query.find();
		};
		
	});
}

