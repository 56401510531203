
/* injects from baggage-loader */

'use strict';

import $ from 'jquery';

export default function (app) {

	app.directive('scrollShow', ScrollShowDirective);

	function ScrollShowDirective($window) {
		'ngInject';

		return {
			restrict: 'A',
			link: linkFn,
			bindings: { 
				// ID of element to track ending position of
				trackElement: '<', 
				// Amount of pixels to offset the end of the tracked element
				offset: '<' 
			}
		};

		function linkFn(scope, elem, attrs) {

			// Establish offset from the top of the page
			let offset = Number(attrs.scrollShow || 0);
			let trackElement = $('#' + attrs.trackElement);

			// Listen for window scroll events
			angular.element($window).bind("scroll", function () {

				// Update the offset value based on the tracked element
				if (typeof trackElement !== 'undefined') {
					offset = trackElement.position().top + trackElement.outerHeight();
					offset += typeof attrs.offset !== 'undefined' ? Number(attrs.offset) : 0;
				}

				// console.log(this.pageYOffset)
				if (this.pageYOffset >= offset) {
					// $(elem).attr('style', 'display:block');
					$(elem).addClass('fade-show-hidden');
					// console.log('Scrolled below header.');
				} else {
					// $(elem).attr('style', 'display:none');
					$(elem).removeClass('fade-show-hidden');
					// console.log('Header is in view.');
				}
				scope.$apply();
			});

		}

	}
}