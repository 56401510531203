
/* injects from baggage-loader */

'use strict';

export default class ModerateCommentModalController {

	constructor($log, $rootScope, $timeout, Comments) {
		'ngInject';

		this.$log = $log;
		this.$rootScope = $rootScope;
		this.$timeout = $timeout;
		this.commentService = Comments;

		this.threadMembers = [];
		this.selectedComment = null;

	}

	$onInit() {

		// Set modal context
		this.comment = this.resolve.context.comment;
		this.comments = this.resolve.context.comments;
		this.focus = this.resolve.context.focus;

		// Filter comment thread members
		// this.commentService.getThreadMembers(this.ctx.comment, this.ctx.comments)
		this.threadMembers = this.comments.reduce((comments, comment) => {
			if (comments.find(c => comment.parent && c.id === comment.parent.id && comment.author.id !== c.author.id)) {
				comments.push(comment)
			}
			return comments;
		}, [this.comment])
			.map(comment => ({
				id: comment.author.id,
				name: `${comment.author.get('first_name')} ${comment.author.get('last_name')}`
			}))


		// Listen for a selected comment
		this.$rootScope.$on('commentClicked', (event, ctx) => {
			this.selectedComment = ctx.comment;
			this.commentActionCallbacks = ctx.callbacks;
			if (this.selectedComment.unread) {
				this.markRead();
			}
		});

		// Click on focus comment
		// if (this.focus) {
		// 	this.$timeout(() => {
		// 		let focusComment = angular.element(document.querySelector(`#comment-${this.focus.id}`));
		// 		if (typeof focusComment.click === 'function') {
		// 			focusComment.click();
		// 		}
		// 	}, 100);
		// }

		// Select the first comment
		this.$timeout(() => {
			let focusComment = angular.element(document.querySelector(`#comment-${this.comment.id}`));
			if (typeof focusComment.click === 'function') {
				focusComment.click();
			}
		}, 100);

	}

	markRead() {
		this.commentActionCallbacks.markRead();
	}

	deleteComment() {
		this.commentActionCallbacks.delete();
	}

	replyToComment() {
		this.commentActionCallbacks.openCommentBox();
	}

	ok() {
		this.close({ $value: true });
	}

	cancel() {
		this.dismiss({ $value: 'cancel' });
	}

}
