
/* injects from baggage-loader */

'use strict';

import ModerateCommentModalComponent from './moderatecommentmodal.component';
import './moderatecommentmodal.scss';

const ModerateCommentModalModule = angular.module('moderate-comment-modal-module', []);

ModerateCommentModalModule
    .component('moderateCommentModal', new ModerateCommentModalComponent());

export default ModerateCommentModalModule;

