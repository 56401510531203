
/* injects from baggage-loader */

'use strict';

import HomeComponent from './home.component';
import './home.scss';

const HomePageModule = angular.module('home-module', [
    'ui.router'
  ])
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject';

    $urlRouterProvider.otherwise('/');

    $stateProvider
      .state('home', {
        parent: 'root',
        url: '/',
        component: 'home',
        title: 'Home',
        transparent_nav: true,
        // Resolve promises into controller bindings, see component for bindings
        resolve: { 
          projects: function(Projects, Parse, MediaSchema, ProjectStatsSchema) {
            // TODO: return 3 projects, only 'select' fields needed
            // return Projects.list({ limit: 3 });
            return Parse.Cloud.run('getFeaturedProjects');
          }
        }
      });
  })
  .component('home', new HomeComponent());

export default HomePageModule;
