
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('AccreditationSchema', function (Parse) {

		// ==================================================
		// Instantiate Objects
		// ==================================================
		let Accreditation = Parse.Object.extend('Accreditations');

		// Define 'Investment' scheme
		let schema = [
			'verified',
			'verified_at',
			'verification_requested',
			'verification_requested_at',
			'request_status',
			'external_status',
			'submitted',
			'has_new_documents',
			'more_info_requested'
		];

		// Load schema into object prototype
		schema.forEach(function (field) {
			Object.defineProperty(Accreditation.prototype, field, {
				get: function () { return this.get(field); },
				set: function (value) { return this.set(field, value); }
			});
		});	
    
    return Accreditation;
		
	});
}