
/* injects from baggage-loader */

'use strict';

import NorthCapitalWireInstructionsComponent from './north-capital-wire-instructions.component';
import './north-capital-wire-instructions.scss';

const NorthCapitalWireInstructionsModule = angular.module('north-capital-wire-instructions-module', []);

NorthCapitalWireInstructionsModule
    .component('northCapitalWireInstructions', new NorthCapitalWireInstructionsComponent());

export default NorthCapitalWireInstructionsModule;

