
/* injects from baggage-loader */

'use strict';

import HeaderTemplate from './header.html';
import HeaderController from './header.controller';

export default class HeaderComponent {
    constructor() {
        this.templateUrl = HeaderTemplate;
        this.controller = HeaderController;
        this.controllerAs = 'nav';
    }
}