
/* injects from baggage-loader */

'use strict';

export default function (app) {
  app.constant('FAQS', [{
    title: 'What is Common Owner?',
    body: 'Common Owner is a platform where you can browse investment opportunities.'
  },
  {
    title: 'What kinds of projects will I see on Common Owner?',
    body: 'You will see projects or funds with multiple projects in Qualified Opportunity Zones. And you may also see a project for the rehabilitation of a historic building that has fallen into disrepair, new construction in a historic district, the conversion of an old warehouse into residential lofts, or any other kind of real estate project involving old buildings or places.',
  },
  {
    title: 'Who can invest?',
    body: 'Anybody can invest in an offering with the label "Reg CF" and only accredited investors can invest in offerings with the label "506C".',
  },
  {
    title: 'What kinds of investments can I make?',
    body: 'We will offer investments in the form of debt, where you are lending money to the project; equity, where you are participating in the ownership of the project; and so-called “hybrid” investments that combine features of both debt and equity.',
  },
  {
    title: 'What is an accredited investor?',
    body: 'In general, a person is an “accredited investor” if she (i) earns at least $200,000 per year, or $300,000 per year with her husband; or (ii) has a net worth of at least $1 million, excluding her principal residence.',
  },
  {
    title: 'What are the fees?',
    body: 'To post an offering on the portal there is a $2,500 listing fee. If an offering is successful there is a 5% success fee. There are other minimal third-party fees for due diligence and escrow.',
  },
  ]);

  app.constant('HTCTERMS1', [{
    title: 'Anticipated Total Project Costs',
    body: 'The estimate of costs to carry out the project, expressed in dollars. This could vary from $1 million to $20 Million+.'
  },
  {
    title: 'Anticipated Qualified Rehabilitation Expenses (QREs)',
    body: 'This is the cost of the rehabilitation of the building. It does not include things like acquisition, site work, personal property, etc. This could range from $600,000 to $18 million+.  In example $4,000,000.',
  },
  {
    title: 'Asset Management Fee',
    body: 'An annual amount paid to the investor for analyzing the investment. Could be anywhere from zero to $25,000. In example $5,000.',
  },
  {
    title: 'Developer Fee',
    body: 'A fee paid back from the development Net Cash Flow to repay the developer after payment of the Priority Return. It is usually around 10-15% of the total project cost and is typically repaid over 5 years.',
  },
  {
    title: 'Federal Credit Pricing',
    body: 'This is a per-dollar price of capital contributed for each federal historic tax credit generated by the project. This could vary from $0.50 to $0.99 but will usually be in the $0.75 to $0.85 range. In example $0.80.',
  }]);

  app.constant('HTCTERMS2', [{
    title: 'Internal Rate of Return (IRR)',
    body: 'This is the amount the investment has netted to the Investor. This is based off of the benefits (including losses) recognized by the investor.'
  }, {
    title: 'Membership Interest & Flip',
    body: 'Typically the interests in the company in which the investment is made will flip from 99%-1% (during the investment recapture period) to 5%-95%.'
  }, {
    title: 'Put Price',
    body: 'Usually the lesser of the "market value" of the investor member\'s (post-flip) 5% interest and a fixed amount ranging from $1,000 to $10,000. In example $5,000.'
  }, {
    title: 'Priority Return',
    body: 'This is a return to the investor out of net cash flow following operating expenses and debt service. It is expressed as a percentage of the total investment. Could range from 0.5-3%. Will usually be about 2%.'
  }, {
    title: 'State Tax Credit Pricing',
    body: 'This is a per-dollar price of capital contributed for each federal historic tax credit generated by the project. This could vary from $0.50 to $0.99 but will usually be in the $0.75 to $0.85 range. In example $0.80.'
  }]);

  app.constant('QOZTERMS1', [{
    title: 'Total Project Costs',
    body: 'The costs to carry out the project, expressed in dollars. This could vary from $1 million to $20 Million+. In example $5,000,000.'
  },
  {
    title: 'Membership Interests Offered',
    body: 'The percentage of the Company being sold to these investors.',
  },
  {
    title: 'Total Raise Range',
    body: 'The Minimum and Maximum Amounts the Issuer will accept in exchange for the interests.',
  },
  {
    title: 'Priority Return',
    body: 'This is a return to the investor out of net cash flow following operating expenses and debt service. It is expressed as a percentage of the total investment. Could range from 0.5-10%.',
  }]);

  app.constant('QOZTERMS2', [{
    title: 'Year of Estimated Valuation',
    body: 'Value of Investment at year 5, 7, or 10. Some Investors may wish to exit at this point as it is when the first basis step-up tax benefit kicks in.'
  },
  {
    title: 'Estimated Return of Capital',
    body: 'When the investor will get their initial investment Back. Expressed in Months. Could Range from 4 months to 24 Months.',
  },
  {
    title: 'Internal Rate of Return',
    body: 'This is the amount the investment has netted to the Investor.'
  }]);
}