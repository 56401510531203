
/* injects from baggage-loader */

'use strict';

import PrivacyPolicyController from './privacy-policy.controller';
import PrivacyPolicyTemplate from './privacy-policy.html';

export default class PrivacyPolicyComponent {
		constructor() {
				this.templateUrl = PrivacyPolicyTemplate;
				this.controller = PrivacyPolicyController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				// this.bindings = { };
		}
}
