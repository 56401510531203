
/* injects from baggage-loader */

'use strict';

export default function (app) {
	app.service('MetaTags', function (STATIC_PAGE_METADATA) {
		let metaTags = {
			url: '',
			title: '',
			description: '',
			image: '',
			keywords: ''
		}
		return {
			set: (data = {}, bash = true) => bash ? metaTags = data : metaTags = Object.assign(metaTags, data),
			getUrl: () => metaTags.url,
			getTitle: () => metaTags.title,
			getDescription: () => metaTags.description,
			getImage: () => metaTags.image,
			getKeywords: () => metaTags.keywords,
			setStaticPageData: (page = '_default') => {
				if (STATIC_PAGE_METADATA[page]) {
					// Assign a direct match
					Object.assign(metaTags, STATIC_PAGE_METADATA[page]);
				} else {
					// Find a similar/parent route to correct to
					for (let key in STATIC_PAGE_METADATA) {
						if (page.startsWith(key)) {
							Object.assign(metaTags, STATIC_PAGE_METADATA[key]);
							return true;
						}
					}

					// Assign site default
					Object.assign(metaTags, STATIC_PAGE_METADATA['_default']);
				}
			}
		}
	});
}

