
/* injects from baggage-loader */

'use strict';

export default function(app) {

    app.service('TeamMembers', function (Parse, TeamMembersSchema) {

        let TeamMembers = TeamMembersSchema;

        // retrieve dataset Team Members
        this.list = () => {
            let query = new Parse.Query(TeamMembers);
            return query.find();
        }
    });
}