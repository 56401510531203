
/* injects from baggage-loader */

'use strict';

import NorthCapitalWireInstructionsController from './north-capital-wire-instructions.controller';
import NorthCapitalWireInstructionsTemplate from './north-capital-wire-instructions.html';

export default class NorthCapitalWireInstructionsComponent {
		constructor() {
				this.templateUrl = NorthCapitalWireInstructionsTemplate;
				this.controller = NorthCapitalWireInstructionsController;
				this.controllerAs = 'ctrl';
				// Bind resolved items to the controller, see module state for resolve
				this.bindings = { 
					amount: '<',
					issuerName: '<',
					investorName: '<'
				};
		}
}
