
/* injects from baggage-loader */

'use strict';

import HeaderComponent from './header.component';
import './header.scss';
import './hamburger-menu.scss';

const HeaderModule = angular.module('header-module', []);

HeaderModule
    .component('header', new HeaderComponent());

export default HeaderModule;
