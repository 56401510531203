
/* injects from baggage-loader */

'use strict';

import { capitalize } from 'lodash';
export default class AccountInvestmentController {

	constructor($log, $uibModal, $timeout, $filter, $document, $window, $rootScope, User, MessageSchema, Parse, Alerts, Projects) {
		'ngInject';

		this.$log = $log;
		this.$uibModal = $uibModal;
		this.$timeout = $timeout;
		this.$filter = $filter;
		this.$document = $document;
		this.$window = $window;
		this.$rootScope = $rootScope
		this.userService = User;
		// this.Message = MessageSchema;
		this.Parse = Parse;
		this.Alerts = Alerts;
		this.Projects = Projects;

		this.issues = [];
		this.tasks = [];
		// this.messages = [];
		this.reconfirmations = [];
		this.pending_reconfirmations = [];

		this.message = '';

		this.sendingMessage = false;
		this.reconfirmation_required = false;

		this.investmentCanceled;
		this.cancelationDeadline;

	}

	$onInit() {
		this.investor = this.investment.get('investor');
		this.$log.debug('Investment', this.investment);
		this.$log.debug('Project', this.project);
		this.$log.debug('Investor', this.investor);
		this.$log.debug('Accreditation', this.accreditation);
		// this.$log.debug(this.messages);
		// this.$log.debug(this.reconfirmations)
		// this.investment.project.type = '506c'

		// Update account heading title and message
		this.$rootScope.$emit('updateAccountHeading', {
			title: 'My Investments',
			subtitle: 'Manage your investments',
			showGreeting: false
		});

		this.issues = this.investment.issues || [];
		this.clearing_failures = this.investment.clearing_failures || ['funds_not_cleared'];
		this.cancelationDeadline = this.Projects.cancelationDeadline(this.investment.project, 'ddd, MMM Do YYYY, h:mm:ss a z');
		this.investmentCanceled = this.isInvestmentCanceled();

		// Stop processing init if canceled
		if (this.investmentCanceled) {
			return false;
		}

		// Run task evaluation against the investment data
		this.evaluateTasks();

		// this.$state.go(this.verificationStep, {
		// 	investmentId: investment.id
		// }, {
		// 	id: this.project.id,
		// });

		if (this.reconfirmations.length) {
			this.parseReconfirmationTasks();
		}

		// NOTE: "clearing_failures" possible values
		// ['accreditation_pending',
		// 	'aml_pending',
		// 	'funds_not_cleared',
		// 	'subscription_agreement_not_signed',
		// 	'trade_review_pending'
		// ]

		// Reverse messages
		// this.messages = this.messages.reverse();

		// this.chat = this.investment.chat;

	}

	evaluateTasks() {
		// Fund America task set
		this.tasks = [{
			title: 'Verify your identity',
			subtitle: 'Follow the link to verify your investor as an accredited investor.',
			completed: this.isTaskComplete('accreditation_pending'),
			visible: false,
			link: 'https://www.verifyinvestor.com/signup?utf8=✓&t=investor&referral=2&referral_note=Common+Owner+Portals+LLC&commit=Continue',
			state: '',
			fn: '',
			completed_on: ''
		}, {
			title: 'Send check to escrow bank',
			subtitle: 'Follow the link for instructions on sending your check to our escrow bank.',
			completed: ['received', 'invested'].includes(this.investment.status),
			visible: this.investment.payment_method === 'check',
			link: '',
			state: '',
			fn: () => this.openCheckMailingInstructionsModal(),
			completed_on: ''
		}, {
			title: 'Wire funds to escrow bank',
			subtitle: 'Follow the link for instructions on sending your wire transfer to our escrow bank.',
			completed: ['received', 'invested'].includes(this.investment.status),
			visible: this.investment.payment_method === 'wire',
			link: '',
			state: '',
			fn: () => this.openWireTransferInstructionsModal(),
			completed_on: ''
		}];

		// North Capital task set merged with FA tasks
		this.tasks = [...this.tasks, {
			title: 'KYC Verification',
			subtitle: this.getVerifyKycTaskMessage(),
			completed: this.isKycVerified(),
			visible: !this.isKycVerified(),
			state: this.getVerifyKycState(),
			completed_on: ''
		}, {
			title: 'Confirm Accredited Investor Status',
			subtitle: 'Click the link upload you accredited investor documents.',
			completed: this.isAccredited(),
			visible: this.shouldShowAccreditedTask(),
			state: `invest.accredited({ investmentId: '${this.investment.id}', id: '${this.investment.project.id}' })`,
			completed_on: ''
		}, {
			title: 'More Information Needed',
			subtitle: 'Additional documentation is needed to verify you accredited investor status.',
			completed: this.isAccredited(),
			visible: this.isAccreditationMoreInfoRequested(),
			state: `invest.accredited({ investmentId: '${this.investment.id}', id: '${this.investment.project.id}' })`,
			completed_on: ''
		}, {
			title: 'Wire Funds to Escrow Bank',
			subtitle: 'Click "GO" to view the wire instructions',
			// TODO: how can we determine if this is done
			//  Allow user to click "complete" in the modal?
			completed: this.investment.has('alternateFundingCompletedAt'),
			visible: this.investment.payment_method === 'WIRE' || this.investment.payment_method === 'IRA',
			fn: () => this.openWireTransferInstructionsModal(),
			completed_on: this.investment.get('alternateFundingCompletedAt')
		}, {
			title: 'Send Check to Escrow Bank',
			subtitle: 'Click "GO" to view the check mailing instructions',
			// TODO: how can we determine if this is done
			//  Allow user to click "complete" in the modal?
			completed: this.investment.has('alternateFundingCompletedAt'),
			visible: this.investment.payment_method === 'CHECK',
			fn: () => this.openCheckMailingInstructionsModal(),
			completed_on: this.investment.get('alternateFundingCompletedAt')
		}, {
			title: 'Sign Subscription Document with DocuSign',
			subtitle: 'Check your email for an invitation to sign your documents.',
			completed: this.investment.documentsSignedAt || this.investment.has('documentsSignedAt'),
			visible: true,
			completed_on: this.investment.documentsSignedAt || this.investment.has('documentsSignedAt')
		}, {
			title: 'Payment Failed',
			subtitle: `There was a problem processing funding of your investment. Please update your payment method.`,
			visible: this.investment.get(`fund_status`) === 'Returned',
			// TODO: implement this method
			fn: () => this.openUpdatePaymentMethodModal(),
		}];

		// Map all visible tasks
		this.tasks = this.tasks.filter(task => task.visible);
		this.$log.debug('Tasks', this.tasks);
	}

	getEscrowProvider() {
		return this.project.get('escrow_provider');
	}

	isEscrowNorthCapital() {
		return this.getEscrowProvider() === 'north_capital';
	}

	isEscrowPrimeTrust() {
		return this.getEscrowProvider() === 'prime_trust';
	}

	isRegCF() {
		return this.project.get('type') === "cf";
	}

	getFormattedPaymentMethod() {
		return capitalize(this.investment.get('payment_method'));
	}

	investmentHasIssue(key) {
		if (!key) {
			return this.issues.length;
		}
		return this.issues.includes(key);
	}

	isTaskComplete(t) {
		return typeof this.clearing_failures.find(f => f === t) === 'undefined' ? true : false;
	}

	isNeedsReconfirmation() {
		return this.pending_reconfirmations.length ? true : false;
	}

	isComplete() {
		return this.investment.get('complete');
	}

	isKycVerified() {
		return this.investor.get('kyc_verified');
	}

	getVerifyKycState() {
		if (this.investor.get('kyc_doc_uploaded')) {
			return '';
		}
		return `invest.kyc({ investmentId: '${this.investment.id}', id: '${this.investment.project.id}' })`;
	}

	getVerifyKycTaskMessage() {
		if (this.investor.get('kyc_doc_uploaded')) {
			return 'Pending document review';
		}
		return 'Click the link to complete your KYC verification.';
	}

	getFundingError() {
		if (!this.isPaymentReturned()) {
			return false;
		}
		return (this.investment.trx_error || 'Unknown').split('-').pop().trim();
	}

	isPaymentReturned() {
		return this.investment.get('fund_status') === `Returned`;
	}

	// ACCREDITATION METHODS

	shouldShowAccreditedTask() {
		return !this.isRegCF()
			&& !this.isAccreditedDocumentsSubmitted()
			&& this.investment.get('is_accredited')
			&& !this.investor.get('acc_verified');
	}

	isAccredited() {
		return this.accreditation.get('verified');
	}

	isAccreditedDocumentsSubmitted() {
		return this.accreditation.get('submitted');
	}

	isAccreditationPending() {
		return this.accreditation.get('submitted') && !this.accreditation.get('verified');
	}

	isAccreditationMoreInfoRequested() {
		return this.accreditation.get('more_info_requested') && !this.accreditation.get('verified');
	}

	// END: ACCREDITATION METHODS

	isInvestmentCanceled() {
		return this.investment.cancelledAt
			|| ['canceled', 'voided', 'refunded'].includes(this.investment.status);
	}

	isInvestmentInvested() {
		return this.investment.status === 'invested';
	}

	parseReconfirmationTasks() {

		// Filter pending reconfirmations
		this.pending_reconfirmations = this.reconfirmations.filter(r => !r.get('confirmed'));

		// Determind if reconfirmation is required
		this.reconfirmation_required = this.isNeedsReconfirmation();

		let tasks = this.tasks.filter(t => t.type !== 'investment_reconfirmation');

		// Append tasks list
		this.tasks = [...tasks, ...this.reconfirmations.map(r => ({
			title: 'Reconfirm Investment',
			subtitle: `The terms of the project were updated on ${this.$filter('date')(r.createdAt, 'longDate')} and by law we require that you reconfirm your initial investment.`,
			completed: r.get('confirmed'),
			visible: true,
			link: '',
			state: '',
			fn: () => this.openInvestmentReconfirmationModal(r),
			completed_on: '',
			type: 'investment_reconfirmation'
		}))];

		// Refresh pending tasks
		this.hasPendingTasks = this.tasks.filter(t => !t.completed).length;

	}

	openCheckMailingInstructionsModal() {

		let resolve = {
			amount: () => this.investment.amount,
			project: () => this.investment.project
		}

		if (this.isEscrowNorthCapital()) {
			// This is a north capital investment
			resolve.escrow_provider = () => this.getEscrowProvider();
		}

		if (this.isEscrowPrimeTrust() && this.investment.fa_data) {
			// This is a prime trust (fund america) investment
			resolve.escrow_provider = () => this.getEscrowProvider();
			resolve.instructions = () => ({
				amount: this.investment.amount,
				check_mailing_address: this.investment.fa_data.check_mailing_address,
				check_mailing_instructions: this.investment.fa_data.check_mailing_instructions
			})
		}

		this.$uibModal.open({
			animation: true,
			component: 'checkMailingInstructionsModal',
			resolve
		});
	}

	openWireTransferInstructionsModal() {

		let resolve = {
			amount: () => this.investment.amount,
			issuerName: () => this.project.get('issuer_name'),
			investorName: () => this.investment.get('investor_name')
		}

		if (this.investment.get('offering_id')) {
			// This is a north capital investment
			resolve.escrow_provider = () => 'north_capital';
		}

		if (this.investment.fa_data && this.investment.fa_data.wire_details) {
			// This is a prime trust (fund america) investment
			resolve.escrow_provider = () => 'prime_trust';
			resolve.wire_details = () => this.investment.fa_data.wire_details
		}

		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'wireInstructionsModalComponent',
			resolve
		});

		modalInstance.result.then(() => angular.noop, () => angular.noop);
	}

	openCancelInvestmentModal() {
		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'cancelInvestmentModal',
			resolve: {
				context: () => ({
					type: this.investment.project.type,
					investmentId: this.investment.id
				})
			}
		});

		modalInstance.result
			.then(() => this.investment.fetch())
			.then(async () => {
				// Canceled successfully
				this.Alerts.setLocal({
					type: 'success',
					msg: 'Investment canceled successfully'
				});
				this.$window.scrollTo(0, 0);
				this.$timeout();
			}, () => angular.noop);
	}

	async openInvestmentReconfirmationModal(reconfirmation) {

		let modalInstance = this.$uibModal.open({
			animation: true,
			backdrop: 'static',
			component: 'investmentReconfirmationModal',
			resolve: {
				context: () => ({
					reconfirmation: reconfirmation,
					investment: this.investment
				})
			}
		});

		modalInstance.result.then(async res => {

			if (res && res.success) {

				reconfirmation.completed = true;
				this.parseReconfirmationTasks();

				this.Alerts.setLocal({
					type: 'success',
					msg: 'Investment reconfirmed successfully'
				});

				this.$document.scrollTopAnimated(0);
				this.$timeout();

			} else if (res && res.cancelInvestment) {
				this.$timeout(() => this.openCancelInvestmentModal(), 475)

			}

		}, () => angular.noop);

	}

	openUpdatePaymentMethodModal() {
		let modalInstance = this.$uibModal.open({
			animation: true,
			component: 'updatePaymentMethodModalComponent',
			resolve: {
				context: () => ({
					project: this.investment.project,
					investment: this.investment
				})
			}
		});

		modalInstance.result
			.then(async res => {
				if (res && res.success) {
					// Refresh the investment
					await this.investment.fetch();

					// Reparse tasks
					this.evaluateTasks();

					// Display success message
					this.Alerts.setLocal({
						type: 'success',
						msg: 'Payment updated successfully'
					});

					// Reset page
					this.$window.scrollTo(0, 0);
					this.$timeout();
				}
			}, () => angular.noop)
	}

	// async sendMessage(msg) {

	// 	if (!this.message.length) {
	// 		return false;
	// 	}

	// 	// Clear message box
	// 	this.message = '';
	// 	// this.resizeMessageBox('messageComposeArea', true);
	// 	// this.currentConvo.lastUpdated = new Date();

	// 	this.sendingMessage = true;

	// 	// Create a new message
	// 	let message = new this.Message({
	// 		from: this.userService.getCurrent(),
	// 		chat: this.chat,
	// 		body: msg,
	// 		investment: this.investment
	// 	});

	// 	// Set permissions the same as chat
	// 	message.setACL(this.chat.getACL());

	// 	// Save the message
	// 	message.save().then(async res => {
	// 		// this.$log.debug('message send:', res.id, res.toJSON());

	// 		// Fetch profile if needed
	// 		await this.Parse.Object.fetchAllIfNeeded([res.sender_profile]);

	// 		this.sendingMessage = false;
	// 		this.$timeout(() => {
	// 			this.messages.push(message);
	// 		}, 10);

	// 	}).catch(err => {
	// 		this.$log.error('failed to send message');
	// 		this.$log.error(err);
	// 		this.sendingMessage = false;
	// 		this.$timeout();
	// 	});

	// }

}
